import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { TRUE } from "sass"

export const wasm3DServiceSlice = createSlice({
  name: "wasm3DService",
  initialState: {
    curArch: "all",
    curView: "front",
    hideRightNavPage: "", // "","start","end"
    runPresetup: "", // "","start","end"
    weSmileUI: true,
    selCaseId: "",
    showTMChart: true,
  },
  reducers: {
    setCurArch(state, action: PayloadAction<string>) {
      state.curArch = action.payload
    },
    setCurView(state, action: PayloadAction<string>) {
      state.curView = action.payload
    },
    setRunPresetup(state, action: PayloadAction<string>) {
      state.runPresetup = action.payload
    },
    setWeSmileUI(state, action: PayloadAction<boolean>) {
      state.weSmileUI = action.payload
    },
    setHideRightNavPage(state, action: PayloadAction<string>) {
      state.hideRightNavPage = action.payload
    },
    setSelCaseID(state, action: PayloadAction<string>) {
      state.selCaseId = action.payload
    },
    setShowTMChart(state, action: PayloadAction<boolean>) {
      state.showTMChart = action.payload
    },
  },
})

export const {
  setCurArch,
  setCurView,
  setRunPresetup,
  setWeSmileUI,
  setHideRightNavPage,
  setSelCaseID,
  setShowTMChart,
} = wasm3DServiceSlice.actions
