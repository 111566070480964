import React, { FC, useEffect, useRef, useState } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormInput, UButton, UText } from "../../../components"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "../../../components/mui.components"
import PasswordIcon from "../../Auth/Login/PasswordIcon"

const ResetPassword: FC<{ setEditPassword: (val: boolean) => void }> = ({
  setEditPassword,
}) => {
  const { t } = useTranslation("common")
  const [showOldPasswordText, setshowOldPasswordText] = useState<boolean>(false)
  const [showNewPasswordText, setshowNewPasswordText] = useState<boolean>(false)
  const [showConfirmPasswordText, setshowConfirmPasswordText] =
    useState<boolean>(false)
  const [passwordVisibility, setPasswordVisibility] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    hasSixChar: false,
    hasSpecialChar: false,
  })
  const [checkboxstate, setCheckboxState] = useState({
    hasSixChar: false,
    hasSpecialChar: false,
  })
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({ mode: "onSubmit" })

  const newPassword = watch("newPassword")

  const onSubmit: SubmitHandler<FieldValues> = (data: any) => {
    console.log("...submited patient form submission", data)
  }

  const validateConfirmPassword = (value: string) => {
    return (
      value === newPassword ||
      t("userProfile.changePassword.confirmPasswordMismatch")
    )
  }

  const checkSpecialChar = (value: string) => {
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|-]/
    return specialCharacterRegex.test(value)
  }

  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormInput
          inputLabel={"Old Password"}
          fieldName={"oldPassword"}
          fieldType={showOldPasswordText ? "text" : "password"}
          rules={{
            required: "userProfile.changePassword.oldPasswordRequired",
          }}
          errors={errors}
          control={control}
          formSxprops={{ mt: 2 }}
          onInputChange={(value, newvalue) => {
            const showOldPassword = newvalue.length > 0

            setPasswordVisibility((prev) => ({
              ...prev,
              showOldPassword,
            }))

            return newvalue
          }}
          endAdornmentIcon={
            passwordVisibility.showOldPassword && (
              <PasswordIcon
                updatePasswordVisible={setshowOldPasswordText}
                isError={!!(errors && errors.password)}
              />
            )
          }
        />
        <FormInput
          inputLabel={"New Password"}
          fieldName={"newPassword"}
          fieldType={showNewPasswordText ? "text" : "password"}
          rules={{
            required: "userProfile.changePassword.newPasswordRequired",
            minLength: 6,
            validate: (value) => {
              return checkSpecialChar(value)
            },
          }}
          errors={errors}
          control={control}
          formSxprops={{ mt: 2 }}
          onBlurChange={() => {
            setCheckboxState((prev) => ({
              ...prev,
              hasSixChar: !passwordVisibility.hasSixChar,
              hasSpecialChar: !passwordVisibility.hasSpecialChar,
            }))
          }}
          onInputChange={(value, newvalue) => {
            const hasSpecialChar = checkSpecialChar(newvalue)
            const hasSixChar = newvalue.length >= 6

            setPasswordVisibility((prev) => ({
              ...prev,
              hasSpecialChar,
              hasSixChar,
              showNewPassword: newvalue.length > 0,
            }))

            return newvalue
          }}
          endAdornmentIcon={
            passwordVisibility.showNewPassword && (
              <PasswordIcon
                updatePasswordVisible={setshowNewPasswordText}
                isError={!!(errors && errors.password)}
              />
            )
          }
        />
        {
          <Box sx={{ ml: 2, display: "flex", flexDirection: "column" }}>
            <UText
              variant={"body2"}
              component={"div"}
              sxProp={{ color: "text.secondary", mt: 2 }}
            >
              {t("userProfile.changePassword.passwordRrequirements")}
            </UText>
            <Grid sx={{ ml: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={passwordVisibility.hasSixChar}
                    color="success"
                    sx={{
                      height: "38px",
                      width: "38px",
                      color:
                        newPassword &&
                        newPassword.length &&
                        checkboxstate.hasSixChar
                          ? "error.main"
                          : "",
                    }}
                  />
                }
                label={
                  <UText
                    variant={"body2"}
                    sxProp={{
                      pt: 1,
                      color:
                        newPassword &&
                        newPassword.length &&
                        checkboxstate.hasSixChar
                          ? "error.main"
                          : "text.primary",
                    }}
                  >
                    {t("userProfile.changePassword.minSixCharacterRequired")}
                  </UText>
                }
              />
            </Grid>
            <Grid sx={{ ml: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={passwordVisibility.hasSpecialChar}
                    color="success"
                    sx={{
                      height: "38px",
                      width: "38px",
                      color:
                        newPassword &&
                        newPassword.length &&
                        checkboxstate.hasSpecialChar
                          ? "error.main"
                          : "",
                    }}
                  />
                }
                label={
                  <UText
                    variant={"body2"}
                    sxProp={{
                      pt: 1,
                      color:
                        newPassword &&
                        newPassword.length &&
                        checkboxstate.hasSpecialChar
                          ? "error.main"
                          : "text.primary",
                    }}
                  >
                    {t("userProfile.changePassword.specialCharacterRequired")}
                  </UText>
                }
              />
            </Grid>
          </Box>
        }
        <FormInput
          inputLabel={t("userProfile.changePassword.confirmPassword")}
          fieldName={"ConfirmPassword"}
          fieldType={showConfirmPasswordText ? "text" : "password"}
          rules={{
            required: "userProfile.changePassword.confirmPasswrdRequired",
            validate: (value) => {
              return validateConfirmPassword(value) || ""
            },
          }}
          errors={errors}
          control={control}
          formSxprops={{ mt: 2 }}
          onInputChange={(value, newvalue) => {
            const showConfirmPassword = newvalue.length > 0
            setPasswordVisibility((prev) => ({
              ...prev,
              showConfirmPassword,
            }))
            return newvalue
          }}
          endAdornmentIcon={
            passwordVisibility.showConfirmPassword && (
              <PasswordIcon
                updatePasswordVisible={setshowConfirmPasswordText}
                isError={!!(errors && errors.password)}
              />
            )
          }
        />
      </Box>
      <Box sx={{ display: "flex", gap: "8px", mt: 2 }}>
        <UButton
          variant={"outlined"}
          btnText={t("button.cancel")}
          key={"yes"}
          sxProp={{ minWidth: 58, height: 36 }}
          onClickHandler={() => {
            console.log("cancel")
            setEditPassword(false)
          }}
        />
        <UButton
          variant={"contained"}
          btnText={t("button.save")}
          key={"no"}
          btnType="submit"
          sxProp={{ minWidth: 54, height: 36 }}
        />
      </Box>
    </Box>
  )
}

export default ResetPassword
