import * as React from "react"
import { useState, Children, Fragment } from "react"
import {
  Button,
  Grid,
  Slider,
  styled,
  Typography,
  Card,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { Tab, Tabs, Box } from "@mui/material"
import { changeLanguage } from "i18next"
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Height } from "@mui/icons-material"

export const FlowButton = (props) => {
  return (
    <Button
      {...props}
      sx={{
        width: "100%",
        textTransform: "none",
        fontSize: 12,
      }}
      disabled={props.disabled}
      variant="outlined"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export const FlowButton2 = (props) => {
  return (
    <Button
      sx={{
        width: "100%",
        textTransform: "none",
        fontSize: 12,
      }}
      disabled={props.disabled}
      variant="outlined"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export const FlowButton3 = (props) => {
  return (
    <Button
      sx={{
        width: "100%",
        textTransform: "none",
        fontSize: 12,
      }}
      disabled={props.disabled}
      variant="outlined"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export const FlowNameSliderGrid = (props) => {
  return (
    <Grid container spacing={1} alignItems="center">
      {props.params.map((param, index) => (
        <Fragment key={index}>
          <Grid item xs={2.5}>
            <Typography variant="body2">{param.title}</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="body2">
              {" "}
              {(param.lableValue as number).toFixed(2)}
              {param.unit}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Slider
              key={param.variable}
              size="small"
              value={param.sliderValue as number}
              disabled={props.disabled}
              onChange={(e, value) => {
                props.onChangeFunc(value as number, param.variable as string)
              }}
              onChangeCommitted={(e, value) => {
                props.onChangeCommittedFunc(
                  value as number,
                  param.variable as string,
                )
              }}
            />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}

export const ExpandBox = styled(Box)({
  width: "100%",
  // boxShadow: '0.2px gray',
  // paddingTop: '10px',
})

export interface btnGroupType {
  title: string
  btnGroupNames: string[]
  disabled: boolean
  onClick: (name: string) => void
}
export interface sliderType {
  title: string
  variable: string
  value: number
  lable: number
  unit: string
  disabled: boolean
  onChange: (value: number) => void
  onChangeCommitted: (value: number) => void
}
export const DrawExpandButtonGroup = (props: btnGroupType) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="start"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={3}>
        <Typography variant="body2">{props.title}</Typography>
      </Grid>
      {props.btnGroupNames.map((btnName) => {
        return (
          <Grid item xs={3} key={btnName}>
            <FlowButton
              variant="outlined"
              size="small"
              disabled={props.disabled}
              onClick={() => {
                props.onClick(btnName)
              }}
            >
              {btnName}
            </FlowButton>
          </Grid>
        )
      })}
    </Grid>
  )
}

export const FlowTabs = (props, labels: string[]) => {
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    if (value === index)
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          <Box>{children}</Box>
        </div>
      )
    else return <></>
  }
  return (
    <>
      <Box>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {props.labels.map((label, index) => (
            <Tab sx={{ textTransform: "none" }} key={label} label={label} />
          ))}
        </Tabs>
      </Box>
      {Children.map(props.children, (child, index) => (
        <TabPanel value={tabValue} key={index} index={index}>
          {child}
        </TabPanel>
      ))}
    </>
  )
}
export const FlowAccordion = (props) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{props.label}</Typography>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  )
}

export const FlowBtnGroup = (props) => {
  return (
    <Grid container spacing={0.5} alignItems="center">
      {props.label ? (
        <Grid item xs={12}>
          <Typography variant="body1">{props.label}</Typography>
        </Grid>
      ) : (
        <></>
      )}
      {props.lineLabel ? (
        <Grid item xs={props.btnW ? props.btnW : 3}>
          <Typography variant="body2">{props.lineLabel}</Typography>
        </Grid>
      ) : (
        <></>
      )}
      {props.btnGroup.map((btn, index) => (
        <Grid key={index} item xs={props.btnW ? props.btnW : 3}>
          <FlowButton
            disabled={props.disabled}
            onClick={() => {
              props.onButtonClick(btn.name)
            }}
          >
            {btn.label}
          </FlowButton>
        </Grid>
      ))}
      {props.children}
    </Grid>
  )
}

export const FlowToggleButton = styled(ToggleButton)({
  textTransform: "none",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    color: "black",
    backgroundColor: "lightskyblue",
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "lightskyblue",
  },
})
