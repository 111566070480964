import { ChangeEvent, FC, useEffect, useState } from "react"
import InfoIcon from "@mui/icons-material/Info"
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material"

import { useAppDispatch } from "../../../core/app/hooks"
import { editClinicalSetting } from "../../../core/app/slices/patients"
import { CENETER_ROW_ALIGN } from "../../../theme/theme.util"
import { UText } from "../../../ui-component"
import FdiSvg from "../assets/fdi.svg"
import PalmerSvg from "../assets/palmer.svg"
import UniversalSvg from "../assets/universal.svg"

interface IClinicalSetting {
  numbering_system: string
  anterior: string
  posterior: string
  distance: string
  stage_type: string
  stage_number?: string
}

const TooltipContent: FC<{ img: string }> = ({ img }) => {
  return (
    <Box
      component={"img"}
      src={img}
      sx={{ height: "254px", width: "210px" }}
      alt={"tooltip"}
    />
  )
}

const NumberingRadioLabelContent: FC<{ label: string; img: string }> = ({
  label,
  img,
}) => {
  const UTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      sx={{
        padding: "1px",
      }}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(97, 97, 97, 0.9)",
      padding: "8px 8px 2px ",
    },
  }))

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <UTooltip title={<TooltipContent img={img} />} placement="right" arrow>
        <IconButton>
          <InfoIcon
            fontSize={"small"}
            sx={{
              color: (theme) => theme.palette.text.disabled,
              width: "24px",
              height: "20px",
            }}
          />
        </IconButton>
      </UTooltip>
    </Box>
  )
}

const ClinicalSettings: FC = () => {
  const [clinicalSetting, setClinicalSetting] = useState<IClinicalSetting>(
    {} as IClinicalSetting,
  )
  const dispatch = useAppDispatch()
  const [stageValue, setStageValue] = useState("")
  const [imgName, setImgName] = useState("")
  const radioOptions = {
    numbering_system: [
      { value: "palmer", label: "Palmer", img: PalmerSvg },
      { value: "universal", label: "Universal", img: UniversalSvg },
      { value: "fdi", label: "FDI", img: FdiSvg },
    ],
    anterior: [
      { value: "smooth", label: "Smooth", id: "0" },
      { value: "semi_scallop", label: "Semi Scallop", id: "1" },
    ],
    posterior: [
      { value: "smooth", label: "Smooth", id: "0" },
      { value: "semi_scallop", label: "Semi Scallop", id: "1" },
    ],
    distance: [
      { value: "no_offset", label: "No Offset", id: "0" },
      { value: "0.75mm", label: "0.75 mm", id: "1" },
    ],
  }

  useEffect(() => {
    setClinicalSetting({
      numbering_system: "palmer",
      anterior: "semi_scallop",
      posterior: "semi_scallop",
      distance: "no_offset",
      stage_type: "at_beginning_of_treatment",
    })
  }, [])

  useEffect(() => {
    //TODO: once API ready  will update the functionality
    console.log("....update API", clinicalSetting)
    dispatch(editClinicalSetting({ payload: clinicalSetting }))
  }, [clinicalSetting])

  useEffect(() => {
    const getId = (
      options: Array<{ value: string; id: string; label: string }>,
      value: string,
    ) => {
      const obj = options.find((option) => option.value === value)
      return obj ? obj.id : ""
    }

    const imgName =
      getId(radioOptions.anterior, clinicalSetting.anterior) +
      getId(radioOptions.posterior, clinicalSetting.posterior) +
      getId(radioOptions.distance, clinicalSetting.distance)

    import(`../assets/${imgName}.png`)
      .then((image) => {
        setImgName(image.default)
      })
      .catch((error) => {
        console.error("Error loading image:", error)
      })
  }, [
    clinicalSetting.anterior,
    clinicalSetting.posterior,
    clinicalSetting.distance,
  ])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    console.log("....target...", target.name, target.value)
    if (target.name === "stage_number") {
      setStageValue(target.value)
    }
    const obj: IClinicalSetting = { ...clinicalSetting }
    obj[target.name] = target.value
    setClinicalSetting(obj)
  }

  return (
    <Box sx={{ width: "640px", p: 4 }}>
      <Box sx={{ ...CENETER_ROW_ALIGN, justifyContent: "center", pb: "16px" }}>
        <UText variant={"h4"}>{"Clinical Settings"}</UText>
      </Box>
      <Box>
        <Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <UText variant={"h6"} sxProp={{ color: "#000000" }}>
              {"Numbering System"}
            </UText>
            <FormControl>
              <RadioGroup
                aria-labelledby="numbering_system_label"
                name="numbering_system"
                sx={{ px: 1 }}
                value={clinicalSetting["numbering_system"] || ""}
                onChange={handleChange}
              >
                {radioOptions.numbering_system.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <NumberingRadioLabelContent
                        label={option.label}
                        img={option.img}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <UText variant={"h6"} sxProp={{ color: "#000000", pt: 1 }}>
              {"uSmile Clear Aligner Trim Line Preferences"}
            </UText>
            <UText variant={"body1"} sxProp={{ color: "#000000", my: 1 }}>
              {"Trim Line Curvature Control"}
            </UText>
            <FormControl>
              <FormLabel
                id={"anterior_label"}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {"Anterior (Labial Canine-Canine)"}
              </FormLabel>
              <RadioGroup
                aria-labelledby="anterior_label"
                name="anterior"
                sx={{ px: 1 }}
                value={clinicalSetting["anterior"] || ""}
                onChange={handleChange}
              >
                {radioOptions.anterior.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <FormControl>
              <FormLabel
                id={"posterior_label"}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {"Posterior (Buccal First Premolar-Terminal Molar)"}
              </FormLabel>
              <RadioGroup
                aria-labelledby="posterior_label"
                name="posterior"
                value={clinicalSetting["posterior"] || ""}
                onChange={handleChange}
                sx={{ px: 1 }}
              >
                {radioOptions.posterior.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <UText variant={"body1"} sxProp={{ color: "#000000", mb: "4px" }}>
              {"Trim Line Level"}
            </UText>
            <FormControl>
              <FormLabel
                id={"distance_label"}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: "14px",
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {"Distance between gum line and trim line"}
              </FormLabel>
              <RadioGroup
                aria-labelledby="distance_label"
                name="distance"
                sx={{ px: 1 }}
                value={clinicalSetting["distance"] || ""}
                onChange={handleChange}
              >
                {radioOptions.distance.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Box
              component={"img"}
              src={imgName}
              alt={"Trim Line Level"}
              sx={{ height: "286px", alignSelf: "stretch", mb: 1 }}
            />
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <UText variant={"h6"} sxProp={{ color: "#000000", mb: "4px" }}>
              {"Attachment Stage"}
            </UText>
            <FormControl>
              <FormLabel
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {"Default Stage to put on Attachments"}
              </FormLabel>
              <RadioGroup
                aria-labelledby="stage_attachments_label"
                name="stage_type"
                value={clinicalSetting["stage_type"] || ""}
                onChange={handleChange}
                sx={{ px: 1 }}
              >
                <FormControlLabel
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                  }}
                  value={"at_beginning_of_treatment"}
                  control={<Radio />}
                  label="At beginning of treatment"
                />
                <FormControlLabel
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                  }}
                  value={"at_stage"}
                  control={<Radio />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {"At stage"}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size={"small"}
                        value={stageValue}
                        name={"stage_number"}
                        onChange={handleChange}
                        sx={{
                          mx: 1,
                          width: "80px",
                        }}
                      />
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClinicalSettings
