import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import { Box, Container, Grid, Paper } from "../../../components/mui.components"
import PatientInfoHeader from "../../../containers/LandingLayout/PatientInfoHeader/PatientInfoHeader"
import { UButton, UText } from "../../../ui-component"

const Confirmation: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { caseId } = useParams()

  return (
    <>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        minWidth={"lg"}
      >
        <Grid container maxWidth={"lg"} sx={{ alignContent: "flex-start" }}>
          <PatientInfoHeader
            chipLabel={"Submitted to uAssist"}
            cancelBtnText={"button.close"}
            onBtnCancelClick={() => {
              navigate("/patients")
            }}
            chipSx={{
              background: "transparent",
              fontSize: "13px",
              fontWeight: "400px",
              color: "info.main",
              border: "1px solid rgba(2, 136, 209, 1)",
            }}
          />
          <Container sx={{ my: 4, width: "600px" }}>
            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
              <Box sx={{ py: 1, mb: 2 }}>
                <UText variant={"h5"}>
                  {t("summary.confirmation.thanksMsg")}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: 2,
                }}
              >
                <Box sx={{ mr: 1 }}>
                  <CheckCircleIcon color={"success"} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <UText variant={"subtitle1"}>
                    {t("summary.confirmation.submitted")}
                  </UText>
                  <UText variant={"body1"}>
                    <UButton
                      btnText={`Case #${caseId}`}
                      data-testId={"InputField-id"}
                      onClickHandler={() => console.log("test")} // TODO during integration
                      variant={"text"}
                      sxProp={{
                        paddingLeft: "0px",
                        textDecoration: "underline",
                        textDecorationColor: "rgb(33, 94, 205, .4)",
                        textTransform: "none",
                        fontSize: "16px",
                        height: "24px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          textDecoration: "underline",
                        },
                      }}
                    />
                  </UText>
                </Box>
              </Box>
              <Box sx={{ mb: 2 }}>
                <UText variant={"body2"}>
                  {t("summary.confirmation.notes")}
                </UText>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end", my: 1 }}>
                <UButton
                  sxProp={{ width: "172px", height: "36px" }}
                  variant={"contained"}
                  btnType={"submit"}
                  btnText={t("button.gotoPatientlist")}
                />
              </Box>
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </>
  )
}

export default Confirmation
