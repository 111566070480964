interface IButtonInfo {
  name: string;
  func: () => void;
}

export function alertPopup(
  type: "info" | "warning" | "error",
  text: string,
  btns?: IButtonInfo[]
) {
  const btnList: any[] = [];
  if (btns) {
    for (const b of btns) {
      btnList.push([b.name, b.func]);
    }
  }
  // if no button setting ,setup a default
  if (btnList.length === 0) {
    btnList.push(["ok", () => {}]);
  }

  // getDvaApp()._store.dispatch({
  //   type: 'alert/setAlert',
  //   payload: {
  //     isAlert: true,
  //     method: type,
  //     text,
  //     btnList,
  //   },
  // });
}

export function modelPopup(
  type: "info" | "warning" | "error",
  text: string,
  btns: IButtonInfo[]
) {
  const btnList: any[] = [];
  for (const b of btns) {
    btnList.push([b.name, b.func]);
  }
  // getDvaApp()._store.dispatch({
  //   type: 'alert/setAlert',
  //   payload: {
  //     isAlert: true,
  //     method: type,
  //     text,
  //     btnList,
  //   },
  // });
}
