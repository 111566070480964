import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  deleteUploadedFiles,
  downloadXrayFiles,
  uploadXrayFiles,
} from "./xray.service"
import { fileDownloadParams, fileUploadParams } from "./xrays.type"

export const uploadXray = createAsyncThunk(
  "XrayService/uploadXray",
  async (
    {
      patientId,
      caseId,
      formData,
      fileName,
      onFileProcesscallback,
    }: fileUploadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadXrayFiles(
        orgId,
        patientId,
        caseId,
        fileName,
        formData,
        onFileProcesscallback,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadXray = createAsyncThunk(
  "XrayService/downloadXray",
  async (
    { patientId, caseId, fileName }: fileDownloadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadXrayFiles(orgId, patientId, caseId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteFiles = createAsyncThunk(
  "XrayService/deleteFiles",
  async (
    { patientId, caseId, fileName, fileType, version }: fileUploadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await deleteUploadedFiles(
        orgId,
        patientId,
        caseId,
        fileName,
        fileType,
        version,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
