import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import ToolButton from "../ToolBar/toolButton"

import { rightTmp } from "./column"

import "./rightPanel.scss"

const RightPanel: FC<{ isReady: boolean }> = ({ isReady }) => {
  const { t } = useTranslation("common")
  const [curBtn, setCurBtn] = useState("none")

  const rightClick = (item: string) => {
    if (curBtn == item) {
      setCurBtn("none")
      props.openPanel("none")
    } else {
      setCurBtn(item)
      props.openPanel(item)
    }
  }

  return (
    <Box className={"rightBar"}>
      {rightTmp.map((i) => {
        return (
          <ToolButton
            disabled={!isReady}
            disabledBg={i.disabledSrc}
            placement={"left"}
            title={i.title}
            key={i.key}
            val={i.key}
            bg={i.src}
            isSelected={i.key == curBtn}
            isFocused={i.key === "vc"}
            callBack={() => {
              rightClick(i.key)
            }}
          />
        )
      })}
    </Box>
  )
}
export default RightPanel
