import { Fragment, useEffect, useState } from "react"
import {
  Typography,
  Box,
  Stack,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import { Dialog, DialogContent, DialogContentText } from "@mui/material"
import {
  FlowButton,
  FlowBtnGroup,
  FlowToggleButton,
} from "../../Custom/FlowCustom"
import React from "react"
import { gdsPlusSetup, OJOBType, SetupType } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"

import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { toothMovement } from "@ulab/wesmileclinical"
import { setNewState, setIsShow } from "../../CommonPages/ToothInfoSlice"

const DrawNewSetup = (props) => {
  const { anterior, archform, disabled, selectedTooth } = useAppSelector(
    (state: RootState) => state.autoSetupSlice,
  )
  const { OJ, OB, LL, MidlineUpper, MidlineLower } = anterior
  const anteriorSliders = [OJ, OB, LL, MidlineUpper, MidlineLower]
  const [openDlg, setOpenDlg] = React.useState(false)
  const [dlgMsg, setDlsMsg] = React.useState("")
  const { isShow, data, toothId } = useAppSelector(
    (state: RootState) => state.toothInfoSlice,
  ) // tooth info data
  const dispatch = useAppDispatch()

  const closeDlg = () => {
    setOpenDlg(false)
  }

  const newByType = (type: SetupType) => {
    setDlsMsg("Please wait, auto setup is running...")
    setOpenDlg(true)
    const timer = setTimeout(() => {
      gdsPlusSetup.setupByType(type, () => {
        setOpenDlg(false)
        console.log("Auto setup the case ...")
        const payload = false
        dispatch({ type: "autosetup/setDisable", payload })
        dispatch({ type: "autosetup/updateState" })
        dispatch({ type: "autostaging/updateState" })
      })
    }, 100) // wait 100ms so the dialog is shown.
  }

  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "Ant00":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.Improve)
        break
      case "Ant05":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.HalfMmSpace)
        break
      case "AutoAP":
        gdsPlusSetup.getAutoSetupModule().SetBestAP()
        break
      case "CloseSpace":
        gdsPlusSetup.ResolveAllColl(() => {
          console.log("ResolveAllColl finished!")
        })
        break
      case "RmvContact":
        gdsPlusSetup.ResolveOccColl(true, false, () => {
          console.log("ResolveOccColl finished!")
        })
        break
      case "ExtractTooth":
        gdsPlusSetup.ExtractTooth()
        break
      case "RestoreAllTeeth":
        gdsPlusSetup.RestoreAllTeeth()
        break
      case "Full":
        newByType(SetupType.Full)
        break
      case "Simple":
        newByType(SetupType.Simple)
        break
      case "Anterior":
        newByType(SetupType.Anterior)
        break
      case "Ant3x3":
        newByType(SetupType.Ant3x3)
        break
      case "Alignment":
        newByType(SetupType.AlignmentOnly)
        break
      case "Init":
        newByType(SetupType.Init)
        break
      case "Lite":
        newByType(SetupType.COPAModerate)
        break
      case "FineTune":
        newByType(SetupType.FineTune)
    }
  }

  const newTypeBtns = [
    { label: "Full (7x7)", name: "Full" },
    { label: "Molar (6x6)", name: "Simple" },
    { label: "Anterior (5x5)", name: "Anterior" },
    { label: "Anterior (3x3)", name: "Ant3x3" },
    { label: "Alignment Only", name: "Alignment" },
    { label: "Init", name: "Init" },
    { label: "Fine Tune", name: "FineTune" },
  ]
  const extractionBtns = [
    { label: "Extract", name: "Full" },
    { label: "Restore All", name: "Simple" },
  ]
  const treatArchBtns = [
    { label: "Both", name: "ArchBoth" },
    { label: "Upper", name: "ArchUpperOnly" },
    { label: "Lower", name: "ArchLowerOnly" },
  ]
  const onSelToothFixedChange = () => {
    let newFixed = !selectedTooth.isFixed
    gdsPlusSetup.SetToothFixed(selectedTooth.ID, newFixed)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
    dispatch({ type: "autosetup/updateSelTooth", payload: selectedTooth.ID })
  }

  useEffect(() => {
    console.log("selected tooth changed :")
    dispatch({ type: "autosetup/updateSelTooth", payload: toothId })
    dispatch({ type: "autosetup/updateState" })
  }, [isShow, toothId, data])

  const DrawSelTooth = () => {
    let labelWithId = "Selected Tooth: " + selectedTooth.ID

    return (
      <FlowBtnGroup
        disabled={disabled}
        label={labelWithId}
        btnGroup={extractionBtns}
        onButtonClick={onButtonClick}
      >
        <Grid item xs={6}>
          <FormControlLabel
            disabled={disabled || selectedTooth.ID <= 0}
            control={
              <Checkbox
                checked={selectedTooth.isFixed}
                onChange={onSelToothFixedChange}
                size="small"
              />
            }
            label="Fixed(Not Move)"
          />
        </Grid>
      </FlowBtnGroup>
    )
  }

  return (
    <Fragment>
      <Stack direction="column" spacing={1}>
        <FlowBtnGroup
          disabled={false}
          label="Treatment Type"
          btnGroup={newTypeBtns}
          onButtonClick={onButtonClick}
        />
        <Divider />
        <DrawSelTooth />
        <Divider />
        <FlowBtnGroup
          disabled={disabled}
          label="Treat Arch"
          btnGroup={treatArchBtns}
          onButtonClick={onButtonClick}
        />
        <Divider />
      </Stack>
      <Dialog open={openDlg} onClose={closeDlg}>
        <DialogContent>
          <Box component="img" sx={{ height: 120 }} alt="Running ..." />
          <DialogContentText id="alert-dialog-description">
            {dlgMsg}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default DrawNewSetup
/*
function mapStateToProps(state) {
  const { anterior, rootTorque, disabled } = state.autosetup
  return { anterior, rootTorque, disabled }
}
export default connect(mapStateToProps)(DrawNewSetup) */
