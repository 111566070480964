import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, Tab, Tabs } from "@mui/material"

import fill from "../../../../assets/svgs/fill.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import {
  downloadPhotographs,
  fetchFilesList,
} from "../../../../core/app/slices/records/photograph"
import {
  FileItem,
  PhotoObject,
} from "../../../../core/app/slices/records/photograph/photograph.type"
import { setDownloadStatus } from "../../../../core/app/slices/records/photograph/photographSlice"
import { downloadXray } from "../../../../core/app/slices/records/xrays"
import { RootState } from "../../../../core/app/store"
import { UText } from "../../../../ui-component"

const photosOrder = {
  FRONT: "Front",
  PROFILE: "Profile",
  SMILE: "Smile",
  UPPEROCCLUSAL: "Upper occlusal",
  RIGHT: "Right",
  ANTERIOR: "Anterior",
  LEFT: "Left",
  LOWEROCCULUSAL: "Lower occlusal",
}

const photosOrderMap = new Map(Object.entries(photosOrder))

export const getPhotoTitle = (photo: PhotoObject) => {
  if (!photo) return ""

  if (photo.position && photosOrderMap.has(photo.position))
    return photosOrderMap.get(photo.position)

  if (photo.fileName.includes("composite")) return "Composite"
  if (photo.fileName.includes("pano")) return "Pano"
  if (photo.fileName.includes("ceph")) return "Ceph"

  return ""
}

const Records: FC<{
  openFill: (arg: { key: string; value: number }) => void
}> = ({ openFill }) => {
  const [selectedRecord, setselectedRecord] = useState(0)

  const { fileList, compositePhoto, individualPhotos, additionalPhotos } =
    useAppSelector((state: RootState) => state.PhotosService)

  const { pano, ceph, otherXrays } = useAppSelector(
    (state: RootState) => state.xrayService,
  )

  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }
  const [recordsList, setrecordsList] = useState<PhotoObject[]>([])
  const [fillId, setFillId] = useState<number>()

  useEffect(() => {
    if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])

  const downloadXrays = () => {
    let downloadTotal = 0
    const totalXrays = fileList.filter(
      (item) =>
        item.attachment_type === "radiograph" &&
        (item.file_name.includes("pano") ||
          item.file_name.includes("ceph") ||
          item.file_name.includes("others")),
    ).length
    const downloadFiles = (item: FileItem, type: string | undefined) => {
      const { file_name, original_file_name } = item
      dispatch(setDownloadStatus("pending"))

      dispatch(
        downloadXray({
          patientId: patientId,
          caseId: caseId,
          fileName: file_name,
          XrayType: type,
          original_file_name: original_file_name,
        }),
      ).then((res) => {
        downloadTotal++
        if (downloadTotal === totalXrays) {
          dispatch(setDownloadStatus("succeeded"))
        }
      })
    }

    const photographTypeMap = {
      pano: "pano",
      ceph: "ceph",
      others: "others",
    }
    if (
      fileList.length > 0 &&
      !ceph.length &&
      !pano.length &&
      !otherXrays.length
    ) {
      fileList.forEach((result) => {
        const attachmentType = result.attachment_type
        const type = Object.keys(photographTypeMap).find((key) => {
          return (
            result.file_name.includes(key) && attachmentType === "radiograph"
          )
        })
        console.log("type", type)
        if (result.attachment_type === "radiograph") {
          downloadFiles(result, type)
        }
      })
    }
  }

  const downloadPhotos = () => {
    if (
      fileList.length &&
      !compositePhoto.length &&
      !individualPhotos.length &&
      !additionalPhotos.length
    ) {
      let countOthers = 0
      let countComposite = 0
      let countIndividual = 0

      fileList.forEach((file) => {
        if (
          file.file_name.includes("others") &&
          file.attachment_type === "photographs"
        ) {
          countOthers++
        } else if (file.file_name.includes("composite")) {
          countComposite++
        } else if (file.file_name.includes("individual")) {
          countIndividual++
        }
      })

      let total = 0
      let downloadTotal = 0
      countComposite > 0
        ? (total = countComposite + countOthers)
        : (total = countIndividual + countOthers)
      fileList.map((file) => {
        if (file.attachment_type === "photographs") {
          dispatch(setDownloadStatus("pending"))
          dispatch(
            downloadPhotographs({
              patientId: patientId,
              caseId: caseId,
              fileName: file.file_name,
              photographType: file.file_name.includes("composite")
                ? "composite"
                : file.file_name.includes("individual")
                ? "individual"
                : "others",
              original_file_name: file.original_file_name,
              sequence: file.sequence,
              position: file.photo_position,
            }),
          ).then((res) => {
            downloadTotal++
            if (downloadTotal === total) {
              dispatch(setDownloadStatus("succeeded"))
            }
          })
        }
      })
    }
  }

  useEffect(() => {
    selectedRecord === 0 ? downloadPhotos() : downloadXrays()
  }, [fileList, selectedRecord])

  useEffect(() => {
    if (selectedRecord === 0) {
      if (compositePhoto.length > 0) {
        setrecordsList([...compositePhoto, ...additionalPhotos])
      } else {
        setrecordsList([...individualPhotos, ...additionalPhotos])
      }
    } else {
      setrecordsList([...pano, ...ceph, ...otherXrays])
    }
  }, [
    selectedRecord,
    compositePhoto,
    individualPhotos,
    additionalPhotos,
    pano,
    ceph,
    otherXrays,
  ])

  return (
    <>
      <Tabs
        id="record-title"
        value={selectedRecord}
        onChange={(e, newvalue) => {
          setselectedRecord(newvalue)
        }}
        sx={{ height: 42, width: "100%" }}
      >
        <Tab label="Photographs" {...a11yProps(0)} />
        <Tab label="X-rays" {...a11yProps(1)} />
      </Tabs>

      <Box
        sx={{
          width: "100%",
          padding: "0 16px",
          overflow: "auto",
          flex: "1 1 auto",
          "::-webkit-scrollbar": { display: "none" },
          height: 0,
        }}
      >
        {recordsList.map((photo, index) => {
          return (
            <Box
              key={index}
              sx={{ display: "flex", flexDirection: "column", padding: 2 }}
            >
              <UText
                variant={"caption"}
                sxProp={{
                  width: "100%",
                  height: 24,
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: "0.15px",
                  marginBottom: "10px",
                }}
              >
                {getPhotoTitle(photo)}
              </UText>
              <Box
                sx={{ position: "relative", width: 324, height: 328 }}
                onMouseEnter={() => {
                  setFillId(index)
                }}
                onMouseLeave={() => {
                  setFillId(undefined)
                }}
              >
                <img
                  alt={""}
                  src={photo.src}
                  style={{
                    width: 324,
                    height: 328,
                    objectFit: "contain",
                    borderRadius: "4px",
                    border: " 1px solid rgba(144, 202, 249, 0.50",
                  }}
                />
                {fillId === index && (
                  <SvgBtn
                    svg={fill}
                    boxSx={{
                      position: "absolute",
                      top: 8,
                      right: 32,
                      "&:hover": { backgroundColor: "rgba(0,0,0, 0.08)" },
                      "&:focus": { backgroundColor: "rgba(0,0,0, 0.12)" },
                    }}
                    clickFun={() => {
                      openFill({
                        key: selectedRecord === 0 ? "photo" : "xray",
                        value: index,
                      })
                    }}
                  ></SvgBtn>
                )}
              </Box>
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default Records
