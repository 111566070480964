import { resourcesSynchronization } from "../resourcemanager/resourcessynchronization";
import {
  wasmModule,
  WASMLifeCircleEventType,
} from "../../modules/wasm/wasmModule";

import { EShowArchType } from "../../common/types";

/**
 *  WASM interface of preview
 *
 * enable preview
 * this will invoke a loop can't do something after invoke this function
 * wasmModule.module._scanPreview();
 *
 * set zoom callback
 * wasmModule.module.getScanPreviewWin().SetUpdateSliderCB
 *
 * set zoom
 * wasmModule.module.getScanPreviewWin().ZoomBySlider(scale)
 *
 * set range of zoom scale
 * wasmModule.module.getScanPreviewWin().SetMinScaleValue(double minScaleValue)
 * wasmModule.module.getScanPreviewWin().SetMaxScaleValue(double maxScaleValue)
 *
 * change arch
 * wasmModule.module.getScanPreviewWin().OnArchChanged(type)
 *
 * drwa arch
 * wasmModule.module.getScanPreviewWin().UpdateArch('upper arch path', 'lower arch path')
 * wasmModule.module.getScanPreviewWin().RemoveArch(isupper:boolean,islower:boolean);
 *
 * fill hole
 * wasmModule.module._autoFillHoles(wasmModule.module.allocateUTF8('test/pre/arch_u.stl'), wasmModule.module.allocateUTF8('test/pre/fillready_arch_u.stl'))
 *
 *
 */

interface IDrawSTLOps {
  upperArch: File | null;
  lowerArch: File | null;
  canvas: HTMLCanvasElement;
  zoomRange: [number, number];
  fillholeCallback?: (isSuccess: boolean, isUpperFlag: "up" | "low") => void;
}

/**
 * draw STL when upload file
 */
let currentShowSTLUpper: Blob | null = null;
let currentShowSTLLower: Blob | null = null;
let stlFilePath: Record<string, any> = {};
let isEnabled = false;

export function isInitPreview() {
  return isEnabled;
}

export async function drawMtcFromZips(
  zips: Record<string, File>,
  zoomRange: [number, number] = [0.25, 2.0]
) {
  if (!isEnabled) return;

  console.log("DrawMtcFromZips :", zips, zoomRange);
  await resourcesSynchronization.writeCaseFilesToVirtualFS(zips);
  wasmModule.module
    .getScanPreviewWin()
    .UpdateArch("test/case/Raw/arch_o_u.mtc", "");

  wasmModule.module
    .getScanPreviewWin()
    .UpdateArch("", "test/case/Raw/arch_o_l.mtc");

  const mtcFiles = resourcesSynchronization.getRawMtcs();
  // console.log("mtcFiles ",mtcFiles);

  if (wasmModule.module.getScanPreviewWin) {
    wasmModule.module.getScanPreviewWin().SetMinScaleValue(zoomRange[0]);
    wasmModule.module.getScanPreviewWin().SetMaxScaleValue(zoomRange[1]);
  }

  return mtcFiles;
}

function getFileExtension(file: File) {
  // 使用split()方法将文件名拆分成名称和后缀
  const parts = file.name.split(".");
  const extension = parts[parts.length - 1].toLowerCase();
  console.log(extension); // 输出：txt
  return extension;
}

// 让drawSTL可重入
let isInitingPreview = false;

export async function drawSTL(drawSTLOps: IDrawSTLOps) {
  if (!isEnabled) {
    if (isInitingPreview) {
      return;
    }
    if (!isInitingPreview) {
      isInitingPreview = true;
    }
    await wasmModule.initWASM(drawSTLOps.canvas, async () => {
      wasmModule.cancelemloop();
      wasmModule.module.setPreviewColor(0.27, 0.35, 0.39, 0.27, 0.35, 0.39);
      // this will invoke a loop can't do something after invoke this function
      setTimeout(() => {
        wasmModule.module._scanPreview();
      }, 100);
      isEnabled = true;
      isInitingPreview = false;
    });
    return;
  }

  if (!wasmModule.module.getScanPreviewWin()) {
    return;
  }

  if (wasmModule.module.getScanPreviewWin) {
    wasmModule.module
      .getScanPreviewWin()
      .SetMinScaleValue(drawSTLOps.zoomRange[0]);
    wasmModule.module
      .getScanPreviewWin()
      .SetMaxScaleValue(drawSTLOps.zoomRange[1]);
  }

  const { upperArch, lowerArch, fillholeCallback } = drawSTLOps;

  if (upperArch !== currentShowSTLUpper) {
    if (upperArch) {
      const extension = getFileExtension(upperArch);

      if (extension == "stl") {
        stlFilePath["test/pre/arch_u.stl"] = upperArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        // fill hole
        const ret = wasmModule.module._autoFillHoles(
          wasmModule.module.allocateUTF8("test/pre/arch_u.stl"),
          wasmModule.module.allocateUTF8("test/pre/fillready_arch_u.stl")
        );
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/pre/fillready_arch_u.stl", "");

        if (!ret) {
          if (fillholeCallback) {
            fillholeCallback(false, "up");
          }
        }
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_u.mtc"] = upperArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/case/Raw/arch_o_u.mtc", "");
      }
      currentShowSTLUpper = upperArch;
    } else if (currentShowSTLUpper) {
      wasmModule.module.getScanPreviewWin().RemoveArch(true, false);
      currentShowSTLUpper = null;
      // const deleteMtcUpper= wasmModule.module.DeleteMtcFiles(0);
      // console.log("deleteMtcUpper :",deleteMtcUpper);
    }
  }

  if (lowerArch !== currentShowSTLLower) {
    if (lowerArch) {
      const extension = getFileExtension(lowerArch);
      if (extension == "stl") {
        stlFilePath["test/pre/arch_l.stl"] = lowerArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        const ret = wasmModule.module._autoFillHoles(
          wasmModule.module.allocateUTF8("test/pre/arch_l.stl"),
          wasmModule.module.allocateUTF8("test/pre/fillready_arch_l.stl")
        );

        if (!ret) {
          if (fillholeCallback) {
            fillholeCallback(false, "low");
          }
        }

        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/pre/fillready_arch_l.stl");
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_l.mtc"] = lowerArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/case/Raw/arch_o_l.mtc");
      }

      currentShowSTLLower = lowerArch;
    } else if (currentShowSTLLower) {
      wasmModule.module.getScanPreviewWin().RemoveArch(false, true);
      currentShowSTLLower = null;
      // const isDeleteMtcLower= wasmModule.module.DeleteMtcFiles(1);
      // console.log('isDeleteMtcLower :',isDeleteMtcLower);
    }
  }

  // create ArchType.json

  let archType;
  if (upperArch && lowerArch) {
    archType = {
      KeepPalate: false,
      downArch: 0,
      upArch: 0,
    };
  } else if (upperArch == null) {
    archType = {
      downArch: 0,
    };
  } else if (lowerArch == null) {
    archType = {
      KeepPalate: false,
      upArch: 0,
    };
  }

  const archTypeJson = JSON.stringify(archType);
  console.log("archTypeJson:", archTypeJson);

  const blob = new Blob([archTypeJson], { type: "text/plain" });
  const file = new File([blob], "case/Setting Data2/ArchType.json");

  // stlFilePath["test/pre/arch_u.stl"] = upperArch;
  const archTypeJsonPath: Record<string, any> = {};
  archTypeJsonPath["test/case/Setting Data2/ArchType.json"] = file;

  await resourcesSynchronization.moduleFS(archTypeJsonPath);
}

export function saveStlToMtc(upperStl: File | null, lowerStl: File | null) {
  if (!upperStl && !lowerStl) {
    console.log("At least one jaw needs to be preserved ");
    wasmModule.module.DeleteMtcFiles(0);
    wasmModule.module.DeleteMtcFiles(1);

    return 0;
  }
  if (upperStl) {
    const ext = getFileExtension(upperStl);
    if (ext == "stl") {
      const isSaveRaw = wasmModule.module.SaveMtcFileToRaw(
        "test/pre/arch_u.stl",
        0
      );
      console.log("isSaveRawUpper :", isSaveRaw);
      const isSaveToSD0 = wasmModule.module.SaveMtcFileToSettingData0(
        "test/pre/fillready_arch_u.stl",
        0
      );
      console.log("isSaveToSD0Upper :", isSaveToSD0);
    }
  } else {
    wasmModule.module.DeleteMtcFiles(0);
  }

  if (lowerStl) {
    const ext = getFileExtension(lowerStl);
    if (ext == "stl") {
      const isSaveRaw = wasmModule.module.SaveMtcFileToRaw(
        "test/pre/arch_l.stl",
        1
      );
      console.log("isSaveRawLower :", isSaveRaw);
      const isSaveToSD0 = wasmModule.module.SaveMtcFileToSettingData0(
        "test/pre/fillready_arch_l.stl",
        1
      );
      console.log("isSaveToSD0Lower :", isSaveToSD0);
    }
  } else {
    wasmModule.module.DeleteMtcFiles(1);
  }

  return 1;
}

/**
 *changeArchMode
 *param name  (1、up.2、low.3、both)
 * */
export function changeArchMode(viewState: "up" | "low" | "both") {
  const type =
    viewState === "up"
      ? EShowArchType.UpArch
      : viewState === "low"
      ? EShowArchType.LowArch
      : EShowArchType.BothArch;
  wasmModule.module.getScanPreviewWin().OnArchChanged(type);
}

/**
 * setup zoom callback
 * @param zoomRange  the [min,max] tuple value of the scale
 * @param callback  (val: number) => void)
 */
export async function setZoomCallback(callback: (val: number) => void) {
  if (!isEnabled) {
    return;
  }
  if (wasmModule.module.getScanPreviewWin && callback) {
    (window as any).preview = {};
    (window as any).preview.preViewCallback = callback;
    if (!wasmModule.module.getScanPreviewWin()) {
      console.warn(
        "module getScanPreviewWin false,cant set update-slider callback."
      );
      return;
    }
    wasmModule.module
      .getScanPreviewWin()
      .SetUpdateSliderCB("preview.preViewCallback");
  }
}

/**
 * zoom with scale
 * @param scale
 */
export function zoomWithValue(scale: number) {
  wasmModule.module.getScanPreviewWin().ZoomBySlider(scale);
  console.log("zoomWithValue:", scale);
}

/**
 * clear preview state after out of preview
 */
export function clearPreview() {
  isEnabled = false;
  currentShowSTLUpper = null;
  currentShowSTLLower = null;
  stlFilePath = {};
  resourcesSynchronization.fsUnlink();
}
