import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Tab } from "@mui/material"
import { pdf } from "@react-pdf/renderer"
import html2canvas from "html2canvas"

import DentalChart from "../../../../components/DentalChart/DentalChart"
import Drag from "../../../../components/Drag/Drag"
import ReportPDF from "../ReportPDF/reportPdf"
import { ScreeshotReport } from "../ReportPDF/screenshotReport"

import Auxilliaries from "./components/Auxilliaries/auxilliaries"
import DentalMenu from "./components/DentalChart/DentalMenu"
import IPR from "./components/IPR/ipr"
import { reportProps } from "./type"

import "./report.scss"

const Report: FC<reportProps> = ({
  reportShow,
  setReportShow,
  toothlist,
  attachment,
  iprData,
}) => {
  const [value, setValue] = useState("IPR")
  const [appliedTeeth, setappliedTeeth] = useState<
    { teeth: string; checked: string }[]
  >([])
  useEffect(() => {
    if (!reportShow) {
      setValue("IPR")
    }
  }, [reportShow])
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const captureScreenshot = () => {
    const auxToCapture = document.getElementById("auxilliaries")
    const iprToCapture = document.getElementById("ipr")

    if (auxToCapture && iprToCapture) {
      Promise.all([
        html2canvas(auxToCapture, { scale: 2 }),
        html2canvas(iprToCapture, { scale: 2 }),
      ]).then(async (res) => {
        const auxCanvas = res[0]
        const iprCanvas = res[1]

        const auximgDataUrl = auxCanvas.toDataURL("image/png")
        const iprimgDataUrl = iprCanvas.toDataURL("image/png")

        const blob = await pdf(
          ReportPDF({
            auxshot: auximgDataUrl,
            iprshot: iprimgDataUrl,
          }),
        ).toBlob()
        const url = URL.createObjectURL(blob)
        window.open(url, "_blank")
      })
    }
  }

  const { t } = useTranslation("common")
  return (
    reportShow && (
      <>
        <Drag
          DragPosition={{
            x: `${
              document.getElementById("left")?.offsetWidth +
              document.getElementById("canvas")?.offsetWidth / 2 -
              305
            }`,
            y: 56,
          }}
        >
          <Box className="ReportBox">
            <TabContext value={value}>
              <Box className={"tabBox"}>
                <TabList
                  onChange={handleChange}
                  aria-label="report tabs"
                  sx={{
                    "& .MuiTab-root": {
                      padding: "9px 16px",
                      fontSize: "14px",
                      fontWeight: "500",
                      minWidth: "unset",
                      maxWidth: "unset",
                      letterSpacing: "0.4px",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  <Tab label="IPR" value="IPR" />
                  <Tab label="ATTACHMENT" value="ATTACHMENT" />
                  <Tab label="AUXILIARIES" value="AUXILIARIES" />
                  <Tab label="DENTAL CHART" value="DENTAL CHART" />
                </TabList>
                <Button
                  style={{
                    display: value === "DENTAL CHART" ? "none" : "unset",
                  }}
                  className={"btn print"}
                  onClick={captureScreenshot}
                />
                <Button
                  className={"btn close"}
                  onClick={() => {
                    setReportShow(false)
                  }}
                />
              </Box>
              <TabPanel className={"iprContent"} value="IPR">
                <IPR toothlist={toothlist} iprData={iprData} />
              </TabPanel>
              <TabPanel value="ATTACHMENT">Item Two</TabPanel>
              <TabPanel value="AUXILIARIES">
                <Auxilliaries toothlist={toothlist} attachment={attachment} />
              </TabPanel>
              <TabPanel
                value="DENTAL CHART"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <DentalMenu></DentalMenu>
                <DentalChart
                  appliedTeeth={appliedTeeth}
                  isReadOnly={true}
                ></DentalChart>
              </TabPanel>
            </TabContext>
          </Box>
        </Drag>
        <ScreeshotReport
          reportShow={reportShow}
          toothlist={toothlist}
          attachment={attachment}
          setReportShow={setReportShow}
          iprData={iprData}
        />
      </>
    )
  )
}
export default Report
