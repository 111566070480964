import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  downloadPhotographFiles,
  fetchFileList,
  updatePhotoPositionAPI,
  uploadPhotographFiles,
} from "./photograph.service"
import {
  fetchFileParams,
  fileDownloadParams,
  fileUploadParams,
  updatePhotoPositionParams,
} from "./photograph.type"

export const uploadPhotographs = createAsyncThunk(
  "PhotosService/uploadPhotographs",
  async (
    {
      patientId,
      caseId,
      formData,
      fileName,
      onFileProcesscallback,
    }: fileUploadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadPhotographFiles(
        orgId,
        patientId,
        caseId,
        fileName,
        formData,
        onFileProcesscallback,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadPhotographs = createAsyncThunk(
  "PhotosService/downloadPhotographs",
  async (
    { patientId, caseId, fileName }: fileDownloadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadPhotographFiles(orgId, patientId, caseId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchFilesList = createAsyncThunk(
  "PhotosService/fetchFileList",
  async (
    { patientId, caseId }: fetchFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await fetchFileList(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updatePhotoPosition = createAsyncThunk(
  "PhotosService/updatePhotoPosition",
  async (
    { patientId, caseId, payload }: updatePhotoPositionParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await updatePhotoPositionAPI(orgId, patientId, caseId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
