const dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  maximumFractionDigits: 0,
})

// eslint-disable-next-line import/prefer-default-export
export const formatCurrency = (value: number) => {
  return dollarUS.format(value)
}

export const convertMBtoKB = (mb: number) => {
  return mb * 1024 // 1 MB = 1024 KB
}
