import { EToothDirection } from "./modules/toothmanager/toothdirection";
import {
  IAttachmentIPRVisible,
  IAuxullartiesShowInfo,
} from "./modules/attachment/attachmentModule";
import {
  ICaseExtInfo,
  ReportAttachmentData,
  ReportAttachmentToothListData,
} from "./common/types";
import { ReportIPRData } from "./modules/ipr/iprModule";
import { TypeInNumber } from "./modules/toothmanager/toothmovement";
import { IWASMBoltonReport } from "./modules/bolton";

export interface IPresetupOptions {
  upperArch: Blob;
  lowerArch: Blob;
  canvas: HTMLCanvasElement;
  donecallback: () => void;
}

export interface PanelDataProps {
  isCrown: boolean;
  isImplant: boolean;
  isBridge: boolean;
  isShow: boolean;
  toothWidth: number;
  toothId: number;
  data: number[];
  isRoot: false;
  isNoMovement: boolean;
  //  ShowInitial: false,
  // isReset: false,
  flag: boolean; // 辅助3d监听是否触发
}

export type CaseFileName =
  | "bulk0"
  | "bulk1"
  | "bulk2"
  | "bulk10"
  | "raw"
  | "photo";

/**
 * Case life management interface
 */
export interface ICaseManagement {
  /**
   * open case from patient list
   * zipFiles: the casedata files :
   *             {
   *                'bulk1.zip': zip File,
   *                'bulk2.zip': zip File，
   *                'bulk3.zip': zip File
   *                'bulk4.zip': zip File
   *                'bulk10.zip': zip File
   *             }
   * canvas: HTMLCanvasElement
   * initFinishedCallback: ()=>void
   */
  openCase: (
    canvas: HTMLCanvasElement,
    zipFiles: Record<string, Blob>
  ) => Promise<void>;

  /**
   * open case from local zip
   * @param canvas HTMLCanvasElement
   * zipfile: local zip file
   * @returns
   */
  openCaseFromZip: (canvas: HTMLCanvasElement, zipfile: File) => Promise<void>;
  /**
   * open case from local zip
   * @param canvas HTMLCanvasElement
   * @param fileUrl zip file url relate to public dir
   * @returns
   */
  openCaseFromLocalZipFileUrl: (
    canvas: HTMLCanvasElement,
    fileUrl: string
  ) => Promise<void>;

  /**
   * open case Finished
   * @param callback
   */
  setCallbackAfterOpencase: (callback: () => void) => void;

  /**
   * close when return back from treatment page.
   */
  closeCase: () => void;

  /**
   * do presetup
   */
  presetupCase: (presetupOps: IPresetupOptions) => void;
  /**
   * redo presetup
   */
  redoPresetup: (canvas: HTMLCanvasElement, donecallback: () => void) => void;
  /**
   * 获取wasm中保存的扫描照片
   * @returns
   */
  getScansPhoto: () => Map<string, File>;
  /**
   * 获取wasm中InitialFinal截图
   * @returns
   */
  getInitialFinalScreenshot: () => Map<string, File>;
  /**
   *  get current case data
   *  if fileName is undefined  then return all files
   *  return file is like :
   *  {
   *     'bulk0.zip': zip File
   *     'bulk2.zip': zip File
   *     'bulk3.zip': zip File
   *     ....
   *  }
   *
   *  else
   *  return a specified file
   *  file name is as 'bulk0' | 'bulk1' | 'bulk2' | 'bulk10' | 'raw' | 'photo'
   *
   */
  getCaseFiles: (fileName?: CaseFileName) => Promise<Record<string, File>>;
  /**
   * get AttachmentReport and IPRReport
   * @param val
   * @returns
   */
  getAttachmentIPRReport: () => {
    attachment: ReportAttachmentData;
    toothlist: ReportAttachmentToothListData;
    ipr: ReportIPRData;
  };

  /**
   * get AttachmentVisible and IPRVisible
   * @returns
   */
  getAttachmentIPRData: () => IAttachmentIPRVisible;

  getToothReports: (isUpper: boolean) => Map<number, any>;

  /**
   * get bolton report data
   */
  getBoltonReport: () => IWASMBoltonReport;

  /**
   * get Case Setup Type
   * @returns
   */
  getCaseSetupType: () => ESetupType;

  /**
   * set Case Setup Type
   * @returns
   */
  setCaseSetupType: (type: ESetupType) => void;
  /**
   * Complete Attachments Automatically
   * @param callback
   * @returns
   */
  setcalsetupfinishCallback: (callback: () => void) => void;

  //------------------ Treatment plan ---------------------

  /**
   * set or get case extra information , it's a json object
   * please confirm that the caseExtInfo has been set before using other treatment-plan interface.
   * @returns CaseExInfo JSON obj
   */
  setCaseExtInfo: (caseExInfoJson: ICaseExtInfo) => void;
  getCaseExtInfo: () => ICaseExtInfo;

  /**
   * Get treatment plan name list
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * @returns the name list of treatment plans.
   */
  getTreatmentPlanList: () => string[];

  /**
   * Change to target tx
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * if target tx name equle current tx , nothing's gonna happen .
   * @param txName  The name of which treatment to changed into.
   * @returns
   */
  changeTreatment: (txName: string) => boolean;

  /**
   * rename a exist treatment plan
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * @param txName  the txPlan to be rename
   * @param newName new name
   * @returns
   */
  renameTreatment: (txName: string, newName: string) => boolean;

  /**
   * Save current Tx
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * @returns
   */
  saveCurrentTx: () => boolean;

  /**
   * Del a Treatment Plan
   * @param txIndex
   * @returns
   */
  delTx: (txName: string) => boolean;

  /**
   * set mainpage's background color
   * @param r
   * @param g
   * @param b
   * @returns
   */
  setBackgroundColor: (r: number, g: number, b: number) => void;

  /**
   * set background picture
   * @param url
   * @returns
   */
  setBackgroundPic: (url: string) => void;

  /**
   * get the archs is exist
   * @returns
   */
  getHasArchType: () => Promise<{
    hasUpperArch: boolean;
    hasLowerArch: boolean;
  }>;
}

/**
 *  STLModel Preview of Uploading & Fillinghole
 */
export interface IDrawSTLOps {
  upperArch: File | null;
  lowerArch: File | null;
  canvas: HTMLCanvasElement;
  zoomRange: [number, number];
  fillholeCallback?: (isSuccess: boolean, errmsg: string) => void;
}

export interface IPreView {
  /**
   *  draw STL when upload file
   * @param drawSTLOps
   * @returns  this function do fill hole and draw 3DModel, return a Promise after all.
   */
  drawSTL: (drawSTLOps: IDrawSTLOps) => Promise<void>;
  /**
   *changeArchMode
   *param name  (1、up.2、low.3、both)
   * */
  changeArchMode: (viewState: "up" | "low" | "both") => void;
  /**
   * zoom with scale
   * @param scale
   */
  zoomWithValue: (scale: number) => void;
  /**
   * setup zoom callback
   * @param zoomRange  the [min,max] tuple value of the scale
   * @param callback  (val: number) => void)
   */
  setZoomCallback: (callback: (val: number) => void) => void;

  /**
   * clear the preview after quit thie prewpage
   */
  clearPreview: () => void;

  /**
   * Convert stl to mtc file
   * Called when you click next after uploading the stl
   * @param upperStl
   * @param lowerStl
   */
  saveStlToMtc(upperStl: File | null, lowerStl: File | null);

  /**
   * Returns whether the preview has been initialized
   */
  isInitPreview: () => boolean;

  /**
   * Loading mtc files from zips in preView page
   * @param zips  zip lists: `bulk0`,`bulk1` ,`bulk2` ,`bulk10` ,`photo` ,`raw ` ,`storage.version.json.zip`
   * @param zoomRange [min sacle number, max scale number]  [0.25,2.0] is the default
   */
  drawMtcFromZips(
    zips: Record<string, File>,
    zoomRange: [number, number]
  ): Promise<Map<string, File>>;
}

/**
 * case Setup type
 */
export enum ESetupType {
  Full = 0, // All teeth
  Simple, // Molar to molar
  Anterior, // 5x5
  Ant3x3, // 3x3
  AlignmentOnly, // Full treatment.
  Aligner5, // Anterior only and minimum root movement.
  Init, // All teeth, OJ/OB not improved
  FineTune, // All teeth, minor adjustment
  COPASimple, // Molar to molar and minimum root movement
  COPAModerate, // Molar to molar and minimum posterior movemen
}

/**
 * Interfaces of Stage controling
 */
export interface IStageControl {
  /**
   * set stage value from UI
   * @param upstep
   * @param lowstep
   */
  setStageStep: (upstep: number, lowstep: number) => void;

  /**
   * give notice to UI when current stage number change
   * @param arch
   * @param index
   */
  setStageCallback: (callback: (wasmStageData) => void) => void;
  /**
   * give notice to UI when Setup Type
   * @param callback
   * @returns
   */
  setSetupTypeCaseCallback: (
    callback: (type: ESetupType, ndoeIndex: number) => void
  ) => void;
  /**
   * set callback which invoke on stageData(ipr,attachment) change(old,Replace：setCompleteAttachmentsAutomaticallyCallback)
   * @param callback
   * @returns
   */
  setCallbackOnStageDataChanged: (
    callback: (stageData: IAttachmentIPRVisible) => void
  ) => void;
  /**
   * EndMessage(old,Replace：setCompleteAttachmentsAutomaticallyCallback)
   * @param callback
   * @returns
   */
  setEndMessageCallback: (
    callback: (stageData: IAttachmentIPRVisible) => void
  ) => void;

  /**
   * get Stage Node Index
   * @returns
   */
  getStageNodeIndex: () => number;
}

/**
 * Interfaces of Initial-final
 */
export interface IInitialFinal {
  /**
   * 打开或者关闭initialfinal窗口
   * @param isEnable
   */
  setInitialFinalEnabled: (isEnabled: boolean) => void;

  /**
   * 前端传入的zoom缩放值
   * @param scaleFac
   * @returns
   */
  zoomByScale: (scaleFac: number) => void;

  /**
   * 鼠标滚轮滑动时回调给前端当前的zoom缩放值(每次重新打开initialfinal模块都需要调用)
   * @param callback
   * @returns
   */
  setZoomInitialFinalCallback: (callback: (value: number) => void) => void;

  /**
   * 当打开initialfinal窗口后再点击occlusal按钮后调用
   * @returns
   */
  openOcclusalView: () => void;
}

/**
 * Interfaces of Treatment
 */
export interface ITreatmentViewportControl {
  /**
   * setZoomCallbackInTreatment
   * it will be invoked on mouse wheel change
   * @param callback (scalevalue: number) => void
   * @returns
   */
  setZoomCallbackInTreatment: (callback: (value: number) => void) => void;

  /**
   * zoomWithValueInTreatment
   * @param val  the scale value.default is 1.0
   * @returns
   */
  zoomWithValueInTreatment: (val: number) => void;

  /**
   * show upper arch or lower
   * @param mode  'up' | 'low' | 'all'
   * @returns
   */
  showArchMode: (mode: "up" | "low" | "all") => void;

  /**
   * set the view type
   * @param type 'left' | 'right' | 'front'|'back' | 'split'
   * @returns
   */
  setViewType: (type: "left" | "right" | "front" | "back" | "split") => void;

  /**
   * set attachment is visibility?
   * @param isVisible boolean
   * @returns
   */
  setAttachmentVisibility: (isVisible: boolean) => void;

  /**
   * draw auxullarties animation in a canvas
   * @param canvasElement canvas element
   * @param auxinfoList auxinfo include the info of aux and the element where to draw it. 
   *  interface IAuxullartiesShowInfo {
   *    //  aux type
        type: "Normal" | "PressurePoint" | "ButtonAndSlits";
        //  [auxId,the dom element where we draw the aux]
        elements: [string, HTMLElement][];
      }
   * @returns
   */
  drawAuxullarties: (
    canvasElement: HTMLElement,
    auxinfoList: IAuxullartiesShowInfo[]
  ) => void;
  /**
   * set IPR is visibile
   * @param val
   * @returns
   */
  setIPRVisibility: (val: boolean, stageIndexForShow: number) => void;

  /**
   * set Grid is visibile
   * @param val
   * @returns
   */
  setGridVisibility: (isVisible: boolean) => void;

  /**
   * set Super-impose Stage
   * @param val
   * @returns
   */
  setSuperimposeStage: (isUpper: boolean, step: number) => void;

  /**
   * set Super-impose visibility
   * @param isVisible
   * @returns
   */
  setSuperimposeVisibility: (isVisible: boolean) => void;

  /**
   * set Super-impose Opacity
   * @param isVisible
   * @returns
   */
  setSuperimposeOpacity: (opacityVal: number) => void;

  /**
   * set Occlusion is Visible
   * @param isVisible
   */
  setOcclusionVisibility: (isVisible: boolean) => any;

  /**
   * set space check is Visible
   * @param isVisible
   */
  setSpacecheckVisibility: (isVisible: boolean) => any;

  /**
   * set Overlay is Visible
   * @param isVisible
   * @returns
   */
  setOverlayVisibility: (isVisible: boolean) => void;

  /**
   * set Overlay opacity
   * @param opacity  from 0 to 1
   * @returns
   */
  setOverlayOpacity: (opacity: number) => void;
}

/**
 * Interfaces of history actions
 */
export interface Ihistory {
  /**
   * undo one step
   * @returns
   */
  undo: () => boolean;

  /**
   * redo one step
   * @returns
   */
  redo: () => boolean;

  /**
   * clear all steps
   * @returns
   */
  clearAll: () => boolean;
}

export interface IRefinement {
  /**
   * open history display module
   * @param isOpen
   */
  openRefinementModule: (isOpen: boolean) => void;

  /**
   * set Refinement Display Range
   * @param displayRange (all/original/refinement1/refinement2/...)
   * @returns
   */
  setRefinementDisplayRange: (displayRange: string) => boolean;

  /**
   * get Refinement Info
   * @param archTpye
   * @returns
   */
  getRefinementInfo: (archTpye: number) => string[];

  /**
   * change Display Stage Value
   */
  changeDisplayStageValue: (index: number, archTpye: number) => void;

  /**
   * get Number Of Refinement
   * @param archTpye
   * @returns
   */
  getNumberOfRefinement: (archTpye: number) => number;

  /**
   * undo refinement
   * @param archTpye
   * @returns
   */
  undoRefinement: (archTpye: number) => void;
}

/**
 * 待修改，目前定义较难理解，把计算流程给暴露出来让前端统一来串
 * 只定义三个接口 设置callback ,调预计算判断是否弹框接口，计算接口。
 */
export interface IToothMovement {
  /**
   * 点击Setup面板中控制牙齿移动的按钮
   * Click the toothmovement buttons in setup panel
   * @param dir
   * @param quick
   * @returns
   */
  disPatchButton: (dir: EToothDirection, quick: boolean) => void;
  /**
   *  set callback for moving molar.
   *  点击 molar 牙齿的回调
   */
  setCallbackForMovingMolar: (callback: () => void) => void;

  /**
   * set callback which invoke on tooth moving out of range.
   * 设置移动牙齿超出范围后的回调
   * @param callback
   */
  setCallbackOutOfRange: (callback: () => void) => void;

  /**
   * set callback when modify the tooth  show modifybutton or not
   *  点击牙齿是否显示 ModificationCompleted 按钮的回调
   */
  setCallbackModifyTooth: (callback: (isShow: boolean) => void) => void;

  /**
   *  ModificationCompleted clicked
   *
   * ModificationCompleted 按钮点击之后调用
   * 点击modificationCompleted后调用此方法
   * 此方法先预计算，判断当前case是否是simple-case,并且modify后会变成mediumcase.
   * 如果是则会调用回调‘ifSimpleCaseChangeToMediumCaseCallback’ 通知前端弹框提示
   * 如果不是则直接走 Modifycation计算。
   * 计算完成后会调用回调 finishedCallback
   *
   * if user select no then use the algorithm which keep case is simple ,else use the one which change case to medium.
   *
   * param finishedCallback
   *       ifSimpleCaseChangeToMediumCaseCallback
   */
  modifyButtonClicked: (
    callback: () => void,
    popupCallback: () => void
  ) => void;

  /**
   *  modification commpleted
   * modificationCompleted 过程完成后设置的回调
   */

  // modificationCompleted: (callback: () => void) => void;
  /**
   * 用户点击NO后调用的计算简单的牙齿方案
   */
  modificationSchemeSimple: () => void;

  /**
   * 用户点击YES后调用的中等的牙齿方案
   */
  modificationSchemeSecondary: () => void;

  /**
   * 设置不允许牙齿移动的回调窗口
   /
   */
  setCallbackCannotMoveTooth: (callback: () => void) => void;

  /**
   *  设置 显示 toothmovement信息的回调窗口
   */
  setCallbackShowToothInfo: (
    callback: (panelDataP: PanelDataProps) => void
  ) => void;

  /**
   * 设置是否可以移牙
   * bReadonly :true  不可移  false :可以移
   */
  setToothReadonly: (bReadonly: boolean) => void;

  /**
   * 设置移牙panel的root切换
   * @param isRoot
   * @returns
   */
  setRootControl: (isRoot: boolean) => void;

  /**
   * 根据面板输入值改变牙齿movement
   * @param val 输入框中的值
   * @param dir 输入框代表的牙齿方向
   * @returns
   */
  moveWithInputValue: (val: number, dir: TypeInNumber) => boolean;
}

export interface IViewEdit {
  /**
   * view <-> edit switch
   * @param isToEdit
   * @returns 返回0 是一个无意义值，返回1 是代表 settingData2 数据未更改，返回2 代表是SettingData2 的数据发生更改。
   */
  switchViewToEdit: (isEdit: boolean) => number;
}
