export const schema = {
  type: "object",
  properties: {
    arch_form_type: {
      type: "string",
      isRadioGroup: true,
      radioOptions: [
        "Ovoid",
        "Natural/Maintain Current Arch Form",
        "Broad/Square",
      ],
    },
    expansion: {
      type: "string",
      isRadioGroup: true,
      radioOptions: ["Both Anterior and Posterior", "Anterior", "Posterior"],
    },
    expansion_limit_canine_to_canine: {
      type: "string",
      isRadioGroup: true,
      radioOptions: ["No Limit", "0 - 2 mm", "2 mm - 4 mm"],
    },
    expansion_limit_first_molar_to_first_molar: {
      type: "string",
      isRadioGroup: true,
      radioOptions: ["No Limit", "0 - 2 mm", "2 mm - 4 mm"],
    },
    level_lower_curve_of_spee: {
      type: "object",
      isChecboxGroup: true,
      checkboxes: [
        { label: "Intrude Posterior", value: "intrudePosterior" },
        { label: "Extrude Anterior", value: "extrudeAnterior" },
        {
          label: "Additional Instructions",
          value: "AdditionalInstructions",
        },
      ],
      input: {
        width: 310,
        const: "AdditionalInstructions",
        minRows: 1,
        key: "instructions",
        multiline: true,
      },
    },
    overbite: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["2 mm - 3 mm", "Edge to Edge", "Overcorrection"],
      input: {
        width: 310,
        const: "Overcorrection",
        minRows: 1,
        key: "stage",
        multiline: true,
        isNum: true,
      },
    },
    overjet: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["0 - 2 mm", "2 mm - 3 mm", "Custom Amount"],
      input: {
        width: 310,
        const: "Custom Amount",
        multiline: true,
        minRows: 1,
        key: "stage",
        isNum: true,
      },
    },
    smile_design: {
      type: "object",
      isRadioGroup: true,
      radioOptions: [
        "Smile Arc",
        "Level the incisal edges",
        "Level the canines with the centrals",
        "Level the gingival margins",
      ],
      input: {
        width: 56,
        startLabel: "Step up laterals",
        endLabel: "mm",
        const: "Level the canines with the centrals",
        minRows: 1,
        key: "stage",
        isNum: true,
      },
    },
    stage_ipr: {
      type: "string",
      isRadioGroup: true,
      radioOptions: ["3 - 3", "4 - 4", "5 - 5", "Not applicable"],
    },
    order_of_priority: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Premolars to maximum limit before anterior teeth",
          value: "Premolars to maximum limit before anterior teeth",
        },
        { label: "Not in the first round", value: "Not in the first round" },
      ],
    },
    max_interproximal_conatct: {
      type: "string",
      isRadioGroup: true,
      radioOptions: ["0.2 mm", "0.3 mm", "0.4 mm", "0.5 mm"],
      default: "0.3 mm",
    },
    set_ipr_staging: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["Preferred Stage(s)", "When contacts align"],
      input: {
        width: 310,
        const: "Preferred Stage(s)",
        minRows: 1,
        multiline: true,
        key: "stage",
        placeholder: "i.e. Stages 0/8/16/24",
      },
      default: "When contacts align",
    },
    sequential_intrusion_protocol: {
      type: "object",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Upper Posterior",
          value: "Upper Posterior",
        },
        { label: "When applicable", value: "When applicable" },
        { label: "Sequential Intrusion", value: "Sequential Intrusion" },
      ],
      input: {
        width: 310,
        const: "Sequential Intrusion",
        multiline: true,
        placeholder: "Instructions (optional)",
        isOptional: true,
        minRows: 1,
        key: "instructions",
      },
    },
    ap_optional: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "AP (optional)",
      checkboxes: [
        {
          value: "correctToClassCanines",
          label: "Correct to Class I - Canines",
          radioGroup: {
            key: "correctToClassCanines",
            type: "string",
            radioOptions: ["Both", "Right", "Left"],
            default: "Both",
          },
        },
        {
          value: "correctToClassMolars",
          label: "Correct to Class I - First Molars",
          radioGroup: {
            key: "correctToClassMolars",
            type: "string",
            radioOptions: ["Both", "Right", "Left"],
            default: "Both",
          },
        },
      ],
    },
    acheived_by: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "Achieved By (optional)",
      checkboxes: [
        {
          value: "biteJumpSimulation",
          label: "Bite Jump Simulation (Global Movement)",
          radioGroup: {
            key: "biteJumpSimulation",
            type: "string",
            radioOptions: [
              "At the end",
              "At the beginning",
              "Throughout treatment",
            ],
            default: "At the end",
          },
        },
        {
          value: "sequentialMovements",
          label: "Sequential Movements (if applicable)",
        },
        {
          value: "denatlMovementOnly",
          label: "Dental Movement Only",
        },
      ],
    },
    elastics: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "Elastics (optional)",
      checkboxes: [
        {
          value: "classII",
          label: "Class II",
          radioGroup: {
            key: "classII",
            type: "string",
            radioOptions: ["Both", "Right", "Left"],
            default: "Both",
          },
        },
        {
          value: "classIII",
          label: "Class III",
          radioGroup: {
            key: "classIII",
            type: "string",
            radioOptions: ["Both", "Right", "Left"],
            default: "Both",
          },
        },
        {
          value: "custom",
          label: "Custom",
          input: {
            width: 310,
            const: "custom",
            isInput: true,
            multiline: true,
            minRows: 1,
            key: "instructions",
          },
        },
      ],
    },
    auxillaries: {
      type: "object",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Precision Aligner Buttons",
          value: "Precision Aligner Buttons",
        },
        {
          label: "Caplin Hook",
          value: "Caplin Hook",
        },
        {
          label: "Smaller Button",
          value: "Smaller Button",
        },
        { label: "Slits", value: "Slits" },
        { label: "Additional Instructions", value: "Additional Instructions" },
      ],
      input: {
        width: 310,
        const: "Additional Instructions",
        multiline: true,
        minRows: 1,
        key: "stage",
      },
    },
    attachments: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["Yes", "No"],
      input: {
        width: 56,
        startLabel: "Stage",
        const: "Yes",
        minRows: 1,
        key: "stage",
        isNum: true,
      },
    },
    add_group_protocal: {
      type: "object",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Deep Bite",
          value: "Deep Bite",
        },
        {
          label: "Open Bite",
          value: "Open Bite",
        },
        {
          label: "Distalization (if applicable)",
          value: "Distalization",
        },
        { label: "Custom", value: "Custom" },
      ],
      input: {
        width: 310,
        const: "Custom",
        multiline: true,
        minRows: 1,
        key: "instructions",
      },
    },
    pressure_points: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["Yes", "No"],
      input: {
        width: 310,
        multiline: true,
        const: "Yes",
        minRows: 1,
        key: "stage",
      },
    },
    bite_ramps: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["Yes", "No"],
      checkboxGroup: {
        const: "Yes",
        key: "checkbox",
        checkboxes: [
          {
            label: "Central Incisors",
            value: "Central Incisors",
          },
          {
            label: "Canines",
            value: "Canines",
          },
          {
            label: "Central and Laterals",
            value: "Central and Laterals",
          },
          {
            label: "Custom",
            value: "Custom",
            input: {
              width: 284,
              multiline: true,
              minRows: 1,
              key: "stage",
              const: "Custom",
            },
          },
        ],
        default: "Central Incisors",
      },
    },
    virtual_chain: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["Yes", "No", "Not in the initial round"],
      input: {
        width: 80,
        const: "Yes",
        startAdornment: "-",
        defaultValue: "0.01",
        minRows: 1,
        key: "stage",
        endLabel: "Amount of virtual IPR at the last stage",
      },
    },
    velocity: {
      type: "object",
      isRadioGroup: true,
      radioOptions: [
        ".20 mm linear / 2.0° angular",
        ".22 mm linear / 2.2° angular",
        ".25 mm linear / 2.5° angular",
        "Custom",
      ],
      input: {
        width: 310,
        const: "Custom",
        minRows: 1,
        key: "stage",
      },
    },
    equal_no_of_aligners: {
      type: "string",
      isRadioGroup: true,
      radioOptions: ["Yes", "No"],
    },
    passive_aligners: {
      type: "object",
      isRadioGroup: true,
      radioOptions: ["Yes (if needed)", "No"],
      input: {
        width: 310,
        const: "Yes (if needed)",
        multiline: true,
        minRows: 1,
        key: "instructions",
        placeholder: "Instructions (optional)",
        isOptional: true,
      },
    },
    wear_time_of_aligners: {
      type: "object",
      title: "Wear Time of Aligners",
      isRadioGroup: true,
      radioOptions: ["7 Days", "14 Days", "custom"],
      input: {
        width: 56,
        showInSameLine: true,
        endLabel: "Days",
        const: "custom",
        minRows: 1,
        key: "customDays",
        isNum: true,
      },
    },
    case_specific_instructions: {
      title: "Open Bite Instructions",
      type: "string",
      isTextarea: true,
      isSettings: true,
      placeholder: `Please add instructions for this template here (no abbreviations).\n\nYou will have the opportunity to enter case specific instructions before submitting each case.`,
      options: {
        rows: 6,
      },
    },
  },

  required: [
    "arch_form_type",
    "expansion",
    "expansion_limit_canine_to_canine",
    "expansion_limit_first_molar_to_first_molar",
    "level_lower_curve_of_spee",
    "overbite",
    "overjet",
    "smile_design",
    "stage_ipr",
    "max_interproximal_conatct",
    "set_ipr_staging",
    "attachments",
    "pressure_points",
    "auxillaries",
    "bite_ramps",
    "virtual_chain",
    "velocity",
    "equal_no_of_aligners",
    "passive_aligners",
    "wear_time_of_aligners",
    "sequential_intrusion_protocol",
  ],
}

export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Group",
      label: "How do you prefer to see teeth at the end of treatment?",
      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/arch_form_type",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/expansion",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/expansion_limit_canine_to_canine",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/expansion_limit_first_molar_to_first_molar",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Vertical and AP",
      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/level_lower_curve_of_spee",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/overbite",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/overjet",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Detailing",
      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/smile_design",
        },
      ],
    },
    {
      type: "Group",
      label: "IPR",
      elements: [
        {
          type: "Control",
          label: "Please select area(s) to stage IPR",
          scope: "#/properties/stage_ipr",
        },
        {
          type: "Control",
          label: "Order of Priority (optional)",
          scope: "#/properties/order_of_priority",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/stage_ipr",
              schema: { const: "Not applicable" },
            },
          },
        },
        {
          type: "Control",
          label: "Maximum amount per interproximal contact",
          scope: "#/properties/max_interproximal_conatct",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/stage_ipr",
              schema: { const: "Not applicable" },
            },
          },
        },
        {
          type: "Control",
          label: "Set IPR Staging",
          scope: "#/properties/set_ipr_staging",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/stage_ipr",
              schema: { const: "Not applicable" },
            },
          },
        },
      ],
    },
    {
      type: "Group",
      label: "How to achieve the stimulated treatment - biomechanics",
      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/sequential_intrusion_protocol",
        },
        {
          type: "Control",
          label: "AP (optional)",
          scope: "#/properties/ap_optional",
        },
        {
          type: "Control",
          label: "Achieved By (optional)",
          scope: "#/properties/acheived_by",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/ap_optional",
              schema: { const: { value: [] } },
            },
          },
        },
        {
          type: "Control",
          label: "Elastics (optional)",
          scope: "#/properties/elastics",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/ap_optional",
              schema: { const: { value: [] } },
            },
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/auxillaries",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/elastics",
              schema: { const: { value: [] } },
            },
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/attachments",
        },
        {
          type: "Control",
          label: "Add Group Protocol (optional)",
          scope: "#/properties/add_group_protocal",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/attachments",
              schema: { const: "No" },
            },
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/pressure_points",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/bite_ramps",
        },
        {
          type: "Control",
          label: "Virtual C-Chain",
          scope: "#/properties/virtual_chain",
        },
        {
          type: "Control",
          label: "Velocity - Rate Limiting Factor Stage",
          scope: "#/properties/velocity",
        },
        {
          type: "Control",
          label: "Equal number of aligners (adjust velocity)",
          scope: "#/properties/equal_no_of_aligners",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/passive_aligners",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/wear_time_of_aligners",
        },
      ],
    },
    {
      type: "Group",
      label: "Additional Instructions",
      elements: [
        {
          type: "Control",
          scope: "#/properties/case_specific_instructions",
          label: true,
        },
      ],
    },
  ],
}

export const defaultData = {
  arch_form_type: "Ovoid",
  expansion: "Both Anterior and Posterior",
  expansion_limit_canine_to_canine: "2 mm - 4 mm",
  expansion_limit_first_molar_to_first_molar: "0 - 2 mm",
  level_lower_curve_of_spee: { value: ["intrudePosterior", "extrudeAnterior"] },
  overbite: { value: "2 mm - 3 mm" },
  overjet: { value: "2 mm - 3 mm" },
  smile_design: { value: "Smile Arc" },
  stage_ipr: "4 - 4",
  order_of_priority: [],
  max_interproximal_conatct: "0.3 mm",
  set_ipr_staging: { value: "When contacts align" },
  sequential_intrusion_protocol: {
    value: ["Upper Posterior", "When applicable"],
  },
  ap_optional: {
    value: ["correctToClassCanines"],
  },
  acheived_by: { value: [] },
  elastics: { value: [] },
  auxillaries: { value: [] },
  attachments: { value: "Yes", stage: 1 },
  add_group_protocal: { value: ["Open Bite"] },
  pressure_points: { value: "No" },
  bite_ramps: { value: "No" },
  virtual_chain: { value: "No", stage: "0.01" },
  velocity: { value: ".22 mm linear / 2.2° angular" },
  equal_no_of_aligners: "Yes",
  passive_aligners: { value: "Yes (if needed)" },
  wear_time_of_aligners: { value: "7 Days" },
}

export const jsonSchemaDefault = JSON.stringify(schema, null, 2)
export const uiSchemaDefault = JSON.stringify(uischema, null, 2)
