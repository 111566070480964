import { FC, useEffect, useState } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  FormAutoComplete,
  FormInput,
  UButton,
  UText,
} from "../../../components"
import { Box, Button } from "../../../components/mui.components"

import { renderUserInfoSection } from "./UserProfile"

const PaymentDetails: FC = () => {
  const { t } = useTranslation("common")
  const {
    control,
    formState: { errors },
    getFieldState,
    trigger,
    setValue,
    watch,
    handleSubmit,
    getValues,
  } = useForm({ mode: "onSubmit" })
  const [editPayment, setEditPayment] = useState<boolean>(false)

  const paymentData = {
    cardNumber: "**** **** **** 0545",
    expiryDate: "**/**",
    nameOnCard: "Sebastian Vettel",
    country: "USA",
    street: "825 Sutter street",
    line2: "",
    city: "San Francisco",
    state: "CA",
    zip: "94107",
  }

  useEffect(() => {
    setValue("country", paymentData.country)
    setValue("street", paymentData.street)
    setValue("addressline2", paymentData.line2)
    setValue("city", paymentData.city)
    setValue("state", paymentData.state)
    setValue("zip", paymentData.zip)
  }, [])

  const onSubmit: SubmitHandler<FieldValues> = (data: any) => {
    console.log("...submited patient form submission", data)
  }

  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <UText
          variant={"h6"}
          component={"div"}
          sxProp={{ textAlign: "center" }}
        >
          {t("userProfile.payment")}
        </UText>
        {!editPayment && (
          <Button
            variant={"text"}
            sx={{
              minWidth: "auto",
              height: "auto",
              width: "auto",
              lineHeight: "22px",
              py: "4px",
              px: "5px",
            }}
            onClick={() => setEditPayment(true)}
          >
            {t("userProfile.edit")}
          </Button>
        )}
      </Box>
      {renderUserInfoSection(
        t("userProfile.addressOnFile.cardDetails.cardNum"),
        paymentData.cardNumber,
      )}
      {renderUserInfoSection(
        t("userProfile.addressOnFile.cardDetails.expDate"),
        paymentData.expiryDate,
      )}
      {renderUserInfoSection(
        t("userProfile.addressOnFile.cardDetails.nameOnCard"),
        paymentData.nameOnCard,
      )}
      <UText
        variant={"body1"}
        component={"div"}
        sxProp={{ color: "text.secondary", pt: 3 }}
      >
        {t("userProfile.addressTitle")}
      </UText>
      {editPayment ? (
        <>
          <Box>
            <FormAutoComplete
              formSxprops={{ mt: 2, mb: 0 }}
              inputLabel={"userProfile.cn"}
              fieldName={"country"}
              isInputLabel={true}
              rules={{
                required: "userProfile.addressOnFile.countryRequired",
              }}
              options={[
                { label: "USA", id: "usa" },
                { label: "Canada", id: "canada" },
                { label: "Australia", id: "aus" },
                { label: "New Zealand", id: "nz" },
              ]}
              errors={errors}
              onBlur={(e) => {
                setValue("country", e.target.value)
              }}
              defaultValue={paymentData.country}
              control={control}
            />
            <FormInput
              inputLabel={"userProfile.addressOnFile.streetaddress"}
              fieldName={"street"}
              fieldType={"text"}
              rules={{
                required: "userProfile.addressOnFile.streetRequired",
              }}
              errors={errors}
              control={control}
              formSxprops={{ mt: 2 }}
            />
            <FormInput
              inputLabel={"userProfile.addressOnFile.addressLine2"}
              fieldName={"addressline2"}
              fieldType={"text"}
              rules={{}}
              errors={errors}
              control={control}
              formSxprops={{ mt: 2 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <FormInput
              inputLabel={"userProfile.addressOnFile.city"}
              fieldName={"city"}
              fieldType={"text"}
              rules={{ required: "userProfile.addressOnFile.cityRequired" }}
              errors={errors}
              control={control}
            />
            <FormAutoComplete
              inputLabel={"userProfile.addressOnFile.state"}
              fieldName={"state"}
              isInputLabel={true}
              rules={{
                required: "userProfile.addressOnFile.stateRequired",
              }}
              options={[
                { label: "Alaska", id: "alaska" },
                { label: "Colorado", id: "colorado" },
              ]}
              errors={errors}
              onBlur={(e) => {
                setValue("state", e.target.value)
              }}
              control={control}
              defaultValue={paymentData.state}
            />
            <FormInput
              inputLabel={"userProfile.addressOnFile.zip"}
              fieldName={"zip"}
              fieldType={"text"}
              rules={{ required: "userProfile.addressOnFile.zipRequired" }}
              errors={errors}
              control={control}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <UButton
              variant={"outlined"}
              btnText={t("button.cancel")}
              key={"yes"}
              sxProp={{ minWidth: 58, height: 36 }}
              onClickHandler={() => {
                console.log("cancel")
                setEditPayment(false)
              }}
            />
            <UButton
              variant={"contained"}
              btnText={t("button.save")}
              key={"no"}
              btnType="submit"
              sxProp={{ minWidth: 54, height: 36 }}
            />
          </Box>
        </>
      ) : (
        <Box>
          <UText variant={"body1"} component={"div"} sxProp={{ color: "#000" }}>
            {paymentData.street}
            <br />
            {paymentData.city +
              ", " +
              paymentData.state +
              " " +
              paymentData.zip}
            <br />
            {paymentData.country}
          </UText>
        </Box>
      )}
    </Box>
  )
}

export default PaymentDetails
