import * as React from "react"
import { useEffect, useState } from "react"
import { Stack, Grid, Typography, Divider } from "@mui/material"
import { FlowBtnGroup, FlowToggleButton } from "../../Custom/FlowCustom"

import { wasmModule } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"

import { RootState } from "../../../core/app/store"
import { useAppSelector, useAppDispatch } from "../../../core/app/hooks"
import { setIsShow } from "../../CommonPages/ManuStagingPercentSlice"
import { setShowTMChart } from "../../Wasm3DServiceSlice"

const WeFlowStaging = (props) => {
  const { isShow, curJaw } = useAppSelector(
    (state: RootState) => state.manuStagingPercentSlice,
  )
  const { showTMChart } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const dispatch = useAppDispatch()

  const onClickStagingBtn = (cmd) => {
    console.log("Auto staging button " + cmd + " is clicked.")
    if (!wasmModule) {
      console.log("wasmModule null ptr")
      return
    }
    for (let i = 0; i < 2; i++) {
      if (cmd == "AutoAll") wasmModule.stagingcontoler.AutoAllStage(i)
      else if (cmd == "RemoveAll")
        wasmModule.stagingcontoler.RemoveAutoAllStage(i)
      else if (cmd == "VelWeekly") wasmModule.stagingcontoler.VelocityWeekly(i)
      else if (cmd == "VelBiWeekly")
        wasmModule.stagingcontoler.VelocityBiWeekly(i)
      else if (cmd == "SyncVelRestore")
        wasmModule.stagingcontoler.VelocityRestore()
      else if (cmd == "SyncVelSync") wasmModule.stagingcontoler.VelocitySync()
      else if (cmd == "SyncVelPassive")
        wasmModule.stagingcontoler.VelocityPassiveAligner()
      else if (cmd == "RemoveSeq")
        wasmModule.stagingcontoler.RemoveSeqMoveNode(i)
      else if (cmd == "DistByStep")
        wasmModule.stagingcontoler.StagingSeqMove(i, 0)
      else if (cmd == "DistBySpc")
        wasmModule.stagingcontoler.StagingSeqMove(i, 1)
      else if (cmd == "PremExtra")
        wasmModule.stagingcontoler.StagingSeqMove(i, 2)
      else if (cmd == "PostExp") wasmModule.stagingcontoler.StagingSeqMove(i, 3)
      else if (cmd == "Deepbite")
        wasmModule.stagingcontoler.StagingSeqMove(i, 4)
      else if (cmd == "RemoveAutoStage")
        wasmModule.stagingcontoler.RemoveAutoAllStage(i)
      else if (cmd == "AutoStage") wasmModule.stagingcontoler.AutoStage(i)
      else if (cmd == "AutoCurStage")
        wasmModule.stagingcontoler.AutoAllCurStage(i)
      else if (cmd == "RemoveC_Chain")
        wasmModule.stagingcontoler.RemoveC_chainNode(i)
      else if (cmd == "3x3Upper")
        wasmModule.stagingcontoler.AddC_chainNode(i, false)
      else if (cmd == "4x4Upper")
        wasmModule.stagingcontoler.AddC_chainNode(i, true)
      else if (cmd == "3x3Lower")
        wasmModule.stagingcontoler.AddC_chainNode(i, false)
      else if (cmd == "4x4Lower")
        wasmModule.stagingcontoler.AddC_chainNode(i, true)
      wasmModule.stagingcontoler.FinalStage(i)
    }
    if (cmd == "AddIntrusion") {
      console.log("AddIntrusion click func")
      wasmModule.stagingcontoler.OverIntrusion()
      wasmModule.stagingcontoler.FinalStage(0)
      wasmModule.stagingcontoler.FinalStage(1)
    } else if (cmd == "RemoveIntrusion") {
      console.log("RemoveIntrusion click func")
      wasmModule.stagingcontoler.RemoveOverIntrusionNode()
      wasmModule.stagingcontoler.FinalStage(0)
      wasmModule.stagingcontoler.FinalStage(1)
    }

    stagingManager.updateStages()
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  const label = { inputProps: { "aria-label": "Size switch demo" } }

  const autoStagingBtns = [
    { label: "Auto", name: "AutoAll" },
    { label: "Remove", name: "RemoveAll" },
  ]
  const seqMoveBtns = [
    { label: "Dist. by Step", name: "DistByStep" },
    { label: "Dist. by Space", name: "DistBySpc" },
    { label: "Prem. Extrac", name: "PremExtra" },
    { label: "Post. Exp", name: "PostExp" },
    { label: "Deepbite", name: "Deepbite" },
  ]
  const stagingIPRBtns = [
    { label: "Auto", name: "AutoStage" },
    { label: "Current Stage", name: "AutoCurStage" },
  ]

  const cchainUpperBtns = [
    { label: "Ant. 3x3", name: "3x3Upper" },
    { label: "Ant. 4x4", name: "4x4Upper" },
  ]

  const cchainLowerBtns = [
    { label: "Ant. 3x3", name: "3x3Lower" },
    { label: "Ant. 4x4", name: "4x4Lower" },
  ]

  const wearTimeBtns = [
    { label: "Weekly", name: "VelWeekly" },
    { label: "Bi-weekly", name: "VelBiWeekly" },
  ]

  const syncULBtns = [
    { label: "Sync Vel", name: "SyncVelSync" },
    { label: "Passive", name: "SyncVelPassive" },
  ]

  const OnEditKF = () => {
    dispatch(setIsShow(!isShow))
  }

  const OnShowTMChart = () => {
    dispatch(setShowTMChart(!showTMChart))
  }

  return (
    <Stack direction="column" spacing={1}>
      <FlowBtnGroup
        label="Staging"
        btnGroup={autoStagingBtns}
        onButtonClick={onClickStagingBtn}
        btnW={4}
      >
        <Grid item xs={3}>
          <FlowToggleButton value="EditKF" selected={isShow} onClick={OnEditKF}>
            KF Edit
          </FlowToggleButton>
        </Grid>
        <Grid item xs={3}>
          <FlowToggleButton
            value="TMChart"
            selected={isShow}
            onClick={OnShowTMChart}
          >
            KF Edit
          </FlowToggleButton>
        </Grid>
      </FlowBtnGroup>
      <Divider />
      <FlowBtnGroup
        label="Sequential Movement"
        btnGroup={seqMoveBtns}
        onButtonClick={onClickStagingBtn}
        btnW={4}
      />
      <Divider />
      <FlowBtnGroup
        label="Staging IPR"
        btnGroup={stagingIPRBtns}
        onButtonClick={onClickStagingBtn}
        btnW={4}
      />
      <Divider />
      <Typography>C-chain</Typography>
      <FlowBtnGroup
        lineLabel="Upper"
        btnGroup={cchainUpperBtns}
        onButtonClick={onClickStagingBtn}
        btnW={4}
      />
      <FlowBtnGroup
        lineLabel="Lower"
        btnGroup={cchainLowerBtns}
        onButtonClick={onClickStagingBtn}
        btnW={4}
      />
      <Divider />
      <Typography>Veloctiy</Typography>
      <FlowBtnGroup
        lineLabel="Wearing Time"
        btnGroup={wearTimeBtns}
        onButtonClick={onClickStagingBtn}
        btnW={4}
      />
      <FlowBtnGroup
        lineLabel="Sync. U/L"
        btnGroup={syncULBtns}
        onButtonClick={onClickStagingBtn}
        btnW={4}
      />
    </Stack>
  )
}
export default WeFlowStaging
