import { FC } from "react"

import { Backdrop, CircularProgress } from "../../mui.components"

export interface IULoading {
  /**
   * This parameter help to enable and diable the loader
   */
  isLoading?: boolean
}

const ULoading: FC<IULoading> = ({ isLoading }) => {
  if (isLoading) {
    return (
      <>
        <Backdrop
          sx={{
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.modal + 1,
          }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      </>
    )
  }
  return <></>
}

ULoading.defaultProps = {
  isLoading: false,
}

export default ULoading
