import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import {
  fetchFormData,
  fetchFormList,
  saveUassistFormDataService,
} from "./form.Service"

export const fetchFormDatas = createAsyncThunk(
  "formService/fetchDoctorsList",
  async () => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return fetchFormData(orgId)
    } catch (err) {
      return err
    }
  },
)

export const fetchFormLists = createAsyncThunk(
  "formService/fetchFormLists",
  async () => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return fetchFormList(orgId)
    } catch (err) {
      return err
    }
  },
)
export const saveUassistFormData = createAsyncThunk(
  "formService/saveUassistFormData",
  async (
    { formId, payload }: { formId: string; payload: any },
    { rejectWithValue, getState },
  ) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return saveUassistFormDataService(orgId, formId, JSON.stringify(payload))
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
