import http from "../../../global/http"

import {
  clinicalRequestBasicType,
  getZipsType,
  UploadZips,
} from "./clinical.types"
export const getFileInfo = async ({
  orgId,
  patientId,
  caseId,
}: clinicalRequestBasicType): Promise<unknown> => {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/files`,
    )

    return data
  } catch (err) {
    return err
  }
}
export const getFileZips = async ({
  patientId,
  caseId,
  zipNames,
  orgId,
}: getZipsType) => {
  return Promise.all(
    zipNames.map((item) => {
      return http.get(
        `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/cases/${item}.zip`,
        {
          method: "GET",
          responseType: "blob",
        },
      )
    }),
  )
}

/**
 * get treatment plan information
 * @param orgId
 * @param patientId
 * @returns: treament list
 */
export async function getCaseByPatient(orgId: string, patientId: string) {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans`,
    )
    return data
  } catch (err) {
    return err
  }
}

export async function uploadZips({
  patientId,
  caseId,
  zips,
  orgId,
}: UploadZips) {
  return Promise.all(
    zips.map(({ file, fileName }) => {
      const formData = new FormData()
      formData.append("attachment", file)
      return http.post(
        `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/cases/${fileName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
    }),
  )
}
