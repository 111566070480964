import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IAlertState {
  alertMsg: string
  isError: boolean
}

const initialState: IAlertState = {
  alertMsg: "",
  isError: false,
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (
      state,
      action: PayloadAction<{ message: string; isError?: boolean }>,
    ) => {
      state.alertMsg = action.payload.message
      state.isError = action.payload.isError || false
    },
  },
})

export const { setAlert } = alertSlice.actions
export default alertSlice.reducer
