import * as React from "react"
import { Typography, Box, Stack, Divider } from "@mui/material"
import { FlowBtnGroup, FlowNameSliderGrid } from "../../Custom/FlowCustom"
import { gdsPlusSetup, OJOBType, MidlineType } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"

import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

const DrawAnterior = (props) => {
  const { anterior, rootTorque, disabled } = useAppSelector(
    (state: RootState) => state.autoSetupSlice,
  )
  const dispatch = useAppDispatch()

  const antBtns = [
    { label: "Ant 0mm", name: "OJImprove" },
    { label: "Ant 0.5mm", name: "OJHalfMm" },
    { label: "Ant 1.0mm", name: "OJOneMm" },
  ]
  const antSliders = [anterior.OJ, anterior.OB, anterior.LL]

  const midlineBtns = [
    { label: "Keep Init", name: "MidlineInit" },
    { label: "Center", name: "MidlineCenter" },
    { label: "To Lower", name: "MidlineToLower" },
    { label: "To Upper", name: "MidlineToUpper" },
  ]
  const midlineSliders = [anterior.MidlineUpper, anterior.MidlineLower]

  const antRootTorqueSliders = [
    rootTorque.AntInclUpper,
    rootTorque.AntInclLower,
  ]

  React.useEffect(() => {
    console.log("mount anterior")
    dispatch({ type: "autosetup/updateState" })
    return () => {
      console.log("unmount anterior")
    }
  }, [])

  const onSliderChange = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    const payload = { variable: variable, value: value }
    let type = "autosetup/setAnterior"
    if (variable.includes("JawMove")) type = "autosetup/setArchform"
    else if (variable.includes("AntInc")) type = "autosetup/rootTorque"
    dispatch({
      type: type,
      payload: payload,
    })
  }

  const onSliderChangeCommitted = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamDouble(variable, value)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "OJImprove":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.Improve, () => {})
        break
      case "OJHalfMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.HalfMmSpace, () => {})
        break
      case "OJOneMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.OneMmSpace, () => {})
        break
      case "MidlineInit":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_INIT, () => {})
        break
      case "MidlineCenter":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_CENTER, () => {})
        break
      case "MidlineToLower":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_TOUPPER, () => {})
        break
      case "MidlineToUpper":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_TOLOWER, () => {})
        break
    }
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">Anterior</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={antBtns}
        onButtonClick={onButtonClick}
      />
      <FlowNameSliderGrid
        params={antSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <Divider />
      <Typography variant="body1">Midline</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={midlineBtns}
        onButtonClick={onButtonClick}
      />
      <FlowNameSliderGrid
        params={midlineSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <Typography variant="body1">Ant. Root Torque</Typography>
      <FlowNameSliderGrid
        params={antRootTorqueSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
    </Stack>
  )
}
export default DrawAnterior
