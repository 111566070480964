import { createSlice } from "@reduxjs/toolkit"

import { PhotoObject } from "../photograph/photograph.type"

import { deleteFiles, downloadXray, uploadXray } from "./xraysThunkApi"

interface XrayUploadState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  xrayUploadLoading: "idle" | "pending" | "succeeded" | "failed"
  pano: PhotoObject[]
  ceph: PhotoObject[]
  otherXrays: PhotoObject[]
}

const initialState: XrayUploadState = {
  loading: "idle",
  xrayUploadLoading: "idle",
  pano: [],
  ceph: [],
  otherXrays: [],
}

export const xrayServiceSlice = createSlice({
  name: "XrayService",
  initialState,
  reducers: {
    resetXrays: (state) => {
      state.pano = []
      state.ceph = []
      state.otherXrays = []
    },
    setAdditionalXrays: (state, action) => {
      const newData = [...state.otherXrays, ...action.payload.fileObj]
      state.otherXrays = [...newData]
    },
    removeAdditionalXray: (state, action) => {
      state.otherXrays = [...action.payload.newArray]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadXray.pending, (state) => {
      state.xrayUploadLoading = "pending"
    })
    builder.addCase(uploadXray.fulfilled, (state, action) => {
      console.log("..uploadxrays.fulfilled.", action.payload)
      const result = action.payload.data.result
      const { file, radioGraphType, isAdditional } = action.meta.arg
      if (file && !isAdditional) {
        const fileObj = {
          src: URL.createObjectURL(file),
          name: result.original_file_name,
          sequence: result.sequence,
          position: result.position,
          fileName: result.file_name,
          size: file.size,
        }
        switch (radioGraphType) {
          case "pano":
            state.pano = [fileObj]
            break
          case "ceph":
            state.ceph = [fileObj]
            break
          case "others":
            state.otherXrays = [...state.otherXrays, fileObj]
            break
        }
      }
      state.xrayUploadLoading = "succeeded"
    })
    builder.addCase(uploadXray.rejected, (state) => {
      console.log("rejected")
      state.xrayUploadLoading = "failed"
    })

    builder.addCase(downloadXray.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadXray.fulfilled, (state, action) => {
      console.log("..uploadxrays.fulfilled.", action)
      const result = action.payload.data
      if (result) {
        const file = new File(
          [result],
          `${action.meta.arg.original_file_name}`,
          { type: "image/jpg" },
        )
        const obj: PhotoObject = {
          src: URL.createObjectURL(file),
          sequence: action.meta.arg.sequence,
          position: action.meta.arg.position,
          name: action.meta.arg.original_file_name,
          fileName: action.meta.arg.fileName,
          size: file.size,
        }
        const xrayType = action.meta.arg.XrayType
        switch (xrayType) {
          case "pano":
            state.pano = [obj]
            break
          case "ceph":
            state.ceph = [obj]
            break
          case "others":
            state.otherXrays = [...state.otherXrays, obj]
            break
          default:
            break
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadXray.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
    builder.addCase(deleteFiles.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(deleteFiles.fulfilled, (state, action) => {
      const res = action.payload // TODO: for testing purposes will remove this later
      if (res.data.status === "Success") {
        const fileName = action.meta.arg.fileName
        if (fileName.includes("pano")) {
          console.log("pano")
          state.pano = []
        } else if (fileName.includes("ceph")) {
          console.log("ceph")
          state.ceph = []
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(deleteFiles.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
  },
})
export const { resetXrays, setAdditionalXrays, removeAdditionalXray } =
  xrayServiceSlice.actions
