import { createMachine, assign } from "xstate";
import { toothCorrectionModule } from "./toothcorrectiondispatcher";
import { wasmModule } from "../wasm/wasmModule";
import { alertPopup } from "../../common/popuphelper";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";

/**
 * 开启/关闭trimming line mode
 */
// moduleManager.TogglePolyPlaneCut(true/false)

/**
 * 开启/关闭trimming surface mode
 */
// moduleManager.ToggleSurfaceCutting(true/false)

/**
 * 开始画线
 * as triming line mode
 * wasmTrimCutModule = wasmModule.moduleManager.GetPolyPlaneCutModule()
 * as triming surface mode
 * wasmTrimCutModule = wasmModule.moduleManager.GetSurfaceCuttingModule()
 *
 */
//wasmTrimCutModule.StartDrawLine()

/**
 * 清理画线
 */
//wasmTrimCutModule.Clear()

/**
 * 裁剪
 */
//wasmTrimCutModule.Cut()

/**
 * 取消所有历史操作
 */
//wasmTrimCutModule.Undo()

class TrimmingModule {
  state;
  private _wasmCutModule;

  get wasmTrimCutModule() {
    return this._wasmCutModule;
  }

  get moduleManager() {
    const ulabwinIns = wasmModule.getWasmObj()._ulabwinIns;
    const moduleManager = ulabwinIns.getModuleManager();
    return moduleManager;
  }

  doTrimline() {
    wasmModule.moduleManager.TogglePolyPlaneCut(true);
    this._wasmCutModule = wasmModule.moduleManager.GetPolyPlaneCutModule();
  }
  closeTrimline() {
    wasmModule.moduleManager.TogglePolyPlaneCut(false);
    this._wasmCutModule = undefined;
  }

  doTrimsurface() {
    wasmModule.moduleManager.ToggleSurfaceCutting(true);
    this._wasmCutModule = wasmModule.moduleManager.GetSurfaceCuttingModule();
  }

  closeTrimsurface() {
    wasmModule.moduleManager.ToggleSurfaceCutting(false);
    this._wasmCutModule = undefined;
  }

  constructor() {
    this.state = createMachine({
      id: "trimmingmodule",
      initial: "trimline",
      context: {
        trimLine: false,
        scissors: false,
      },
      states: {
        trimline: {
          entry: [assign({ trimLine: () => true }), () => this.doTrimline()],
          exit: [assign({ trimLine: () => false }), () => this.closeTrimline()],
          on: {
            TOGGLE_TRIMMING: "scissors",
          },
        },
        scissors: {
          entry: [assign({ scissors: () => true }), () => this.doTrimsurface()],
          exit: [
            assign({ scissors: () => false }),
            () => this.closeTrimsurface(),
          ],
          on: {
            TOGGLE_TRIMMING: "trimline",
          },
        },
      },
    });
  }

  /**
   * start draw line
   */
  startDrawLine() {
    const ret = this.wasmTrimCutModule?.StartDrawLine();
    if (ret !== 0) {
      alertPopup("warning", "the function must need single jaw.");
    }
  }

  /**
   * do cut action
   */
  cut() {
    const ret = this.wasmTrimCutModule?.Cut();

    if (ret !== 0) {
      alertPopup("warning", "cut false.");
    }
  }

  /**
   * clear all lines
   */
  clear() {
    this.wasmTrimCutModule?.Clear();
  }

  /**
   * undo history actions
   */
  undo() {
    this.wasmTrimCutModule?.Undo();
  }

  doAction() {
    toothCorrectionModule.service.send("CLICKED_TRIMMING");
  }
}

export const trimmingModule = new TrimmingModule();

/**
 * bind button clicked events
 */
globalEvents.on(GlobalEvents.ON_TRIMMING_DRAW, () => {
  trimmingModule.startDrawLine();
});

globalEvents.on(GlobalEvents.ON_TRIMMING_CUT, () => {
  trimmingModule.cut();
});

globalEvents.on(GlobalEvents.ON_TRIMMING_UNDO, () => {
  trimmingModule.undo();
});

globalEvents.on(GlobalEvents.ON_TRIMMING_CLEAR, () => {
  trimmingModule.clear();
});

globalEvents.on(
  GlobalEvents.TOOTH_CORRECTION_TRIMMING_CLICKED,
  (type: "trimline" | "scissors") => {
    toothCorrectionModule.service.state.context.trimmingRef.send(
      "TOGGLE_TRIMMING"
    );
  }
);
