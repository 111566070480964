import { FC, useMemo, useState } from "react"
import { JsonSchema, UISchemaElement } from "@jsonforms/core"
import { materialCells, materialRenderers } from "@jsonforms/material-renderers"
import { JsonForms } from "@jsonforms/react"

import {
  UAccordionContainer,
  UAccordionContainerTester,
  UArchesTreat,
  UArchesTreatTester,
  UCheckboxAccordian,
  UCheckboxAccordianTester,
  UCheckboxGroup,
  UCheckboxGroupTester,
  UGroupContainer,
  UGroupContainerTester,
  UInput,
  UInputTester,
  URadioGroup,
  URadioGroupTester,
  URadioTextGroup,
  URadioTextGroupTester,
  USummary,
  USummaryTester,
  UTemplateSelect,
  UTemplateSelecTester,
  UTextarea,
  UTextareaTester,
} from "../components"

const JsonFormBuilder: FC<{
  schema: JsonSchema
  uischema: UISchemaElement
  defaultData: any
  isFormSubmitted: boolean
  setIsFormSubmitted?: any
  setData?: any
  handleChange?: any
}> = ({
  schema,
  uischema,
  defaultData,
  setData,
  isFormSubmitted,
  setIsFormSubmitted,
  handleChange,
}) => {
  const createTranslator =
    (locale: string) => (key: string, defaultMessage: string) => {
      if (key === "error.required") {
        return "This field is required"
      }
      return defaultMessage
    }
  //TODO: In future we can make dynamic for now default as "en"
  const [locale, setLocale] = useState<"en">("en")
  const translation = useMemo(() => createTranslator(locale), [locale])

  const changeHandler = (data) => {
    if (data.arch_form_type !== undefined) {
      setData(data)
    }
  }

  const renderers = [
    ...materialRenderers,
    //register custom renderers
    { tester: UArchesTreatTester, renderer: UArchesTreat },
    { tester: UGroupContainerTester, renderer: UGroupContainer },
    { tester: UTemplateSelecTester, renderer: UTemplateSelect },
    { tester: URadioGroupTester, renderer: URadioGroup },
    { tester: UInputTester, renderer: UInput },
    { tester: URadioTextGroupTester, renderer: URadioTextGroup },
    { tester: UCheckboxGroupTester, renderer: UCheckboxGroup },
    { tester: UCheckboxAccordianTester, renderer: UCheckboxAccordian },
    { tester: UTextareaTester, renderer: UTextarea },
    { tester: USummaryTester, renderer: USummary },
    { tester: UAccordionContainerTester, renderer: UAccordionContainer },
  ]

  const isDeepEqual = (currData: any, prevData: any) => {
    // Check if the objects are of the same type and not null
    if (
      typeof currData !== "object" ||
      typeof prevData !== "object" ||
      currData === null ||
      prevData === null
    ) {
      return currData === prevData
    }

    const keysA = Object.keys(currData)
    const keysB = Object.keys(prevData)

    // Check if the number of keys is the same
    if (keysA.length !== keysB.length) {
      return false
    }

    for (const key of keysA) {
      // Recursively compare nested objects
      if (!isDeepEqual(currData[key], prevData[key])) {
        return false
      }
    }

    // If no differences are found, the objects are equal
    return true
  }

  return (
    <JsonForms
      i18n={{ locale: locale, translate: translation }}
      schema={schema}
      uischema={uischema}
      data={defaultData}
      renderers={renderers}
      cells={materialCells}
      validationMode={isFormSubmitted ? "ValidateAndShow" : "NoValidation"}
      onChange={({ data, errors }) => {
        if (isDeepEqual(data, defaultData)) return
        setIsFormSubmitted(false)
        handleChange(data, errors)
      }}
      sx={{ width: "100%" }}
    />
  )
}

export default JsonFormBuilder
