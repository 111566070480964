import { FC, useEffect, useState } from "react"
import { Box, Tooltip } from "@mui/material"

import uassist from "../../../../assets/svgs/chat.svg"
import rx from "../../../../assets/svgs/paste.svg"
import close from "../../../../assets/svgs/pre.svg"
import records from "../../../../assets/svgs/records.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import SvgToggleBtn from "../../../../components/DarkMode/SvgButton/svgtogglebtn"

import Message from "./message"
import Records from "./records"

import "./sideBar.scss"

const SideBar: FC<{
  id: string
  openFill: (arg: { key: string; value: number } | undefined) => void
  boardingStep: number
  showSummary: (val: boolean) => void
  isShowSummary: boolean
}> = ({ id, openFill, boardingStep, showSummary, isShowSummary }) => {
  const btnList = ["uassist", "records", "rx"]
  const svg = { uassist: uassist, records: records, rx: rx }

  const [selectBtn, setselectBtn] = useState("")

  const [readMessage, setreadMessage] = useState(false)

  const name = {
    uassist: "uAssist",
    records: "Patient records",
    rx: "SmartRx",
  }

  useEffect(() => {
    if (selectBtn === btnList[0]) {
      setreadMessage(true)
    }
    if (selectBtn === btnList[2]) {
      showSummary(true)
      return
    } else {
      showSummary(false)
    }
  }, [selectBtn])

  useEffect(() => {
    if (!isShowSummary) setselectBtn("")
  }, [isShowSummary])

  const toolBar = () => {
    return (
      <Box
        id={id}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 48,
          height: "100%",
          border:
            boardingStep === 0 ? "1px solid #0288D1" : "1px solid #455A64",
          zIndex: boardingStep === 0 && 2000,
          background: "#37474F",
        }}
      >
        {btnList.map((item) => {
          return (
            <Tooltip
              key={item}
              title={name[item]}
              placement="right"
              id={"tooltip"}
            >
              <Box sx={{ position: "relative" }}>
                <SvgToggleBtn
                  boxSx={{
                    height: 48,
                    width: 48,
                    borderRadius: 0,
                  }}
                  value={item}
                  svg={svg[item]}
                  isSelected={selectBtn === item}
                  clickFun={() => {
                    setselectBtn(selectBtn === item ? "" : item)
                  }}
                />
                {item === "uassist" && !readMessage && (
                  <Box
                    sx={{
                      position: "absolute",
                      background: "#FFA726",
                      height: 8,
                      width: 8,
                      borderRadius: 4,
                      top: 10,
                      right: 10,
                    }}
                  />
                )}
              </Box>
            </Tooltip>
          )
        })}
      </Box>
    )
  }

  const drawer = () => {
    return (
      <Box
        sx={{
          transition:
            "width 0.4s ease-out, opacity 0.2s ease-in, visibility 0.2s ease-in",
          border:
            (selectBtn === btnList[0] || selectBtn === btnList[1]) &&
            "1px solid #455A64",
          backgroundColor: "#37474F",
          opacity: selectBtn === btnList[0] || selectBtn === btnList[1] ? 1 : 0,
          visibility:
            selectBtn === btnList[0] || selectBtn === btnList[1] ? 1 : 0,
          width: selectBtn === btnList[0] || selectBtn === btnList[1] ? 390 : 0,
          height: "100%",
        }}
      >
        <Box
          id="draws-title"
          sx={{
            height: 48,
            color: "rgba(255, 255, 255, 0.70)",
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: "0.15px",
            padding: "0 16px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
          }}
        >
          <Box>{name[selectBtn]}</Box>
          <SvgBtn
            svg={close}
            clickFun={() => {
              setselectBtn("")
            }}
          ></SvgBtn>
        </Box>
        <Box
          id="draws-content"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 48px)",
          }}
        >
          {selectBtn === btnList[1] && <Records openFill={openFill} />}
          {selectBtn === btnList[0] && <Message />}
        </Box>
      </Box>
    )
  }
  return (
    <Box sx={{ display: "flex" }}>
      {toolBar()}
      {drawer()}
    </Box>
  )
}

export default SideBar
