import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactSVG } from "react-svg"
import { Box, Button, Switch } from "@mui/material"

import close from "../../../../assets/svgs/close.svg"
import order from "../../../../assets/svgs/shopping.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import { UText } from "../../../../ui-component"
import ToolBar from "../ToolBar/toolBar"
import { headerProps } from "../ToolBar/type"

const Header = forwardRef(
  (
    {
      preparation,
      setBoltonPanelShow,
      boltonPanelShow,
      setOpenOverlay,
      openOverlay,
      boardingStep,
      isHasUpAndlow,
      isReady,
    }: headerProps,
    ref,
  ) => {
    const { t } = useTranslation("common")
    const toolBarRef = useRef()
    useImperativeHandle(ref, () => ({
      closeToothSizeByHead: () => {
        toolBarRef?.current.closeToothSizeByToolBar()
      },
    }))
    const [checkedSwtich, setcheckedSwtich] = useState(false)

    return (
      <Box
        id="header"
        sx={{
          height: "48px",
          background: "#37474F",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "0 16px",
          marginTop: "1px",
        }}
      >
        <Box
          component={"span"}
          sx={{
            color: "#FFFFFF",
            height: 34,
            width: 336,
            lineHeight: 2.3,
          }}
          onClick={() => {
            // TODO: goto overview page
          }}
        >
          <Button
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
              "&:focus": {
                backgroundColor: "rgba(255, 255, 255, 0.12)",
              },
              cursor: "pointer",
              width: 171,
              padding: "6px 8px",
              borderRadius: 0.5,
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#fff",
            }}
          >
            L. HAMILTON #1214314
          </Button>
        </Box>
        <ToolBar
          ref={toolBarRef}
          preparation={preparation}
          setBoltonPanelShow={setBoltonPanelShow}
          boltonPanelShow={boltonPanelShow}
          setOpenOverlay={setOpenOverlay}
          openOverlay={openOverlay}
          isHasUpAndlow={isHasUpAndlow}
          isReady={isReady}
        />
        <Box
          sx={{
            width: 336,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Switch
            checked={checkedSwtich}
            disabled={!isReady}
            onClick={() => {
              setcheckedSwtich(!checkedSwtich)
            }}
            size="small"
          />
          <UText
            sxProp={{ display: "block", color: "#FFF", marginRight: 3.5 }}
            variant={"body1"}
          >
            {t("button.edit")}
          </UText>

          <Button
            disabled={!isReady}
            variant="contained"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "4px 10px",
              height: 30,
              width: 89,
              backgroundColor: (theme) => theme.palette.primary.main,
              marginRight: 3,
              zIndex: boardingStep === 2 && 2000,
              border: boardingStep === 2 && "1px solid #0288D1",
            }}
          >
            <ReactSVG src={order} style={{ marginTop: 7 }}></ReactSVG>
            <UText
              variant="caption"
              sxProp={{
                fontSize: 13,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.contrastText,
                marginLeft: 1,
              }}
            >
              {t("button.order")}
            </UText>
          </Button>
          <SvgBtn
            svg={close}
            clickFun={() => {
              // TODO: 关闭clinical
            }}
            boxSx={{ float: "right" }}
          />
        </Box>
      </Box>
    )
  },
)
Header.displayName = "Header"
export default Header
