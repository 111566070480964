import more40thumbDown from "../../../../assets/images/clinical/more40selectDown.svg"
import more40thumbUp from "../../../../assets/images/clinical/more40selectUp.svg"
import less40thumbDown from "../../../../assets/images/clinical/selectedDown.svg"
import less40thumbUp from "../../../../assets/images/clinical/selectedUp.svg"

export const stageConfig = {
  over40: {
    hoverLeft: "calc(50% - 6px)",
    normalCirLeft: "calc(50% - 2px)",
    normalCirTop: "6px",
    pointWidth: "4px",
    hoverWidth: "12px",
    everyPointNum: 10,
    thumbUp: more40thumbUp,
    thumbDown: more40thumbDown,
    thumbMarkMarginLeft: "-1px",
  },
  less40: {
    hoverLeft: "calc(50% - 10px)",
    normalCirLeft: "calc(50% - 4px)",
    normalCirTop: "4px",
    pointWidth: "8px",
    hoverWidth: "20px",
    everyPointNum: 5,
    thumbUp: less40thumbUp,
    thumbDown: less40thumbDown,
    thumbMarkMarginLeft: "-3px",
  },
}
