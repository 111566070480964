import {
  SageMakerRuntimeClient,
  InvokeEndpointCommand,
} from "@aws-sdk/client-sagemaker-runtime";
import { SCANS_PHOTO } from "../resourcemanager/common";
import { wasmModule } from "../wasm/wasmModule";
import { getAccessToken } from "../../../utils/utils";
import { Buffer } from "buffer";

// import  ori  from './oriData.json'
// import molar from './featuremolar.json'
// import seed from './seedData.json'
// import premolar from './featurePremolar.json'
// import canine from './featurecanine.json'
// import incisor from './featureincisor.json'

export interface featureSendData {
  part_id: string;
  label_id: number;
  vertice: any[];
  adj: any[];
}

const modelHeader = {
  seed: 0,
  orientation: 1,
  molar: 2,
  premolar: 3,
  incisor: 4,
  canine: 5,
  segment: 6,
};

class SagemakerModule {
  preModule: any = undefined;
  logMsgCallback?:Function ; 
  segmentAction: Function = () => {};
  labelingEndCallback?: Function;
  presetupDoneCallback?: Function;
  FileList = [];
  private cacheScansReadCallback?: (value: Map<string, Blob>) => void;
  region = "us-west-2";
  // molar: 'feat-back', canine: 'feat-can', incisor: 'feat-front', premolar: 'feat-mid', orientation: 'orientation', seed: 'seed', segment: 'segment'
  modelType = {
    molar: "molar",
    canine: "canine",
    incisor: "incisor",
    premolar: "premolar",
    orientation: "orientation",
    seed: "seed",
    segment: "segment",
  };
  upOrLow = 1;
  //  presetupCanvas: any = undefined;

  initCallback() {
    (window as any).initNewPreSetupCallback =
      this.initNewPreSetupCallback.bind(this);
    (window as any).GettingOrientationDataCallback =
      this.GettingOrientationDataCallback.bind(this);
    (window as any).GettingSeedDataCallback =
      this.GettingSeedDataCallback.bind(this);
    (window as any).segmentEndCallback = () => {
      if (this.segmentAction) this.segmentAction();
    };
    (window as any).featureEndCallback = this.featureEndCallback.bind(this);
    (window as any).featureGetDataCallback =
      this.featureGetDataCallback.bind(this);
    (window as any).labelingEndCallback = () => {
      if (this.labelingEndCallback) this.labelingEndCallback();
    };
    (window as any).presetupDoneCallback = () => {
      if (this.presetupDoneCallback) this.presetupDoneCallback();
    };
    (window as any).segmentDataCallback = this.segmentDataCallback.bind(this);
    (window as any).logMsgCallback = (msg:string) => {
      if (this.logMsgCallback) 
        this.logMsgCallback(msg); 
      else 
        console.log(msg);
    };
  }

  /**
   * 设置加载结束callback
   * @param segmentDoneCallback
   * @param presetupDoneCallback
   */
  setLoadEndCallback(segmentDoneCallback, presetupDoneCallback) {
    this.segmentAction = segmentDoneCallback;
    this.presetupDoneCallback = presetupDoneCallback;
  }
  initNewPreSetupCallback() {
    this.preModule = wasmModule.module.getUNewPreSetUpWin();
  }
  segmentDataCallback(length) {
    // console.log('segmentdata length:', length);
    this.logMsgCallback("Segemntation: data lenth=" + length); 
    const segData = wasmModule.module
      .getWrapperIns()
      .GetAISplitTeethRequestMsg();
    const aryccs = new Uint8Array(
      wasmModule.module.HEAPU8.buffer,
      segData,
      length
    );
    // console.log('segdata', aryccs);
    const sendData = this.addHead(this.modelType.segment, aryccs);

    this.awsRequest(this.modelType.segment, sendData);

    // //  test--------------------------------------------------------------------
    // let rqCount = 0
    // const rollrq = setInterval(() => {
    //   this.uploadToS3(this.modelType.segment, sendData);
    //   rqCount++;
    //   if(rqCount === 500){
    //     clearInterval(rollrq)
    //   }
    // }, 300)
  }

  addHead(type, input) {
    const sendData = new Uint8Array(input.length + 1);
    sendData[0] = modelHeader[type];
    sendData.set(input, 1);
    return sendData;
  }

  GettingOrientationDataCallback(jsonStr) {
    console.log("GettingOrientationDataCallback*******", jsonStr);
    this.logMsgCallback("Orientation: jsonStr length=" + jsonStr.length); 
    setTimeout(() => {
      this.awsRequest(
        this.modelType.orientation,
        this.addHead(
          this.modelType.orientation,
          new Uint8Array(Buffer.from(JSON.stringify(jsonStr)))
        )
      );
    }, 200);

    // const shot =  this.module.FS.readFile('pre/test.png')
    // console.log("pre/saved",shot)
    //  const link = document.createElement('a')
    // link.style.display = 'none'
    // document.body.appendChild(link)
    // // const change = window.decodeURIComponent(escape(window.atob(Buffer.from(shot).toString('utf8'))))
    // // const blob = new Blob([change])
    // const blob = new Blob([shot])
    // link.href = URL.createObjectURL(blob)
    // link.download = 'shot.png'
    // link.click()
    // document.body.removeChild(link)
  }

  GettingSeedDataCallback(jsonStr) {
    this.logMsgCallback("SeedData: jsonStr length= " + jsonStr.length); 
    this.awsRequest(
      this.modelType.seed,
      this.addHead(
        this.modelType.seed,
        new Uint8Array(Buffer.from(JSON.stringify(jsonStr)))
      )
    );
  }

  featureEndCallback() {
    if (this.segmentAction) this.segmentAction();
  }

  featureGetDataCallback(type, data) {
    this.logMsgCallback("Feature: data length="+ data.length ); 
    this.awsRequest(
      data.tooth_type,
      this.addHead(
        data.tooth_type,
        new Uint8Array(Buffer.from(JSON.stringify(data)))
      )
    );
  }

  webglcontextlost(canvasRef) {
    canvasRef.addEventListener(
      "webglcontextlost",
      this.contextlostprevent,
      false
    );
    canvasRef.addEventListener(
      "mouseover",
      this.webglMouseOver(canvasRef),
      false
    );
    return canvasRef;
  }

  contextlostprevent(e) {
    console.error("WebGL context lost. You will need to reload the page.");
    e.preventDefault();
  }
  webglMouseOver(canvasRef) {
    canvasRef.focus();
  }
  testDownloadStl() {
    const stl = wasmModule.module.FS.readFile("test/pre/arch.vtp");
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    const blob = new Blob([stl]);
    link.href = URL.createObjectURL(blob);
    link.download = "prearch.vtp";
    link.click();
    document.body.removeChild(link);
  }

  awsRequest(smType, file) {
    const selfModel = this;
    const uuid = this.getUuid().concat(smType);
    const accessToken =
      getAccessToken() === null
        ? "for_uplus_access_sagemaker"
        : getAccessToken();
    // const tempAwsUrl = 'wss://o4rr6qzd6e.execute-api.us-west-2.amazonaws.com/qa'
    // const uploadUrlRq = new WebSocket(`${tempAwsUrl}?jwt=${accessToken}`);
    const uploadUrlRq = new WebSocket(
      "wss://apis.clinical.dev.usmile.ai",
      accessToken
    );
    uploadUrlRq.onopen = function () {
      // console.log('websocket open***********')
      const body = { action: "message", type: "s3_put", input_file_name: uuid };
      uploadUrlRq.send(JSON.stringify(body));
    };
    uploadUrlRq.onerror = function () {
      console.error(`${smType} upload error`);
    };
    // uploadUrlRq.onclose = function (e) {
    //     console.log(`${smType} upload 断开: ` + e.code + ' ' + e.reason + ' ' + e.wasClean)
    // }
    uploadUrlRq.onmessage = function (msg) {
      // console.log('upload msg:',msg)
      const uploadUrl = JSON.parse(msg.data);
      if (uploadUrl.s3_put_temp_url) {
        uploadUrlRq.close();
        const uploadRq = new XMLHttpRequest();
        uploadRq.open("PUT", uploadUrl.s3_put_temp_url, false);
        uploadRq.setRequestHeader("Content-type", "text/plain");
        uploadRq.onload = function () {
          // const sagemakerRq = new WebSocket(`${tempAwsUrl}?jwt=${accessToken}`)
          const sagemakerRq = new WebSocket(
            "wss://apis.clinical.dev.usmile.ai",
            accessToken
          );
          sagemakerRq.onopen = function () {
            // console.log('websocket open***********')
            const sgBody = {
              action: "message",
              type: "sagemaker",
              input_file_name: uuid,
            };
            sagemakerRq.send(JSON.stringify(sgBody));
          };
          sagemakerRq.onerror = function () {
            console.error(`${smType} sagemaker error`);
          };
          // sagemakerRq.onclose = function (e) {
          //     console.log(`${smType} sagemaker 断开: ` + e.code + ' ' + e.reason + ' ' + e.wasClean)
          // }
          sagemakerRq.onmessage = function (result) {
            // console.log('sagemaker msg:', result)
            const rt = JSON.parse(result.data);
            if (rt.invocationStatus) {
              if (rt.invocationStatus === "Success") {
                const smResultRq = new XMLHttpRequest();
                smResultRq.open("GET", rt.s3_get_temp_url, false);
                smResultRq.onload = function () {
                  if (smResultRq.response && smResultRq.status == 200) {
                    selfModel.dealSagemakerResult(
                      smType,
                      JSON.parse(smResultRq.response)
                    );
                    sagemakerRq.close();
                  }
                };
                smResultRq.send();
              } else {
                console.error(`${smType} sagemaker error`);
              }
            }
          };
        };
        uploadRq.send(file);
      }
    };
  }

  getUuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx-".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  dealSagemakerResult(type, dataOut) {
    let result;
    switch (type) {
      case this.modelType.orientation:
        result = { type: "ori", outputs: dataOut.outputs };
        this.preModule.getOrientationModule().DealAiMsg(JSON.stringify(result));
        break;
      case this.modelType.canine:
      case this.modelType.incisor:
      case this.modelType.molar:
      case this.modelType.premolar:
        this.preModule.getToothFeatureModule().DealMsg(JSON.stringify(dataOut));
        break;
      case this.modelType.segment:
        this.preModule
          .getCommunicationFunctions()
          .AISplitTeethResponse(JSON.stringify(dataOut));
        break;
    }
  }

  // awsRequest(type, input) {
  //   const selfModel = this;
  //   const jwt = getAccessToken() === null? 'for_uplus_access_sagemaker': getAccessToken();
  //   const xhr = new XMLHttpRequest();
  //   xhr.open('POST', AWS_URL, false);
  //   xhr.onload = function () {
  //     const result = JSON.parse(xhr.response)
  //     const client = new SageMakerRuntimeClient({ region: selfModel.region,
  //       credentials: { accessKeyId: result.AccessKeyId, secretAccessKey: result.SecretAccessKey, sessionToken: result.SessionToken } });
  //     const params = {
  //         EndpointName: result.EndpointName,
  //         ContentType: 'binary',
  //         Body: input,
  //     }

  //     const command = new InvokeEndpointCommand(params)

  //     client.send(command).then(
  //       (data) => {
  //         // console.log(`${type} sagemaker data:`, data);
  //         if (data.Body) {
  //           const dataOut = JSON.parse(Buffer.from(data.Body).toString('utf8'));
  //           selfModel.dealSagemakerResult(type, dataOut)
  //         }
  //       },
  //       (error) => {
  //         // error handling.
  //         // const { requestId, cfId, extendedRequestId } = error.$metadata;
  //         console.log(`${type} sagemaker error:`, error);
  //       },
  //     );
  //   }
  //   const sBody = { jwt: jwt }
  //   xhr.send(JSON.stringify(sBody));
  // }
  getScansPhoto() {
    const photo: Map<string, Blob> = new Map<string, Blob>();
    for (let i = 0; i < SCANS_PHOTO.length; i++) {
      if (wasmModule.module.FS.analyzePath(SCANS_PHOTO[i]).exists) {
        const dir: Blob = wasmModule.module.FS.readFile(SCANS_PHOTO[i]);
        photo.set(SCANS_PHOTO[i], dir);
      }
    }
    return photo;
  }
}
export const sagemakerModule = new SagemakerModule();
