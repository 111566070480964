import {
  ToggleButton,
  Typography,
  styled,
  IconButton,
  IconButtonProps,
} from "@mui/material"

export const NavIconButton = styled(IconButton)({
  width: "60px",
  height: "60px",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0px",
  paddingRight: "0px",
  borderStyle: "solid",
  color: "black",
  "&:hover": {
    color: "black",
    backgroundColor: "lightskyblue",
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "lightskyblue",
  },
})

export const NavToggleButton = styled(ToggleButton)({
  width: "60px",
  height: "60px",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0px",
  paddingRight: "0px",
  color: "black",
  "&:hover": {
    color: "black",
    backgroundColor: "lightskyblue",
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "lightskyblue",
  },
})

export const NavLabel = styled(Typography)({
  textTransform: "none",
  align: "center",
  fontSize: 11,
})
