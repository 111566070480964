import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useOktaAuth } from "@okta/okta-react"

import faq from "../../../assets/images/faq.svg"
import profile from "../../../assets/images/profile.svg"
import logo from "../../../assets/images/uLab-Logo.svg"
import { UText } from "../../../components"
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Toolbar,
} from "../../../components/mui.components"
import useOnClickOutside from "../../../core/app/customHooks/useComponentVisible"
import { useAppDispatch } from "../../../core/app/hooks"
import { fetchDoctorsList } from "../../../core/app/slices/doctors/doctorThunkApi"
import {
  fetchCaseByCaseId,
  fetchPatientById,
} from "../../../core/app/slices/patients/patientThunkApi"

import ProfileOptions from "./ProfileOptions"

import { RootState } from "../../../core/app/store"
import { useAppSelector } from "../../../core/app/hooks"
import { setWeSmileUI } from "../../../wasm3d/Wasm3DServiceSlice"
import { AddNewPatientParams } from "../../../core/app/slices/patients/patient.types"
import { addNewPatient, deletePatient } from "../../../core/app/slices/patients"

const Header: FC = () => {
  const navigate = useNavigate()
  const refElement = useRef(null)
  const dispatch = useAppDispatch()
  const { caseId, patientId } = useParams()

  const { t } = useTranslation("common")
  const { oktaAuth, authState } = useOktaAuth()
  const [tabValue, setTabValue] = useState(0)
  const [isProfileOpen, setIsProfileOpen] = useState(false)

  const logOutRedirect = async () => oktaAuth.signOut()

  useEffect(() => {
    dispatch(fetchDoctorsList())
  }, [])

  useEffect(() => {
    if (patientId && caseId) {
      dispatch(
        fetchPatientById({
          patientId,
        }),
      )
      dispatch(
        fetchCaseByCaseId({
          patientId,
          caseId,
        }),
      )
    }
  }, [caseId, patientId])

  const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  useOnClickOutside(refElement, () => setIsProfileOpen(false))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const homePageRedirect = () => {
    navigate("/")
  }
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )

  const menuItems = [
    {
      name: "Settings",
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/settings/")
      },
    },
    {
      name: "Billing History",
      onClick: () => setIsProfileOpen(false),
    },
    {
      name: "uLab Account",
      onClick: () => setIsProfileOpen(false),
    },
    {
      name: "Custom Packaging",
      onClick: () => setIsProfileOpen(false),
    },
    {
      name: "uDesign App",
      onClick: () => setIsProfileOpen(false),
    },
    {
      name: "Logout",
      onClick: logOutRedirect,
    },
    {
      name: "Show/Hide weSmile",
      onClick: () => dispatch(setWeSmileUI(!weSmileUI)),
    },
  ]

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          backgroundColor: "#ffffff !important",
          boxShadow: "0px 0px 0px 1px #E0E0E0",
          height: "63px",
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box
            component={"img"}
            src={logo}
            onClick={homePageRedirect}
            alt={"Logo"}
            loading="lazy"
            sx={{ cursor: "pointer" }}
          />
          <Box
            component="div"
            sx={{
              borderLeft: "1px solid #000000",
              opacity: "0.12",
              height: "35px",
              mx: 2,
            }}
          ></Box>
          <UText variant="h6" color="#757575" sxProp={{ mr: 2 }}>
            {t("header.company")}
          </UText>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ width: "100%", ml: 4 }}>
              <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="tabs"
                >
                  <Tab
                    label={t("header.patientTabtitle")}
                    {...tabProps(0)}
                    sx={{ height: "64px", letterSpacing: "0.4px", mr: "16px" }}
                  />
                  <Tab
                    label={t("header.trainingTabtitle")}
                    {...tabProps(1)}
                    sx={{ height: "64px", letterSpacing: "0.4px" }}
                  />
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box
            component={"img"}
            src={faq}
            alt={"Logo"}
            loading="lazy"
            sx={{ cursor: "pointer", mr: 2 }}
          />
          <Box component={"div"} ref={refElement} sx={{ display: "flex" }}>
            <Box
              component={"img"}
              onClick={() => setIsProfileOpen(!isProfileOpen)}
              src={profile}
              alt={"Logo"}
              loading="lazy"
              sx={{ cursor: "pointer" }}
            />
            {isProfileOpen && <ProfileOptions menuItems={menuItems} />}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
