import { createSlice } from "@reduxjs/toolkit"

import {
  fetchFormDatas,
  fetchFormLists,
  saveUassistFormData,
} from "./formThunkApi"

interface IFormState {
  formData: any
  formTemplateList: any
  selectedTemplateForm: {
    id: string
    name: string
  }
  isLoading: boolean
  saveDataStatus: "idle" | "pending" | "succeeded" | "failed"
}

const initialState: IFormState = {
  formData: {},
  selectedTemplateForm: {
    id: "",
    name: "",
  },
  formTemplateList: {},
  isLoading: false,
  saveDataStatus: "idle",
}

export const formServiceSlice = createSlice({
  name: "formService",
  initialState,
  reducers: {
    setSelectedTemplateForm: (state, action) => {
      state.selectedTemplateForm = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormDatas.pending, (state) => {
      console.log("pending")

      state.isLoading = true
    })
    builder.addCase(fetchFormDatas.fulfilled, (state, action) => {
      console.log(state, action)
      state.formData = action.payload
      state.isLoading = false
    })
    builder.addCase(fetchFormDatas.rejected, (state) => {
      console.log("rejected")

      state.isLoading = false
    })
    builder.addCase(fetchFormLists.pending, (state) => {
      console.log("pending")
      state.isLoading = true
    })
    builder.addCase(fetchFormLists.fulfilled, (state, action) => {
      state.formTemplateList = action.payload.data.Result
      state.isLoading = false
    })
    builder.addCase(fetchFormLists.rejected, (state) => {
      console.log("rejected")
      state.isLoading = false
    })
    builder.addCase(saveUassistFormData.pending, (state) => {
      console.log("pending")
      state.saveDataStatus = "pending"
    })
    builder.addCase(saveUassistFormData.fulfilled, (state, action) => {
      console.log("fulfilled", action.payload.status)
      state.saveDataStatus =
        action.payload.status === "success" ? "succeeded" : "failed"
    })
    builder.addCase(saveUassistFormData.rejected, (state) => {
      console.log("rejected")
      state.saveDataStatus = "failed"
    })
  },
})

export const { setSelectedTemplateForm } = formServiceSlice.actions
