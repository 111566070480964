import { FC, useEffect } from "react"
import { ControlProps } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"

import { UText } from "../../../components"
import { Box } from "../../../components/mui.components"
import { formVocabs } from "../../Json/Summary"

const USummary: FC<ControlProps> = ({
  schema,
  uischema,
  errors,
  path,
  label,
  data,
  handleChange,
}) => {
  const summaryStyle = {
    width: 316,
    ml: 1,
    lineHeight: "16px",
    color: "text.primary",
    whiteSpace: "break-spaces",
  }

  const findLabelByValue = (inputValue, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].value === inputValue) {
        return data[i].label
      }
    }
    return null
  }

  return (
    <>
      <Box
        sx={{ py: 1, mb: "4px", display: "flex", flexDirection: "row", pl: 1 }}
      >
        <UText
          variant={"body2"}
          sxProp={{
            width: 200,
            color: "rgba(0, 0, 0, 0.60)",
          }}
        >
          {label}
        </UText>
        <Box>
          {
            <>
              {data && schema.ismultiLine ? (
                <>
                  {schema.isobj ? (
                    Object.keys(data).map((key) => (
                      <UText
                        key={key}
                        component={"div"}
                        variant={"subtitle2"}
                        sxProp={{
                          ...summaryStyle,
                        }}
                      >
                        {data[key].radioGroup
                          ? formVocabs[key] + "; " + data[key].radioGroup
                          : formVocabs[key]}
                      </UText>
                    ))
                  ) : (
                    <>
                      <UText
                        component={"div"}
                        variant={"subtitle2"}
                        sxProp={{ ...summaryStyle }}
                      >
                        {data.planning_for_restorations}
                      </UText>
                      <UText
                        component={"div"}
                        variant={"subtitle2"}
                        sxProp={{
                          ...summaryStyle,
                          fontWeight: 400,
                        }}
                      >
                        {data.planning_for_restorations_details}
                      </UText>
                    </>
                  )}
                </>
              ) : data && schema.isArray ? (
                data.map((item) => (
                  <>
                    <UText
                      component={"div"}
                      variant={"subtitle2"}
                      sxProp={{
                        ...summaryStyle,
                      }}
                    >
                      {findLabelByValue(item, schema.checkbox)}
                    </UText>
                  </>
                ))
              ) : (
                <UText
                  component={"div"}
                  variant={"subtitle2"}
                  sxProp={{
                    ...summaryStyle,
                    fontWeight: schema.fontweight,
                  }}
                >
                  {data || ""}
                </UText>
              )}
            </>
          }
        </Box>
      </Box>
    </>
  )
}

export default withJsonFormsControlProps(USummary)
