import { FC, ReactNode } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SxProps } from "@mui/system"

import { UAutoComplete, USelect } from "../index"
import { FormControl, FormHelperText, InputLabel } from "../mui.components"

interface items {
  id: string
  label: string
}
interface IFormAutoComplete {
  inputLabel: string
  helperText?: string | null
  fieldName: string
  rules: RegisterOptions
  errors: FieldErrors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any
  formSxprops?: SxProps
  options: ReactNode | items[]
  sxProp?: SxProps
  defaultValue?: string
  customPopupIcon?: ReactNode
  isHeader?: boolean
  onBlur?: () => void
  isInputLabel?: boolean
}

const FormAutoComplete: FC<IFormAutoComplete> = ({
  fieldName,
  inputLabel,
  rules,
  errors,
  control,
  helperText,
  formSxprops,
  options,
  sxProp,
  defaultValue,
  customPopupIcon,
  isHeader,
  onBlur,
  isInputLabel,
}) => {
  const { t } = useTranslation("common")
  const hasError = !!(errors && errors[fieldName])
  const errorMsg = errors[fieldName]?.message?.toString()
  return (
    <FormControl
      fullWidth
      size={"medium"}
      sx={{ ...formSxprops }}
      error={hasError}
    >
      {/* <InputLabel id={fieldName}>{t(inputLabel)}</InputLabel> */}
      <Controller
        control={control}
        name={fieldName}
        rules={rules}
        render={({ field: { onChange } }) => (
          <UAutoComplete
            label={t(inputLabel)}
            onChangeHandler={(e): void => {
              onChange(e)
            }}
            onBlur={onBlur}
            isInputLabel={isInputLabel}
            id={fieldName}
            options={options}
            hasError={hasError}
            sxProp={{ ...sxProp }}
            defaultValue={defaultValue}
            customPopupIcon={customPopupIcon}
            isHeader={isHeader}
          />
        )}
      />
      {helperText && (
        <FormHelperText id={`${fieldName}-helper-text`}>
          {t(helperText)}
        </FormHelperText>
      )}
      {hasError && errorMsg && (
        <FormHelperText id={`${fieldName}-error`}>{t(errorMsg)}</FormHelperText>
      )}
    </FormControl>
  )
}

FormAutoComplete.defaultProps = {
  helperText: null,
  formSxprops: {
    my: 2,
  },
}

export default FormAutoComplete
