import { createSlice } from "@reduxjs/toolkit"

import { FileItem, PhotoObject } from "./photograph.type"
import {
  downloadPhotographs,
  fetchFilesList,
  updatePhotoPosition,
  uploadPhotographs,
} from "./photographThunkApi"

interface IPhotographState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  isPhotoUploading: "idle" | "pending" | "succeeded" | "failed"
  fileList: FileItem[]
  compositePhoto: PhotoObject[]
  individualPhotos: PhotoObject[]
  additionalPhotos: PhotoObject[]
  isDownloading: "idle" | "pending" | "succeeded" | "failed"
}

const initialState: IPhotographState = {
  loading: "idle",
  isPhotoUploading: "idle",
  fileList: [],
  compositePhoto: [],
  individualPhotos: [],
  additionalPhotos: [],
  isDownloading: "idle",
}

export const photographServiceSlice = createSlice({
  name: "photographService",
  initialState,
  reducers: {
    resetPhotoLists: (state) => {
      state.compositePhoto = []
      state.individualPhotos = []
      state.additionalPhotos = []
      state.fileList = []
    },
    setDownloadStatus: (state, action) => {
      state.isDownloading = action.payload
    },
    setIndividualPhotos: (state, action) => {
      const newData = [
        ...state.individualPhotos,
        ...action.payload.fileObj,
      ].sort((a, b) => Number(a.sequence) - Number(b.sequence))
      state.individualPhotos = [...newData]
    },
    setAdditionalPhotos: (state, action) => {
      const newData = [...state.additionalPhotos, ...action.payload.fileObj]
      state.additionalPhotos = [...newData]
    },
    removeCompositePhoto: (state) => {
      state.compositePhoto = []
    },
    removeAdditionalPhoto: (state, action) => {
      state.additionalPhotos = [...action.payload.newArray]
    },
    removeIndividualPhotos: (state, action) => {
      const newArray = action.payload.newArray.filter(
        (item) => item.name !== "Add",
      )
      state.individualPhotos = [...newArray]
    },
  },
  extraReducers: (builder) => {
    // Upload Photos reducers
    builder.addCase(uploadPhotographs.pending, (state) => {
      state.isPhotoUploading = "pending"
    })
    builder.addCase(uploadPhotographs.fulfilled, (state, action) => {
      const result = action.payload.data.result
      const { file, isAdditional } = action.meta.arg
      if (file && !isAdditional) {
        const fileObj = {
          src: URL.createObjectURL(file),
          name: result.original_file_name,
          sequence: result.sequence,
          position: result.photo_position,
          fileName: result.file_name,
          size: file.size,
        }
        console.log(fileObj)
        if (fileObj.fileName.includes("composite")) {
          state.compositePhoto.push(fileObj)
        } else if (fileObj.fileName.includes("individual")) {
          const newData = [...state.individualPhotos, fileObj].sort(
            (a, b) => Number(a.sequence) - Number(b.sequence),
          )
          state.individualPhotos = [...newData]
        }
      }
      state.isPhotoUploading = "succeeded"
    })
    builder.addCase(uploadPhotographs.rejected, (state) => {
      console.log("rejected")

      state.isPhotoUploading = "failed"
    })

    // Download Photos reducers
    builder.addCase(downloadPhotographs.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadPhotographs.fulfilled, (state, action) => {
      const photographType = action.meta.arg.photographType
      const result = action.payload.data
      if (result) {
        const file = new File(
          [result],
          `${action.meta.arg.original_file_name}`,
          { type: "image/jpg" },
        )
        const obj: PhotoObject = {
          src: URL.createObjectURL(file),
          sequence: action.meta.arg.sequence,
          position: action.meta.arg.position,
          name: action.meta.arg.original_file_name,
          fileName: action.meta.arg.fileName,
          size: file.size,
        }
        switch (photographType) {
          case "composite":
            state.compositePhoto = [obj]
            break
          case "individual":
            state.individualPhotos = [
              ...[...state.individualPhotos, obj].sort(
                (a, b) => Number(a.sequence) - Number(b.sequence),
              ),
            ]
            break
          case "others":
            state.additionalPhotos.push(obj)
            break
          default:
            break
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadPhotographs.rejected, (state, action) => {
      state.loading = "failed"
    })

    // Fetch File list reducers
    builder.addCase(fetchFilesList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchFilesList.fulfilled, (state, action) => {
      const { result } = action.payload.data
      if (result && result.length > 0) {
        state.fileList = [...result]
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchFilesList.rejected, (state) => {
      state.loading = "failed"
    })

    //Update Photo position reducers
    builder.addCase(updatePhotoPosition.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(updatePhotoPosition.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const {
        payload: { data },
      } = action
      state.individualPhotos.map((photo) => {
        data.result.map((res) => {
          if (res.file_name === photo.fileName) {
            photo.position = res.photo_position
          }
        })
      })
    })
    builder.addCase(updatePhotoPosition.rejected, (state) => {
      state.loading = "failed"
    })
  },
})

export const {
  resetPhotoLists,
  setIndividualPhotos,
  setAdditionalPhotos,
  removeCompositePhoto,
  removeAdditionalPhoto,
  removeIndividualPhotos,
  setDownloadStatus,
} = photographServiceSlice.actions
