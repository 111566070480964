import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CloseIcon from "@mui/icons-material/Close"

import { UButton, UText } from "../../../components"
import { Box, Button, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { fetchFormDatas } from "../../../core/app/slices/uassist/formThunkApi"
import { RootState } from "../../../core/app/store"
import JsonFormBuilder from "../../../rx-form/builder/JsonFormBuilder"
import {
  generatePrescriptionDefaultData,
  generatePrescriptionSchema,
  generatePrescriptionUISchema,
} from "../../../rx-form/Json/Summary"

const Summary: FC<{ isPreview?: boolean; hideSummary: () => void }> = ({
  isPreview,
  hideSummary,
}) => {
  const { t } = useTranslation("common")
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const { caseId, patientId } = useParams()
  const { formData } = useAppSelector((state: RootState) => state.formService)

  useEffect(() => {
    window.scrollTo({ top: 0 })
    dispatch(fetchFormDatas())
  }, [])

  return (
    <Grid
      container
      flexDirection={"column"}
      sx={{
        backgroundColor: "background.default",
        borderRadius: 2,
        padding: 5,
        justifyContent: "space-between",
      }}
    >
      {isPreview && (
        <Box
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "end",
            height: "24px",
          }}
        >
          <CloseIcon
            fontSize={"medium"}
            sx={{
              color: "rgba(0, 0, 0, 0.56)",
              fontSize: "14px",
              cursor: "pointer",
              position: "fixed",
              zIndex: "1000",
              m: "5px",
            }}
            onClick={hideSummary}
          />
        </Box>
      )}
      <UText variant={"h4"} component={"div"} sxProp={{ textAlign: "center" }}>
        <Trans components={{ newLine: <br /> }}>
          {"uassist.summary.title"}
        </Trans>
      </UText>
      {!isPreview && (
        <Box sx={{ height: "24px" }}>
          <UText
            component={"div"}
            variant={"body2"}
            sxProp={{ textAlign: "center", color: "rgb(0,0,0,0.6)" }}
          >
            {t("uassist.summary.summarydesc")}
          </UText>
        </Box>
      )}

      {formData.jsonSchemaDefault && (
        <Box sx={{ pt: "4px", mt: 1 }}>
          <JsonFormBuilder
            schema={generatePrescriptionSchema(
              formData.jsonSchemaDefault,
              formData.otherschema,
            )}
            uischema={generatePrescriptionUISchema(
              formData.uiSchemaDefault,
              formData.defaultData,
              formData.otheruischema,
              formData.otherdata,
            )}
            data={generatePrescriptionDefaultData({
              ...formData.defaultData,
              ...formData.otherdata,
            })}
            isFormSubmitted={isFormSubmitted}
          />
        </Box>
      )}
      {!isPreview && (
        <Box
          component={"div"}
          sx={{ justifyContent: "space-between", display: "flex", mt: "28px" }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              navigate(
                `/uassist/patient/${patientId}/case/${caseId}/prescription`,
              )
            }}
            sx={{ width: "96px", height: "36px" }}
          >
            <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
            {t("button.back")}
          </Button>
          <Box>
            <Button
              variant={"text"}
              onClick={() =>
                dispatch(
                  setAlert({
                    message: t("uassist.uAssistForm.uassistCaseSaved"),
                  }),
                )
              }
              sx={{
                minWidth: "auto",
                height: "auto",
                width: "auto",
                lineHeight: "24px",
                marginRight: "16px",
              }}
            >
              {t("button.save")}
            </Button>
            <Button
              variant={"contained"}
              sx={{ width: "196px", height: "36px" }}
              onClick={() => setIsFormSubmitted(true)}
            >
              {t("button.submittoUassist")}
              <ChevronRightIcon
                fontSize={"small"}
                sx={{ height: "20px", width: "20px", marginLeft: "8px" }}
              />
            </Button>
          </Box>
        </Box>
      )}
    </Grid>
  )
}

export default Summary
