import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { wasmModule } from "../wasm/wasmModule";

export enum SetupType {
  Full = 0, // All teeth
  Simple, // Molar to molar
  Anterior, // 5x5
  Ant3x3, // 3x3
  AlignmentOnly, // Full treatment.
  Aligner5, // Anterior only and minimum root movement.
  Init, // All teeth, OJ/OB not improved
  FineTune, // All teeth, minor adjustment
  COPASimple, // Molar to molar and minimum root movement
  COPAModerate, // Molar to molar and minimum posterior movement
}

export enum ArchformType {
  Natural = 0,
  Tapered,
  Ovoid,
  Square,
}

export enum TreatArchType {
  Both = 0,
  UpperOnly,
  LowerOnly,
}
export enum MidlineType {
  MIDTYPE_INIT = 0, // to initial value
  MIDTYPE_CENTER, // to center
  MIDTYPE_TOUPPER, // to upper arch initial
  MIDTYPE_TOLOWER, // to lower arch initial
}
export enum OJOBType {
  Init = 0,
  Improve, // 0 mm
  OneMmSpace,
  QuaterMmSpace,
  HalfMmSpace,
}

export enum IPRRange {
  AntOnly = 0,
  Full,
  IncisorOnly,
  CanineOnly,
}
interface AutoSetupSliderParameter {
  name: string;
  min: number;
  max: number;
}
class GDSPlusSetup {
  sliderParamMap: AutoSetupSliderParameter[] = [
    { name: "ArchformAnt", min: 20, max: 40 },
    { name: "ArchformMid", min: 0.786, max: 0.866 },
    { name: "ArchformPost", min: 40, max: 60 },

    { name: "JawMoveLR", min: -3, max: 3 },
    { name: "JawMoveMD", min: -3, max: 3 },
    { name: "JawMoveUD", min: -3, max: 3 },

    { name: "OJ", min: 1, max: 5 },
    { name: "OB", min: 0.5, max: 5 },
    { name: "LL", min: -1, max: 5 },

    { name: "MidlineUpper", min: -4, max: 4 },
    { name: "MidlineLower", min: -4, max: 4 },
    { name: "AntInclUpper", min: -10, max: 20 },
    { name: "AntInclLower", min: -20, max: 10 },

    { name: "IPRToPrev", min: 0, max: 2 },
    { name: "SpaceToPrev", min: 0, max: 20 },
    { name: "IPRToNext", min: 0, max: 2 },
    { name: "SpaceToNext", min: 0, max: 20 },

    { name: "TMJMoveLimit", min: 0, max: 4 },
  ];

  SwitchAutoSetupModule(isOpen: boolean) {
    if (!wasmModule.isInit) {
      return;
    }
    wasmModule.moduleManager.SwitchAutoSetupModule(isOpen);
  }

  getAutoSetupModule() {
    if (!wasmModule.isInit) {
      return undefined;
    }
    const autoSetupModule = wasmModule.moduleManager.GetAutoSetupModule();
    return autoSetupModule;
  }

  setupByType = (type: SetupType, callback: Function) => {
    const autoSetupModule = this.getAutoSetupModule();
    autoSetupModule.SetupByTypeID(type);

    stagingManager.updateStages();

    callback();
  };

  SetArchformTypeByID = (type: ArchformType, callback: Function) => {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      callback();
      return;
      // return {
      //   ArchformAnt: { lableValue: 0, sliderValue: 0 },
      //   ArchformMid: { lableValue: 0, sliderValue: 0 },
      //   ArchformPost: { lableValue: 0, sliderValue: 0 },
      // };
    }
    autoSetupModule.SetArchformTypeByID(type);
    stagingManager.updateStages();

    callback();

    const ArchformAnt = this.GetParamDouble("ArchformAnt");
    const ArchformMid = this.GetParamDouble("ArchformMid");
    const ArchformPost = this.GetParamDouble("ArchformPost");
    return {
      ArchformAnt: ArchformAnt,
      ArchformMid: ArchformMid,
      ArchformPost: ArchformPost,
    };
  };
  SetTreatArchTypeID = (type: TreatArchType, callback: Function) => {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      callback();
      return;
    }
    autoSetupModule.SetTreatArchTypeID(type);
    callback();
  };

  // ArchformAnt  ArchformMid  ArchformPost  OJ    OB
  GetParamDouble(sliderName: string) {
    const autoSetupModule = this.getAutoSetupModule();

    if (!autoSetupModule) return { lableValue: 0, sliderValue: 0 };

    const slider = this.sliderParamMap.find(x => {
      return x.name === sliderName;
    });
    if (!slider) return { lableValue: 0, sliderValue: 0 };

    const param = autoSetupModule.GetParamDouble(sliderName);

    const pos = ((param - slider.min) * 100.0) / (slider.max - slider.min);

    let sliderlableVal: number = param;
    if (sliderName === "ArchformMid") sliderlableVal = sliderlableVal * 100;

    return { lableValue: sliderlableVal, sliderValue: pos };
  }

  GetSliderLableValue(sliderName: string): number {
    const autoSetupModule = this.getAutoSetupModule();

    if (!autoSetupModule) return 0;

    const slider = this.sliderParamMap.find(x => {
      return x.name === sliderName;
    });
    if (!slider) return 0;

    const param = autoSetupModule.GetParamDouble(sliderName);

    if (sliderName === "ArchformMid") return param * 100;
    else return param;
  }
  SetParamDouble(sliderName: string, sliderValue: number) {
    console.log("SetParamDouble:" + sliderName + ":" + sliderValue);
    const autoSetupModule = this.getAutoSetupModule();

    if (!autoSetupModule) return;

    const slider = this.sliderParamMap.find(x => {
      return x.name === sliderName;
    });
    if (!slider) return;

    const param =
      (sliderValue / 100.0) * (slider.max - slider.min) + slider.min;

    autoSetupModule.SetParamDouble(sliderName, param);

    stagingManager.updateStages();
  }

  ResolveOccColl(
    postOnly: boolean,
    checkSelTeeth: boolean,
    callback: Function
  ) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      callback();
      return;
    }

    autoSetupModule.ResolveOccColl(postOnly, checkSelTeeth);

    stagingManager.updateStages();

    callback();
  }

  /**
   * 4 button function of  Overjet/Overbite in Anterior
   * @param type
   * @param callback
   * @returns
   */
  SetOJOBByTypeID(type: OJOBType, callback: Function) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      callback();
      return;
    }

    autoSetupModule.SetOJOBByTypeID(type);
    callback();

    const OJ = this.GetParamDouble("OJ");
    const OB = this.GetParamDouble("OB");
    const LL = this.GetParamDouble('"LL');
    return {
      OJ: OJ,
      OB: OB,
      LL: LL,
    };
  }
  ResetArchShift() {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) return;
    autoSetupModule.ResetArchShift();
  }

  SetMidlineByTypeID = (type: MidlineType, callback: Function) => {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      callback();
      return;
    }
    autoSetupModule.SetMidlineByTypeID(type);
    callback();

    const MidlineUpper = this.GetParamDouble("MidlineUpper");
    const MidlineLower = this.GetParamDouble("MidlineLower");
    return {
      MidlineUpper: MidlineUpper,
      MidlineLower: MidlineLower,
    };
  };
  SetMidlineEnable(isUpper: boolean, enable: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.SetMidlineEnable(isUpper, enable);
  }

  SetupAPUpperToLower() {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.SetupAPUpperToLower();

    const upper = this.GetSideAPPosAndEnable(true);
    const lower = this.GetSideAPPosAndEnable(false);

    return {
      upper: upper,
      lower: lower,
    };
  }

  NoAPControl() {
    this.EnableSideAP(true, true, false);
    this.EnableSideAP(true, false, false);
    this.EnableSideAP(false, true, false);
    this.EnableSideAP(false, false, false);

    const upper = this.GetSideAPPosAndEnable(true);
    const lower = this.GetSideAPPosAndEnable(false);

    return {
      upper: upper,
      lower: lower,
    };
  }

  SetSideAP(upper: boolean, rightSide: boolean, pos: number) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.SetSideAP(upper, rightSide, Math.ceil(pos));
  }

  EnableSideAP(upper: boolean, rightSide: boolean, enable: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.EnableSideAP(upper, rightSide, enable);
  }

  SetToothAPEnable(enable: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.SetToothAPEnable(enable);
  }

  SetToothAP(pos: number) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.SetToothAP(Math.ceil(pos));
  }

  SetIPRRangeByID(upper: boolean, range: IPRRange) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.SetIPRRangeByID(upper, range);
  }

  SetSelectedToothIPR(pos: number, isSpace: boolean, toNext: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    if (isSpace) pos = -pos;
    autoSetupModule.SetSelectedToothIPR(pos, toNext);
  }
  SetToothIPREnable(enable: boolean, toNext: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return;
    }
    autoSetupModule.SetToothIPREnable(enable, toNext);
  }

  GetMidlineEnable(upper: boolean): boolean {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return false;
    }
    return autoSetupModule.GetMidlineEnable(upper);
  }

  GetToothIRPAndSpace(toNext: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return { enable: false, space: 0, ipr: 0 };
    }
    const enable = this.GetToothIPREnable(toNext);
    const value = this.GetSelectedToothIPRValue(toNext);
    return { enable: enable, space: value, ipr: -value };
  }

  ResolveAllColl(callback: Function) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      callback();
      return;
    }
    autoSetupModule.ResolveAllColl();
    stagingManager.updateStages();
    callback();
  }

  private GetSelectedToothIPRValue(toNext: boolean): number {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return 0;
    }
    return autoSetupModule.GetSelectedToothIPRValue(toNext);
  }

  GetToothIPREnable(toNext: boolean): boolean {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return false;
    }
    return autoSetupModule.GetToothIPREnable(toNext);
  }

  GetToothAPAEnableAndValue() {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return { apEnable: false, apValue: 0 };
    }
    const enable = this.GetToothAPEnable();
    const value = this.GetToothAPPosValue();

    return { apEnable: enable, apValue: value };
  }

  private GetToothAPEnable(): boolean {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return false;
    }
    return autoSetupModule.GetToothAPEnable();
  }

  private GetToothAPPosValue(): number {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return 0;
    }
    return autoSetupModule.GetToothAPPosValue();
  }

  GetSideAPPosAndEnable(isUpper: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return {
        rightEnable: false,
        rightValue: 0,
        leftEnable: false,
        leftValue: 0,
      };
    }
    const rightPos = this.GetSideAPPos(isUpper, true);
    const leftPos = this.GetSideAPPos(isUpper, false);

    const rightEnable = this.GetEnableSideAP(isUpper, true);
    const leftEnable = this.GetEnableSideAP(isUpper, false);

    return {
      rightEnable: rightEnable,
      rightValue: rightPos,
      leftEnable: leftEnable,
      leftValue: leftPos,
    };
  }
  ExtractTooth() {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) return;
    autoSetupModule.PullOutTeeth();
    stagingManager.updateStages();
  }
  RestoreAllTeeth() {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) return;
    autoSetupModule.RestoreAllTeeth();
    stagingManager.updateStages();
  }
  SetToothFixed(tid: number, fixed: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) return;
    autoSetupModule.SetToothFixed(tid, fixed);
    stagingManager.updateStages();
  }

  SetLowerGlobalMovement(autoOrRemove: boolean, biteJump: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) return;
    autoSetupModule.SetLowerGlobalMovement(autoOrRemove, biteJump);
    stagingManager.updateStages();
  }

  GetTMJMoveDist(leftSide: boolean) {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) return 0.0;
    return autoSetupModule.GetTMJMoveDist(leftSide);
  }

  private GetSideAPPos(upper: boolean, rightSide: boolean): number {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return 0;
    }
    return autoSetupModule.GetSideAPPos(upper, rightSide);
  }

  private GetEnableSideAP(upper: boolean, rightSide: boolean): boolean {
    const autoSetupModule = this.getAutoSetupModule();
    if (!autoSetupModule) {
      return false;
    }
    return autoSetupModule.GetEnableSideAP(upper, rightSide);
  }
}
export const gdsPlusSetup = new GDSPlusSetup();
