import cutoutSvg from "../../../../../../assets/images/clinical/report/auxilliaries/cutout.svg"
import cutoutDownSvg from "../../../../../../assets/images/clinical/report/auxilliaries/cutoutBox.svg"
import cutoutUpSvg from "../../../../../../assets/images/clinical/report/auxilliaries/cutoutUp.svg"
import missSvg from "../../../../../../assets/images/clinical/report/auxilliaries/miss.svg"
import pressureSvg from "../../../../../../assets/images/clinical/report/auxilliaries/pressure.svg"
import pressureBoxSvg from "../../../../../../assets/images/clinical/report/auxilliaries/pressureBox.svg"
import slitSvg from "../../../../../../assets/images/clinical/report/auxilliaries/slit.svg"
import slitBox24 from "../../../../../../assets/images/clinical/report/auxilliaries/slitBox.svg"
import slitBox13 from "../../../../../../assets/images/clinical/report/auxilliaries/slitBox13.svg"

export const legendsTmp = [
  {
    key: "Pressure point",
    name: "Pressure point",
    icon: pressureSvg,
  },
  {
    key: "Slit",
    name: "Slit",
    icon: slitSvg,
  },
  {
    key: "Button cutout",
    name: "Button cutout",
    icon: cutoutSvg,
  },
  {
    key: "Missing tooth",
    name: "Missing tooth",
    icon: missSvg,
  },
]
export const qua1Ids = [1, 2, 3, 4, 5, 6, 7, 8]
export const qua2Ids = [9, 10, 11, 12, 13, 14, 15, 16]
export const qua3Ids = [24, 23, 22, 21, 20, 19, 18, 17]
export const qua4Ids = [32, 31, 30, 29, 28, 27, 26, 25]

export const leftNums = [8, 7, 6, 5, 4, 3, 2, 1]
export const rightNums = [1, 2, 3, 4, 5, 6, 7, 8]

export const svgs = {
  pressure: pressureBoxSvg,
  slit24: slitBox24,
  slit13: slitBox13,
  cutoutUp: cutoutUpSvg,
  cutoutDown: cutoutDownSvg,
  miss: missSvg,
}

export const pressIDs = [7, 19, 20]
export const cutoutIDs = [4, 5, 6]
export const slitIDs = [23]
