import { createSlice } from "@reduxjs/toolkit"

import {
  fetchUserPreferences,
  fetchUserProfile,
  IUserPreferences,
  updateUserPreferences,
  updateUserProfile,
} from "./userApis"

interface IUserProfile {
  firstName: string
  lastName: string
  username?: string
  user_type?: string
  org_name?: string
}

interface IUserServiceState {
  user: {
    authorized_roles: []
    global_permissions: []
    email: string
    name: string
    current_orgId: string
    user_id: string
    uid: string
    preferences?: IUserPreferences
  }
  userProfile?: IUserProfile
  loading?: "idle" | "pending" | "succeeded" | "failed"
}

const initialState: IUserServiceState = {
  user: {
    authorized_roles: [],
    global_permissions: [],
    email: "",
    name: "",
    current_orgId: "",
    user_id: "",
    uid: "",
    preferences: {} as IUserPreferences,
  },
  userProfile: {
    firstName: "",
    lastName: "",
    username: "",
    user_type: "",
    org_name: "",
  } as IUserProfile,
  loading: "idle",
}

export const userServiceSlice = createSlice({
  name: "userService",
  initialState,
  reducers: {
    updateLoggedInUser(state, action) {
      const { claims } = action.payload
      try {
        if (claims) {
          const userObj: IUserServiceState = {
            user: {
              authorized_roles: claims.authorized_roles,
              global_permissions: claims.global_permissions,
              email: claims.email,
              name: claims.name,
              current_orgId: claims.authorized_roles[0].org_id,
              user_id: claims.ulab_userId,
              uid: claims.uid,
            },
          }
          if (!userObj.user.current_orgId) {
            throw new Error("orgId not found in authorized_roles")
          }
          state.user = userObj.user
        }
      } catch (err) {
        console.log("error on update logged in user object", err)
      }
    },
    clearLoggedInUser(state) {
      state.user = {
        authorized_roles: [],
        global_permissions: [],
        email: "",
        name: "",
        current_orgId: "",
        user_id: "",
        uid: "",
        preferences: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserPreferences.fulfilled, (state, action) => {
      console.log("get user preference000")
      const { result } = action.payload
      if (result != undefined && result.preferences) {
        state.user.preferences = result.preferences
        console.log("get user preference::", result)
      }
    })
    builder.addCase(updateUserPreferences.fulfilled, (state, action) => {
      const { result } = action.payload as any
      if (result != undefined && result.preferences) {
        console.log("update user preference::", result.preferences)
        state.user.preferences = result.preferences
      }
    })
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      const { result } = action.payload
      state.userProfile = {
        firstName: result.first_name,
        lastName: result.last_name,
        username: result.username,
        user_type: result.user_type,
        org_name: result.org_name,
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchUserProfile.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      const { result } = action.payload
      state.userProfile = {
        ...result,
        firstName: result.first_name,
        lastName: result.last_name,
      }
      state.loading = "succeeded"
    })
    builder.addCase(updateUserProfile.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.loading = "failed"
    })
  },
})

const { updateLoggedInUser, clearLoggedInUser } = userServiceSlice.actions

export { updateLoggedInUser, clearLoggedInUser, fetchUserPreferences }
