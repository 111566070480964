import { FC, useEffect, useState } from "react"
import { Box, Slider, Tooltip } from "@mui/material"
import { stageControl } from "@ulab/wesmileclinical"

import bigDown from "../../../../assets/images/clinical/bigSelectDown.svg"
import bigUp from "../../../../assets/images/clinical/bigSelectUp.svg"
import iprDown from "../../../../assets/images/clinical/iprDown.svg"
import iprUp from "../../../../assets/images/clinical/iprUp.svg"

import { stageConfig } from "./column"

import "./stageBar.scss"
const { setStageCallback, setStageStep } = stageControl
interface retouchType {
  showStage: number | string // 显示的stage点
  postStage: number // 本段的stage点
  postNum: number // 段数
  currentNum: number // 回传的数字
  cirPoint: boolean // 圆点标记
  iprPoint: boolean // 菱形点标记
  quickPoint: boolean // quick3标记
  passiveAlignerPoint: boolean // passiveAlignerPoint
}
// stage单位长度
const uniNumber = 32
let runStep_up = 0
let runStep_down = 0
let timer: any = null
const StageBar: FC = ({ btnObj, setBtnObj, isFold, setStageObj }) => {
  // 设置stageData数据
  const [upList, setUpList] = useState<any[]>([])
  const [downList, setDownList] = useState<any[]>([])
  // marks
  const [up_step_Marks, setUpStepMarks] = useState([])
  const [down_step_Marks, setDownStepMarks] = useState([])
  // one step unit length
  const [unitLen, setUnitLen] = useState<number>(0)
  // up stage length
  const [up_steps, setUpStepLen] = useState<number>(0)
  // down stage length
  const [down_steps, setDownStepLen] = useState<number>(0)
  const [up_current, setUpCurrent] = useState<number>(0)
  const [down_current, setDownCurrent] = useState<number>(0)
  // stageBar type
  const [stageType, setStageType] = useState<string>("less40")
  // get stage marks
  const getMark = (direction: string, data: retouchType[]) => {
    const isupLong =
      upList.length > downList.length || upList.length === downList.length
    const isSmall = upList.length - 1 < 11 && downList.length - 1 < 11
    const percentNum = stageType === "less40" ? 5 : 10
    const tmp: Mark[] = []
    for (let i = 0; i < data.length; i++) {
      const moreThan10 =
        i === 0 || i === data.length - 1 || i % percentNum === 0
          ? "block"
          : direction === "up"
          ? isupLong
            ? i % percentNum === 0
              ? "block"
              : "none"
            : "none"
          : isupLong
          ? "none"
          : i % percentNum === 0
          ? "block"
          : "none"
      const less10 =
        i === 0 || i === data.length - 1 || i % percentNum === 0
          ? "block"
          : direction === "up"
          ? isupLong
            ? "block"
            : "none"
          : isupLong
          ? "none"
          : "block"

      const mark = {
        value: data[i].showStage,
        label: (
          <>
            {/* Ovals on the left and right */}
            {i === 0 && (
              <div
                style={{
                  left: "-6px",
                  top: direction === "down" ? "83px" : "41px",
                }}
                className={"leftProtrusions"}
              ></div>
            )}
            {i === data.length - 1 && (
              <div
                style={{
                  right: stageType === "over40" ? "-2px" : "-4px",
                  top: direction === "down" ? "83px" : "41px",
                }}
                className={"rightProtrusions"}
              ></div>
            )}
            {/* Common points other than first and last */}
            {direction === "up" && !data[i].passiveAlignerPoint && (
              <Tooltip
                placement="top"
                title={data[i].showStage}
                arrow
                classes={{ popper: "upStageTip" }}
              >
                <div
                  style={{
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                    top: "41px",
                  }}
                  className={"normalBox"}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                    }}
                    className={"normalCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
            {direction === "down" && !data[i].passiveAlignerPoint && (
              <Tooltip
                placement="bottom"
                title={data[i].showStage}
                arrow
                classes={{ popper: "downStageTip" }}
              >
                <div
                  style={{
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                    top: "83px",
                  }}
                  className={"normalBox"}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                    }}
                    className={"normalCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
            {/* Common scale */}
            <span
              style={{
                display: isSmall ? less10 : moreThan10,
              }}
              className={"label"}
            >{`${data[i].showStage}`}</span>
            {/* ipr */}
            {data[i].iprPoint && (
              <img
                src={direction === "up" ? iprUp : iprDown}
                style={{
                  position: "absolute",
                  left: `calc(50% - 3.5px + ${unitLen / 2}px)`,
                  top: direction === "up" ? "32px" : "103px",
                }}
              />
            )}
            {/*passiveAlignerPoint */}
            {direction === "up" && data[i].passiveAlignerPoint && (
              <Tooltip placement="top" title={"Passive aligner"} arrow>
                <div
                  className={"alignerBox"}
                  style={{
                    top: "41px",
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                  }}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                    }}
                    className={"alignerCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
            {direction === "down" && data[i].passiveAlignerPoint && (
              <Tooltip placement="bottom" title={"Passive aligner"} arrow>
                <div
                  className={"alignerBox"}
                  style={{
                    top: "83px",
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                  }}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                    }}
                    className={"alignerCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
          </>
        ),
        ...data[i],
      }
      tmp.push(mark)
    }
    if (direction === "up") {
      setUpStepMarks(tmp)
    } else {
      setDownStepMarks(tmp)
    }
  }
  // get unitLen
  const setUnit = (
    upNum: number,
    downNum: number,
    upData: retouchType[],
    downData: retouchType[],
  ) => {
    // let unitCount = 0 // 标记是否被更改了的单位长度
    // if (Number.isFinite(+upNum)) {
    //   if (downNum >= upNum && downNum > 26) {
    //     setUnitLen((uniNumber * 26) / downNum)
    //     unitCount = (uniNumber * 26) / downNum
    //   } else if (unitCount === 0) {
    //     setUnitLen(uniNumber)
    //   }
    // } else if (downNum > 26) {
    //   setUnitLen((uniNumber * 26) / downNum)
    //   unitCount = (uniNumber * 26) / downNum
    // } else if (unitCount === 0) {
    //   setUnitLen(uniNumber)
    // }
    // if (Number.isFinite(+downNum)) {
    //   if (downNum < upNum && upNum > 26) {
    //     setUnitLen((uniNumber * 26) / upNum)
    //     unitCount = (uniNumber * 26) / upNum
    //   } else if (unitCount === 0) {
    //     setUnitLen(uniNumber)
    //   }
    // } else if (upNum > 26) {
    //   setUnitLen((uniNumber * 26) / upNum)
    //   unitCount = (uniNumber * 26) / upNum
    // } else if (unitCount === 0) {
    //   setUnitLen(uniNumber)
    // }
    const width =
      document.getElementsByClassName("toggleBottomWrap")[0].offsetWidth - 265
    if (upNum - 1 < 11 && downNum - 1 < 11) {
      setUnitLen(32)
      return
    }
    if (upNum - 1 >= 20 || downNum - 1 >= 20) {
      setUnitLen(downNum >= upNum ? width / (downNum - 1) : width / (upNum - 1))
      return
    }
  }
  // change stage
  const handleChange = (direction: string, step: number) => {
    if (upList.length && downList.length) {
      if (step <= upList.length - 1) {
        setUpCurrent(step)
        runStep_up = step
      }
      if (step <= downList.length - 1) {
        setDownCurrent(step)
        runStep_down = step
      }
    } else if (upList.length && !downList.length) {
      if (step <= upList.length - 1) {
        setUpCurrent(step)
        runStep_up = step
      }
    } else if (!upList.length && downList.length) {
      if (step <= downList.length - 1) {
        setDownCurrent(step)
        runStep_down = step
      }
    }
  }
  // play or stop stage
  const playOrStop = () => {
    clearInterval(timer)
    runStep_up = 0
    runStep_down = 0
    const upLen = upList.length - 1
    const downLen = downList.length - 1
    if (btnObj.type === "play") {
      timer = setInterval(() => {
        if (runStep_up <= upLen || runStep_down <= downLen) {
          if (runStep_up <= upLen) {
            setUpCurrent(runStep_up)
            runStep_up++
          }
          if (runStep_down <= downLen) {
            setDownCurrent(runStep_down)
            runStep_down++
          }
        } else {
          runStep_up = 0
          runStep_down = 0
          clearInterval(timer)
          setBtnObj({
            type: "stop",
            isBool: !btnObj.isBool,
          })
        }
      }, 200)
    }
  }
  useEffect(() => {
    getMark("up", upList)
    getMark("down", downList)
    setUnit(upList.length, downList.length, upList, downList)
  }, [upList, downList, unitLen])
  useEffect(() => {
    // stage data callback
    setStageCallback((data) => {
      const upStageList: any[] = []
      const downStageList: any[] = []
      const urTmp: number[] = []
      const urArr: any[] = []
      const drTmp: number[] = []
      const drArr: any[] = []
      const upNodes = data.jointUpKeypoints
      const lowerNodes = data.jointLowerKeypoints
      const type =
        upNodes.length - 1 >= 40 || lowerNodes.length - 1 >= 40
          ? "over40"
          : "less40"
      setStageType(type)
      // 处理上颌的点nodes
      if (upNodes?.length > 1) {
        for (let u = 0; u < upNodes.length; u++) {
          const obj: any = {}
          obj.showStage = upNodes[u].stepindex
          obj.currentNum = u
          obj.postNum = 0
          obj.cirPoint = false
          obj.iprPoint = false
          obj.quickPoint = false
          obj.passiveAlignerPoint = false
          // point 分装
          upNodes[u].nodeType.map((i) => {
            if (i === 0) {
              obj.cirPoint = true
            } else if (i === 1) {
              obj.iprPoint = true
            } else if (i === 6) {
              obj.quickPoint = true
            } else if (i === 15) {
              obj.passiveAlignerPoint = true
            }
            return i
          })
          upStageList.push(obj)
          if (typeof upNodes[u].stepindex !== "number") {
            urTmp.push(u)
          }
        }
        if (urTmp.length) {
          const arr: any[] = []
          urTmp.unshift(0)
          for (let ur = 0; ur < urTmp.length; ur++) {
            arr.push(upStageList.slice(urTmp[ur], urTmp[ur + 1]))
          }
          arr.map((item, bigIndex) => {
            item.map((e) => {
              e.postNum = bigIndex
              urArr.push(e)
              return e
            })
            return item
          })
        }
      }
      // 处理下颌的点nodes
      if (lowerNodes?.length > 1) {
        for (let u = 0; u < lowerNodes.length; u++) {
          const obj: any = {}
          obj.showStage = lowerNodes[u].stepindex
          obj.currentNum = u
          obj.postNum = 0
          obj.cirPoint = false
          obj.iprPoint = false
          obj.quickPoint = false
          obj.passiveAlignerPoint = false
          // point 分装
          lowerNodes[u].nodeType.map((i) => {
            if (i === 0) {
              obj.cirPoint = true
            } else if (i === 1) {
              obj.iprPoint = true
            } else if (i === 6) {
              obj.quickPoint = true
            } else if (i === 15) {
              obj.passiveAlignerPoint = true
            }
            return i
          })
          downStageList.push(obj)
          if (typeof lowerNodes[u].stepindex !== "number") {
            drTmp.push(u)
          }
        }
        if (drTmp.length) {
          const arr: any[] = []
          drTmp.unshift(0)
          for (let dr = 0; dr < drTmp.length; dr++) {
            arr.push(downStageList.slice(drTmp[dr], drTmp[dr + 1]))
          }
          arr.map((item, bigIndex) => {
            item.map((e) => {
              e.postNum = bigIndex
              drArr.push(e)
              return e
            })
            return item
          })
        }
      }
      setUpList(urArr.length ? urArr : upStageList)
      setDownList(drArr.length ? drArr : downStageList)
    })
  }, [])
  useEffect(() => {
    setStageStep(up_current, down_current)
  }, [up_current, down_current])
  useEffect(() => {
    clearInterval(timer)
    runStep_up = 0
    runStep_down = 0
    if (btnObj.type === "icStart") {
      setUpCurrent(0)
      setDownCurrent(0)
    } else if (btnObj.type === "icEnd") {
      if (upList.length) {
        setUpCurrent(upList.length - 1)
      }
      if (downList.length) {
        setDownCurrent(downList.length - 1)
      }
    } else {
      playOrStop()
    }
  }, [btnObj])
  useEffect(() => {
    const upSlider = document.getElementById("sliderUp")
    const downSlider = document.getElementById("sliderDown")
    if (upSlider) {
      const upLabel = upSlider.getElementsByClassName("MuiSlider-markLabel")
      if (upLabel[0]) {
        upLabel[0].style.zIndex = "unset"
      }
      if (upLabel.length) {
        upLabel[upLabel.length - 1].style.zIndex = "unset"
      }
    }
    if (downSlider) {
      const downLabel = downSlider.getElementsByClassName("MuiSlider-markLabel")
      if (downLabel[0]) {
        downLabel[0].style.zIndex = "unset"
      }
      if (downLabel.length) {
        downLabel[downLabel.length - 1].style.zIndex = "unset"
      }
    }
  }, [isFold, unitLen])
  useEffect(() => {
    setStageObj({ upList, downList, up_current, down_current })
  }, [upList, downList, up_current, down_current])
  return (
    <Box className={"stageBox"}>
      <div
        className={"stageContainer"}
        style={{ display: upList.length ? "flex" : "none" }}
      >
        <div className={"directName"}>Upper</div>
        <Slider
          id={"sliderUp"}
          aria-label="Temperature"
          value={up_current}
          // getAriaValueText={valuetext}
          valueLabelDisplay="off"
          step={1}
          marks={up_step_Marks}
          min={0}
          max={upList.length - 1}
          className={"stageSlider"}
          sx={{
            width: unitLen * (upList.length - 1),
            color: "#546E7A",
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-rail": {
              opacity: 1,
            },
            "& .MuiSlider-mark": {
              width: `${stageConfig[stageType].pointWidth}`,
              height: `${stageConfig[stageType].pointWidth}`,
              borderRadius: "50%",
              backgroundColor: "#ECEFF1",
              opacity: 1,
              marginLeft: `${stageConfig[stageType].thumbMarkMarginLeft}`,
              zIndex: 2,
              display: "none",
            },
            "& .MuiSlider-thumb": {
              backgroundImage:
                stageType === "over40" &&
                (up_current === 0 || up_current === upList.length - 1)
                  ? `url(${bigUp})`
                  : `url(${stageConfig[stageType].thumbUp})`,
              backgroundColor: "#37474F",
              backgroundRepeat: "no-repeat",
              borderRadius: "0",
              top: "14px",
              height: "30px",
              backgroundPosition: "center",
              boxShadow: "none",
              zIndex: 5,
              width: `${stageConfig[stageType].hoverWidth}`,
            },
            "& .MuiSlider-thumb:before": {
              display: "none",
            },
            "& .MuiSlider-markLabel": {
              top: "-28px",
              zIndex: 6,
            },
          }}
          onChange={(event, newvalue) => {
            handleChange("up", newvalue)
          }}
        />
      </div>
      <div
        className={"stageContainer"}
        style={{ display: downList.length ? "flex" : "none" }}
      >
        <div className={"directName"}>Lower</div>
        <Slider
          id="sliderDown"
          aria-label="Temperature"
          value={down_current}
          // getAriaValueText={valuetext}
          valueLabelDisplay="off"
          step={1}
          marks={down_step_Marks}
          min={0}
          max={downList.length - 1}
          className={"stageSlider"}
          sx={{
            width: unitLen * (downList.length - 1),
            color: " #546E7A",
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-rail": {
              opacity: 1,
            },
            "& .MuiSlider-mark": {
              width: `${stageConfig[stageType].pointWidth}`,
              height: `${stageConfig[stageType].pointWidth}`,
              borderRadius: "50%",
              backgroundColor: "#CFD8DC",
              opacity: 1,
              marginLeft: `${stageConfig[stageType].thumbMarkMarginLeft}`,
              zIndex: 2,
              display: "none",
            },
            "& .MuiSlider-thumb": {
              backgroundColor: "#37474F",
              backgroundImage:
                stageType === "over40" &&
                (down_current === 0 || down_current === downList.length - 1)
                  ? `url(${bigDown})`
                  : `url(${stageConfig[stageType].thumbDown})`,
              backgroundRepeat: "no-repeat",
              borderRadius: "0",
              top: "28px",
              height: "30px",
              backgroundPosition: "center",
              boxShadow: "none",
              zIndex: 5,
              width: `${stageConfig[stageType].hoverWidth}`,
            },
            "& .MuiSlider-thumb:before": {
              display: "none",
            },
            "& .MuiSlider-markLabel": {
              top: "-70px",
              zIndex: 6,
            },
          }}
          onChange={(event, newvalue) => {
            handleChange("down", newvalue)
          }}
        />
      </div>
    </Box>
  )
}
export default StageBar
