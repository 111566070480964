import { wasmModule } from "../wasm/wasmModule";

/**
 * bolton data format
 *  tooth[x] : number (x number : 1-32)
 */
export interface IWASMBoltonReport {
  boltonIndex3_3: string;
  diffience3_3: string;
  boltonIndex6_6: string;
  diffience6_6: string;
  boltonData: Map<string, string>;
}

class BoltonModule {
  boltonReport = {} as IWASMBoltonReport;
  boltonData = new Map<string, string>();

  computeBolton() {
    wasmModule.moduleManager.SwitchBoltonDisplayModule(true);
    const boltonDisplayModule =
      wasmModule.moduleManager.GetBoltonDisplayModule();

    const boltonIndex3_3 = boltonDisplayModule.GetBoltonIndex3_3();
    this.boltonReport.boltonIndex3_3 = boltonIndex3_3;

    const diffience3_3 = boltonDisplayModule.GetDiffience3_3();
    this.boltonReport.diffience3_3 = diffience3_3;

    const boltonIndex6_6 = boltonDisplayModule.GetBoltonIndex6_6();
    this.boltonReport.boltonIndex6_6 = boltonIndex6_6;

    const diffience6_6 = boltonDisplayModule.GetDiffience6_6();
    this.boltonReport.diffience6_6 = diffience6_6;

    this.boltonData.clear();
    const toothupIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.UpArch)
      .GetToothIds();
    for (let i = 0; i < toothupIds.size(); i++) {
      const value = boltonDisplayModule.GetToothWidth(toothupIds.get(i));
      this.boltonData.set(toothupIds.get(i), value);
    }
    const toothlowIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.DownArch)
      .GetToothIds();
    for (let i = 0; i < toothlowIds.size(); i++) {
      const value = boltonDisplayModule.GetToothWidth(toothlowIds.get(i));
      this.boltonData.set(toothlowIds.get(i), value);
    }
    this.boltonReport.boltonData = this.boltonData;
    wasmModule.moduleManager.SwitchBoltonDisplayModule(false);
  }

  getBoltonReport(): IWASMBoltonReport {
    return this.boltonReport;
  }

  displayBolton(enabled: boolean) {
    wasmModule.wrapInstance.BoltonDisplay(enabled);
  }
}

export const boltonModule = new BoltonModule();
