import { EToothDirection } from "@ulab/wesmileclinical"
export const panelTmp = [
  {
    index: 0,
    leftLabel: "Distal",
    rightLabel: "Mesial",
    leftDir: EToothDirection.Distal,
    rightDir: EToothDirection.Mesial,
    unit: "mm",
    key: "",
  },
  {
    index: 1,
    leftLabel: "Buccal",
    rightLabel: "Lingual",
    leftDir: EToothDirection.Buccal,
    rightDir: EToothDirection.Lingual,
    unit: "mm",
    key: "",
  },
  {
    index: 2,
    leftLabel: "Extrusion",
    rightLabel: "Intrusion",
    leftDir: EToothDirection.Extrusion,
    rightDir: EToothDirection.Intrusion,
    unit: "mm",
    key: "",
  },

  {
    index: 3,
    leftLabel: "Torque -",
    rightLabel: "Torque +",
    leftDir: EToothDirection.TorqueSub,
    rightDir: EToothDirection.TorqueAdd,
    unit: "°",
    key: "",
  },
  {
    index: 4,
    leftLabel: "Tipping -",
    rightLabel: "Tipping +",
    leftDir: EToothDirection.TippingSub,
    rightDir: EToothDirection.TippingAdd,
    unit: "°",
    key: "",
  },
  {
    index: 5,
    leftLabel: "Rotation -",
    rightLabel: "Rotation +",
    leftDir: EToothDirection.RotationSub,
    rightDir: EToothDirection.RotationAdd,
    unit: "°",
    key: "",
  },
]
