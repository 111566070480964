import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import { store } from "./core/app/store"
import App from "./App"

import "./index.scss"

const contentElement = document.getElementById("root") as HTMLElement

ReactDOM.createRoot(contentElement).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)
