import { forwardRef, useEffect, useImperativeHandle } from "react"
import React, { useState } from "react"
import { Box } from "@mui/material"
import { viewControlInTreatment } from "@ulab/wesmileclinical"

import { toolsTmp, viewTmp, viewTmp1 } from "./columns"
import ToolButton from "./toolButton"
import { toolBarProps } from "./type"
const {
  setAttachmentVisibility,
  setViewType,
  showArchMode,
  setSpacecheckVisibility,
  setGridVisibility,
  setOcclusionVisibility,
  setSuperimposeVisibility,
  setOverlayVisibility,
} = viewControlInTreatment
const ToolBar = forwardRef(
  (
    {
      preparation,
      boltonPanelShow,
      setBoltonPanelShow,
      setOpenOverlay,
      openOverlay,
      isHasUpAndlow,
      isReady,
    }: toolBarProps,
    ref,
  ) => {
    const [openUp, setOpenUp] = useState<boolean>(false)
    const [openLow, setOpenLow] = useState<boolean>(false)

    // tools button status (selected or not)
    const [openSpace, setOpenSpace] = useState<boolean>(false)
    const [openOcclusalgram, setOpenOcclusalgram] = useState<boolean>(false)
    const [openAttachment, setOpenAttachment] = useState<boolean>(
      isReady ? true : false,
    )
    const [openSuperimposition, setOpenSuperimposition] =
      useState<boolean>(false)
    const [openToothSize, setOpenToothSize] = useState<boolean>(false)
    const [openGrid, setOpenGrid] = useState<boolean>(false)
    //close toothsize
    useImperativeHandle(ref, () => ({
      closeToothSizeByToolBar: () => {
        setOpenToothSize(false)
      },
    }))

    const viewClick = (item: string) => {
      /* arch */
      if (item === "up") {
        setOpenUp(!openUp)
      }
      if (item === "low") {
        setOpenLow(!openLow)
      }
      if (
        /* viewType */
        item === "left" ||
        item === "right" ||
        item === "front" ||
        item === "split" ||
        item === "back"
      ) {
        setViewType(item)
      }
    }
    // click tool
    const clickTool = (item: string) => {
      switch (item) {
        case "Space":
          setOpenSpace(!openSpace)
          setSpacecheckVisibility(!openSpace)
          break
        case "Occlusalgram":
          setOpenOcclusalgram(!openOcclusalgram)
          setOcclusionVisibility(!openOcclusalgram)
          break
        case "Attachment":
          setOpenAttachment(!openAttachment)
          setAttachmentVisibility(!openAttachment)
          break
        case "Superimposition":
          setOpenSuperimposition(!openSuperimposition)
          setSuperimposeVisibility(!openSuperimposition)
          break
        case "Overlay":
          setOpenOverlay(!openOverlay)
          setOverlayVisibility(!openOverlay)
          break
        case "ToothSize":
          setOpenToothSize(!openToothSize)
          setBoltonPanelShow(!boltonPanelShow)
          break
        case "Grid":
          setOpenGrid(!openGrid)
          setGridVisibility(!openGrid)
          break
      }
    }

    useEffect(() => {
      if (!preparation) return

      if ((!openUp && !openLow) || (openUp && openLow)) {
        showArchMode("all")
        // close overlay
        setOpenOverlay(false)
        setOverlayVisibility(false)
      } else if (openUp) {
        showArchMode("up")
      } else {
        showArchMode("low")
      }
    }, [openUp, openLow])
    return (
      <Box
        id="toolBar"
        sx={{
          height: "48px",
          background: "#37474F",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {viewTmp.map((i) => {
          return (
            <ToolButton
              disabled={!isReady}
              disabledBg={i.disabledSrc}
              title={i.title}
              key={i.key}
              val={i.key}
              bg={i.src}
              callBack={() => {
                viewClick(i.key)
              }}
              isSelected={i.title === "Upper arch" ? openUp : openLow}
            ></ToolButton>
          )
        })}
        <div
          style={{
            width: "1px",
            height: "48px",
            background: "rgba(255,255,255,0.12)",
          }}
        ></div>
        {viewTmp1.map((i) => {
          return (
            <ToolButton
              disabled={!isReady}
              title={i.title}
              key={i.key}
              val={i.key}
              bg={i.src}
              isFocused={true}
              disabledBg={i.disabledSrc}
              callBack={() => {
                viewClick(i.key)
              }}
            ></ToolButton>
          )
        })}
        <div
          style={{
            width: "1px",
            height: "48px",
            background: "rgba(255,255,255,0.12)",
          }}
        ></div>
        {toolsTmp.map((i) => {
          if (
            i.key === "Overlay" &&
            (!isHasUpAndlow || (!openUp && !openLow) || (openUp && openLow))
          ) {
            return (
              <ToolButton
                disabled={true}
                title={i.title}
                key={i.key}
                val={i.key}
                bg={i.src}
                disabledBg={i.disabledSrc}
                isSelected={openOverlay}
                callBack={() => {
                  clickTool(i.key)
                }}
              ></ToolButton>
            )
          }
          return (
            <ToolButton
              disabled={!isReady}
              title={i.title}
              key={i.key}
              val={i.key}
              bg={i.src}
              disabledBg={i.disabledSrc}
              isSelected={
                i.key === "Space"
                  ? openSpace
                  : i.key === "Occlusalgram"
                  ? openOcclusalgram
                  : i.key === "Attachment"
                  ? openAttachment
                  : i.key === "Superimposition"
                  ? openSuperimposition
                  : i.key === "Overlay"
                  ? openOverlay
                  : i.key === "ToothSize"
                  ? openToothSize
                  : openGrid
              }
              callBack={() => {
                clickTool(i.key)
              }}
            ></ToolButton>
          )
        })}
      </Box>
    )
  },
)
ToolBar.displayName = "ToolBar"
export default ToolBar
