import * as React from "react"
import { useState, useEffect, Fragment } from "react"
import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

import { Box, Stack, Grid, Typography, Divider } from "@mui/material"
import { Dialog, DialogContent, DialogContentText, Button } from "@mui/material"

import { FlowButton, FlowBtnGroup } from "../../Custom/FlowCustom"

import { gdsPlusSetup, SetupType, OJOBType } from "@ulab/wesmileclinical"
import { caseManagement, wasmModule } from "@ulab/wesmileclinical"
import { setRunPresetup } from "../../Wasm3DServiceSlice"
import { tryPresetup, UFlowImportSTL } from "../../CommonPages/UFlowPresetup"
import { useParams, useNavigate } from "react-router-dom"
import { UploadZips } from "../../../core/app/slices/clinical/clinical.types"
import { uploadCaseZips } from "../../../core/app/slices/clinical/clinicalThunkApi"

const QuickFinal = (props) => {
  const [showScan, setShowScan] = useState(false)
  const { disabled, selectedTooth } = useAppSelector(
    (state: RootState) => state.autoSetupSlice,
  )
  const { isShow, data, toothId } = useAppSelector(
    (state: RootState) => state.toothInfoSlice,
  ) // tooth info data
  const dispatch = useAppDispatch()

  const [openDlg, setOpenDlg] = useState(false)
  const [dlgMsg, setDlsMsg] = useState("")
  const closeDlg = () => {
    setOpenDlg(false)
  }

  const newByType = (type: SetupType) => {
    setDlsMsg("Please wait, auto setup is running...")
    setOpenDlg(true)
    const timer = setTimeout(() => {
      gdsPlusSetup.setupByType(type, () => {
        setOpenDlg(false)
        console.log("Auto setup the case ...")
        const payload = false
        dispatch({ type: "autosetup/setDisable", payload })
        dispatch({ type: "autosetup/updateState" })
      })
    }, 100) // wait 100ms so the dialog is shown.
  }

  const runResolveOccColl = () => {
    setDlsMsg("Please wait, removing heavy contact...")
    setOpenDlg(true)
    const timer = setTimeout(() => {
      gdsPlusSetup.ResolveOccColl(true, false, () => {
        setOpenDlg(false)
        console.log("ResolveOccColl finished!")
        const payload = false
        dispatch({ type: "autosetup/setDisable", payload })
        dispatch({ type: "autosetup/updateState" })
        dispatch({ type: "autostaging/updateState" })
      })
    }, 100) // wait 100ms so the dialog is shown.
  }

  const extractRestoreTooth = (extract) => {
    setDlsMsg("Please wait, redo setup ...")
    setOpenDlg(true)
    const timer = setTimeout(() => {
      if (extract) gdsPlusSetup.ExtractTooth()
      else gdsPlusSetup.RestoreAllTeeth()
      setOpenDlg(false)
      const payload = false
      dispatch({ type: "autosetup/setDisable", payload })
      dispatch({ type: "autosetup/updateState" })
      dispatch({ type: "autostaging/updateState" })
    }, 100) // wait 100ms so the dialog is shown.
  }

  const rerunPresetup = () => {
    dispatch(setRunPresetup("start"))
  }

  const saveArchScan = () => {
    let root = "test/case/"
    let files = wasmModule.module.FS.readdir(root)
    console.log(files)
    const { VITE_APP_TYPE } = import.meta.env
    if (VITE_APP_TYPE == "wesmile") {
      console.log(VITE_APP_TYPE + "is wesmile")
    }
    files.map((file) => {
      if (file != "." && file != "..") {
        let subpath = root + file
        let stat = wasmModule.module.FS.stat(subpath)
        console.log(subpath)
        if (wasmModule.module.FS.isDir(stat.mode)) {
          let subFiles = wasmModule.module.FS.readdir(root + file)
          console.log(subFiles)
        }
      }
    })
  }

  const { patientId, caseId } = useParams()

  const saveCase = () => {
    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        let params = {
          patientId: patientId,
          caseId: caseId,
          zips: zips,
        } as UploadZips
        dispatch(uploadCaseZips(params))
        dispatch({ type: "autosetup/setDisable", payload: true })
      }
    })
  }

  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "Ant00":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.Improve)
        break
      case "Ant05":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.HalfMmSpace)
        break
      case "AutoAP":
        gdsPlusSetup.getAutoSetupModule().SetBestAP()
        break
      case "CloseSpace":
        gdsPlusSetup.ResolveAllColl(() => {
          console.log("ResolveAllColl finished!")
        })
        break
      case "RmvContact":
        runResolveOccColl()
        break
      case "OptimizeOcc":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.HalfMmSpace)
        gdsPlusSetup.ResolveAllColl(() => {
          console.log("ResolveAllColl finished!")
        })
        runResolveOccColl()
        break
      case "ExtractTooth":
        extractRestoreTooth(true)
        break
      case "RestoreAllTeeth":
        extractRestoreTooth(false)
        break
      case "Full":
        newByType(SetupType.Full)
        break
      case "Simple":
        newByType(SetupType.Simple)
        break
      case "Anterior":
        newByType(SetupType.Ant3x3)
        break
      case "Lite":
        newByType(SetupType.COPAModerate)
        break
      case "FineTune":
        newByType(SetupType.FineTune)
        break
      case "AlignmentOnly":
        newByType(SetupType.AlignmentOnly)
        break
      case "OJImprove":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.Improve, () => {})
        break
      case "OJHalfMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.HalfMmSpace, () => {})
        break
      case "OJOneMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.OneMmSpace, () => {})
        break
      case "Presetup":
        rerunPresetup()
        break
      case "ShowArchScan":
        if (wasmModule.isInit) {
          setShowScan(!showScan)
          wasmModule.statusController.ShowArchScan(showScan)
        }
        break
      case "SaveArchScan":
        saveArchScan()
        break
      case "SaveCase":
        saveCase()
        break
    }
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  useEffect(() => {
    console.log("selected tooth changed :")
    dispatch({ type: "autosetup/updateSelTooth", payload: toothId })
    dispatch({ type: "autosetup/updateState" })
  }, [isShow, toothId, data])

  useEffect(() => {
    console.log("Start QuickFinal")
    gdsPlusSetup.SwitchAutoSetupModule(true)
    return () => {
      //console.log("End QuickFinal")
      //gdsPlusSetup.SwitchAutoSetupModule(false)
      //const payload = true
      //dispatch({ type: "autosetup/setDisable", payload })
    }
  }, [])

  const presetupBtns = [
    { label: "Show Arch Scan", name: "ShowArchScan" },
    { label: "Run Presetup", name: "Presetup" },
    // { label: "Save Arch Scan", name: "SaveArchScan" },
    { label: "Save Case", name: "SaveCase" },
  ]

  const quickPlanBtns = [
    { label: "Full All Teeth", name: "Full" },
    { label: "6x6 Molar", name: "Simple" },
    { label: "3x3  Anterior", name: "Anterior" },
    { label: "Lite Molar", name: "Lite" },
    { label: "Alignment Only", name: "AlignmentOnly" },
    { label: "Modi. Current", name: "FineTune" },
  ]
  const extractionBtns = [
    { label: "Extract", name: "ExtractTooth" },
    { label: "Restore All", name: "RestoreAllTeeth" },
  ]

  const QuickFinalTypes = () => {
    return (
      <Grid container spacing={0.5} alignItems="center">
        <Grid item xs={12}>
          Presetup
        </Grid>
        <Grid item xs={12}>
          <Typography>New Plan</Typography>
        </Grid>
        <Grid item xs={3}>
          <FlowButton
            onClick={() => {
              onButtonClick("Full")
            }}
          >
            Full <br /> All Teeth
          </FlowButton>
        </Grid>
        <Grid item xs={3}>
          <FlowButton
            onClick={() => {
              onButtonClick("Simple")
            }}
          >
            6x6 <br /> To Molar
          </FlowButton>
        </Grid>
        <Grid item xs={3}>
          <FlowButton
            onClick={() => {
              onButtonClick("Anterior")
            }}
          >
            3x3
            <br /> Anterior
          </FlowButton>
        </Grid>
        <Grid item xs={3}>
          <FlowButton
            onClick={() => {
              onButtonClick("Lite")
            }}
          >
            Lite <br /> To Molar
          </FlowButton>
        </Grid>
        <Grid item xs={3}>
          <FlowButton
            onClick={() => {
              onButtonClick("FineTune")
            }}
          >
            Alignment <br /> Only
          </FlowButton>
        </Grid>
        <Grid item xs={3}>
          <FlowButton
            onClick={() => {
              onButtonClick("FineTune")
            }}
          >
            Mod. Current
          </FlowButton>
        </Grid>
      </Grid>
    )
  }

  const QuickSelTooth = () => {
    let labelWithId = "Selected Tooth: "
    if (isShow) labelWithId = labelWithId + selectedTooth.ID
    return (
      <Fragment>
        <Grid container spacing={0.5} alignItems="center">
          <Grid item xs={12}>
            {labelWithId}
          </Grid>
          <Grid item xs={4}>
            {isShow ? (
              <FlowButton
                onClick={() => {
                  onButtonClick("ExtractTooth")
                }}
              >
                Extract Tooth
              </FlowButton>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={4}>
            <FlowButton
              onClick={() => {
                onButtonClick("RestoreAllTeeth")
              }}
            >
              Restore Extract
            </FlowButton>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const QuickAnt = () => {
    const antBtns = [
      { label: "Ant 0mm", name: "OJImprove" },
      { label: "Ant 0.5mm", name: "OJHalfMm" },
      { label: "Ant 1.0mm", name: "OJOneMm" },
    ]
    return (
      <>
        <Typography>Anterior </Typography>
        <FlowBtnGroup
          lineLabel={"Auto OJ"}
          disabled={disabled}
          btnGroup={antBtns}
          onButtonClick={onButtonClick}
        />
      </>
    )
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography>Presetup</Typography>
      <FlowBtnGroup
        btnGroup={presetupBtns}
        onButtonClick={onButtonClick}
        btnW={4}
      />
      <UFlowImportSTL arch="up">Import STL Up</UFlowImportSTL>
      <UFlowImportSTL arch="low">Import STL Low</UFlowImportSTL>
      <Typography>New Plan</Typography>
      <FlowBtnGroup btnGroup={quickPlanBtns} onButtonClick={onButtonClick} />
      <Divider />
      {!disabled ? (
        <>
          <QuickSelTooth /> <Divider />{" "}
        </>
      ) : (
        <></>
      )}
      {!disabled ? <QuickAnt /> : <></>}
      <Dialog open={openDlg} onClose={closeDlg}>
        <DialogContent>
          <Box
            component="img"
            sx={{ height: 120 }}
            alt="Running ..."
            // src={require("@/assets/images/timer10s.gif")}
          />
          <DialogContentText id="alert-dialog-description">
            {dlgMsg}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Stack>
  )
}

export default QuickFinal
