export enum EShowArchType {
  UpArch = 0,
  LowArch = 1,
  BothArch = 2,
}

export enum EArchType {
  UpArch = 0,
  LowArch = 1,
}

/**
 * 牙齿 Property
 */
export enum ToothProperty {
  ToothProperty_Normal = 0, // 正常
  ToothProperty_PullOut = 1, // 拔除
  ToothProperty_Implant = 2, // 种植牙（紫色）
  ToothProperty_Missing = 3, // 缺失
  ToothProperty_Fix = 4, // 固定不动（黑色）
  ToothProperty_RetainerTooth = 5, // 保持器
}

export class TeethData {
  private _teethgroup: Record<number, ToothData>;
  constructor() {
    this._teethgroup = {} as Record<number, ToothData>;
  }

  get teethgroup() {
    return this._teethgroup;
  }

  getToothIds() {
    return Object.keys(this._teethgroup);
  }

  get(toothId: number) {
    return this._teethgroup[toothId];
  }
  set(toothId: number, toothData: ToothData) {
    this._teethgroup[toothId] = toothData;
  }
}
export interface ToothData {
  toothId: number;
  attribute: Attribute;
  feature: any;
}

/**
 * 牙齿Attribute
 */
export interface Attribute {
  isPullOut: boolean; // 拔牙
  toothProperty: ToothProperty;
  isCrown: boolean; // 牙冠
  isBridge: boolean; // 牙桥
  isImplant: boolean; // 种植牙

  isUnerupted: boolean; // 临时牙
  isTemporary: boolean; // 未张全牙

  isPonticFlag: boolean[]; // pullout
  isImplantFlag: boolean[]; // pullout
  isPullOutFlag: boolean[]; // pullout
  archType: number; // pullout
  // pullOutFlagData: PullOutFlag;
}

/**
 * stage node type
 */
export enum ENodeType {
  NodeNormal = 0,
  NodeIPR,
  NodeSmartStage,
  NodeDoctor,
  NodeRetouch,
  NodeFreeze,
  NodeQuick3,
  NodeEvelocityStart,
  NodeEvelocityEnd,

  // add xue
  NodeExpansion,
  NodeDistalization,
  NodeIPRCut,
  NodeFinal,
  NodeChain,
  NodeOverIntrusion,
  NodePassiveAligner,
}

/**
 * stage 节点数据
 */
export class NodeStru {
  nodeType: ENodeType[];
  stepindex: number;
  posPace: number;
  degreePace: number;
  name: string;
}

/**
 * stagebar 数据更新通知数据
 */
export interface JointStageData {
  jointUpKeypoints: NodeStru[]; // 拼接上颌stage数据
  jointLowerKeypoints: NodeStru[]; // 拼接下颌stage数据
  // stageUpNum; // Stage中retouch节点
  // stageLowNum; // Stage中retouch节点
  curUpNodeIndex: number; // 当前上颌stage number
  curLowerNodeIndex: number; // 当前下颌stage number
  curNodeIndex: number;
  keypointsIndex: number;
  hasUpLastData: boolean; // 该上颌拼接数据是否存在正常模式下的数据
  hasLowerLastData: boolean; // 该下颌拼接数据是否存在正常模式下的数据
  refinementHistoryUpNumber: number; //上颌历史refinement个数
  historyStepUpCount: number; //上颌存在refinement的情况下，历史的step个数，比如：R2，7，8，9...， 历史个数则为7
  refinementHistoryDownNumber: number; //下颌历史refinement个数
  historyStepDownCount: number; //下颌存在refinement的情况下，历史的step个数，比如：R1，3，4，5...， 历史个数则为3
}

/**
 * the case extra attribute information follow to UD design
 */
export interface ICaseExtraAttr {
  ApAttachment: boolean;
  BigSpace: boolean;
  BoneLoss: false;
  CurrentOrderDate: string;
  EruptionDome: number;
  FutureOrderDate: string;
  Implant: boolean;
  LowerMaxStageNum: number;
  LowerPrintStageNum: number;
  LowerRetainer: number;
  LowerTemplate: number;
  LowerType: string;
  MissTooth: boolean;
  Pullout: boolean;
  RetouchSolution: string;
  SetUP: boolean;
  StartDate: number;
  StopDate: number;
  TimeBeforeSetUp: number;
  TimeOnManualSetUp: number;
  UpperMaxStageNum: number;
  UpperPrintStageNum: number;
  UpperRetainer: number;
  UpperTemplate: number;
  UpperType: string;
  recessionfill: string;
  saveTime: string;
  TreatmentPlanNames: string[];
}

/**
 * the case extra information follow to UD design
 */
export interface ICaseExtInfo {
  upper_stage: number;
  lower_stage: number;
  case_extra_attrs: ICaseExtraAttr;
  complexity: number;
  upper_retainer_stage: string;
  lower_retianer_stege: string;
}

export interface IWASMRootObject {
  _scanPreview: () => void;
}

/**
 * WASM StatusController模块接口
 */
export interface IWASMStateController {
  /**
   * 获取当前显示的ArchType
   * @returns
   */
  GetCurrentDiplayArch: () => EShowArchType;
  /**
   * canvas中绘制IPR信息
   * @params  archType    当前的上下颚显示状态
   *          isEnabled   是否绘制
   *          stageIndexForShow  当前绘制的stage index number(因为IPR计算的始终是最后一步stage,显示数据所在的stage的位置和最终的stage位置可能不一致，所以需要指定显示的stage index number)
   * @returns void
   */
  DisplayArchIPR: (
    archType: EShowArchType,
    isEnabled: boolean,
    stageIndexForShow: number
  ) => void;

  /**
   * get ipr report
   * @param archType only EArchType.UpArch or EArchType.LowArch
   * @returns
   */
  GetIPRReport: (archType: EArchType) => any;

  /**
   * 查询是否有附件
   * @returns
   */
  IsAddAttachment: () => boolean;

  /**
   * 查询牙齿之间是否有Collision
   * @returns
   */
  HasCollision: () => boolean;

  /**
   * 显示附件
   * @param isEnabled
   * @returns
   */
  DisplayAttachment: (isEnabled: boolean) => void;

  /**
   * 显示后视图
   * @returns
   */
  SetToBackView: () => void;

  /**
   * 打开或者关闭 BiteCheck
   * @returns
   */
  TurnOnBiteCheck: () => void;
  TurnOffBiteCheck: () => void;

  /**
   * 左右视角控制
   * @returns
   */
  SetToRightBuccalView: () => void;
  SetToAnteriorView: () => void;
  SetToLeftBuccalView: () => void;
  /**
   * 开核视角控制
   * @param isEnabled
   * @returns
   */
  SplitScreenDisplay: (isEnabled: boolean) => void;
  /**
   * 显示上下颌控制
   * @returns
   */
  DisplayUpperArch: () => void;
  DisplayLowerArch: () => void;
  DisplayBothArch: () => void;
  /**
   * 显示牙龈控制
   * @param isEnabled
   * @returns
   */
  SwitchGum: (isEnabled: boolean) => void;

  /*
    * Show arch model (3D scan) 
  */ 
  ShowArchScan: (show:boolean) => void; 

  /**
   * view <-> edit switch
   * @param isToEdit
   * @returns 返回0 是一个无意义值，返回1 是代表 settingData2 数据未更改，返回2 代表是SettingData2 的数据发生更改。
   */
  SwitchViewAndEdit: (isToEdit: boolean) => number;

  GetStagesIPRReport: () => any;
}

/**
 * WASM StageController模块接口
 */
export interface IWASMStageController {
  //-------------------Treatment interfaces---------------------
  /**
   * 切换方案
   * @returns
   */
  ChangeTreatment: (txIndex: number) => boolean;

  /**
   * 检测方案二是否存在
   * @returns boolean
   */
  Tx2Exist: () => boolean;

  /**
   * 保存当前方案
   * @returns
   */
  SaveCurTx: () => boolean;

  /**
   *
   * @param archType EArchType
   * @param txIndex 要删除的方案Index,从1开始计数
   */
  DelTx: (txIndex: number) => boolean;

  //-------------------Stage interfaces-------------------------------

  /**
   * Change Stage 切换牙齿到其他Stage
   * @param stageIndex
   * @param archType
   * @returns
   */
  ChangeStage: (stageIndex: number, archType: EArchType) => void;

  /**
   * 获取当前的stage的Index
   * @param archType
   * @returns
   */
  GetCurrentStageIndex: (archType: EArchType) => number;

  /**
   * 删除普通节点
   * @param archType
   * @param stepIndex 准备删除的关键节点的index
   * @returns
   */
  DeleteNode: (archType: EArchType, stepIndex: number) => boolean;

  /**
   * 添加普通节点
   * @param archType    牙颌类型
   * @param stepIndex   准备添加节点的位置
   * @param bMore       是否在最后
   * @returns
   */
  AddNormalNode: (
    archType: EArchType,
    stepIndex: number,
    bMore: boolean
  ) => boolean;

  //----------------------Velocity interfaces-------------------------
  /**
   * 让上颌下颌步长一致
   * @returns
   */
  VelocityUpAndDownArchEqual: () => boolean;

  /**
   * 上颌Velocity 增加步长操作
   * @returns
   */
  VelocityUpArchPlus: () => boolean;
  /**
   * 上颌Velocity 减少步长操作
   * @returns
   */
  VelocityUpArchMinus: () => boolean;

  /**
   * 下颌Velocity 增加步长操作
   * @returns
   */
  VelocityDownArchPlus: () => boolean;

  /**
   * 下颌Velocity 减少步长操作
   * @returns
   */
  VelocityDownArchMinus: () => boolean;

  /**
   * 撤销上颌Velocity 的操作
   * @returns
   */
  VelocityUpArchRestore: () => boolean;

  /**
   * 撤销下颌Velocity 的操作
   * @returns
   */
  VelocityDownArchRestore: () => boolean;

  /**
   * ???????
   * @param archType
   * @param movePace
   * @param rotatePace
   * @param startNum
   * @param endNum
   * @returns
   */
  VelocityModifyPaceValue: (
    archType: EArchType,
    movePace: number,
    rotatePace: number,
    startNum: number,
    endNum: number
  ) => void;

  /**
   * ?????
   * @param archType
   * @returns
   */
  GetDefaultSpaceAndRotateValue: (archType: EArchType) => [number, number];

  //-------------------InitialFinalScreenshot----------------------------

  GetInitAndFinalStagePNG: (enabled: boolean) => void;
  /**
   * 保存手动移牙后方案数据
   * 前端调用后触发wasm数据保存，保存数据后js获取数据文件，并通过zip压缩返回给前端
   * @returns
   */
  SaveSetUpResult: () => boolean;

  /**
   * 保存Autosetup后方案数据的结果
   * @returns
   */
  SaveAutoSetUpResult: () => boolean;

  //-------------------Unknown---------------------
  /**
   * Byte 和IPR 这个是一个状态设置，和Frank 那边的一个前端分支需求有关，wasm 要获取那两个按键状态
   * ???
   * @param enabled
   * @returns
   */
  UpdateByteButtonStatus: (enabled: boolean) => void;
  UpdateIPRButtonStatus: (enabled: boolean) => void;
  /**
   * 这个是邹恒以前要的一个接口，清空 牙齿的变化信息
   * @param toothId
   * @param archType
   * @returns
   */
  ClearToothStageTransInfo: (toothId: number, archType: EArchType) => boolean;

  /**
   * Rename a exist treatment plan
   * @param txIndex tx Index, count from 1
   * @param txName new tx name
   * @returns
   */
  RenameTX: (txIndex: number, txName: string) => boolean;

  /**
   * Get treatment plan name
   * @param txIndex tx Index, count from 1
   * @returns
   */
  GetTXName: (txIndex: number) => string;

  OpenOrClosetoothMoveModule: (isOpen: boolean) => void;
}

export type ReportAttachmentData = Record<
  number,
  {
    toothId: number;
    attachment: { attachmentId: number; onLingualSide: boolean }[];
  }
>;

export type ReportAttachmentToothListData = {
  uplist: number[];
  lowlist: number[];
};
