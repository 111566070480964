import { FC, useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { useAppDispatch } from "../../core/app/hooks"
import { clearLoggedInUser } from "../../core/app/slices/user/userSlice"

import ForgotPassword from "./ForgotPassword/ForgotPassword"
import ForgotUsername from "./ForgotUsername/ForgotUsername"
import Login from "./Login/Login"

const AuthModule: FC = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(clearLoggedInUser())
  }, [dispatch])
  return (
    <Routes>
      <Route path={"login"} element={<Login />} />
      <Route path={"forgot-username"} element={<ForgotUsername />} />
      <Route path={"forgot-password"} element={<ForgotPassword />} />

      <Route path={"*"} element={<Navigate to={"login"} replace />} />
    </Routes>
  )
}

export default AuthModule
