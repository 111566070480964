import { FC, useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { UFileBox, UImagePreview, UText } from "../../../components"
import { Container, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  FileItem,
  PhotoObject,
} from "../../../core/app/slices/records/photograph/photograph.type"
import { setDownloadStatus } from "../../../core/app/slices/records/photograph/photographSlice"
import {
  deleteFiles,
  downloadXray,
} from "../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../core/app/store"
import { convertMBtoKB } from "../../../core/utils/formatters"
import AdditionalPhotograph from "../AdditionalPhotograph"

import UploadXrayProcessing from "./uploadXrayProcessing"

const FileUploadSection: FC<{
  selectedFiles: File[]
  setSelectedFiles: (files: File[]) => void
  onRemoveFileFromList: (file: File) => void
  deleteFile: () => void
  uploadFiles: (file: FileList) => void
  id: string
  downloadedFile: PhotoObject[]
}> = ({
  id,
  selectedFiles,
  setSelectedFiles,
  onRemoveFileFromList,
  deleteFile,
  uploadFiles,
  downloadedFile,
}) => {
  const { t } = useTranslation("common")
  const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
  const FILE_ALLOWED_SIZE = 10 //in MB
  const FILE_ALLOWED_LIMIT = 1

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        sx={{ pt: "10px !important", maxWidth: "396px" }}
      >
        <Container
          maxWidth={"md"}
          sx={{
            padding: "0 !important",
          }}
        >
          {selectedFiles.length > 0 && (
            <Container
              maxWidth={"md"}
              id="processing"
              sx={{
                paddingLeft: "0 !important",
                paddingRight: "0px !important",
                width: "396px",
              }}
            >
              <UploadXrayProcessing
                key={id}
                radioGraphType={id}
                file={selectedFiles[0]}
                onRemove={onRemoveFileFromList}
                onUploadSuccess={setSelectedFiles}
              />
            </Container>
          )}
          {!!downloadedFile.length && (
            <Container
              maxWidth={"md"}
              id="preview"
              sx={{
                paddingLeft: "0 !important",
                paddingRight: "0px !important",
                width: "400px",
              }}
            >
              <UImagePreview
                showFileName={true}
                files={
                  downloadedFile.length
                    ? [
                        {
                          src: downloadedFile[0].src,
                          name: downloadedFile[0].name,
                        },
                      ]
                    : selectedFiles.map((file) => {
                        return {
                          src: URL.createObjectURL(file),
                          name: file.name,
                        }
                      })
                }
                sxImageProp={{ mb: "0px", mt: "0px" }}
                imageHeight={400}
                imageWidth={400}
                deleteImage={deleteFile}
              />
            </Container>
          )}
          {selectedFiles.length === 0 && !downloadedFile.length && (
            <UFileBox
              boxSize={{ height: "216px", width: "396px", mt: "0 !important" }}
              allowedFileExtensions={FILE_ALLOWED_TYPES}
              allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
              fileAcceptType={"image/*"}
              id={id}
              selectedFile={uploadFiles}
              fileLimit={FILE_ALLOWED_LIMIT}
              isRequired={false}
              messages={{
                fileNote: t("records.file.filenote", {
                  fileTypes: "JPG, PNG, TIFF, BMP",
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                uploadButton: t("records.file.btnclicktoupload"),
                uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                invalidfileFormat: (
                  <Trans components={{ newLine: <br /> }}>
                    {"records.photograph.invalidfileformat"}
                  </Trans>
                ),
                invalidFileSize: t("records.file.invalidfilesize", {
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                invalidFileLimit: t("records.file.invalidfilelimit", {
                  noOfFiles: FILE_ALLOWED_LIMIT,
                }),
              }}
            />
          )}
        </Container>
      </Grid>
    </>
  )
}

const UploadPano: FC<{
  setXrayErrors: (val: boolean) => void
}> = ({ setXrayErrors }) => {
  const [selectedPanoFiles, setSelectedPanoFiles] = useState<File[]>([])
  const [selectedCephFiles, setSelectedCephFiles] = useState<File[]>([])

  const [openModal, setOpenModal] = useState<boolean>(false)

  const { t } = useTranslation("common")
  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)
  const { pano, ceph, otherXrays } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    let downloadTotal = 0
    const totalXrays = fileList.filter(
      (item) =>
        item.attachment_type === "radiograph" &&
        (item.file_name.includes("pano") ||
          item.file_name.includes("ceph") ||
          item.file_name.includes("others")),
    ).length
    const downloadFiles = (item: FileItem, type: string | undefined) => {
      const { file_name, original_file_name } = item
      dispatch(setDownloadStatus("pending"))

      dispatch(
        downloadXray({
          patientId: patientId,
          caseId: caseId,
          fileName: file_name,
          XrayType: type,
          original_file_name: original_file_name,
        }),
      ).then((res) => {
        downloadTotal++
        if (downloadTotal === totalXrays) {
          dispatch(setDownloadStatus("succeeded"))
        }
      })
    }

    const photographTypeMap = {
      pano: "pano",
      ceph: "ceph",
      others: "others",
    }
    if (
      fileList.length > 0 &&
      !ceph.length &&
      !pano.length &&
      !otherXrays.length
    ) {
      fileList.forEach((result) => {
        const attachmentType = result.attachment_type
        const type = Object.keys(photographTypeMap).find((key) => {
          return (
            result.file_name.includes(key) && attachmentType === "radiograph"
          )
        })
        console.log("type", type)
        if (result.attachment_type === "radiograph") {
          downloadFiles(result, type)
        }
      })
    }
  }, [fileList, patientId, caseId])

  const removeFileFromList = (
    file: File,
    setState: React.Dispatch<React.SetStateAction<File[]>>,
  ): void => {
    setState((prevFiles) => prevFiles.filter((f) => f !== file))
  }

  const onRemoveFileFromList = (file: File): void => {
    removeFileFromList(file, setSelectedPanoFiles)
  }

  const onRemoveCephFileFromList = (file: File): void => {
    removeFileFromList(file, setSelectedCephFiles)
  }

  const deletePanoFile = (): void => {
    dispatch(
      deleteFiles({
        patientId: patientId,
        caseId: caseId,
        fileName: pano[0].fileName,
        fileType: "radiograph",
        version: "v1",
      }),
    )
  }

  const deleteCephFile = (): void => {
    dispatch(
      deleteFiles({
        patientId: patientId,
        caseId: caseId,
        fileName: ceph[0].fileName,
        fileType: "radiograph",
        version: "v1",
      }),
    )
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={"space-between"}
        gap={2}
        mt={0}
        pt={1}
        ml={0}
        width={"872px"}
        sx={{ minHeight: "278px" }}
      >
        <Grid
          item
          justifyContent={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ p: "10px !important", ml: "0px !important" }}
        >
          <UText
            color={"text.primary"}
            variant={"h6"}
            sxProp={{ display: "flex", justifyContent: "center" }}
          >
            {t("records.xray.pano")}
          </UText>
          <FileUploadSection
            id="pano"
            selectedFiles={selectedPanoFiles}
            setSelectedFiles={setSelectedPanoFiles}
            onRemoveFileFromList={onRemoveFileFromList}
            deleteFile={deletePanoFile}
            uploadFiles={(files: FileList) => setSelectedPanoFiles([...files])}
            downloadedFile={pano}
          />
        </Grid>
        <Grid
          item
          justifyContent={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ p: "10px !important", ml: "0px !important" }}
        >
          <UText
            component={"div"}
            color={"text.primary"}
            variant={"h6"}
            sxProp={{ textAlign: "center" }}
          >
            {t("records.xray.ceph")}
          </UText>
          <FileUploadSection
            id="ceph"
            selectedFiles={selectedCephFiles}
            setSelectedFiles={setSelectedCephFiles}
            onRemoveFileFromList={onRemoveCephFileFromList}
            deleteFile={deleteCephFile}
            uploadFiles={(files: FileList) => setSelectedCephFiles([...files])}
            downloadedFile={ceph}
          />
          {!ceph.length && (
            <UText
              component={"div"}
              color={"text.secondary"}
              variant={"caption"}
              sxProp={{
                lineHeight: "166%",
                letterSpacing: "0.4px",
                pt: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {t("records.xray.optional")}
            </UText>
          )}
        </Grid>
      </Grid>
      <AdditionalPhotograph
        isXray={true}
        title={t("records.xray.modalPopupTitle")}
        openModal={openModal}
        setOpenModal={setOpenModal}
        linkText={t("records.xray.addXray") || ""}
        showAdditionalFilesSection={
          (!!pano.length && !!ceph.length) || !!otherXrays.length
        }
        photographType={"xrays"}
        downloadedFiles={otherXrays}
        version="v1"
        fileType="radiograph"
      />
    </>
  )
}

export default UploadPano
