import type { FC } from "react"
import * as React from "react"

import {
  FlowButton,
  FlowTabs,
  FlowNameSliderGrid,
} from "../../Custom/FlowCustom"

import QuickSetup from "./QuickSetup"
import QuickTreatment from "./QuickTreatment"
import QuickRx from "./QuickRx"
import QuickFinal from "./QuickFinal"

const UFlowQuickPlan = (props) => {
  return (
    <FlowTabs labels={["New Plan", "Setup", "Treatment", "Rx"]}>
      <QuickFinal />
      <QuickSetup />
      <QuickTreatment />
      <QuickRx />
    </FlowTabs>
  )
}

export default UFlowQuickPlan
