import * as React from "react"
import { Typography, Grid, Stack, Divider } from "@mui/material"
import {
  FlowBtnGroup,
  FlowButton,
  FlowNameSliderGrid,
} from "../../Custom/FlowCustom"
import { gdsPlusSetup, ArchformType } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"
// import { ButtonParaMap } from "./WeFlowSetup"

import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

const DrawArchform = (props) => {
  const { archform, archShift, disabled } = useAppSelector(
    (state: RootState) => state.autoSetupSlice,
  )
  const dispatch = useAppDispatch()

  const archformBtns = [
    { label: "Natural", name: "ShapeNatural" },
    { label: "Tapered", name: "ShaperTapered" },
    { label: "Ovoid", name: "ShapeOvoid" },
    { label: "Square", name: "ShapeSquare" },
  ]

  const archformSliders = [
    archform.ArchformAnt,
    archform.ArchformMid,
    archform.ArchformPost,
  ]

  const archShiftSliders = [
    archShift.JawMoveLR,
    archShift.JawMoveMD,
    archShift.JawMoveUD,
  ]

  React.useEffect(() => {
    console.log("mount Archform")
    dispatch({ type: "autosetup/updateState" })
    return () => {
      console.log("unmount Archform")
    }
  }, [])

  const onSliderChange = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    const payload = { variable: variable, value: value }
    let type = "autosetup/setArchShift"
    if (variable.includes("Archform")) type = "autosetup/setArchform"
    dispatch({
      type: type,
      payload: payload,
    })
  }

  const onSliderChangeCommitted = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamDouble(variable, value)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  const onClickResetArchShift = () => {
    gdsPlusSetup.ResetArchShift()
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  const onButtonClickShape = (btnName: string) => {
    console.log(btnName, "clicked")
    let type = ArchformType.Natural
    switch (btnName) {
      case "ShapeNatural":
        type = ArchformType.Natural
        break
      case "ShapeTapered":
        type = ArchformType.Tapered
        break
      case "ShapeOvoid":
        type = ArchformType.Ovoid
        break
      case "ShapeSquare":
        type = ArchformType.Square
        break
    }
    gdsPlusSetup.SetArchformTypeByID(type, () => {})
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">Archform</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={archformBtns}
        onButtonClick={onButtonClickShape}
      />
      <FlowNameSliderGrid
        params={archformSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <Divider />
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">Arch Shift</Typography>
        </Grid>
        <Grid item xs={3}>
          <FlowButton onClick={onClickResetArchShift}>Reset</FlowButton>
        </Grid>
      </Grid>
      <FlowNameSliderGrid
        params={archShiftSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
    </Stack>
  )
}
export default DrawArchform
