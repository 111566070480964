import * as React from "react"
import { Typography, Box, Stack, Divider, Grid } from "@mui/material"
import { FlowBtnGroup, FlowNameSliderGrid } from "../../Custom/FlowCustom"
import { gdsPlusSetup, OJOBType, MidlineType } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"

import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

const DrawPosterior = (props) => {
  const { anterior, rootTorque, globalMove, TMJMoveDist, disabled } =
    useAppSelector((state: RootState) => state.autoSetupSlice)
  const dispatch = useAppDispatch()

  const apBtns = [
    { label: "Auto AP", name: "AutoAP" },
    { label: "No AP", name: "NoAP" },
  ]

  const occBtns = [
    { label: "Full Arch", name: "OccFull" },
    { label: "Post Only", name: "OccPost" },
    { label: "Sel. Teeth", name: "OccTeeth" },
  ]

  const gmBtns = [
    { label: "Auto", name: "GMAuto" },
    { label: "Remove", name: "GMRemove" },
    { label: "Min Upper", name: "GMMin" },
  ]

  const maxGmSliders = [globalMove.TMJMoveLimit]

  React.useEffect(() => {
    console.log("mount anterior")
    dispatch({ type: "autosetup/updateState" })
    return () => {
      console.log("unmount anterior")
    }
  }, [])

  const onSliderChange = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    const payload = { variable: variable, value: value }
    let type = "autosetup/setArchShift"
    if (variable.includes("Archform")) type = "autosetup/setArchform"
    else if (variable.includes("TMJMoveLimit")) type = "autosetup/setGlobalMove"
    dispatch({
      type: type,
      payload: payload,
    })
  }

  const onSliderChangeCommitted = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamDouble(variable, value)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }
  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "AutoAP":
        gdsPlusSetup.SetupAPUpperToLower()
        break
      case "NoAP":
        gdsPlusSetup.NoAPControl()
        break
      case "OccFull":
        gdsPlusSetup.ResolveOccColl(false, false, () => {})
        break
      case "OccPost":
        gdsPlusSetup.ResolveOccColl(true, false, () => {})
        break
      case "OccTeeth":
        gdsPlusSetup.ResolveOccColl(true, true, () => {})
        break
      case "GMAuto":
        gdsPlusSetup.SetLowerGlobalMovement(true, false)
        break
      case "GMRemove":
        gdsPlusSetup.SetLowerGlobalMovement(false, false)
        break
      case "GMMin":
        gdsPlusSetup.SetLowerGlobalMovement(true, true)
        break
    }
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }
  const DrawTMJMoveDist = () => {
    return (
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={3}>
          <Typography variant="body2">TMJ Dist</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">Left</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography variant="body2">
            {TMJMoveDist.leftDist.toFixed(2) + " mm"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">Right</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography variant="body2">
            {TMJMoveDist.rightDist.toFixed(2) + " mm"}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">AP Control</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={apBtns}
        onButtonClick={onButtonClick}
      />
      <Divider />
      <Typography variant="body1">Occlusal Contact</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={occBtns}
        onButtonClick={onButtonClick}
      />
      <Divider />
      <Typography variant="body1">Global Movement</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={gmBtns}
        onButtonClick={onButtonClick}
      />
      <FlowNameSliderGrid
        params={maxGmSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <DrawTMJMoveDist />
    </Stack>
  )
}
export default DrawPosterior
/* 
function mapStateToProps(state) {
  const { archform, archShift, disabled } = state.autosetup
  return { archform, archShift, disabled }
}
export default connect(mapStateToProps)(DrawArchform)
*/
