export class Event {
  // eventname <-> listener[]
  eventMapper: Record<string, ((...arg: any) => void)[]> = {};

  on(name: string, callback: (...arg: any) => void) {
    let event = this.eventMapper[name];
    if (!event) {
      this.eventMapper[name] = [];
      event = this.eventMapper[name];
    }

    const ind = event.findIndex(item => {
      return item === callback;
    });
    if (ind === -1) {
      event.push(callback);
      console.log("onEvent:", name, this.eventMapper);
    }
    return callback;
  }

  onFireOnce(name: string, callback: (...arg: any) => void) {
    const oneCall = (...a) => {
      callback(a);
      this.off(name, oneCall);
    };
    this.on(name, oneCall);
  }

  fire(name: string, ...arg: any) {
    // console.log('fireevent::', name, this.eventMapper[name]);
    const eventListeners = this.eventMapper[name];
    if (eventListeners) {
      for (let index = 0; index < eventListeners.length; index++) {
        const listener = eventListeners[index];
        listener(...arg);
      }
    }
  }

  offAll() {
    this.eventMapper = {};
  }

  off(name: string, callback?: (arg?: any) => void) {
    // console.log('off', name, this.eventMapper);
    const events = this.eventMapper[name];
    if (!events) {
      console.warn("cant find event to be remove!!", name, this.eventMapper);
      return;
    }
    if (callback) {
      const delIndex = events.indexOf(callback);
      if (delIndex > -1) {
        events.splice(delIndex, 1);
      }
    } else {
      this.eventMapper[name] = [];
    }
  }

  hasEvent(name: string) {
    return !!this.eventMapper[name];
  }
}
