import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Dialog } from "@mui/material"

import Next from "../../../../assets/svgs/next.svg"
import Pre from "../../../../assets/svgs/pre.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import { useAppSelector } from "../../../../core/app/hooks"
import { PhotoObject } from "../../../../core/app/slices/records/photograph/photograph.type"
import { RootState } from "../../../../core/app/store"
import { UText } from "../../../../ui-component"
import { getPhotoTitle } from "../SideBar/records"

/**
 *  点击照片后放大
 * @param open: key:照片类型，value:选中的照片id，undefined:关闭Dialog
 * @param setOpen:控制是否打开Dialog
 * @returns component
 */

const Fill: FC<{
  open: { key: string; value: number } | undefined
  setOpen: (arg: { key: "photo" | "xray"; value: number } | undefined) => void
}> = ({ open, setOpen }) => {
  const [activeId, setactiveId] = useState(0)
  const [list, setlist] = useState<PhotoObject[]>([])

  const [scrollStep, setscrollStep] = useState(0)

  const { compositePhoto, individualPhotos, additionalPhotos } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )

  const { pano, ceph, otherXrays } = useAppSelector(
    (state: RootState) => state.xrayService,
  )

  const { t } = useTranslation("common")
  useEffect(() => {
    if (open?.key === "photo") {
      if (compositePhoto.length > 0)
        setlist([...compositePhoto, ...additionalPhotos])
      else setlist([...individualPhotos, ...additionalPhotos])
    } else {
      setlist([...pano, ...ceph, ...otherXrays])
    }
    setactiveId(open?.value)
  }, [open])

  const scrollWidth = document.getElementById("fill-foot")?.offsetWidth - 85 * 2
  const listWidth = 108 * list.length

  useEffect(() => {
    if (scrollStep < 0) {
      setscrollStep(0)
      return
    }
    if (scrollStep * scrollWidth > listWidth) {
      setscrollStep(listWidth / scrollWidth)
      return
    }
    const scrollDom = document.getElementById("fill-scroll")
    scrollDom?.scrollTo({
      left: scrollWidth * scrollStep,
      behavior: "smooth",
    })
  }, [scrollStep])

  const scroll = (action: string) => {
    if (action === "pre") {
      setscrollStep(scrollStep - 1)
    }

    if (action === "next") {
      setscrollStep(scrollStep + 1)
    }
  }

  return (
    <Dialog
      fullScreen
      onClose={() => {
        setOpen(undefined)
      }}
      open={!!open}
      sx={{ marginTop: 8 }}
    >
      <Box
        id="fill"
        sx={{
          backgroundColor: "rgba(55, 71, 79, 0.96)",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 4,
        }}
      >
        <Box
          id="fill-header"
          sx={{
            width: "100%",
            height: 84,
            padding: "16px 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <UText
            variant={"caption"}
            sxProp={{
              fontSize: 20,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            {open?.key === "photo" ? "Photograph" : "X-RAY"}
          </UText>
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            onClick={() => {
              setOpen(undefined)
            }}
          >
            {t("button.close")}
          </Button>
        </Box>
        <Box
          id="fill-content"
          sx={{ height: 0, flex: "1 1 auto", width: "100%", display: "flex" }}
        >
          <Box
            id="fill-pre"
            sx={{
              width: 83,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgBtn
              svg={Pre}
              clickFun={() => {
                setactiveId(activeId - 1)
              }}
              isdisable={activeId === 0}
              boxSx={{ height: 59, width: 59, padding: 1.5 }}
            />
          </Box>
          <Box
            id="fill-photo"
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "62px 77px",
            }}
          >
            <img
              src={`${list[activeId]?.src}`}
              alt=""
              style={{
                borderRadius: 4,
                border: "1px solid rgba(144, 202, 249, 0.50)",
                background: "rgba(255, 255, 255, 0.04)",
                objectFit: "contain",
                flex: 1,
                height: "100%",
                width: "100%",
              }}
            />
            <UText
              variant={"h6"}
              sxProp={{
                height: 32,
                width: "100%",
                textAlign: "center",
                color: "#FFF",
                display: "block",
              }}
            >
              {getPhotoTitle(list[activeId])}
            </UText>
          </Box>

          <Box
            id="fill-next"
            sx={{
              width: 83,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgBtn
              svg={Next}
              clickFun={() => {
                setactiveId(activeId + 1)
              }}
              boxSx={{ height: 59, width: 59, padding: 1.5 }}
              isdisable={activeId + 1 === list.length}
            />
          </Box>
        </Box>
        <Box
          id="fill-foot"
          sx={{
            height: 144,
            width: "100%",
            padding: "16px 85px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {listWidth > scrollWidth && (
            <SvgBtn
              svg={Pre}
              clickFun={() => {
                scroll("pre")
              }}
              boxSx={{
                position: "absolute",
                left: 61,
                height: 48,
                width: 48,
                padding: 1.5,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.56)",
                },
              }}
              svgColor="black"
              isdisable={!(scrollStep * scrollWidth > 0)}
            />
          )}
          <Box
            id="fill-scroll"
            sx={{ display: "flex", flexFlow: "nowrap", overflow: "hidden" }}
          >
            {list.map(({ position, src }, index) => {
              return (
                <img
                  key={index}
                  src={src}
                  alt=""
                  onClick={() => {
                    setactiveId(index)
                  }}
                  style={{
                    marginRight: 16,
                    borderRadius: 4,
                    border:
                      activeId === index
                        ? "2px solid #90CAF9"
                        : "1px solid rgba(144, 202, 249, 0.50)",
                    background: "rgba(255, 255, 255, 0.04)",
                    height: 128,
                    width: 92,
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                />
              )
            })}
          </Box>

          {listWidth > scrollWidth && (
            <SvgBtn
              svg={Next}
              clickFun={() => {
                scroll("next")
              }}
              boxSx={{
                position: "absolute",
                right: 61,
                height: 48,
                width: 48,
                padding: 1.5,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.56)",
                },
              }}
              svgColor="black"
              isdisable={!(scrollStep * scrollWidth < listWidth)}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  )
}

export default Fill
