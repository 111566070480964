import { FC } from "react"
import { Outlet } from "react-router-dom"

import logo from "../assets/images/ulablogo.svg"
import { Block } from "../components"
import { Box, Grid } from "../components/mui.components"
import { clearLocalStorage } from "../core/config/okta.config"
import { CENETER_COLUMN_ALIGN } from "../theme/theme.util"

const Auth: FC = () => {
  clearLocalStorage()

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ minHeight: "80vh" }}
    >
      <Block component={"div"} sxProp={{ ...CENETER_COLUMN_ALIGN }}>
        <Box
          component={"img"}
          src={logo}
          alt={"Logo"}
          loading="lazy"
          sx={{ mb: 3 }}
        />
        <Outlet />
      </Block>
    </Grid>
  )
}

export default Auth
