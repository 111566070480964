import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Tooltip } from "@mui/material"

import { toolButtonProps } from "./type"

import "./toolButton.scss"
const ToolButton: FC<toolButtonProps> = ({
  val,
  placement = "bottom",
  title,
  callBack,
  bg,
  isSelected,
  disabled = false,
  disabledBg = "",
  isFocused = false,
  sxProps,
}) => {
  const { t } = useTranslation("common")
  return (
    <Tooltip
      key={val}
      title={title}
      placement={placement}
      classes={{ popper: "toolButton" }}
    >
      <Button
        disabled={disabled}
        onClick={() => {
          callBack && callBack(val)
        }}
        sx={{
          width: "48px",
          height: "48px",
          padding: 0,
          color: isFocused ? "#fff" : "#0288D1",
          minWidth: "unset!important",
          maxWidth: "none",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: 0,
          border: "0",
          margin: "0",
          cursor: disabled ? "default" : "pointer",
          backgroundImage: `url(${disabled ? disabledBg : bg})`,
          boxShadow: "none",
          backgroundColor:
            !disabled && isSelected ? "#0288D1!important" : "unset",
          "&:hover": {
            backgroundColor:
              !disabled && !isSelected
                ? "rgba(255, 255, 255, 0.08)!important"
                : "unset",
          },
          "&:focus": {
            backgroundColor: isFocused
              ? "rgba(255, 255, 255, 0.12)!important"
              : "unset",
          },
          ...sxProps,
        }}
      ></Button>
    </Tooltip>
  )
}
export default ToolButton
