import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import { UText } from "../../../components"
import { Box, Button, Grid } from "../../../components/mui.components"
import { useAppDispatch } from "../../../core/app/hooks"
import { uassistAddform } from "../../../core/app/slices/uassistForm/uassistFormThunkApi"
import JsonFormBuilder from "../../../rx-form/builder/JsonFormBuilder"
import {
  defaultData,
  jsonSchemaDefault,
  uiSchemaDefault,
} from "../../../rx-form/Json/deepBiteForm"

const DeepBite: FC<{
  isSettings?: boolean
  isResetData?: boolean
  setIsResetData?: (val: boolean) => void
  isSaveData?: boolean
  setIsSaveData?: (val: boolean) => void
}> = ({
  isSettings,
  isResetData,
  setIsResetData,
  isSaveData,
  setIsSaveData,
}) => {
  const { t } = useTranslation("common")
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const { caseId, patientId } = useParams()

  const [data, setData] = useState(defaultData)

  useEffect(() => {
    setData(data)
  }, [data])

  useEffect(() => {
    if (!isSaveData) return
    setIsFormSubmitted(true)
    dispatch(
      uassistAddform({
        patientId: 1234,
        caseId: 11234,
        payload: data,
      }),
    )
    setIsSaveData && setIsSaveData(false)
  }, [isSaveData])

  useEffect(() => {
    setData(defaultData)
    setIsResetData && setIsResetData(false)
  }, [isResetData])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
  const schema = JSON.parse(jsonSchemaDefault)
  // eslint-disable-next-line no-prototype-builtins
  if (schema.properties.hasOwnProperty("case_specific_instructions")) {
    delete schema.properties.case_specific_instructions
  }

  const uiSchema = JSON.parse(uiSchemaDefault)

  uiSchema.elements = uiSchema.elements.filter(
    (element) => element.label !== "Additional Instructions",
  )

  const submitData = () => {
    setIsFormSubmitted(true)
    dispatch(
      uassistAddform({
        patientId: 1234,
        caseId: 11234,
        payload: data,
      }),
    )
  }

  return (
    <Grid
      container
      flexDirection={"column"}
      sx={{
        backgroundColor: "background.default",
        borderRadius: 2,
        padding: 5,
        justifyContent: "space-between",
        width: "640px",
      }}
    >
      <UText variant={"h4"} component={"div"} sxProp={{ textAlign: "center" }}>
        {t("uassist.uAssistForm.deepBite")}
      </UText>
      <UText
        variant={"body2"}
        sxProp={{
          mt: isSettings ? 1 : 0,
          mb: 3,
          textAlign: "center",
          color: "rgb(0,0,0,0.6)",
        }}
      >
        {isSettings
          ? t("uassist.uAssistForm.deepbiteSettingsDescription")
          : t("uassist.uAssistForm.formInfo")}
      </UText>
      <JsonFormBuilder
        schema={isSettings ? JSON.parse(jsonSchemaDefault) : schema}
        uischema={isSettings ? JSON.parse(uiSchemaDefault) : uiSchema}
        data={data}
        isFormSubmitted={isFormSubmitted}
        setData={setData}
      />
      <Box
        component={"div"}
        sx={{ justifyContent: "space-between", display: "flex", mt: 1 }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate(
              `/uassist/patient/${patientId}/case/${caseId}/prescription`,
            )
          }}
          sx={{ width: "96px", height: "36px" }}
        >
          <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
          {t("button.back")}
        </Button>
        <Button
          variant={"contained"}
          sx={{ width: "179px", height: "36px" }}
          onClick={submitData}
        >
          {t("button.saveCase")}
        </Button>
      </Box>
    </Grid>
  )
}

export default DeepBite
