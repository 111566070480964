import { createSlice } from "@reduxjs/toolkit"

import {
  fetchFileList,
  fetchFileZips,
  getTreatmentInfos,
  uploadCaseZips,
} from "./clinicalThunkApi"
export const DOWNLOAD_FILEZIP = [
  "bulk0",
  `bulk1`,
  `bulk2`,
  `bulk10`,
  `photo`,
  `raw`,
  "storage.version.json",
]
export const clinicalServiceSlice = createSlice({
  name: "clinicalService",
  initialState: {
    fileNames: [],
    zipNames: [],
    zipList: {},
    treatmentList: [],
    fileListReady: false,
    isSuccessUploadZips: false,
  },
  reducers: {
    setData(state, { playload }) {
      return {
        ...state,
        ...playload,
      }
    },
    resetStore(state) {
      return {
        ...state,
        zipNames: [],
        zipList: {},
        isSuccessUploadZips: false,
      }
    },
    resetCase(preState) {
      console.log("resetCase is called!")
      preState.fileListReady = false
      preState.treatmentList = []
      preState.fileNames = []
      preState.zipNames = []
      preState.zipList = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFileList.pending, (state) => {
      console.log("pending")
    })
    builder.addCase(fetchFileList.fulfilled, (state, action) => {
      state.fileListReady = true
      const { result } = action.payload
      state.fileNames = []
      let error = ""
      for (const { file_name } of result) {
        state.fileNames.push(file_name)
      }
      state.zipNames = []
      for (const name of DOWNLOAD_FILEZIP) {
        if (!state.fileNames.includes(`${name}.zip`)) {
          error = `not find ${name}.zip`
        } else {
          state.zipNames.push(name)
        }
      }
    })
    builder.addCase(fetchFileList.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(fetchFileZips.pending, (state) => {
      console.log("pending")
    })
    builder.addCase(fetchFileZips.fulfilled, (state, action) => {
      const { payload } = action
      const zipList = {}
      for (let i = 0; i < state.zipNames.length; i++) {
        const name = state.zipNames[i] as string
        zipList[name] = payload[i].data
      }
      state.zipList = zipList
    })
    builder.addCase(fetchFileZips.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(getTreatmentInfos.pending, (state) => {
      console.log("pending")
    })
    builder.addCase(getTreatmentInfos.fulfilled, (state, action) => {
      const { payload } = action
      state.treatmentList = payload.result
    })
    builder.addCase(getTreatmentInfos.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(uploadCaseZips.fulfilled, (state, action) => {
      const { payload } = action
      let isSuccess = true,
        error = ""
      for (const {
        response_code = 200,
        errors: [{ error_message = "uploadCaseZip fail" }] = [{}],
      } of payload) {
        if (response_code !== 200) {
          isSuccess = false
          error = error_message
        }
      }
      state.isSuccessUploadZips = isSuccess
      if (!isSuccess) {
        alert(error)
      }
    })
    builder.addCase(uploadCaseZips.rejected, (state) => {
      console.log("rejected")
      state.isSuccessUploadZips = false
    })
  },
})
export const { resetStore: resetAction, resetCase } =
  clinicalServiceSlice.actions
