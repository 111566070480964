// Get the name of the UR/UL by tooth id
export const getName = (id: number) => {
  let name = ""
  if (id >= 1 && id <= 8) {
    name = `UR${9 - id}`
  } else if (id >= 9 && id <= 16) {
    name = `UL${id - 8}`
  } else if (id >= 17 && id <= 24) {
    name = `LL${9 - (id - 16)}`
  } else {
    name = `LR${id - 24}`
  }
  return name
}
