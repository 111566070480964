import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import { flatMap } from "lodash"

import { UText } from "../../../components"
import { Button, Grid } from "../../../components/mui.components"
import JsonFormBuilder from "../../../rx-form/builder/JsonFormBuilder"
import {
  defaultData,
  jsonSchemaDefault,
  uiSchemaDefault,
} from "../../../rx-form/Json/prescriptionForm"

const Prescription: FC = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const { t } = useTranslation("common")
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
  return (
    <Grid
      container
      flexDirection={"column"}
      sx={{
        backgroundColor: "background.default",
        borderRadius: 2,
        padding: 5,
        minHeight: 1800,
        justifyContent: "start",
      }}
    >
      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
          {t("uassist.uAssistForm.prescription")}
        </UText>
      </Grid>
      <Grid item>
        <JsonFormBuilder
          schema={JSON.parse(jsonSchemaDefault)}
          uischema={JSON.parse(uiSchemaDefault)}
          data={defaultData}
          isFormSubmitted={isFormSubmitted}
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Button
          variant={"outlined"}
          onClick={() => {
            console.log("...Tobe enable redirection")
          }}
          sx={{ width: "96px", height: "36px" }}
        >
          <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
          {t("button.back")}
        </Button>

        <Button
          variant={"contained"}
          onClick={() => {
            setIsFormSubmitted(true)
          }}
          sx={{ width: "92px", height: "36px" }}
        >
          {t("button.next")}
          <ChevronRight fontSize={"small"} sx={{ marginLeft: "3px" }} />
        </Button>
      </Grid>
    </Grid>
  )
}

export default Prescription
