import { FC, useEffect } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"

import { useAppDispatch } from "../../core/app/hooks"
import { fetchPatientList } from "../../core/app/slices/patients"

import PatientForm from "./PatientForm/PatientForm"
import PatientList from "./PatientList/PatientList"

const PatientModule: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    /**
     * TODO: This should be use matchpath from react router to validate path match
     */
    if (location.pathname === "/patients") {
      dispatch(
        fetchPatientList({
          patientQueryparams: {
            page: 1,
            page_size: 20,
          },
        }),
      )
    }
  }, [dispatch, location])

  return (
    <Routes>
      <Route path={""} element={<PatientList />} />
      <Route path={"new"} element={<PatientForm />} />
      <Route path={"*"} element={<Navigate to={""} replace />} />
    </Routes>
  )
}

export default PatientModule
