import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AxiosProgressEvent } from "axios"

import { UFileProcessingCard } from "../../../components"
import { useAppDispatch } from "../../../core/app/hooks"
import { uploadXray } from "../../../core/app/slices/records/xrays/xraysThunkApi"

const UploadXrayProcessing: FC<{
  file: File
  onRemove: (f: any) => void
  onUploadSuccess?: (val: File[]) => void
  radioGraphType: string
}> = ({ file, onRemove, onUploadSuccess, radioGraphType }) => {
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressstatus, setProgresssStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()

  useEffect(() => {
    const newFile = new File(
      [file],
      `${radioGraphType}.${file.type.split("/")[1]}`,
      {
        type: file.type,
      },
    )
    const formPayload = new FormData()
    formPayload.append("attachment", newFile)
    formPayload.append("original_file_name", file.name)
    dispatch(
      uploadXray({
        file,
        radioGraphType,
        patientId: patientId,
        caseId: caseId,
        formData: formPayload,
        fileName: newFile.name,
        orgId: "",
        onFileProcesscallback: function (progress: AxiosProgressEvent): void {
          const { loaded, total } = progress
          setProgresssStatus("loading")
          const percentageProgress = Math.floor((loaded / total) * 100)
          setProgressValue(percentageProgress)
        },
      }),
    ).then((action) => {
      if (action.payload.status === 200) {
        setProgresssStatus("complete")
        onUploadSuccess && onUploadSuccess([])
      } else {
        setProgresssStatus("failed")
      }
    })
  }, [file])

  return (
    <>
      <UFileProcessingCard
        fileName={file.name}
        fileSize={file.size}
        progressValue={progressValue}
        progressstatus={progressstatus}
        onRemove={() => onRemove(file)}
      />
    </>
  )
}

export default UploadXrayProcessing
