import { sagemakerModule } from "./sagemakerModule";
import { wasmModule } from "../wasm/wasmModule";

const _sagemakerinit = async (upperArch: Blob, lowerArch: Blob, canvas) => {
  // const writeFileList: Record<string, any> = {};
  // if (upperArch) {
  //   const ab = await upperArch.arrayBuffer();
  //   const up = new Uint8Array(ab);
  //   writeFileList['test/pre/arch_u.stl'] = up;
  // }
  // if (lowerArch) {
  //   const lab = await lowerArch.arrayBuffer();
  //   const low = new Uint8Array(lab);
  //   writeFileList['test/pre/arch_l.stl'] = low;
  // }

  wasmModule.initWASM(canvas, () => {
    const u = wasmModule.module.allocateUTF8("test/pre/fillready_arch_u.stl");
    const l = wasmModule.module.allocateUTF8("test/pre/fillready_arch_l.stl");
    wasmModule.module._newpresetup(u, l);
  });
};

const redoPresetup = async canvas => {
  wasmModule.initWASM(canvas, () => {
    const u = wasmModule.module.allocateUTF8(
      "test/case/Setting Data0/arch_u.mtc"
    );
    const l = wasmModule.module.allocateUTF8(
      "test/case/Setting Data0/arch_l.mtc"
    );
    wasmModule.module._newpresetup(u, l);
  });
};

/**
 * param canvas： 绘制canvas的位置
 * param upperArch 上牙stl
 * param lowerArch 下牙stl
 * loadingendcallback: 完成segment(上下颌各一次)
 * donecallback: 完成presetup
 * */
export async function doPresetupWorkflow(
  upperArch: Blob,
  lowerArch: Blob,
  canvas,
  loadingendcallback: () => void,
  donecallback: () => void
) {
  if (wasmModule.module) {
    wasmModule.module.cancel_em_loop();
    wasmModule.module._freePreview();
  }
  _sagemakerinit(upperArch, lowerArch, canvas);
  sagemakerModule.setLoadEndCallback(loadingendcallback, donecallback);
  wasmModule.isPresetup = true;
}
export async function redoPresetupFlow(
  canvas,
  loadingendcallback: () => void,
  donecallback: () => void
) {
  if (wasmModule.module) {
    wasmModule.module.cancel_em_loop();
    // wasmModule.module._freePreview();
  }
  redoPresetup(canvas);

  sagemakerModule.setLoadEndCallback(loadingendcallback, donecallback);
  wasmModule.isPresetup = true;
}
