import http from "../../../global/http"

interface IUserPreferences {
  bothscanspopup_no_shows?: boolean
}

export interface IUpdateUser {
  first_name: string
  last_name: string
}

export const updateUserPreferences = async (
  orgId: string,
  usrId: string,
  payload: IUserPreferences,
) => {
  try {
    const { data } = await http.put(
      `/user-mgmt/v1/org/${orgId}/users/${usrId}/preferences/`,
      { user_preference: { ...payload } },
    )
    return data
  } catch (err) {
    return err
  }
}

export const getUserPreferences = async (
  orgId: string,
  usrId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/${usrId}?fields=preferences,id`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const getUserProfile = async (
  orgId: string,
  userId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/${userId}/?fields=preferences,id,org_name,first_name,last_name,update_date,last_login_date,org_id,user_type,status,lang_cd,country_cd,username,email`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const updateUserProfile = async (
  orgId: string,
  userId: string,
  payload: IUpdateUser,
): Promise<any> => {
  try {
    const { data } = await http.patch(
      `/user-mgmt/v1/org/${orgId}/users/${userId}/`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}
