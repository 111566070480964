export enum EToothDirection {
  /**
   (远离牙中线)
 * */
  Mesial = 0,
  /**
  (靠近牙中线)
 * */
  Distal,
  /**
  靠近脸颊,远离舌头(Y+)
 * */
  Buccal,
  /**
  远离脸颊,靠近舌头(Y-)
 * */
  Lingual,
  /**
  伸长牙齿(Z+)
 * */
  Extrusion,
  /**
  压低牙齿(Z-)
 * */
  Intrusion,
  /**
  绕 x轴+
 * */
  TorqueAdd,
  /**
  绕x轴-
 * */
  TorqueSub,

  /**
  绕Y轴+
 * */
  TippingAdd,
  /**
  绕Y轴-
 * */
  TippingSub,
  /**
  绕z轴+
 * */
  RotationAdd,
  /**
  绕z轴-
 * */
  RotationSub,
  None,
}
export enum EToothdisCheckbox {
  Crown = 0, // Crown checkbox
  Implant,
  Bridge,
}
