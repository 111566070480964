import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { createLogger } from "redux-logger"

import { alertSlice } from "./slices/alert/alertSlice"
import { analyticSlice } from "./slices/AnalyticsSlice"
import { clinicalServiceSlice } from "./slices/clinical/clinicalSlice"
import { configSlice } from "./slices/ConfigSlice"
import { doctorServiceSlice } from "./slices/doctors/doctorSlice"
import { patientServiceSlice } from "./slices/patients/patientSlice"
import { photographServiceSlice } from "./slices/records/photograph/photographSlice"
import { xrayServiceSlice } from "./slices/records/xrays/xraysSlice"
import { formServiceSlice } from "./slices/uassist/formSlice"
import { userServiceSlice } from "./slices/user/userSlice"
import { wasm3DServiceSlice } from "../../wasm3d/Wasm3DServiceSlice"
import { autoSetupSlice } from "../../wasm3d/AutoSetupSlice"
import { autoStagingSlice } from "../../wasm3d/AutoStagingSlice"
import { manuStagingPercentSlice } from "../../wasm3d/CommonPages/ManuStagingPercentSlice"
import { toothInfoSlice } from "../../wasm3d/CommonPages/ToothInfoSlice"

const logger = createLogger({
  level: "info",
  collapsed: true,
})

export const store = configureStore({
  reducer: {
    config: configSlice.reducer,
    analytic: analyticSlice.reducer,
    patientService: patientServiceSlice.reducer,
    clinicalService: clinicalServiceSlice.reducer,
    userService: userServiceSlice.reducer,
    wasm3DServiceSlice: wasm3DServiceSlice.reducer,
    autoSetupSlice: autoSetupSlice.reducer,
    autoStagingSlice: autoStagingSlice.reducer,
    manuStagingPercentSlice: manuStagingPercentSlice.reducer,
    doctorService: doctorServiceSlice.reducer,
    toothInfoSlice: toothInfoSlice.reducer,
    PhotosService: photographServiceSlice.reducer,
    xrayService: xrayServiceSlice.reducer,
    alert: alertSlice.reducer,
    formService: formServiceSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default store
