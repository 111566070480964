import { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import { FormAutoComplete, UButton, UText } from "../../../components"
import { Box, Chip, Grid, SxProps } from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { useAppDispatch } from "../../../core/app/hooks"
import { updateCase } from "../../../core/app/slices/patients/patientThunkApi"
import { RootState } from "../../../core/app/store"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"

const PatientInfoHeader: FC<{
  chipLabel: string
  onBtnCancelClick: () => void
  cancelBtnText?: string
  chipSx: SxProps
}> = ({ chipLabel, onBtnCancelClick, cancelBtnText, chipSx }) => {
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")

  const {
    control,
    formState: { errors },
    watch,
  } = useForm()

  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )

  const { caseDetails, patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const formValue = watch("doctor", caseDetails.assigned_to)

  const formLabel = doctorList?.find((list) => list.id === formValue)

  useEffect(() => {
    if (caseDetails.assigned_to === formValue) return
    dispatch(
      updateCase({
        patientId: patientId,
        caseId: caseId,
        payload: {
          assigned_to: formValue,
        },
      }),
    )
  }, [formValue])
  return (
    <Grid container sx={{ mt: 2, py: 2, height: "fit-content" }}>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <Grid item sm={6} display={"flex"}>
          <UText
            variant={"h5"}
            component={"div"}
            sxProp={{
              maxWidth: "500px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {patientData?.firstName + " " + patientData?.lastName}
          </UText>
        </Grid>
        <Grid item sm={6} display={"flex"} justifyContent={"end"}>
          <UButton
            variant={"shade"}
            btnType={"button"}
            btnText={t(cancelBtnText)}
            onClickHandler={onBtnCancelClick}
            sxProp={{
              fontSize: "13px",
              padding: "4px 10px",
              height: "unset",
            }}
          />
        </Grid>
      </Box>
      <Box
        component={"div"}
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Chip
          label={chipLabel}
          sx={{
            ...chipSx,
            padding: " 2px 4px",
            borderRadius: "50px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            whiteSpace: "nowrap",
            height: "unset",
          }}
        />
        <Box
          component="div"
          sx={{
            borderLeft: "1px solid rgba(0, 0, 0, 0.12);",
            height: "20px",
            mx: 1,
          }}
        ></Box>
        {formLabel?.label && (
          <>
            <Box
              component={"div"}
              sx={{
                ...CENETER_COLUMN_ALIGN,
                height: "24px",
                width: "50px",
                backgroundColor: "success.dark",
                borderRadius: "50px",
                marginRight: "8px",
                fontSize: "10px",
                justifyContent: "center",
                fontWeight: 500,
                color: "#ffffff",
              }}
            >
              {formLabel?.label
                .charAt(0)
                .concat(formLabel?.label.split(" ")[1].charAt(0))
                .toUpperCase()}
            </Box>
            <FormAutoComplete
              inputLabel={"formfields.alldoctors"}
              fieldName={"doctor"}
              rules={{
                required: "formfieldErrors.assigntorequired",
              }}
              defaultValue={
                doctorList?.find((list) => list.id === caseDetails.assigned_to)
                  ?.label
              }
              isHeader
              options={doctorList}
              errors={false}
              control={control}
              formSxprops={{ my: 0, display: "block" }}
              customPopupIcon={<KeyboardArrowDownIcon fontSize={"medium"} />}
              sxProp={{
                "& .MuiOutlinedInput-root": {
                  padding: "0 !important",
                  width: "200px",
                },
                "& .MuiOutlinedInput-root:hover:not(.Mui-focused )": {
                  "& fieldset": {
                    border: "none !important",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "inline-flex",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    marginRight: "-10px",
                  },
                },
                "& .MuiOutlinedInput-root:focus-within": {
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "inline-flex !important",
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    marginRight: "-10px",
                  },
                },
                "& input": {
                  padding: "0 !important",
                  fontSize: "14px",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
                "& svg": {
                  width: "18px",
                  height: "18px",
                },
                "& fieldset": {
                  border: "none !important",
                  padding: "0 !important",
                },
                "& .MuiFormControl-root": {
                  display: "block",
                },
                "& .MuiAutocomplete-popupIndicator": {
                  display: "none",
                },
              }}
            />
          </>
        )}
      </Box>
    </Grid>
  )
}

PatientInfoHeader.defaultProps = {
  cancelBtnText: "button.cancel",
}

export default PatientInfoHeader
