import { FC, useEffect, useState } from "react"
import { preview } from "@ulab/wesmileclinical"
import { isEmpty } from "lodash"

import low from "../../assets/images/clinical/lower.png"
import up from "../../assets/images/clinical/upper.png"
import { UText } from "../../ui-component/components"
import { Box, Grid } from "../../ui-component/mui.components"
import WasmCanves from "../WasmView/wasmCanves"

import {
  canvas,
  model,
  tool,
  view,
  viewbox,
  zoom,
  zoomin,
  zoomout,
} from "./preview.style"

interface previewType {
  upperStl: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }
  sx?: object
  lowerStl: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }
  fillholeCallback?: () => void
  placeHolder?: string
  zips: Record<string, File>
  setUpperStl?: (data: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }) => void
  setLowerStl?: (data: {
    data: File | null
    action: "init" | "stl" | "download" | "delete"
  }) => void
}

const Preview: FC = ({
  sx = {},
  upperStl = { data: null, action: "init" },
  lowerStl = { data: null, action: "init" },
  fillholeCallback,
  placeHolder = "",
  zips,
  setUpperStl,
  setLowerStl,
}: previewType) => {
  const [activeArch, setactiveArch] = useState("both")
  const [currentZoomValue, setcurrentZoomValue] = useState(1.125)
  useEffect(() => {
    preview.drawSTL({
      upperArch: null,
      lowerArch: null,
      canvas: document.getElementById("canvas") as HTMLCanvasElement,
      zoomRange: [0.25, 2],
    })
    preview.setZoomCallback((value) => {
      setcurrentZoomValue(value)
    })
    return () => {
      preview.clearPreview()
    }
  }, [])

  useEffect(() => {
    if (
      (lowerStl.action === "init" && upperStl.action === "init") ||
      !preview.isInitPreview()
    )
      return
    preview.setZoomCallback((value) => {
      setcurrentZoomValue(value)
    })
    preview.drawSTL({
      upperArch: upperStl.data,
      lowerArch: lowerStl.data,
      canvas: document.getElementById("canvas") as HTMLCanvasElement,
      zoomRange: [0.25, 2],
      fillholeCallback: fillholeCallback,
    })
  }, [lowerStl.action, upperStl.action, preview.isInitPreview()])

  useEffect(() => {
    if (
      preview.isInitPreview() &&
      !isEmpty(zips) &&
      upperStl.action === "init" &&
      lowerStl.action === "init"
    ) {
      preview.setZoomCallback((value) => {
        setcurrentZoomValue(value)
      })
      preview.drawMtcFromZips(zips, [0.25, 2.0]).then((res) => {
        setUpperStl &&
          res.get("arch_o_u.mtc") &&
          setUpperStl({
            data: res.get("arch_o_u.mtc") || null,
            action: "download",
          })
        setLowerStl &&
          res.get("arch_o_l.mtc") &&
          setLowerStl({
            data: res.get("arch_o_l.mtc") || null,
            action: "download",
          })
      })
    }
  }, [zips, preview.isInitPreview()])

  useEffect(() => {
    if (preview.isInitPreview()) preview.zoomWithValue(currentZoomValue)
  }, [currentZoomValue])

  const ToolBar = (
    <Grid
      container
      sx={{
        ...tool,
      }}
    >
      <Grid sx={view}>
        <Box
          component={"span"}
          sx={{
            ...viewbox,
            "&:hover": {
              backgroundColor: !upperStl.data ? "#546E7A" : "#4E6670",
            },
            backgroundColor: activeArch === "up" ? "#455A64" : "#546E7A",
            opacity: !upperStl.data ? 0.6 : 1,
            borderRight: "1px solid #455A64",
            borderEndStartRadius: 4,
            borderStartStartRadius: 4,
          }}
          onClick={() => {
            if (!upperStl.data) {
              return
            }
            if (activeArch === "up") {
              preview.changeArchMode("both")
              setactiveArch("both")
            } else {
              preview.changeArchMode("up")
              setactiveArch("up")
            }
          }}
        >
          <input type="image" alt="up" src={up}></input>
        </Box>
        <Box
          component={"span"}
          sx={{
            ...viewbox,
            "&:hover": {
              backgroundColor: !lowerStl.data ? "#546E7A" : "#4E6670",
            },
            borderEndEndRadius: 4,
            borderStartEndRadius: 4,
            backgroundColor: activeArch === "low" ? "#455A64" : "#546E7A",
            opacity: !lowerStl.data ? 0.6 : 1,
          }}
          onClick={() => {
            if (!lowerStl.data) {
              return
            }
            if (activeArch === "low") {
              preview.changeArchMode("both")
              setactiveArch("both")
            } else {
              preview.changeArchMode("low")
              setactiveArch("low")
            }
          }}
        >
          <input alt="low" type="image" src={low}></input>
        </Box>
      </Grid>
      <Grid container sx={zoom}>
        <Box
          component={"span"}
          sx={{
            ...zoomin,
            "&:hover": {
              backgroundColor:
                (upperStl.data || lowerStl.data) && currentZoomValue > 0.25
                  ? "#4E6670"
                  : "#546E7A",
            },
            borderEndStartRadius: 4,
            borderStartStartRadius: 4,
            "&:active": {
              backgroundColor:
                (upperStl.data || lowerStl.data) && currentZoomValue > 0.25
                  ? "#455A64"
                  : "#546E7A",
            },
            opacity:
              (upperStl.data || lowerStl.data) && currentZoomValue > 0.25
                ? 1
                : 0.6,
          }}
          onClick={() => {
            if (upperStl.data || lowerStl.data) {
              if (currentZoomValue === 0.25) return
              setcurrentZoomValue(
                currentZoomValue - 0.25 < 0.25 ? 0.25 : currentZoomValue - 0.25,
              )
            }
          }}
        >
          -
        </Box>
        <Box
          component={"span"}
          sx={{
            ...zoomout,
            "&:hover": {
              backgroundColor:
                (upperStl.data || lowerStl.data) && currentZoomValue < 2
                  ? "#4E6670"
                  : "#546E7A",
            },
            borderEndEndRadius: 4,
            borderStartEndRadius: 4,
            "&:active": {
              backgroundColor:
                (upperStl.data || lowerStl.data) && currentZoomValue < 2
                  ? "#455A64"
                  : "#546E7A",
            },
            opacity:
              (upperStl.data || lowerStl.data) && currentZoomValue < 2
                ? 1
                : 0.6,
          }}
          onClick={() => {
            if (upperStl.data || lowerStl.data) {
              if (currentZoomValue === 2) return
              setcurrentZoomValue(
                currentZoomValue + 0.25 > 2 ? 2 : currentZoomValue + 0.25,
              )
            }
          }}
        >
          +
        </Box>
      </Grid>
    </Grid>
  )
  return (
    <Grid container sx={{ ...model, ...sx }}>
      {ToolBar}
      <WasmCanves style={canvas}></WasmCanves>
      <UText
        variant={"subtitle1"}
        sxProp={{
          position: "absolute",
          color: "rgba(255, 255, 255, 0.7)",
          top: 160,
        }}
      >
        {(upperStl.action === "init" || upperStl.action === "delete") &&
          (lowerStl.action === "init" || lowerStl.action === "delete") &&
          placeHolder}
      </UText>
    </Grid>
  )
}

export default Preview
