import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { UText } from "../../../components"
import { Box, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  removeAdditionalPhoto,
  setDownloadStatus,
} from "../../../core/app/slices/records/photograph/photographSlice"
import {
  downloadPhotographs,
  fetchFilesList,
} from "../../../core/app/slices/records/photograph/photographThunkApi"
import { deleteFiles } from "../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../core/app/store"

import CompositePhotgraph from "./CompositePhotgraph"
import Individualphotograph from "./IndividualphotoGraph"

const Photograph: FC<{
  setPhotographErrors: (value: boolean) => void
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  setIsDragOpen: (Value: boolean) => void
  isDragOpen: boolean
}> = ({
  setPhotographErrors,
  setIsDragEmpty,
  isAlertOpen,
  setIsAlertOpen,
  isDragOpen,
  setIsDragOpen,
}) => {
  const { t } = useTranslation("common")
  const [showUploadSection, setShowUploadSection] = useState<
    "composite" | "individual" | "both"
  >("both")
  const selectedFileSection = (
    section: "composite" | "individual" | "both",
  ) => {
    setShowUploadSection(section)
  }

  const { patientId, caseId } = useParams()

  const dispatch = useAppDispatch()
  const { fileList, compositePhoto, individualPhotos, additionalPhotos } =
    useAppSelector((state: RootState) => state.PhotosService)

  useEffect(() => {
    if (
      fileList.length &&
      !compositePhoto.length &&
      !individualPhotos.length &&
      !additionalPhotos.length
    ) {
      let countOthers = 0
      let countComposite = 0
      let countIndividual = 0

      fileList.forEach((file) => {
        if (
          file.file_name.includes("others") &&
          file.attachment_type === "photographs"
        ) {
          countOthers++
        } else if (file.file_name.includes("composite")) {
          countComposite++
        } else if (file.file_name.includes("individual")) {
          countIndividual++
        }
      })

      let total = 0
      let downloadTotal = 0
      countComposite > 0
        ? (total = countComposite + countOthers)
        : (total = countIndividual + countOthers)
      fileList.map((file) => {
        if (file.attachment_type === "photographs") {
          dispatch(setDownloadStatus("pending"))
          dispatch(
            downloadPhotographs({
              patientId: patientId,
              caseId: caseId,
              fileName: file.file_name,
              photographType: file.file_name.includes("composite")
                ? "composite"
                : file.file_name.includes("individual")
                ? "individual"
                : "others",
              original_file_name: file.original_file_name,
              sequence: file.sequence,
              position: file.photo_position,
            }),
          ).then((res) => {
            downloadTotal++
            if (downloadTotal === total) {
              dispatch(setDownloadStatus("succeeded"))
            }
          })
        }
      })
    }
  }, [fileList])

  useEffect(() => {
    if (showUploadSection === "individual") {
      additionalPhotos.forEach((file) => {
        dispatch(
          deleteFiles({
            patientId: patientId,
            caseId: caseId,
            fileName: file.fileName,
            fileType: "photographs",
            version: "v2",
          }),
        ).then((action) => {
          if (action.payload.data.status === "Success") {
            const filteredFilesArray = additionalPhotos.filter(
              (item) => item.fileName !== file.fileName,
            )
            const newArray = [...filteredFilesArray]
            dispatch(removeAdditionalPhoto({ newArray }))
          }
        })
      })
    }
  }, [showUploadSection])

  return (
    <>
      <UText
        sxProp={{
          display: "flex",
          justifyContent: "center",
          lineHeight: "133.4%",
          height: 40,
        }}
        color={"text.primary"}
        variant={"h5"}
      >
        {t("records.photograph.title")}
      </UText>
      <Grid
        container
        spacing={3}
        justifyContent={isDragOpen ? "unset" : "center"}
        ml={0}
        mt={!isDragOpen && "8px"}
        width={isDragOpen ? "1070px" : "872px"}
        minHeight={328}
        sx={{ flexWrap: "nowrap" }}
      >
        {(showUploadSection === "both" ||
          showUploadSection === "composite") && (
          <Grid
            sm={10}
            md={6}
            item
            justifyContent={"center"}
            sx={{
              padding: "10px !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "10px",
              maxWidth: "416px",
            }}
          >
            <CompositePhotgraph selectedFileSection={selectedFileSection} />
          </Grid>
        )}
        {showUploadSection === "both" && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ position: "relative", padding: 1 }}
          >
            <UText
              variant={"body1"}
              color={"text.primary"}
              sxProp={{
                mt: 3,
                position: "inherit",
                top: "-25px",
              }}
            >
              {"OR"}
            </UText>
          </Box>
        )}
        {(showUploadSection === "both" ||
          showUploadSection === "individual") && (
          <Grid
            item
            justifyContent={"center"}
            sx={{
              padding: isDragOpen ? "0!important" : "10px !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: !isDragOpen && "10px",
              maxWidth: isDragOpen ? "1070px" : "416px",
            }}
          >
            <Individualphotograph
              selectedFileSection={selectedFileSection}
              isDragOpen={isDragOpen}
              setIsDragOpen={setIsDragOpen}
              setIsDragEmpty={setIsDragEmpty}
              isAlertOpen={isAlertOpen}
              setIsAlertOpen={setIsAlertOpen}
              showUploadSection={showUploadSection}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Photograph
