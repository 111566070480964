import { FC, useEffect, useMemo, useState } from "react"
import { Close, Done, Error, Image } from "@mui/icons-material"

import { Box, Card, CardContent, Grid, IconButton } from "../../mui.components"
import { UText } from ".."

import BorderLinearProgress from "./UFIleProcessing.style"

export interface IUFileProcessingProps {
  /**
   * This params hold file name
   */
  fileName: string
  /**
   * This params hold file size in bytes
   */
  fileSize: number
  /**
   * This params value help to load progress value
   */
  progressValue?: number
  /**
   * This params value help to display file progress status example : loading | complete | failed
   */
  progressstatus: "loading" | "complete" | "failed" // Loading | Completed | Failed
  /**
   * This params hold the function for remove the file
   */
  onRemove: (data?: any) => void
  /**
   * we can pass file erorr message if any to display progress
   */
  filleErrorMessage?: string | null
}

const UFileProcessingCard: FC<IUFileProcessingProps> = ({
  fileName,
  fileSize,
  progressValue,
  progressstatus,
  onRemove,
  filleErrorMessage,
}) => {
  const [fileLoadingPercentage, setFileLoadingPercentage] = useState<number>(0)

  const fileStatus = {
    complete: "Complete",
    failed: "Failed",
    loading: "Loading",
  }
  useEffect(() => {
    setFileLoadingPercentage(progressValue)
  }, [progressValue])

  const formatBytes = (bytes: number, decimals = 0) => {
    if (!+bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  )
  const statusBasedIcon = useMemo(() => {
    if (progressstatus === "complete") {
      return (
        <Done
          fontSize={"large"}
          sx={{
            display: "flxe",
            alignSelf: "center",
            color: (theme) => theme.palette.success.main,
          }}
        />
      )
    }
    if (progressstatus === "failed") {
      return (
        <Error
          fontSize={"large"}
          sx={{
            display: "flxe",
            alignSelf: "center",
            color: (theme) => theme.palette.error.main,
          }}
        />
      )
    }
    return (
      <Image
        fontSize={"large"}
        sx={{
          display: "flxe",
          alignSelf: "center",
          color: (theme) => theme.palette.text.disabled,
        }}
      />
    )
  }, [progressstatus])

  return (
    <>
      <Card elevation={1}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={2}
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
            >
              {statusBasedIcon}
            </Grid>
            <Grid item xs={8}>
              <Box
                component={"div"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  alignItems: "start",
                  mb: "4px",
                }}
              >
                <UText
                  color={
                    progressstatus === "failed" ? "error.main" : "text.primary"
                  }
                  variant={"subtitle1"}
                  sxProp={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  {fileName}
                </UText>
                <UText
                  variant={"body2"}
                  sxProp={{ mt: 0 }}
                  color={
                    progressstatus === "failed"
                      ? "error.main"
                      : "text.secondary"
                  }
                >
                  {progressstatus === "failed"
                    ? filleErrorMessage
                    : formatBytes(fileSize)}
                  {bull}
                  {fileStatus[progressstatus]}
                </UText>
              </Box>
              {progressValue === -1 ||
                (progressstatus !== "failed" && (
                  <Box component={"div"} sx={{ width: "100%" }}>
                    <BorderLinearProgress
                      variant={"determinate"}
                      value={fileLoadingPercentage}
                    />
                  </Box>
                ))}
            </Grid>
            <Grid
              item
              xs={2}
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
            >
              <IconButton
                aria-label={"close"}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
                onClick={() => onRemove()}
              >
                <Close
                  fontSize={"medium"}
                  sx={{
                    display: "flxe",
                    alignSelf: "center",
                    color: "rgba(0, 0, 0, 0.56)",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

UFileProcessingCard.defaultProps = {
  filleErrorMessage: null,
  progressValue: -1,
}

export default UFileProcessingCard
