import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import { doctorList } from "./doctor.service"

export const fetchDoctorsList = createAsyncThunk(
  "doctorService/fetchDoctorsList",
  async () => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return doctorList(orgId)
    } catch (err) {
      return err
    }
  },
)
