import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Checkbox, Modal, styled } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import {
  fetchUserPreferences,
  updateUserPreferences,
} from "../../../../core/app/slices/user/userApis"
import { UText } from "../../../../ui-component"

const Boarding: FC<{
  step: number
  setstep: (arg: number) => void
  open: boolean
  setOpen: (arg: boolean) => void
}> = ({ step, setstep, open, setOpen }) => {
  const { preferences } = useAppSelector((state) => state.userService.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUserPreferences())
  }, [])

  useEffect(() => {
    if (preferences?.onboarding_no_shows) {
      setOpen(false)
    }
  }, [preferences])

  const mockCaseStatus = "done"
  const { t } = useTranslation("common")
  const StyledModal = styled(Modal)`
    position: absolute;
    z-index: 1300;
    inset: 0;
  `

  const style = () => ({
    borderRadius: "4px",
    backgroundColor: "#0288d1",
    width: 275,
    height: 241,
    position: "absolute",
    left: step === 0 && 56,
    right: (step === 1 || step === 2) && 56,
    top: 120,
    color: "#FFF",
    border: "none !important",
    display: "flex",
    flexDirection: "column",
    fontWeight: 400,
  })

  const stepConetent = [
    {
      title: "uAssist",
      content:
        "Review uAssist comments and submit modification request as needed.",
    },
    {
      title: "Setup",
      content: "Use tools on properties panel to fine tune your plan.",
    },
    {
      title: "Ordering",
      content: "Please order once you are satisfied with the plan.",
    },
  ]

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
    >
      <Box sx={style}>
        <Box
          id="boarding-header"
          sx={{
            height: 64,
            width: "100%",
            padding: 2,
            fontSize: 24,
            fontWeight: 400,
          }}
        >
          {stepConetent[step].title}
        </Box>
        <Box
          id="boarding-content"
          sx={{ height: 0, flex: "1 1 auto", padding: 2, fontSize: 14 }}
        >
          {stepConetent[step].content}
        </Box>
        <Box
          id="boarding-step"
          sx={{
            height: 46,
            padding: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UText
            variant={"caption"}
            sxProp={{
              padding: "0 16px",
              fontSize: 14,
              display: "block",
              textAlign: "center",
              lineHeight: 2.5,
            }}
          >
            {step + 1}/{mockCaseStatus === "done" ? "3" : "1"}
          </UText>
          <Box id="boarding-btn" sx={{ display: "flex" }}>
            {step < (mockCaseStatus === "done" ? 2 : 0) ? (
              <>
                <Button
                  onClick={() => {
                    setOpen(false)
                  }}
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid rgba(144, 202, 249, 0.50)",
                    color: (theme) => theme.palette.primary.main,
                    height: 30,
                    padding: "0px 24px",
                    backgroundColor: "initial",
                    cursor: "pointer",
                    marginRight: 1,
                  }}
                >
                  {t("button.dismiss")}
                </Button>
                <Button
                  onClick={() => {
                    setstep(step + 1)
                  }}
                  sx={{
                    background: (theme) => theme.palette.primary.main,
                    boxShadow:
                      "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                    height: 30,
                    width: 54,
                    border: "none",
                    cursor: "pointer",
                    padding: "0 10px",
                  }}
                >
                  <UText
                    variant="caption"
                    sxProp={{
                      color: (theme) => theme.palette.primary.contrastText,
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                  >
                    {t("button.next")}
                  </UText>
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  setOpen(false)
                }}
                sx={{
                  background: (theme) => theme.palette.primary.main,
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                  color: (theme) => theme.palette.primary.contrastText,
                  fontSize: 13,
                  fontWeight: 500,
                  height: 30,
                  width: 54,
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {t("button.done")}
              </Button>
            )}
          </Box>
        </Box>
        <Box
          id="boarding-foot"
          sx={{
            height: 38,
            padding: "0 8px",
            display: "flex",
            alignItems: "center",
            fontSize: 10,
            fontWeight: 500,
          }}
        >
          <Checkbox
            sx={{ height: 20, width: 20 }}
            onChange={({ target: { checked } }) => {
              if (checked) {
                dispatch(
                  updateUserPreferences({
                    onboarding_no_shows: true,
                  }),
                )
              } else {
                dispatch(
                  updateUserPreferences({
                    onboarding_no_shows: false,
                  }),
                )
              }
            }}
          />
          <UText variant={"caption"} sxProp={{ fontSize: 10, marginLeft: 1 }}>
            {t("popup.showAgain1")}
          </UText>
        </Box>
      </Box>
    </StyledModal>
  )
}

export default Boarding
