import {
  Downloader,
  DownloaderEventType,
} from "../../modules/resourcemanager/downloader";
import { Event } from "../../../utils/event";
import JSZip from "jszip";

/**
 * events
 */
export enum ResourceManagerEventType {
  /**
   * args: none
   */
  DOWNLOADLOADCASE_START_EVENT = "LoadCaseStartEvent",

  /**
   * args: load percent
   */
  FETCH_DATA_EVENT = "FetchDataEvent",
  /**
   * args: ture or false
   */
  DOWNLOADCASE_FINISHED_EVENT = "LoadCaseFinishedEvent",
}

export function blobToFile(blot, fileName): File {
  const isFile = blot instanceof File;
  const blob = blot;
  if (!isFile) {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
  }

  return blob;
}

class ResourceManager {
  resDownloader = new Downloader();
  staticResDownloader = new Downloader();
  isHasDownloadStaticResource = false;
  events = new Event();
  percent = 0;
  downloadListLength = 0;

  initModule(): void {
    this.percent = 0;
    this.downloadListLength = 0;
  }
  getResourceStatic() {
    return this.staticResDownloader.outFileList;
  }

  getCaseResource() {
    return this.resDownloader.outFileList;
  }

  /**
   * download static resource in staticResDownloader
   * the download process is asynchronous
   * return a promise resolve when download done.
   */
  async downloadStaticResource() {
    console.log("[download static resource]");
    const fetchDataHandle = () => {
      this.updateLoadPercent();
    };

    this.initModule();
    /**
     * download static resource
     */
    const localResNames = ["resource"];
    const localBaseUrl = `/assets/`;
    this.staticResDownloader.clear();
    for (const resName of localResNames) {
      this.staticResDownloader.registerDownloadFiles({
        path: `${localBaseUrl + resName}.zip`,
        name: resName,
        reqType: "zip",
        isStatic: true,
      });
    }
    const promise = new Promise((resolve, reject) => {
      console.log("function: 0");
      this.events.onFireOnce(
        ResourceManagerEventType.DOWNLOADCASE_FINISHED_EVENT,
        async () => {
          console.log("function: 011");
          resolve(true);
        }
      );
    });

    this.downloadListLength += this.staticResDownloader.loadFileList.length;
    this.staticResDownloader.events.on(
      DownloaderEventType.FETCH_DATA_EVENT,
      fetchDataHandle
    );
    this.events.fire(ResourceManagerEventType.DOWNLOADLOADCASE_START_EVENT);
    await this.staticResDownloader.downloadFiles("");
    this.isHasDownloadStaticResource = true;

    return promise;
  }

  /**
   * download both static resource and relate to case resource
   * @param uuid
   */
  async downloadResource(uuid: string) {
    console.log("[downloadResource]");
    const fetchDataHandle = () => {
      this.updateLoadPercent();
    };

    this.initModule();

    // /**
    //  * download static resource once
    //  */
    // if (!this.isHasDownloadStaticResource) {
    //   const localResNames = ['resource'];
    //   const localBaseUrl = `/`;
    //   for (const resName of localResNames) {
    //     console.log('resName: ', resName);

    //     this.staticResDownloader.registerDownloadFiles({
    //       path: `${localBaseUrl + resName}.zip`,
    //       name: resName,
    //       reqType: 'zip',
    //       isStatic: true,
    //     });
    //   }
    //   this.downloadListLength += this.staticResDownloader.loadFileList.length;
    //   this.staticResDownloader.events.on(DownloaderEventType.FETCH_DATA_EVENT, fetchDataHandle);
    // }

    this.resDownloader.clear();
    /**
     * download resources related with case
     */
    const resNames = ["bulk0", "bulk1", "bulk2", "bulk10", "raw", "photo"];
    const baseUrl = `/case/api/case/${uuid}/zip?fileName=`;
    for (const resName of resNames) {
      this.resDownloader.registerDownloadFiles({
        path: baseUrl + encodeURIComponent(`${resName}.zip`),
        name: resName,
        reqType: "zip",
      });
    }

    this.downloadListLength += this.resDownloader.loadFileList.length;
    this.resDownloader.events.on(
      DownloaderEventType.FETCH_DATA_EVENT,
      fetchDataHandle
    );

    this.events.fire(ResourceManagerEventType.DOWNLOADLOADCASE_START_EVENT);
    // if (!this.isHasDownloadStaticResource) {
    //   console.log('download static....');
    //   await this.staticResDownloader.downloadFiles(uuid);
    //   this.isHasDownloadStaticResource = true;
    // }
    console.log("download resource....");
    await this.resDownloader.downloadFiles(uuid);

    const promise = new Promise((resolve, reject) => {
      this.events.onFireOnce(
        ResourceManagerEventType.DOWNLOADCASE_FINISHED_EVENT,
        async () => {
          resolve(true);
        }
      );
    });

    return promise;
  }

  updateLoadPercent() {
    const loadCount = this.downloadListLength;
    this.percent += 100 / loadCount;
    console.log("percent:::::", this.percent, loadCount);
    if (this.percent >= 99.9) {
      console.log("下载数据结束", this.percent);
      this.events.fire(
        ResourceManagerEventType.DOWNLOADCASE_FINISHED_EVENT,
        true
      );
    }
    this.events.fire(ResourceManagerEventType.FETCH_DATA_EVENT, {
      percent: this.percent,
    });
  }

  /**
   * uncompress zip files to a list
   */
  parseZipFilesToList() {
    const resStatic = this.getResourceStatic();
    const caseRes = this.getCaseResource();
    return this.parseResourceZipFilesToList(resStatic, caseRes);
  }

  /**
   * Create a file directory from all files downloaded , include static resource and case resource.
   * @param resStatic
   * @param caseRes
   * @returns
   */
  async parseResourceZipFilesToList(
    resStatic: Map<string, Blob>,
    caseRes: Map<string, Blob>
  ) {
    const fileListStatic = await this.turnBlobMapIntoFileDirectory(resStatic);
    const fileListOfCase = await this.turnBlobMapIntoFileDirectory(caseRes);
    const outPut: Record<string, Record<string, any>> = {};
    Object.assign(outPut, fileListStatic, fileListOfCase);
    return outPut;
  }

  /**
   * Create a file directory from files in Map, it's used to make file dir in WASM .
   */
  async turnBlobMapIntoFileDirectory(fileBlobMap: Map<string, Blob>) {
    const outFiles: Record<string, Record<string, any>> = {};
    for (const key of fileBlobMap.keys()) {
      const fileList: Record<string, any> = {};
      const res = fileBlobMap.get(key);
      await this.getFilesFromZip(blobToFile(res, key), fileList);
      outFiles[key] = fileList;
    }
    return outFiles;
  }

  /**
   * find zip files from zip package
   */
  async checkZipFilesFromZipPackage(zipFile: File) {
    const filelist: Record<string, any> = {};
    await this.getFilesFromZip(zipFile, filelist, /.*/);
    const keys = Object.keys(filelist);
    const findkeyFromKeys = str => {
      const key = keys.find(item => {
        const i = item.toLowerCase().indexOf(str);
        return i !== -1;
      });
      return key;
    };
    const fileDir = {
      bulk0: "Setting Data0",
      bulk1: "Setting Data1",
      bulk2: "Setting Data2",
      bulk10: "Setting Data10",
      raw: "Raw",
      photo: "Photo",
    };
    const outfiles: Record<string, Record<string, any>> = {};
    for (const key of Object.keys(fileDir)) {
      const fileList2: Record<string, any> = {};
      const regVal = fileDir[key];
      const k = findkeyFromKeys(`${key}.zip`);
      if (k && filelist[k]) {
        await this.getFilesFromZip(
          filelist[k],
          fileList2,
          new RegExp(regVal ? `(${regVal}/)` : "")
        );
        outfiles[key] = fileList2;
      }
    }

    console.log("loadZips::", outfiles);
    return outfiles;
  }

  /**
   * get files information from case zip file
   * support the dir structure include bulk0.zip - bulk10.zip
   * or  include settingdata[x] directory .
   * @param zipFile
   * @returns
   */
  async parseZipFilesFromAZipPacket(zipFile: File) {
    let outfiles: Record<string, Record<string, any>> = {};
    // if has zip filelist then do this way first
    outfiles = await this.checkZipFilesFromZipPackage(zipFile);
    if (Object.keys(outfiles).length === 6) {
      return outfiles;
    }
    // else read the file directly
    outfiles = {};

    const fileDir = {
      bulk0: "Setting Data0",
      bulk1: "Setting Data1",
      bulk2: "Setting Data2",
      bulk10: "Setting Data10",
      raw: "Raw",
      photo: "Photo",
    };

    for (const key of Object.keys(fileDir)) {
      const regVal = fileDir[key];
      const fileList: Record<string, any> = {};
      await this.getFilesFromZip(
        zipFile,
        fileList,
        new RegExp(regVal ? `(${regVal}/)` : "")
      );
      outfiles[key] = fileList;
    }

    return outfiles;
  }

  /**
   * get file information list from a Zip File
   * @param zipfile
   * @param outFiles
   * @param path
   * @returns
   */
  private getFilesFromZip(
    zipfile: File,
    outFiles: Record<string, any>,
    path?: RegExp
  ): Promise<File[]> {
    return JSZip.loadAsync(zipfile).then(async (zip: any) => {
      return Promise.all(this.loadFiles(zip, outFiles, path));
      // return Promise.all(this.loadfilesFromZip(zip));
    });
  }

  private loadFiles(
    zip,
    out: Record<string, any>,
    path?: RegExp
  ): Promise<any>[] {
    const promises: any[] = [];
    path = path || new RegExp("");
    const files = path ? zip.file(path) : zip;
    // console.log('>>>@@@', path, zip, files);
    files.forEach(fileProto => {
      if (!fileProto.dir) {
        promises.push(
          zip
            .file(fileProto.name)
            .async("blob")
            .then(blob => {
              if (fileProto.name === "undefined") {
                return;
              }
              fileProto.name.sub();

              const startIndex = fileProto.name.search(path);
              // console.log('sssssss::::', startIndex);

              // const pathStrs = fileProto.name.split('/');
              // exclude the files in sub dir.
              // if (pathStrs.length > 2) {
              //   // console.log('exclude files:', fileProto.name);
              //    return;
              // }
              // console.log('parse name:', fileProto.name);
              //  const fileName = pathStrs[pathStrs.length - 1];
              let fileName = fileProto.name;
              if (startIndex > 0) {
                fileName = (fileProto.name as string).substring(startIndex);
              }
              const fileblob = new File([blob], fileName);
              out[fileName] = fileblob;
              return fileblob;
            })
        );
      }
    });
    return promises;
  }
}

export const resourceManager = new ResourceManager();
