import { wasmModule } from "../wasm/wasmModule";
import { GlobalEvents, globalEvents } from "../../../utils/globalevents";

export interface IIPRData {
  stage: number;
  value: any;
}

export type ReportIPRData = Record<number, IIPRData[]>;

class IprModule {
  private isOpen = false;
  private stageIndexForShow = 0;

  setIPRVisibility(isVisible, stageIndexForShow: number) {
    if (!wasmModule.isInit) return;
    this.isOpen = isVisible;
    this.stageIndexForShow = stageIndexForShow;
    wasmModule.statusController.DisplayArchIPR(
      wasmModule.statusController.GetCurrentDiplayArch(),
      this.isOpen,
      this.stageIndexForShow
    );
  }
  updateIPRVisibility() {
    if (this.isOpen)
      wasmModule.statusController.DisplayArchIPR(
        wasmModule.statusController.GetCurrentDiplayArch(),
        this.isOpen,
        this.stageIndexForShow
      );
  }
  getIPRReport(): ReportIPRData {
    const iprReport: ReportIPRData = {};

    const newIpr = wasmModule.statusController.GetStagesIPRReport();

    for (let i = 0; i < newIpr.size(); i++) {
      const iprs = newIpr.get(i);
      const toothid = iprs.toothId;
      const iprData: IIPRData[] = [];

      for (let j = 0; j < iprs.valVec.size(); j++) {
        const ipr = iprs.valVec.get(j);
        const stage = ipr.stepNum;
        const value = ipr.value;

        if (!value || Number.parseFloat(value) == 0) continue;

        iprData.push({
          stage,
          value,
        });
        // console.log("ysss🚀 ~ file: iprModule.ts:44 ~ ipr:", ipr);
      }

      iprReport[toothid] = iprData;
    }

    console.log("ysss🚀 ~ file: iprModule.ts:56 ~ iprReport:", iprReport);

    // const iprup = wasmModule.statusController.GetIPRReport(0);
    // // const toothupIds = wasmModule.mouthModel.GetArchModel(wasmModule.module.ArchType.UpArch).GetToothIds();
    // const size = iprup.size();
    // for (let i = 0; i < size; i++) {
    //   const iprData = iprup.get(i);
    //   const toothipr = iprData.value;
    //   const toothid1 = iprData.toothId1;
    //   const toothid2 = iprData.toothId2;
    //   const toothid = toothid1 < toothid2 ? toothid1 : toothid2;
    //   if (toothipr && toothipr !== 0) {
    //     const ipr = {
    //       stage: 0,
    //       total: toothipr,
    //     };
    //     iprReport[toothid] = ipr;
    //   }
    // }
    // const iprlow = wasmModule.statusController.GetIPRReport(1);
    // // const toothlowIds = wasmModule.mouthModel.GetArchModel(wasmModule.module.ArchType.DownArch).GetToothIds();
    // const sizelow = iprlow.size();
    // for (let i = 0; i < sizelow; i++) {
    //   const iprData = iprlow.get(i);
    //   const toothipr = iprData.value;
    //   const toothid1 = iprData.toothId1;
    //   const toothid2 = iprData.toothId2;
    //   const toothid = toothid1 < toothid2 ? toothid1 : toothid2;
    //   if (toothipr !== 0) {
    //     const ipr = {
    //       stage: 0,
    //       total: toothipr,
    //     };
    //     iprReport[toothid] = ipr;
    //   }
    // }
    return iprReport;
  }
}

export const iprModule = new IprModule();

globalEvents.on(GlobalEvents.VIEW_SWITCH_TYPE, (type: string) => {
  console.log("updateIPRVisibility......", type);
  iprModule.updateIPRVisibility();
});
