import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import waiting from "../../../../assets/images/clinical/waiting.svg"

import "./transition.scss"
const Transition: FC = () => {
  const { t } = useTranslation("common")
  return (
    <Box className="transitionBox">
      <p className={"tranTitle"}>{t("treat.openPlanTitle")}</p>
      <img className={"waitingPng"} src={waiting} />
      <div className={"loadingBar"}>
        <div className={"loadingThum"}></div>
      </div>
      <p className={"waitText"}>{t("treat.openPlanWaitText")}</p>
    </Box>
  )
}
export default Transition
