import * as React from "react"

import { useEffect, useRef, useState } from "react"

import { Stack, Grid, Typography, Divider } from "@mui/material"

import { FlowButton, FlowBtnGroup } from "../../Custom/FlowCustom"

import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

import { wasmModule } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"
import { attachmentModule } from "@ulab/wesmileclinical"
import { toothMovement } from "@ulab/wesmileclinical"
import { setNewState, setIsShow } from "../../CommonPages/ToothInfoSlice"

const QuickTreatment = (props) => {
  const dispatch = useAppDispatch()
  const onClickStagingBtn = (cmd) => {
    console.log("Auto staging button " + cmd + " is clicked.")
    if (!wasmModule) {
      console.log("wasmModule null ptr")
      return
    }
    for (let i = 0; i < 2; i++) {
      if (cmd == "AutoAll") wasmModule.stagingcontoler.AutoAllStage(i)
      else if (cmd == "RemoveAll")
        wasmModule.stagingcontoler.RemoveAutoAllStage(i)
      else if (cmd == "VelWeekly") wasmModule.stagingcontoler.VelocityWeekly(i)
      else if (cmd == "VelBiWeekly")
        wasmModule.stagingcontoler.VelocityBiWeekly(i)
      else if (cmd == "SyncVelRestore")
        wasmModule.stagingcontoler.VelocityRestore()
      else if (cmd == "SyncVelSync") wasmModule.stagingcontoler.VelocitySync()
      else if (cmd == "SyncVelPassive")
        wasmModule.stagingcontoler.VelocityPassiveAligner()
    }
    stagingManager.updateStages()
    dispatch({ type: "autostaging/updateState" })
  }
  const autoStagingBtns = [
    { label: "Add all", name: "AutoAll" },
    { label: "Remove", name: "RemoveAll" },
  ]
  const wearTimeBtns = [
    { label: "Weekly", name: "VelWeekly" },
    { label: "Bi-weekly", name: "VelBiWeekly" },
  ]

  const syncULBtns = [
    { label: "None", name: "SyncVelRestore" },
    { label: "Sync Vel", name: "SyncVelSync" },
    { label: "Passive", name: "SyncVelPassive" },
  ]

  const [manualAtt, setManualAtt] = useState(false)

  const onClickAttBtn = (cmd) => {
    console.log("Auto Att button " + cmd + " is clicked.")
    if (!wasmModule) {
      console.log("wasmModule null ptr")
      return
    }
    attachmentModule.openAttachmentModule(true)
    if (cmd == "AttAutoAll") attachmentModule.addAllAttachment()
    else if (cmd == "AttRemoveAll") attachmentModule.deleteAllAttachment(0)
    attachmentModule.openAttachmentModule(false)
  }

  const attBtns = [
    { label: "Add all", name: "AttAutoAll" },
    { label: "Remove", name: "AttRemoveAll" },
  ]
  const manualAttBtns = [
    { label: "Start", name: "AttAutoAll" },
    { label: "End", name: "AttRemoveAll" },
  ]

  useEffect(() => {
    console.log("Start QuickTreatment")
    //  props.dispatch({ type: "autosetup/updateState" })
    //######## This will disable "tooth click" event. How to re-enable it?
    attachmentModule.openAttachmentModule(true)
    const resetFunc = (e) => {
      console.log("Listen resetbuttonevent")
      // setPressurePointName(null)
      // setAttachmentName(null)
    }
    // document.addEventListener("resetbuttonevent", resetFunc)
    return () => {
      console.log("End QuickTreatment")
      attachmentModule.openAttachmentModule(false)
      // window.removeEventListener("resetbuttonevent", resetFunc)
    }
  }, [])

  return (
    <Stack direction="column" spacing={1}>
      <Typography>Staging</Typography>
      <FlowBtnGroup
        lineLabel="Auto"
        btnGroup={autoStagingBtns}
        onButtonClick={onClickStagingBtn}
        btnW={3}
      ></FlowBtnGroup>
      <Divider />
      <Typography>Veloctiy</Typography>
      <FlowBtnGroup
        lineLabel="Wearing Time"
        btnGroup={wearTimeBtns}
        onButtonClick={onClickStagingBtn}
        btnW={3}
      />
      <FlowBtnGroup
        lineLabel="Sync. U/L"
        btnGroup={syncULBtns}
        onButtonClick={onClickStagingBtn}
        btnW={3}
      />
      <Divider />
      <Typography>Attachment</Typography>
      <FlowBtnGroup
        lineLabel="Auto"
        btnGroup={attBtns}
        onButtonClick={onClickAttBtn}
        btnW={3}
      ></FlowBtnGroup>
      <FlowBtnGroup
        lineLabel="Manual"
        btnGroup={manualAttBtns}
        onButtonClick={onClickAttBtn}
        btnW={3}
      ></FlowBtnGroup>
    </Stack>
  )
}

export default QuickTreatment
