import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { caseManagement } from "@ulab/wesmileclinical"
import { gdsPlusSetup } from "@ulab/wesmileclinical"

const initState = {
  archform: {
    ArchformAnt: {
      title: "Anterior",
      variable: "ArchformAnt",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    ArchformMid: {
      title: "Middle",
      variable: "ArchformMid",
      sliderValue: 0,
      unit: "%",
      lableValue: 0,
    },
    ArchformPost: {
      title: "Posterior",
      variable: "ArchformPost",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
  },
  archShift: {
    JawMoveLR: {
      title: "Left/Right",
      variable: "JawMoveLR",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    JawMoveMD: {
      title: "A/P",
      variable: "JawMoveMD",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    JawMoveUD: {
      title: "Up/Down",
      variable: "JawMoveUD",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
  },
  anterior: {
    OJ: {
      title: "Overjet",
      variable: "OJ",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    OB: {
      title: "Overbite",
      variable: "OB",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    LL: {
      title: "Spee Curve",
      variable: "LL",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    MidlineUpper: {
      title: "U Midline",
      variable: "MidlineUpper",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    MidlineLower: {
      title: "L Midline",
      variable: "MidlineLower",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
  },
  rootTorque: {
    AntInclUpper: {
      title: "U RootT.",
      variable: "AntInclUpper",
      sliderValue: 0,
      unit: "\xB0",
      lableValue: 0,
    },
    AntInclLower: {
      title: "L RootT.",
      variable: "AntInclLower",
      sliderValue: 0,
      unit: "\xB0",
      lableValue: 0,
    },
  },
  globalMove: {
    TMJMoveLimit: {
      title: "TMJ Limit",
      variable: "TMJMoveLimit",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
  },
  toothIPRSpace: {
    IPRToPrev: {
      title: "IPR",
      variable: "IPRToPrev",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    SpaceToPrev: {
      title: "Space",
      variable: "SpaceToPrev",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    IPRToNext: {
      title: "IPR",
      variable: "IPRToNext",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
    SpaceToNext: {
      title: "Space",
      variable: "SpaceToNext",
      sliderValue: 0,
      unit: "mm",
      lableValue: 0,
    },
  },
  selectedTooth: {
    ID: 0,
    disabled: true,
    isFixed: false,
    isFixedIprSpacePrev: false,
    isFixedIprSpaceNext: false,
  },
  TMJMoveDist: {
    leftDist: 0,
    rightDist: 0,
  },
  uiWeFlow: true,
  disabled: true,
}

export const autoSetupSlice = createSlice({
  name: "autosetup",
  initialState: initState,
  reducers: {
    setSelToothFixed(preState, action) {
      const { fixed } = action.payload
      const { selectedTooth } = preState
      selectedTooth.isFixed = fixed
    },
    setSelIprSpaceFixed(preState, action) {
      const { fixed, isToNext } = action.payload
      const { selectedTooth } = preState
      if (isToNext) selectedTooth.isFixedIprSpaceNext = fixed
      else selectedTooth.isFixedIprSpacePrev = fixed
    },
    updateSelTooth(state, action: PayloadAction<number>) {
      if (state.disabled) return
      const { selectedTooth } = state
      const id = action.payload
      state.selectedTooth.ID = id
      selectedTooth.isFixed = gdsPlusSetup
        .getAutoSetupModule()
        .GetToothFixed(id)
      selectedTooth.isFixedIprSpacePrev = gdsPlusSetup.GetToothIPREnable(false)
      selectedTooth.isFixedIprSpaceNext = gdsPlusSetup.GetToothIPREnable(true)
    },
    setDisable(preState, action) {
      preState.disabled = action.payload
    },
    updateState(state, action) {
      const {
        archform,
        archShift,
        anterior,
        rootTorque,
        toothIPRSpace,
        selectedTooth,
        globalMove,
      } = state

      Object.keys(archform).forEach((key) => {
        const values = gdsPlusSetup.GetParamDouble(key)
        archform[key].sliderValue = values.sliderValue
        archform[key].lableValue = values.lableValue
      })

      Object.keys(archShift).forEach((key) => {
        const values = gdsPlusSetup.GetParamDouble(key)
        archShift[key].sliderValue = values.sliderValue
        archShift[key].lableValue = values.lableValue
      })

      Object.keys(anterior).forEach((key) => {
        const values = gdsPlusSetup.GetParamDouble(key)
        anterior[key].sliderValue = values.sliderValue
        anterior[key].lableValue = values.lableValue
      })

      Object.keys(rootTorque).forEach((key) => {
        const values = gdsPlusSetup.GetParamDouble(key)
        rootTorque[key].sliderValue = values.sliderValue
        rootTorque[key].lableValue = values.lableValue
      })

      Object.keys(toothIPRSpace).forEach((key) => {
        const values = gdsPlusSetup.GetParamDouble(key)
        toothIPRSpace[key].sliderValue = values.sliderValue
        toothIPRSpace[key].lableValue = values.lableValue
      })

      Object.keys(globalMove).forEach((key) => {
        const values = gdsPlusSetup.GetParamDouble(key)
        globalMove[key].sliderValue = values.sliderValue
        globalMove[key].lableValue = values.lableValue
      })
      state.TMJMoveDist.leftDist = gdsPlusSetup.GetTMJMoveDist(true)
      state.TMJMoveDist.rightDist = gdsPlusSetup.GetTMJMoveDist(false)
      selectedTooth.isFixedIprSpacePrev = gdsPlusSetup.GetToothIPREnable(false)
      selectedTooth.isFixedIprSpaceNext = gdsPlusSetup.GetToothIPREnable(true)
    },
    setArchform(preState, action) {
      const { variable, value } = action.payload
      const { archform } = preState
      archform[variable].sliderValue = value as number
    },
    setArchShift(preState, action) {
      const { variable, value } = action.payload
      const { archShift } = preState
      archShift[variable].sliderValue = value as number
    },
    setAnterior(preState, action) {
      const { variable, value } = action.payload
      const { anterior } = preState
      anterior[variable].sliderValue = value as number
    },
    setRootTorque(preState, action) {
      const { variable, value } = action.payload
      const { rootTorque } = preState
      rootTorque[variable].sliderValue = value
    },
    setToothIPRSpace(preState, action) {
      const { variable, value } = action.payload
      const { toothIPRSpace } = preState
      toothIPRSpace[variable].sliderValue = value
    },
    setGlobalMove(preState, action) {
      const { variable, value } = action.payload
      const { globalMove } = preState
      globalMove[variable].sliderValue = value
    },
    setUIWeFlow(preState, action) {
      preState.uiWeFlow = action.payload.value
    },
  },
})
