import { isUpTooth } from "../../common/toothtool";
import { report } from "../report/report";
import { wasmModule } from "../wasm/wasmModule";
import * as popupHelper from "../../common/popuphelper";
import { Mi } from "../../../thirdparts/index";
import { resourcesSynchronization } from "../resourcemanager/resourcessynchronization";

const resetbuttonevent = new CustomEvent("resetbuttonevent", {});

export const NormalAttIDs = [
  0, 1, 2, 3, 8, 9, 10, 21, 11, 12, 13, 14, 15, 16, 17, 18, 22,
];
export const PressurePointIDs = [7, 19, 20];
export const ButtonAndSlitsIDs = [6, 5, 4, 23];

/**
 * data of all stage
 */
export interface IAttachmentIPRVisible {
  isHasIPRData: boolean;
  isHasAttachmentData: boolean;
}

export interface IAuxullartiesShowInfo {
  /**
   * aux type
   */
  type: "Normal" | "PressurePoint" | "ButtonAndSlits";
  /**
   * [auxId,the dom element where we draw the aux]
   */
  elements: [string, HTMLElement][];
}

class AttachmentModule {
  attachmentPanelData: {
    data;
    isAttachmentPanelShow;
    deletePos;
  };

  wasmAttachmentModule;
  attachmentiprVisibility?: (stageData: IAttachmentIPRVisible) => void;
  constructor() {
    this.attachmentPanelData = {
      data: [0, 0, 0, 0, 0, 0], // 六排数据
      isAttachmentPanelShow: false, // attachment panel开关
      deletePos: [0, 0], // 删除按钮位置
    };
  }

  /**
   * 打开/关闭 attachment module
   * @param isOpen
   * @returns
   */
  openAttachmentModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;

    wasmModule.moduleManager.SwitchAttachmentModule(isOpen);

    //  if (!this.wasmAttachmentModule) {
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule();
    //   }
    console.log("wasmAttachmentModule :", this.wasmAttachmentModule);
    this.setAttachmentSelectAbleType("AttachmentAndTeeth");

    if (!isOpen) {
      this.attachmentPanelData.isAttachmentPanelShow = false;

      this.dispatchDataToUI(this.attachmentPanelData);
    }
  }

  /**
   * 设置鼠标可以选中的模型类型
   * @param type `NormalAttachment`：鼠标只能选中牙齿上的附件，在打开右侧aux面板时设置
   * `AttachmentAndTeeth `：鼠标可以选中牙齿或者牙齿上的附件
   * @returns
   */
  setAttachmentSelectAbleType(type: "NormalAttachment" | "AttachmentAndTeeth") {
    if (!wasmModule.isInit) return;
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule();
    if (!this.wasmAttachmentModule) return;

    switch (type) {
      case "NormalAttachment":
        this.wasmAttachmentModule.SetAttachmentSelectAbleType(
          wasmModule.module.AttachmentSelectAbleType.NormalAttachment
        );
        break;
      case "AttachmentAndTeeth":
        this.wasmAttachmentModule.SetAttachmentSelectAbleType(
          wasmModule.module.AttachmentSelectAbleType.AttachmentAndTeeth
        );
        break;
      default:
        break;
    }
  }

  /**
   * 在AUX UI面板中选中相应附件后调用
   * @param attId 附件ID
   * @returns
   */
  setAttachmentSelectLibID(attId: number) {
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;

    this.wasmAttachmentModule.SelectLibAttachment(attId);
  }

  addAllAttachment() {
    console.log("AddAllAttachment");
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    this.wasmAttachmentModule.AddAllAttachment(0);
    this.wasmAttachmentModule.AddAllAttachment(1);
  }

  addAllBiteRamp() {
    console.log("AddAllBiteRamp");
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    this.wasmAttachmentModule.AddAllBiteRamp();
  }

  deleteSelectedAttachment() {
    this.wasmAttachmentModule.DeleteAttachment();
    this.attachmentPanelData.isAttachmentPanelShow = false;
    this.dispatchDataToUI(this.attachmentPanelData);
    // popupHelper.alertPopup("warning", "Are your sure?", [
    //   {
    //     name: "ok",
    //     func: () => {
    //       this.wasmAttachmentModule.DeleteAttachment();

    //       this.attachmentPanelData.isAttachmentPanelShow = false;
    //       this.dispatchDataToUI(this.attachmentPanelData);
    //     },
    //   },
    //   {
    //     name: "cancel",
    //     func: () => {},
    //   },
    // ]);
  }

  deleteAllAttachment(attType) {
    console.log("DeleteAllAttachment");
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    this.wasmAttachmentModule.CleanAllAttachment(attType);
  }

  onSelectedAttachment() {
    const toothId = this.wasmAttachmentModule.Getm_CurSelectedAttachToothID();
    console.log("onSelectAttachment :", toothId);

    let distanceXYZ;

    // if (isUpTooth(toothId))
    //   distanceXYZ = report.UpReportArrays.get(wasmModule.stagingcontoler.GetCurrentStageIndex(0))[
    //     toothId
    //   ].distanceRotate;
    // else
    //   distanceXYZ = report.LowReportArrays.get(wasmModule.stagingcontoler.GetCurrentStageIndex(1))[
    //     toothId
    //   ].distanceRotate;

    // this.attachmentPanelData.data = distanceXYZ;
    // this.attachmentPanelData.isAttachmentPanelShow = true;
    // this.dispatchDataToUI(this.attachmentPanelData);
  }

  async dispatchDataToUI(attachmentPanelData) {
    // await getDvaApp()._store.dispatch({
    //   type: 'patients/setStageData',
    //   payload: {
    //     vtkmodel: { attachmentPanelData },
    //   },
    // });
  }
  openGMAttachmentModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;
    wasmModule.moduleManager.ToggleLowerArchMove(isOpen);
    this.wasmAttachmentModule = wasmModule.moduleManager.GetAttachmentModule();
  }
  onSelectedGMAttachment(archType: number) {
    console.log("archType: ", archType);

    wasmModule.moduleManager.OnGMAttachmentButtonDown(archType);
  }
  getAttachmentReport() {
    const attachment: Record<
      number,
      {
        toothId: number;
        attachment: { attachmentId: number; onLingualSide: boolean }[];
      }
    > = {};
    const uplist: number[] = [];
    const lowlist: number[] = [];
    const attachmentModel = wasmModule.mouthModel.GetAttachmentModel();
    const toothupIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.UpArch)
      .GetToothIds();
    const size = toothupIds.size();
    for (let i = 0; i < size; i++) {
      const toothid = toothupIds.get(i);
      uplist.push(toothid);
      if (!attachmentModel) continue;
      const attachmentDataInfo: any[] = [];
      const num = attachmentModel.GetToothAttachmentCount(toothid);
      for (let j = 0; j < num; j++) {
        const attachmentInfo = attachmentModel.GetAttachmentInfo(
          toothid,
          j,
          false
        );
        const isLinggua =
          attachmentModel.BAttachmentOnLingualSide(attachmentInfo);
        attachmentDataInfo.push({
          attachmentId: attachmentInfo.AttachmentId,
          onLingualSide: isLinggua,
        });
      }
      attachment[toothid] = {
        toothId: toothid,
        attachment: attachmentDataInfo,
      };
    }
    if (uplist[0] > uplist[uplist.length - 1]) {
      uplist.reverse();
    }
    const toothlowIds = wasmModule.mouthModel
      .GetArchModel(wasmModule.module.ArchType.DownArch)
      .GetToothIds();
    const sizelow = toothlowIds.size();
    for (let i = 0; i < sizelow; i++) {
      const toothid = toothlowIds.get(i);
      lowlist.push(toothid);
      if (!attachmentModel) continue;
      const attachmentDataInfo: any[] = [];
      const num = attachmentModel.GetToothAttachmentCount(toothid);
      for (let j = 0; j < num; j++) {
        const attachmentInfo = attachmentModel.GetAttachmentInfo(
          toothid,
          j,
          false
        );
        const isLinggua =
          attachmentModel.BAttachmentOnLingualSide(attachmentInfo);
        attachmentDataInfo.push({
          attachmentId: attachmentInfo.AttachmentId,
          onLingualSide: isLinggua,
        });
      }

      attachment[toothid] = {
        toothId: toothid,
        attachment: attachmentDataInfo,
      };
    }
    if (lowlist[0] < lowlist[uplist.length - 1]) {
      lowlist.reverse();
    }
    return { attachmentReport: attachment, toothList: { uplist, lowlist } };
  }
  onResetAttachmentSelectButton() {
    console.log("dispatch resetbuttonevent");
    document.dispatchEvent(resetbuttonevent);
  }
  onAddTempAttachemnt(checkId: number, optionType: number) {
    console.log("onAddTempAttachemnt: ", checkId, "; ", optionType);
    if (!wasmModule.isInit && this.wasmAttachmentModule) return;
    if (checkId != 3) {
      this.wasmAttachmentModule.AddTempAttachemnt(checkId, optionType);
    } else {
      popupHelper.alertPopup("info", "", [
        {
          name: "left side",
          func: () => {
            this.wasmAttachmentModule.AddTempAttachemnt(checkId, 1);
          },
        },
        {
          name: "right side",
          func: () => {
            this.wasmAttachmentModule.AddTempAttachemnt(checkId, 2);
          },
        },
        {
          name: "both side",
          func: () => {
            this.wasmAttachmentModule.AddTempAttachemnt(checkId, 3);
          },
        },
      ]);
    }
  }

  // drawAuxullarties(
  //   canvasElement: HTMLElement,
  //   auxinfoList: IAuxullartiesShowInfo[]
  // ) {
  //   const engine = Mi.Engine.singleton;
  //   engine.clearAllViews();
  //   const view = engine.createDefaultView(canvasElement);
  //   console.log("?????22", canvasElement.getBoundingClientRect());
  //   const camera = view.getCamera();
  //   const scene = view.getScene();
  //   camera.position = Mi.Vector3.create(0.0, 0.0, 400.0);
  //   camera.upVector = Mi.Vector3.create(0.0, 1.0, 0.0);
  //   const zeroVector = Mi.Vector3.create(0.0, 0.0, 0.0);
  //   camera.aspect = canvasElement.clientWidth / canvasElement.clientHeight;
  //   console.log(
  //     "aaaaaa",
  //     canvasElement.clientWidth,
  //     canvasElement.clientHeight
  //   );
  //   camera.lookAt = zeroVector;
  //   scene.addNode(camera);

  //   // setup light
  //   const light = new Mi.DirectionLight(null);
  //   light.position = Mi.Vector3.create(100.0, 200.0, 300.0);
  //   light.direction = Mi.Vector3.normalize(
  //     Mi.Vector3.sub(light.position, zeroVector)
  //   );
  //   scene.addLight(light);

  //   // setup entity

  //   const material = new Mi.PhoneMaterial();
  //   const geometry = new Mi.BoxGeometry({
  //     width: 100,
  //     height: 100,
  //     length: 100,
  //   });

  //   const entity = new Mi.Entity();
  //   entity.addGeometry(geometry, material);
  //   scene.addNode(entity);

  //   // const camController = new Mi.OrbitCameraController();
  //   // camController.setup(view);
  //   view.render();

  //   // engine.startRenderLoop();
  // }

  currentAnim;
  currentTW;
  stlModelsMap;

  async loadAtmModels() {
    if (!this.stlModelsMap) {
      const readFiles = async idArray => {
        for (const id of idArray) {
          const file = resourcesSynchronization.getFile(
            `${basePathUrl}/${id}.stl`
          );
          // let geometry = defaultGeometry;
          if (file) {
            const ab = await file.arrayBuffer();
            const geometry = stlLoader.parseBinary(ab);
            this.stlModelsMap[id.toString()] = geometry;
          }
        }
      };
      const stlLoader = new Mi.STLLoader();
      this.stlModelsMap = {} as Record<string, File>;
      const basePathUrl = "/test/resource/AttachmentLib";
      await readFiles(NormalAttIDs);
      await readFiles(PressurePointIDs);
      await readFiles(ButtonAndSlitsIDs);
      this.stlModelsMap["21"] = this.stlModelsMap["10"];
      this.stlModelsMap["22"] = this.stlModelsMap["18"];
      this.stlModelsMap["23"] = this.stlModelsMap["19"];
    }
    // console.log("readStl:::", this.stlModelsMap);
  }

  async drawAuxullarties(
    canvasElement: HTMLElement,
    auxinfoList: IAuxullartiesShowInfo[]
  ) {
    // load stl models
    await this.loadAtmModels();

    const engine = Mi.Engine.singleton;
    engine.clearAllViews();
    const view = engine.createDefaultView(canvasElement);
    const material = new Mi.PhoneMaterial();

    const setupViewport = (element, geometry) => {
      const newScene = new Mi.Scene();
      const camera = new Mi.Camera();
      camera.position = Mi.Vector3.create(0.0, 0.0, 8.0);
      camera.upVector = Mi.Vector3.create(0.0, 1.0, 0.0);
      const zeroVector = Mi.Vector3.create(0.0, 0.0, 0.0);
      camera.aspect = element.clientWidth / element.clientHeight;
      camera.lookAt = zeroVector;
      newScene.addNode(camera);

      // setup light
      const light = new Mi.DirectionLight(null);
      light.position = Mi.Vector3.create(100.0, 200.0, 300.0);
      light.direction = Mi.Vector3.normalize(
        Mi.Vector3.sub(light.position, zeroVector)
      );
      newScene.addLight(light);
      // setup entity
      const entity = new Mi.Entity();
      entity.addGeometry(geometry, material);
      newScene.addNode(entity);

      element.addEventListener("mouseenter", () => {
        const tw2 = new Mi.Tween({
          src: entity.rotation,
          to: { updateobj: Mi.Vector3.create(0, 360, 0), costtime: 2500 },
          onUpdate: (obj: any) => {
            entity.rotation = obj;
          },
          onStop: () => {
            entity.rotation = [0, 0, 0];
            entity.scale = [1, 1, 1];
            view.render();
          },
        });
        tw2.repeat(Infinity);

        const tw1 = new Mi.Tween({
          src: entity.scale,
          to: { updateobj: Mi.Vector3.create(1.2, 1.2, 1.2), costtime: 100 },
          onUpdate: (obj: any) => {
            entity.scale = obj;
          },
        });
        tw1.chain(tw2);

        this.currentTW = tw1;
        if (!this.currentAnim) {
          this.currentAnim = new Mi.Animation();
        }
        this.currentAnim.removeAll();
        this.currentAnim.addTween(this.currentTW);
        this.currentAnim.start();
      });

      element.addEventListener("mouseleave", () => {
        if (this.currentAnim) {
          this.currentAnim.removeAll();
        }
      });

      return {
        viewportElem: element,
        scene: newScene,
        camera: camera,
        backgroundColor: Mi.Vector4.create(1, 0, 0, 1),
      };
    };

    const viewportInfos = [];

    // const defaultGeometry = new Mi.BoxGeometry({
    //   width: 5,
    //   height: 5,
    //   length: 5,
    // });

    for (const item of auxinfoList) {
      for (let index = 0; index < item.elements.length; index++) {
        const elem = item.elements[index];
        const auxId = elem[0];
        const domelement = elem[1];
        const geometry = this.stlModelsMap[auxId.toString()];
        const vp = setupViewport(domelement, geometry);
        viewportInfos.push(vp);
      }
    }

    view.setViewports(viewportInfos);
    view.render();
  }
}

export const attachmentModule = new AttachmentModule();
