import { FC } from "react"
import { Outlet } from "react-router-dom"
import { Box, CssBaseline, Grid } from "@mui/material"

import { USmilleCommonStyle } from "../../ui-component"

const LandingLayout: FC = () => {
  return (
    <>
      <CssBaseline />
      <Grid
        sx={{
          ...USmilleCommonStyle.LandingLayoutProps,
        }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{}}>
          <Outlet />
        </Box>
      </Grid>
    </>
  )
}

export default LandingLayout
