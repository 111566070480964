import * as React from "react"
import type { FC } from "react"
import { memo } from "react"

import { Grid, Stack, Card, CardContent, Typography } from "@mui/material"
import { useState, useEffect, useRef } from "react"
import {
  NavToggleButton,
  NavLabel,
  NavIconButton,
} from "../../Custom/NavCustom"
import { ToggleButtonGroup, ToggleButton } from "@mui/material"
import AttVert from "@mui/icons-material/CropPortraitTwoTone"
import AttHori from "@mui/icons-material/Crop32TwoTone"
import AttTrape from "@mui/icons-material/CropDinTwoTone"
import AttElli from "@mui/icons-material/CircleOutlined"

import { FlowButton, FlowButton2, FlowButton3 } from "../../Custom/FlowCustom"
import { attachmentModule } from "@ulab/wesmileclinical"

const WeFlowFeatures: FC<any> = ({ isOpen }) => {
  const [pressurePointName, setPressurePointName] = React.useState<
    string | null
  >(null)
  const [attachmentName, setAttachmentName] = React.useState<string | null>(
    null,
  )
  const handlePressurePointName = (
    event: React.MouseEvent<HTMLElement>,
    selectedName: string | null,
  ) => {
    setPressurePointName(selectedName)
  }
  const handleAttachmentName = (
    event: React.MouseEvent<HTMLElement>,
    selectedName: string | null,
  ) => {
    setAttachmentName(selectedName)
  }
  React.useEffect(() => {
    console.log("mount UFlowFeatures")
    // props.dispatch({ type: 'autosetup/updateState' });
    attachmentModule.openAttachmentModule(true)
    const resetFunc = (e) => {
      console.log("Listen resetbuttonevent")
      setPressurePointName(null)
      setAttachmentName(null)
    }
    document.addEventListener("resetbuttonevent", resetFunc)
    return () => {
      console.log("unmount UFlowFeatures")
      attachmentModule.openAttachmentModule(false)
      window.removeEventListener("resetbuttonevent", resetFunc)
    }
  }, [])

  const Generic = [0, 1, 2, 3]
  const Pressure = [7, 19, 20]

  function onClickAttIcon(id: number) {
    attachmentModule.setAttachmentSelectLibID(id)
  }

  return (
    <Stack direction="column" spacing={1}>
      <Card>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography>Auto Attachment</Typography>
            </Grid>
            <Grid item xs={6}>
              <FlowButton
                onClick={() => {
                  attachmentModule.addAllAttachment()
                }}
              >
                Add All
              </FlowButton>
            </Grid>
            <Grid item xs={6}>
              <FlowButton
                onClick={() => {
                  attachmentModule.deleteAllAttachment(1)
                }}
              >
                Remove All
              </FlowButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography>Manual Attachment</Typography>
          <Grid container sx={{ justifyContent: "center" }} marginTop={1}>
            <Grid>
              <ToggleButtonGroup
                orientation="horizontal"
                aria-label="Platform"
                exclusive
                value={attachmentName}
                onChange={handleAttachmentName}
                size="large"
              >
                <NavToggleButton
                  onClick={() => onClickAttIcon(Generic[0])}
                  value={"Generic1"}
                >
                  <AttVert />
                  <NavLabel textTransform="none" align="center">
                    Rectangle
                  </NavLabel>
                </NavToggleButton>
                <NavToggleButton
                  onClick={() => onClickAttIcon(Generic[1])}
                  value={"Generic2"}
                >
                  <AttHori />
                  <NavLabel textTransform="none" align="center">
                    Tapped
                  </NavLabel>
                </NavToggleButton>
                <NavToggleButton
                  onClick={() => onClickAttIcon(Generic[2])}
                  value={"Generic3"}
                >
                  <AttTrape />
                  <NavLabel textTransform="none" align="center">
                    Extrusion
                  </NavLabel>
                </NavToggleButton>
                <NavToggleButton
                  onClick={() => onClickAttIcon(Generic[3])}
                  value={"Generic4"}
                >
                  <AttElli />
                  <NavLabel textTransform="none" align="center">
                    Ellipse
                  </NavLabel>
                </NavToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography>Bite Ramp</Typography>
            </Grid>
            <Grid item xs={6}>
              <FlowButton
                onClick={() => {
                  attachmentModule.addAllBiteRamp()
                }}
              >
                Add All
              </FlowButton>
            </Grid>
            <Grid item xs={6}>
              <FlowButton
                onClick={() => {
                  attachmentModule.deleteAllAttachment(2)
                }}
              >
                Remove All
              </FlowButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default memo(WeFlowFeatures)
