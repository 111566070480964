import auxiliares from "../../../../assets/images/clinical/toolBar/auxiliares.svg"
import auxiliaresDis from "../../../../assets/images/clinical/toolBar/auxiliaresDis.svg"
import bolton from "../../../../assets/images/clinical/toolBar/bolton.svg"
import boltonDis from "../../../../assets/images/clinical/toolBar/boltonDis.svg"
import front from "../../../../assets/images/clinical/toolBar/front.svg"
import frontDis from "../../../../assets/images/clinical/toolBar/frontDis.svg"
import grid from "../../../../assets/images/clinical/toolBar/grid.svg"
import gridDis from "../../../../assets/images/clinical/toolBar/gridDis.svg"
import left from "../../../../assets/images/clinical/toolBar/left.svg"
import leftDis from "../../../../assets/images/clinical/toolBar/leftDis.svg"
import low from "../../../../assets/images/clinical/toolBar/low.svg"
import lowDis from "../../../../assets/images/clinical/toolBar/lowDis.svg"
import occlusal from "../../../../assets/images/clinical/toolBar/occlusal.svg"
import occlusalDis from "../../../../assets/images/clinical/toolBar/occlusalDis.svg"
import right from "../../../../assets/images/clinical/toolBar/right.svg"
import rightDis from "../../../../assets/images/clinical/toolBar/rightDis.svg"
import space from "../../../../assets/images/clinical/toolBar/spaceCheck.svg"
import spaceDis from "../../../../assets/images/clinical/toolBar/spaceCheckDis.svg"
import spaceInter from "../../../../assets/images/clinical/toolBar/spaceInter.svg"
import spaceInterDis from "../../../../assets/images/clinical/toolBar/spaceInterDis.svg"
import split from "../../../../assets/images/clinical/toolBar/split.svg"
import splitDis from "../../../../assets/images/clinical/toolBar/splitDis.svg"
import superImpose from "../../../../assets/images/clinical/toolBar/superImpose.svg"
import up from "../../../../assets/images/clinical/toolBar/up.svg"
import upDis from "../../../../assets/images/clinical/toolBar/upDis.svg"
import weizhi from "../../../../assets/images/clinical/toolBar/weizhi.svg"

export const viewTmp = [
  {
    src: up,
    disabledSrc: upDis,
    key: "up",
    title: "Upper arch",
  },
  {
    src: low,
    disabledSrc: lowDis,
    key: "low",
    title: "Lower arch",
  },
]
export const viewTmp1 = [
  {
    src: left,
    disabledSrc: leftDis,
    key: "left",
    title: "Left buccal",
  },

  {
    src: front,
    disabledSrc: frontDis,
    key: "front",
    title: "Anterior",
  },
  {
    src: weizhi,
    disabledSrc: weizhi,
    key: "back",
    title: "Rear view (TBD)",
  },
  {
    src: right,
    disabledSrc: rightDis,
    key: "right",
    title: "Right buccal",
  },
  {
    src: split,
    disabledSrc: splitDis,
    key: "split",
    title: "Occlusal",
  },
]

export const toolsTmp = [
  {
    src: spaceInter,
    disabledSrc: spaceInterDis,
    key: "Space",
    title: "Space interference",
  },
  {
    src: occlusal,
    disabledSrc: occlusalDis,
    key: "Occlusalgram",
    title: "Occlusalgram",
  },
  {
    src: auxiliares,
    disabledSrc: auxiliaresDis,
    key: "Attachment",
    title: "Attachment",
  },
  {
    src: space,
    disabledSrc: spaceDis,
    key: "Superimposition",
    title: "Superimposition",
  },
  {
    src: weizhi,
    disabledSrc: weizhi,
    key: "Overlay",
    title: "Opposing overlay",
  },
  {
    src: bolton,
    disabledSrc: boltonDis,
    key: "ToothSize",
    title: "Tooth Size",
  },
  {
    src: grid,
    disabledSrc: gridDis,
    key: "Grid",
    title: "Grid",
  },
]
export const toolValues = [
  "Space",
  "Occlusal",
  "Auxiliaries",
  "SuperImpose",
  "Bolton",
  "Grid",
]
