import { FC, ReactNode } from "react"

import { MenuItem, MenuList, Paper } from "../../../components/mui.components"

interface items {
  name: string
  onClick: () => void
}

const ProfileOptions: FC<{ menuItems: ReactNode | items[] }> = ({
  menuItems,
}) => {
  return (
    <Paper
      elevation={8}
      sx={{
        position: "absolute",
        top: "58px",
        right: "24px",
        width: "200px",
        zIndex: "99",
      }}
    >
      <MenuList>
        {menuItems.map((item, i, row) => {
          return (
            <MenuItem divider={false} key={item.name} onClick={item.onClick}>
              {item.name}
            </MenuItem>
          )
        })}
      </MenuList>
    </Paper>
  )
}

export default ProfileOptions
