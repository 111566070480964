import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

const initialState = {
  isCrown: false,
  isImplant: false,
  isBridge: false,
  isShow: true,
  toothWidth: 0,
  toothId: 0,
  data: [0, 0, 0, 0, 0, 0],
  isNoMovement: false,
  flag: false, // 辅助3d监听是否触发
}

export const toothInfoSlice = createSlice({
  name: "toothInfo",
  initialState,
  reducers: {
    setIsShow(state, action: PayloadAction<boolean>) {
      state.isShow = action.payload
    },
    setData(state, action: PayloadAction<number[]>) {
      state.data = action.payload
    },
    setNewState(state, action) {
      state.toothId = action.payload.toothId
      state.data = action.payload.data
      state.isCrown = action.payload.isCrown
      state.isImplant = action.payload.isImplant
      state.isBridge = action.payload.isBridge
      state.isShow = action.payload.isShow
      state.isNoMovement = action.payload.isNoMovement
      state.flag = action.payload.flag
    },
  },
})

export const { setIsShow, setData, setNewState } = toothInfoSlice.actions
