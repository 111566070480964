import { createAsyncThunk } from "@reduxjs/toolkit"

import * as userApiService from "./user.service"
import { IUpdateUser } from "./user.service"
export interface IUserPreferences {
  bothscanspopup_no_shows?: boolean
  onboarding_no_shows?: boolean
}

export const fetchUserPreferences = createAsyncThunk(
  "userService/fetchUserPreferences",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    if (userState.preferences) {
      return userState.preferences
    }
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.getUserPreferences(orgId, userId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateUserPreferences = createAsyncThunk(
  "userService/updateUserPreferences",
  async (payload: IUserPreferences, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id
      console.log("update...", payload)
      const response = await userApiService.updateUserPreferences(
        orgId,
        userId,
        payload,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchUserProfile = createAsyncThunk(
  "userService/fetchUserProfile",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.getUserProfile(orgId, userId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateUserProfile = createAsyncThunk(
  "userService/updateUserProfile",
  async (payload: IUpdateUser, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.updateUserProfile(
        orgId,
        userId,
        payload,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
