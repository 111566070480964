import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export const manuStagingPercentSlice = createSlice({
  name: "manustagingpercent",
  initialState: {
    isShow: false,
    curJaw: "upper",
  },
  reducers: {
    setIsShow(state, action: PayloadAction<boolean>) {
      state.isShow = action.payload
    },
    setCurJaw(state, action: PayloadAction<string>) {
      state.curJaw = action.payload
    },
  },
})

export const { setIsShow, setCurJaw } = manuStagingPercentSlice.actions
