import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  getFileInfo,
  getFileZips,
  getCaseByPatient,
  uploadZips,
} from "./clinical.service"
import {
  clinicalRequestBasicType,
  getZipsType,
  UploadZips,
} from "./clinical.types"
export const fetchFileList = createAsyncThunk(
  "clinicalService/fetchFileList",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getFileInfo({ orgId, patientId, caseId })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getTreatmentInfos = createAsyncThunk(
  "clinicalService/getTreatmentInfos",
  async ({ patientId }: any, { rejectWithValue, getState }) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getCaseByPatient(orgId, patientId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchFileZips = createAsyncThunk(
  "clinicalService/fetchFileZips",
  async (
    { patientId, caseId, zipNames }: getZipsType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getFileZips({ orgId, patientId, caseId, zipNames })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
export const uploadCaseZips = createAsyncThunk(
  "clinicalService/uploadFileZips",
  async (
    { patientId, caseId, zips }: UploadZips,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadZips({ orgId, patientId, caseId, zips })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
