import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button } from "@mui/material"

import { UText } from "../../../../ui-component"

import "./notReady.scss"

const NotReady: FC<{ isReady: boolean }> = ({ isReady }) => {
  const { t } = useTranslation("common")
  return (
    <Box className="notReady" sx={{ display: isReady ? "none" : "flex" }}>
      <div className={"title"}>{t("treat.notReady.title")}</div>
      <div className={"des"}>{t("treat.notReady.des")}</div>
      <Button
        variant="contained"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          width: "212px",
          height: "30px",
          padding: "4px 0px",
        }}
      >
        <UText
          variant="caption"
          sxProp={{
            fontSize: 13,
            fontWeight: 500,
            color: (theme) => theme.palette.primary.contrastText,
            letterSpacing: "0.46px",
            lineHeight: "22px",
            height: "22px",
          }}
        >
          {t("treat.notReady.backToOther")}
        </UText>
      </Button>
    </Box>
  )
}
export default NotReady
