import { wasmModule } from "../wasm/wasmModule";
import { alertPopup } from "../../common/popuphelper";

class TrimToolModule {
  get wasmTrimCutModule() {
    return this._wasmCutModule;
  }

  private _wasmCutModule;
  private _currentMode: "Line" | "Surface" = "Line";

  /**
   * close and quit current mode and clear data.
   */
  close() {
    if (this._wasmCutModule) {
      if (this._currentMode === "Line") {
        wasmModule.moduleManager.TogglePolyPlaneCut(false);
      } else {
        wasmModule.moduleManager.ToggleSurfaceCutting(false);
      }
      this._wasmCutModule = undefined;
    }
  }

  /**
   * start/close PolyPlaneCut module
   */
  start(isActive: boolean) {
    wasmModule.moduleManager.TogglePolyPlaneCut(isActive);
    this._currentMode = "Line";
    this._wasmCutModule = isActive
      ? wasmModule.moduleManager.GetPolyPlaneCutModule()
      : undefined;
  }

  /**
   * start/close SurfaceCut module
   */
  startSurface(isActive: boolean) {
    console.log("???????????", wasmModule.moduleManager);
    wasmModule.moduleManager.ToggleSurfaceCutting(isActive);
    this._currentMode = "Surface";
    this._wasmCutModule = isActive
      ? wasmModule.moduleManager.GetSurfaceCuttingModule()
      : undefined;
  }

  /**
   * start draw line
   */
  startDrawLine() {
    const ret = this.wasmTrimCutModule?.StartDrawLine();
    if (ret !== 0) {
      alertPopup("warning", "the function must need single jaw.");
    }
  }
  /**
   * do cut action
   */
  cut() {
    const ret = this.wasmTrimCutModule?.Cut();

    if (ret !== 0) {
      alertPopup("warning", "cut false.");
    }
  }
  /**
   * clear all lines
   */
  clear() {
    this.wasmTrimCutModule?.Clear();
  }
  /**
   * undo history actions
   */
  undo() {
    this.wasmTrimCutModule?.Undo();
  }

  initModule() {
    // for test
    window.onkeypress = e => {
      if (e.key === "q") {
        this.start(true);
      }
      if (e.key === "w") {
        this.startDrawLine();
      }
      if (e.key === "e") {
        this.cut();
      }
      if (e.key === "r") {
        this.clear();
      }
      if (e.key === "t") {
        this.undo();
      }
    };
  }
}

export const trimToolModule = new TrimToolModule();
