import { ElementType, FC, ReactNode } from "react"
import React from "react"
import { Theme } from "@emotion/react"
import { Variant } from "@mui/material/styles/createTypography"

import { SxProps, Typography } from "../../mui.components"

export interface ITitleProps {
  /**
   * We can pass different variants of the title h1,h2, ...,h6,body1, body2,caption, overline, button,subtitle1,subtitle2
   */
  variant: Variant
  /**
   * We can pass different colors of the title primary, error, text, inherit,
   */
  color?: string | "inherit"
  /**
   * Component Element  "div" or "span"
   */
  component?: ElementType
  /**
   * Child element
   */
  children: ReactNode
  /**
 * 	
The system prop that allows defining system overrides as well as additional CSS styles. See the `sx` page for more details.
 */
  sxProp?: SxProps
}

const UText: FC<ITitleProps> = ({
  variant,
  color,
  sxProp,
  component = "span",
  children,
}) => {
  return (
    <Typography
      component={component}
      variant={variant}
      color={color}
      sx={{ ...sxProp }}
    >
      {children}
    </Typography>
  )
}

UText.defaultProps = {
  sxProp: undefined,
  color: "inherit",
}

export default UText
