import { FC, useEffect, useLayoutEffect, useRef, useState } from "react"

import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../../../../components/mui.components"

import { IColumnDef, IUTable } from "./table"
import USearch from "./USearch"

const UTable: FC<IUTable> = ({
  columnData,
  columnDef,
  name,
  isFetching,
  onRowClick,
  paging,
  enableSearch,
  onSearch,
}) => {
  const tableRef = useRef(null)
  const [height, setHeight] = useState<number>(1)
  const [search, setSearch] = useState<string>("")
  const [page, setPage] = useState<number>(0)
  const rowsPerPage = 10
  columnDef = columnDef.filter((column) => column.visible)
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - paging.totalRecords)

  useEffect(() => {
    console.log("...isFetching...", isFetching)
  }, [isFetching])

  useLayoutEffect(() => {
    if (tableRef && tableRef.current) {
      setHeight(tableRef.current.clientHeight)
    }
  }, [tableRef])

  return (
    <Box component={"div"} sx={{ mb: 3, position: "relative" }}>
      <Box
        component={"div"}
        sx={{
          mb: 3,
          justifyContent: "start",
          flexWrap: "wrap",
          "> div": { mx: 1, alignSelf: "center" },
        }}
      >
        <Grid container>
          {enableSearch && (
            <Grid item xs={4}>
              <USearch
                search={search}
                updateSearch={(search: string) => {
                  setPage(0)
                  setSearch(search)
                  onSearch && onSearch(search)
                }}
              />
            </Grid>
          )}
          <Grid item xs={4} />
        </Grid>
      </Box>

      <Box component={"div"} sx={{ position: "relative", width: "100%" }}>
        <TableContainer
          component={Paper}
          sx={{
            minHeight: height,
          }}
        >
          <Table
            sx={{ width: "auto" }}
            stickyHeader
            aria-label={`${name}table`}
          >
            <TableHead>
              <TableRow>
                {columnDef.map((column: IColumnDef) => (
                  <TableCell key={column.id}>{column.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching === "succeeded" &&
                columnData &&
                columnData.map((row: any, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      onRowClick && onRowClick(row)
                    }}
                  >
                    {columnDef.map((col: IColumnDef, index) => {
                      return (
                        <TableCell key={index} component="th" scope="row">
                          {row ? row[col.dataKey] : ""}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}

              {isFetching === "pending" &&
                Array(rowsPerPage)
                  .fill({})
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      onClick={() => {
                        onRowClick && onRowClick(row)
                      }}
                    >
                      {columnDef.map((col: IColumnDef, index) => {
                        return (
                          <TableCell key={index} component="th" scope="row">
                            <Skeleton
                              data-testid="skeleton"
                              height={10}
                              animation="wave"
                            />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}

              {!paging.totalRecords && isFetching !== "pending" && (
                <>
                  <TableRow
                    style={{ height: 40 * (isNaN(emptyRows) ? 0 : emptyRows) }}
                  >
                    {columnDef.map((col: IColumnDef, index) => {
                      return <TableCell key={index} />
                    })}
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

UTable.defaultProps = {
  onRowClick: undefined,
  onSearch: undefined,
  isFetching: "idle",
  enableSearch: false,
}

export default UTable
