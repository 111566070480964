import { FC, ReactElement, ReactNode, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ControlProps } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setSelectedTemplateForm } from "../../../core/app/slices/uassist/formSlice"
import { fetchFormLists } from "../../../core/app/slices/uassist/formThunkApi"
import { RootState } from "../../../core/app/store"

const UTemplateSelect: FC<ControlProps> = (props) => {
  const { label, path, schema, errors, data, handleChange } = props
  const [value, setValue] = useState(schema.default || "")
  const navigate = useNavigate()
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const { formTemplateList } = useAppSelector(
    (state: RootState) => state.formService,
  )

  useEffect(() => {
    setValue(data)
  }, [data])

  useEffect(() => {
    dispatch(fetchFormLists())
  }, [])

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
    handleChange(path, event.target.value)
  }

  const routeToTemplate = (templateVal: string) => {
    const templateRoutes: { [key: string]: string } = {
      Crowding: "crowding",
      "Open Bite": "openbite",
      "Deep Bite": "deepbite",
      "Limited Treatment": "limitedtreatment",
      Spacing: "spacing",
    }

    const selectedForm = Object.values(formTemplateList).find(
      (formobject) => formobject.name === templateVal,
    )

    // eslint-disable-next-line no-prototype-builtins
    if (selectedForm && templateRoutes.hasOwnProperty(selectedForm.name)) {
      dispatch(
        setSelectedTemplateForm({
          id: selectedForm.id,
          name: selectedForm.name,
        }),
      )
      const route = `/uassist/patient/${patientId}/case/${caseId}/${
        templateRoutes[selectedForm.name]
      }`
      navigate(route)
    }
  }
  return (
    <>
      <Box sx={{ my: 1 }}>
        {label && (
          <InputLabel
            sx={{
              mb: 1,
              color: "rgba(0, 0, 0, 0.60)",
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.60)",
              },
            }}
            error={errors ? true : false}
            id={`${path}-label}`}
          >
            {label}
          </InputLabel>
        )}
        <FormControl fullWidth error={errors ? true : false}>
          <Select
            labelId={`${path}-label}`}
            id={`${path}-select}`}
            value={value}
            onChange={handleChangeSelect}
            renderValue={
              value
                ? undefined
                : () => (
                    <Box component={"div"} sx={{ color: "#aaa" }}>
                      {"Select"}
                    </Box>
                  )
            }
            displayEmpty
          >
            {formTemplateList &&
              Object.values(formTemplateList).map((item, index) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
        <Button
          fullWidth
          onClick={() => {
            routeToTemplate(value)
          }}
          sx={{
            display: !value ? "none" : "block",
            my: 2,
            borderRadius: "4px",
            background: "#E0E0E0",
            color: "#000000",
            boxShadow:
              "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
        >
          {schema.options.buttonText}
        </Button>
      </Box>
    </>
  )
}

export default withJsonFormsControlProps(UTemplateSelect)
