export const schema = {
  type: "object",
  properties: {
    arch_form_type: {
      type: "string",
      isRadioGroup: true,
      enum: ["Ovoid", "Natural/Maintain Current Arch Form", "Broad/Square"],
    },
    smile_design: {
      type: "string",
      isRadioGroup: true,
      enum: [
        "Smile Arc",
        "Level the incisal edges",
        "Level the canines with the centrals",
        "Level the gingival margins",
      ],
      input: {
        width: 56,
        startLabel: "Step up laterals",
        endLabel: "mm",
        const: "Level the canines with the centrals",
        minRows: 1,
        name: "stage",
        isNum: true,
      },
    },
    areas_to_stage_ipr: {
      title: "Please select area(s) to stage IPR",
      type: "string",
      isRadioGroup: true,
      enum: ["3-3", "4-4", "5-5", "Not applicable"],
    },
    order_of_priority: {
      title: "Order of Priority (optional)",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Premolars to maximum limit before anterior teeth",
          value: "premolartolimited",
        },
        { label: "Not in the first round", value: "notfirst" },
      ],
    },
    max_per_interproximal: {
      title: "Maximum amount per interproximal contact",
      type: "string",
      isRadioGroup: true,
      enum: ["0.2 mm", "0.3 mm", "0.4 mm", "0.5 mm"],
    },
    set_ipr_staging: {
      title: "Set IPR Staging ",
      type: "string",
      isRadioGroup: true,
      enum: ["Preferred Stage(s)", "When contacts align"],
      input: {
        width: 310,
        labelPosition: "end",
        const: "Preferred Stage(s)",
        maxRows: 4,
        minRows: 1,
        placeholder: "i.e. Stages 0/8/16/24",
        defaultValue: 1,
        multiline: true,
      },
    },
    attachments: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
      input: {
        width: 56,
        startLabel: "Stage",
        const: "Yes",
        minRows: 1,
        name: "stage",
        isNum: true,
        defaultValue: "1",
      },
    },
    add_group_protocol: {
      title: "Add Group Protocol (optional)",
      isChecboxGroup: true,
      checkboxes: [
        { label: "Deep Bite", value: "deepbite" },
        { label: "Open Bite", value: "openbite" },
        { label: "Distalization (if applicable)", value: "distalization" },
        { label: "Custom", value: "custom" },
      ],
      input: {
        width: 310,
        const: "custom",
        maxRows: 4,
        minRows: 1,
        name: "instructions",
        multiline: true,
      },
    },
    virtual_chain: {
      title: "Virtual C-Chain",
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No", "Not in the initial round"],
      input: {
        width: 80,
        endLabel: "Amount of virtual IPR at the last stage",
        const: "Yes",
        minRows: 1,
        name: "c-chain",
        startAdornment: "-",
        defaultValue: "0.01",
      },
    },
    velocity_rating: {
      title: "Velocity - Rate Limiting Factor Stage",
      type: "string",
      isRadioGroup: true,
      enum: [
        ".20 mm linear / 2.0° angular",
        ".22 mm linear / 2.2° angular",
        ".25 mm linear / 2.5° angular",
        "Custom",
      ],
    },
    equal_aligners: {
      title: "Equal number of aligners",
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
    },
    passive_aligners: {
      title: "Passive Aligners",
      type: "string",
      isRadioGroup: true,
      enum: ["Yes (if needed)", "No"],
      input: {
        width: 310,
        const: "Yes (if needed)",
        minRows: 1,
        name: "passive_aligner",
        placeholder: "instructions (optional)",
        multiline: true,
      },
    },
    wear_time_aligners: {
      type: "string",
      title: "Wear Time of Aligners",
      isRadioGroup: true,
      enum: ["7 Days", "14 Days", "Days"],
      input: {
        width: 56,
        endLabel: "Days",
        labelPosition: "end",
        const: "Days",
        showInSameLine: true,
        isNum: true,
      },
    },
    case_specific_instructions: {
      title: "Limited Treatment Instructions",
      type: "string",
      isTextarea: true,
      isSettings: true,
      placeholder: `Please add instructions for this template here (no abbreviations).\n\nYou will have the opportunity to enter case specific instructions before submitting each case.`,
      options: {
        rows: 6,
      },
    },
  },
  required: [
    "arch_form_type",
    "smile_design",
    "areas_to_stage_ipr",
    "attachments",
    "add_group_protocol",
    "passive_aligners",
    "wear_time_aligners",
    "equal_aligners",
    "velocity_rating",
    "max_per_interproximal",
    "set_ipr_staging",
    "virtual_chain",
  ],
}

export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Group",
      label: "How do you prefer to see teeth at the end of treatment?",
      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/arch_form_type",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Detailing",
      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/smile_design",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "IPR",
      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/areas_to_stage_ipr",
          options: {
            format: "radio",
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/order_of_priority",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/max_per_interproximal",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/set_ipr_staging",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "How to achieve the stimulated treatment - biomechanics",
      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/attachments",
          options: {
            format: "radio",
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/add_group_protocol",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/virtual_chain",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/velocity_rating",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/equal_aligners",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/passive_aligners",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/wear_time_aligners",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Additional Instructions",
      elements: [
        {
          type: "Control",
          scope: "#/properties/case_specific_instructions",
          label: true,
        },
      ],
    },
  ],
}

export const defaultData = {
  arch_form_type: "Natural/Maintain Current Arch Form",
  attachments: "Yes",
  smile_design: "Smile Arc",
  areas_to_stage_ipr: "3-3",
  max_per_interproximal: "0.2 mm",
  order_of_priority: [],
  set_ipr_staging: "Preferred Stage(s)",
  virtual_chain: "No",
  add_group_protocol: [],
  velocity_rating: ".22 mm linear / 2.2° angular",
  equal_aligners: "Yes",
  passive_aligners: "No",
  wear_time_aligners: "7 Days",
}

export const jsonSchemaDefault = JSON.stringify(schema, null, 2)
export const uiSchemaDefault = JSON.stringify(uischema, null, 2)
