import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material"

import chat from "../../../../assets/images/clinical/chat.png"
import logoSvg from "../../../../assets/images/ulablogo.svg"
import close from "../../../../assets/svgs/close.svg"
import success from "../../../../assets/svgs/duihao.svg"
import select from "../../../../assets/svgs/duihao2.svg"
import warning from "../../../../assets/svgs/warning.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import { UText } from "../../../../ui-component"

import "./message.scss"

const Message: FC = () => {
  const [mockMessageData, setmockMessageData] = useState([])

  const { patientId, caseId } = useParams()

  const [holdplace, setholdplace] = useState("")

  const [alert, setalert] = useState(true)

  const [alertText, setalertText] = useState("")

  const [selectedPlan, setselectedPlan] = useState("")

  const [dialog, setdialog] = useState(false)

  const [mockStatus, setmockStatus] = useState("")

  const [inputValue, setinputValue] = useState("")

  const [isreadMore, setisreadMore] = useState<boolean[]>([])

  const left = ({ name, time, message, color }, index, ishistory) => {
    return (
      <Box
        id="message-item"
        key={index}
        sx={{
          color: "#FFF",
          fontWeight: 400,

          marginTop: 2,
        }}
      >
        <Box id="message-title" sx={{ display: "flex" }}>
          <Avatar
            sx={{ bgcolor: "#FFF", width: 32, height: 32 }}
            src={logoSvg}
          />

          <Box sx={{ padding: "0 8px" }}>
            <Box
              id="message-name"
              sx={{ fontSize: 14, fontWeight: 400, color: "#FFF" }}
            >
              {name}
              <Box
                component={"span"}
                sx={{
                  color: "rgba(255, 255, 255, 0.70)",
                }}
              >
                <Box
                  component={"span"}
                  sx={{ margin: "0 8px", color: "rgba(255, 255, 255, 0.70)" }}
                >
                  |
                </Box>
                UASSIST TEAM
              </Box>
            </Box>
            <Box id="message-time" sx={{ fontSize: 12, fontWeight: 400 }}>
              {time}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: 4.4,
          }}
        >
          <Box
            id="message-text"
            sx={{
              padding: "16px 12px",
              color: "#ECEFF1",
              fontSize: 14,
              borderRadius: 0.5,
              bordertop: "1px solid rgba(144, 202, 249, 0.50)",
              borderRight: "1px solid rgba(144, 202, 249, 0.50)",
              borderBottom: "1px solid rgba(144, 202, 249, 0.50)",
              borderLeft: "6px solid rgba(144, 202, 249, 0.50)",
              background: "#546E7A",
              width: 320,
              margin: "8px 0px",
            }}
          >
            {mockStatus.indexOf("plan1") === -1 && (
              <Box
                sx={
                  mockStatus.indexOf("plan2") !== -1 && {
                    color: "rgba(144, 202, 249, 1)",
                  }
                }
              >
                {message.title}
              </Box>
            )}
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: isreadMore[index] && ishistory ? 3 : "",
                "-webkit-box-orient": "vertical",
              }}
            >
              {message.text}
            </Box>
            {isreadMore[index] && ishistory && (
              <Button
                size="small"
                variant="text"
                sx={{ marginTop: 1, marginLeft: 1 }}
                onClick={() => {
                  const res = isreadMore
                  res[index] = false
                  setisreadMore([...res])
                }}
              >
                READ MORE
              </Button>
            )}
          </Box>
          {index === 0 && !ishistory && (
            <Box
              sx={{
                color: "#90CAF9",
                fontSize: 12,
                padding: "0px 14px",
              }}
            >
              New
            </Box>
          )}
          {mockStatus.indexOf("selected") !== -1 &&
            selectedPlan === message.title && (
              <Box
                sx={{
                  color: "#90CAF9",
                  fontSize: 12,
                  padding: "0px 14px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Selected
                <ReactSVG
                  src={select}
                  style={{ marginLeft: 8, marginTop: 5 }}
                ></ReactSVG>
              </Box>
            )}
          {mockStatus.indexOf("plan") !== -1 &&
            selectedPlan !== message.title && (
              <Button
                size="medium"
                color="primary"
                variant="outlined"
                sx={{ marginLeft: 7 }}
                disabled={
                  mockStatus.indexOf("selected") !== -1 &&
                  selectedPlan &&
                  selectedPlan !== message.title
                }
                onClick={() => {
                  setselectedPlan(message.title)
                  setmockStatus(mockStatus + ",selected")
                }}
              >
                start modification request
              </Button>
            )}
        </Box>
      </Box>
    )
  }

  const right = ({ name, photo, time, message, color }, index, ishistory) => {
    const logo = photo
      ? "/photo/2.jpg"
      : name.split(" ")[0][0] + name.split(" ")[1][0]
    return (
      <Box
        id="message-item"
        key={index}
        sx={{ color: "#FFF", fontWeight: 400, marginTop: 2 }}
      >
        <Box
          id="message-title"
          sx={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Avatar sx={{ bgcolor: { color }, width: 32, height: 32 }} src={logo}>
            {logo}
          </Avatar>

          <Box sx={{ padding: "0 8px" }}>
            <Box
              id="message-name"
              sx={{ fontSize: 14, fontWeight: 400, color: "#FFF" }}
            >
              {name}
              <Box
                component={"span"}
                sx={{
                  color: "rgba(255, 255, 255, 0.70)",
                  textTransform: "uppercase",
                }}
              >
                <Box
                  component={"span"}
                  sx={{ margin: "0 8px", color: "rgba(255, 255, 255, 0.70)" }}
                >
                  |
                </Box>
                Orthodontist
              </Box>
            </Box>
            <Box
              id="message-time"
              sx={{ fontSize: 12, fontWeight: 400, textAlign: "right" }}
            >
              {time}
            </Box>
          </Box>
        </Box>

        <Box
          id="message-text"
          sx={{
            marginLeft: 4.4,
            color: "#ECEFF1",
            fontSize: 14,
            width: 320,
            marginTop: 1,
            marginBottom: 1,
            border: "1px solid rgba(255, 255, 255, 0.42)",
            borderRadius: 0.5,
            padding: "16px 12px",
          }}
        >
          <Box sx={{ color: "#90CAF9" }}>{message.title}</Box>
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: isreadMore[index] && ishistory ? 3 : "",
              "-webkit-box-orient": "vertical",
            }}
          >
            {message.text}
          </Box>

          {isreadMore[index] && ishistory && (
            <Button
              size="small"
              variant="text"
              sx={{ marginTop: 1, marginLeft: 1 }}
              onClick={() => {
                const res = isreadMore
                res[index] = false
                setisreadMore([...res])
              }}
            >
              READ MORE
            </Button>
          )}
        </Box>

        {mockStatus.indexOf("error") !== -1 && (
          <Box
            id="message-error"
            sx={{
              width: 320,
              height: 48,
              padding: "6px 16px",
              background: "#F44336",
              borderRadius: 0.5,
              marginLeft: 4.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ReactSVG
              src={warning}
              afterInjection={(svg) => {
                const path = svg.getElementsByTagName("path")[0]
                path?.setAttribute("fill", "#FFF")
              }}
              style={{
                width: 22,
                height: "22px",
              }}
            ></ReactSVG>
            <UText
              variant={"caption"}
              sxProp={{
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.4,
                color: "#FFF",
              }}
            >
              Comment failed to sent.
            </UText>
            <Button
              variant="text"
              size="small"
              sx={{
                padding: "4px 5px",
                fontSize: 13,
                fontWeight: 500,
                letterSpacing: 0.46,
                background: "#F44336",
                color: "#FFF",
              }}
            >
              TRY AGAIN
            </Button>
          </Box>
        )}
        {mockStatus.indexOf("success") !== -1 && (
          <Box
            id="message-success"
            sx={{
              width: 320,
              height: 48,
              padding: "6px 16px",
              borderRadius: 0.5,
              marginLeft: 4.4,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <UText variant="body2" sxProp={{ color: "#CCE8CD" }}>
              Modification request submitted
            </UText>
            <ReactSVG
              src={success}
              style={{ height: "22px", width: "22px", marginRight: 12 }}
            ></ReactSVG>
          </Box>
        )}
      </Box>
    )
  }

  useEffect(() => {
    /**
     *  plan1: 一个plan modification
     *  plan2: 两个plan modification
     *  select: 选择了一个case
     *  success: 发送成功
     *  error: 发送失败
     */
    setmockStatus(window.location.search)
    if (window.location.search.indexOf("progress") !== -1) {
      setholdplace("Disabled while uAssist is working on your case.")
    } else {
      setholdplace("Write your comments to submit the modification request.")
    }

    if (
      window.location.search.indexOf("plan2") !== -1 ||
      window.location.search.indexOf("planA") !== -1
    ) {
      setalertText(
        "Make minor modifications as needed and order, or send modification request to uAssist for plan 1 or plan 2.",
      )
      setmockMessageData([
        {
          name: "Robin A",
          time: "5/23/23 9:32 PM",
          message: {
            title: "PLAN 1",
            text: "here is the extraction plan based on your request.",
          },
        },
        {
          name: "Robin A",
          time: "5/23/23 9:32 PM",
          message: {
            title: "PLAN 2",
            text: "here is the non-extraction plan we believe may work better for your patient’s case.",
          },
        },
      ])
    }

    if (window.location.search.indexOf("plan1") !== -1) {
      setalertText(
        "Make minor modifications as needed and order, or send modification request to uAssist.",
      )
      setmockMessageData([
        {
          name: "Robin A",
          time: "5/23/23 9:32 PM",
          message: {
            title: "PLAN 1",
            text: "Here is the plan. We decide to change a, b. and c to aligner with e, f and g. ",
          },
        },
      ])
    }
    if (window.location.search.indexOf("list") !== -1) {
      setalertText(
        "Make minor modifications as needed and order, or send modification request to uAssist",
      )
      setmockMessageData([
        {
          name: "Robin A",
          time: "5/23/23 9:32 PM",
          message: {
            title: "Treatment plan on hold.",
            text: "Please upload new upper and lower scans",
          },
        },
        {
          name: "Jean R.",
          photo: true,
          time: "5/23/23 9:32 PM",
          message: { title: "", text: "Record added." },
        },
        {
          name: "Robin A",
          time: "5/20/23 9:32 PM",
          message: {
            title: "Here is the plan. ",
            text: "We decide to change a, b. and c to aligner with e, f and g. ",
          },
        },
        {
          name: "Jean R.",
          photo: false,
          color: "yellow",
          time: "5/19/23 9:32 PM",
          message: {
            title: "",
            text: "When treating deep bites, end with heavy posterior contacts and no anterior contacts. Lower anterior teeth should be intruded and overcorrected, the L4s and L5s should be extruded/have heavy contacts, and the maxillary anterior teeth should not be intruded.",
          },
        },
      ])
    }

    if (window.location.search.indexOf("selected") !== -1) {
      setselectedPlan("PLAN 1")
    }
  }, [window.location.search])

  useEffect(() => {
    if (mockStatus.indexOf("progress") !== -1) {
      setholdplace("Disabled while uAssist is working on your case.")
    } else {
      setholdplace("Write your comments to submit the modification request.")
    }

    if (mockStatus.indexOf("plan2") !== -1) {
      setalertText(
        "Make minor modifications as needed and order, or send modification request to uAssist for plan 1 or plan 2.",
      )
    }

    if (mockStatus.indexOf("plan1") !== -1) {
      setalertText(
        "Make minor modifications as needed and order, or send modification request to uAssist for plan 1 or plan 2.",
      )
    }
    if (mockStatus.indexOf("list") !== -1) {
      setalertText(
        "Make minor modifications as needed and order, or send modification request to uAssist",
      )
    }
  }, [mockStatus])

  useEffect(() => {
    if (mockMessageData.length === 0) {
      // 无message
      setholdplace("Write your comments to submit the modification request.")
    } else {
      const res: boolean[] = []
      mockMessageData.forEach(({ message: { text } }) => {
        res.push(text.length > 120)
      })
      setisreadMore([...res])
    }
  }, [mockMessageData])

  return (
    <>
      {mockMessageData.length === 0 && (
        <Box
          id="no-message"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "32px 8px",
            height: "100%",
            marginBottom: 14.5,
          }}
        >
          <img src={chat} alt="" style={{ height: "96px", width: "96px" }} />
          <Box sx={{ marginTop: 3, padding: 1.3, color: "#FFF" }}>
            <Box
              sx={{
                marginBottom: 1.3,
                fontSize: 20,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              No comment
            </Box>
            <Box
              sx={{
                fontSize: 14,
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              You can send modification request to uAssist once your plan is
              ready to review
            </Box>
          </Box>
        </Box>
      )}
      {mockMessageData.length !== 0 && (
        <Box
          id="message"
          sx={{
            width: "100%",
            padding: "6px 16px",
            overflow: "auto",
            flex: "1 1 auto",
            height: 0,
            marginBottom: "131px",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          {mockStatus.indexOf("progress") !== -1 && (
            <Alert severity={"info"} sx={{ marginTop: 1 }}>
              Planning in progress
            </Alert>
          )}

          {alert && (
            <Box
              id="message-alert"
              sx={{
                borderRadius: 0.5,
                background: "#29B6F6",
                color: "rgba(0, 0, 0, 0.87)",
                display: "flex",
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: "0.17px",
                margin: "16px 0px",
                marginBottom: 0,
                padding: "6px 16px",
              }}
            >
              <ReactSVG
                src={warning}
                style={{ margin: "7px 12px 7px 0" }}
              ></ReactSVG>
              <Box sx={{ height: "100%", padding: "8px 0px" }}>{alertText}</Box>
              <SvgBtn
                svg={close}
                boxSx={{ padding: 2 }}
                clickFun={() => {
                  setalert(false)
                }}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  path?.setAttribute("fill-opacity", "1")
                }}
              />
            </Box>
          )}
          {mockMessageData.map(
            ({ name, photo, time, message, type, color }, index) => {
              if (name === "Robin A") {
                return left({ name, time, message, color }, index, false)
              } else {
                return right(
                  { name, photo, time, message, color },
                  index,
                  false,
                )
              }
            },
          )}
          {mockStatus.indexOf("list") !== -1 && (
            <>
              <Divider
                sx={{
                  margin: 2,
                  color: "rgba(255, 255, 255, 0.38)",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                History
              </Divider>
              {mockMessageData.map(
                ({ name, photo, time, message, type, color }, index) => {
                  if (name === "Robin A") {
                    return left({ name, time, message, color }, index, true)
                  } else {
                    return right(
                      { name, photo, time, message, color },
                      index,
                      true,
                    )
                  }
                },
              )}
            </>
          )}
        </Box>
      )}
      <Box
        id="message-foot"
        sx={{
          position: "fixed",
          width: 390,
          background: "#37474F",
          bottom: 0,
          padding: "16px",
        }}
      >
        <Box
          id="message-foot-text"
          sx={{
            padding: "8px 12px",
            width: "100%",
            background: "#263238",
            borderRadius: "4px 4px 0px 0px",
          }}
        >
          <TextField
            multiline
            sx={{
              width: "100%",
              background: "#263238",
            }}
            rows={2}
            placeholder={holdplace}
            variant="standard"
            disabled={
              mockMessageData.length === 0 ||
              mockStatus.indexOf("progress") !== -1 ||
              !selectedPlan
            }
            label={mockStatus.indexOf("progress") !== -1 ? "" : selectedPlan}
            value={inputValue}
            onChange={({ target: { value } }) => {
              setinputValue(value)
            }}
          ></TextField>
        </Box>

        <Button
          variant="contained"
          disabled={
            mockMessageData.length === 0 ||
            mockStatus.indexOf("progress") !== -1 ||
            !selectedPlan
          }
          sx={{
            padding: "4px 10px",
            float: "right",
            marginTop: 1,
            background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            fontSize: 13,
            fontWeight: 500,
            height: 30,
          }}
          onClick={() => {
            if (!inputValue) return
            if (mockStatus.indexOf("plan2") !== -1) {
              setdialog(true)
              return
            }
            // TODO: SEND DATA TO BE

            setmockMessageData([
              ...mockMessageData,
              {
                name: "Jean R.",
                photo: true,
                time: "5/23/23 9:32 PM",
                message: { title: selectedPlan, text: inputValue },
                type: "message",
              },
            ])
            setmockStatus(mockStatus + ",success")
            setinputValue("")
          }}
        >
          SUBMIT
        </Button>
      </Box>
      <Dialog
        open={dialog}
        onClose={() => {
          setdialog(false)
        }}
      >
        <Box sx={{ width: 444 }}>
          <DialogTitle>{selectedPlan} is selected</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Only one plan can be modified by uAssist. You can still review and
              modify the other plan. Do you want to continue with this plan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setdialog(false)
                setselectedPlan("")
                setinputValue("")
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setdialog(false)
                // TODO: post plan message to BE
                setmockMessageData([
                  ...mockMessageData,
                  {
                    name: "Jean R.",
                    photo: true,
                    time: "5/23/23 9:32 PM",
                    message: { title: selectedPlan, text: inputValue },
                    type: "message",
                  },
                ])
                setmockStatus(mockStatus + ",error")
                setinputValue("")
              }}
              autoFocus
            >
              Yes, CONTINUE
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default Message
