import { AxiosProgressEvent } from "axios"

import http from "../../../../global/http"

export const uploadXrayFiles = (
  orgId: string,
  patientId: string,
  caseId: string,
  fileName: string,
  formData: FormData,
  callback: (progress: AxiosProgressEvent) => void,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/radiograph/${fileName}`
  try {
    const res = http.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: callback,
    })
    return res
  } catch (err) {
    return err
  }
}

export const downloadXrayFiles = (
  orgId: string,
  patientId: string,
  caseId: string,
  fileName: string,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/radiograph/${fileName}`
  try {
    const res = http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}

export const deleteUploadedFiles = (
  orgId: string,
  patientId: string,
  caseId: string,
  fileName: string,
  fileType: string,
  version: string,
) => {
  try {
    const res = http.delete(
      `/patient-mgmt/${version}/org/${orgId}/patients/${patientId}/txplans/${caseId}/${fileType}/${fileName}`,
    )
    return res
  } catch (err) {
    return err
  }
}
