export const columnKeys = {
  CASE_DETAILS: "case_detail",
  PATIENT_STATUS: "status",
  ORDER_STATUS: "orderSatus",
  DENTAL_MONITORING: "dental_monitoring",
  LAST_MONITORING_SCAN: "lastMonitoringScan",
  LAST_NAME: "last_name",
  FIRST_NAME: "first_name",
  PATIENT_DOB: "patient_dob",
}

export const tableData = {
  columnDef: [
    {
      name: "Last name",
      id: "lastName",
      dataKey: "lastName",
      cell: {
        style: {
          fontWeight: "bold !important",
          maxWidth: 150,
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
    {
      name: "First name",
      id: "firstName",
      dataKey: "firstName",
      cell: {
        style: {
          fontWeight: "bold !important",
          maxWidth: 100,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      visible: true,
    },
    {
      name: "Date of birth",
      id: "patient_dob",
      dataKey: "dob",
      visible: true,
    },
    {
      name: "Patient Status",
      id: columnKeys.PATIENT_STATUS,
      dataKey: "status",
      cell: {
        style: {
          background: "#F8F8F8 0% 0% no-repeat padding-box",
        },
      },
      visible: true,
    },
    {
      name: "Case Details",
      id: columnKeys.CASE_DETAILS,
      dataKey: "caseDetail",
      cell: {
        style: {
          background: "#F8F8F8 0% 0% no-repeat padding-box",
          width: 225,
        },
      },
      visible: true,
    },
    {
      name: "Order status",
      id: columnKeys.ORDER_STATUS,
      dataKey: "orderStatus",
      cell: {
        style: {
          background: "#F8F8F8 0% 0% no-repeat padding-box",
        },
      },
      visible: true,
    },
    {
      name: "Dental monitoring®",
      id: columnKeys.DENTAL_MONITORING,
      dataKey: "dentalMonitoring",
      visible: true,
    },
    {
      name: "Last monitoring Scan",
      id: "lastMonitoringScan",
      dataKey: "lastMonitoring",
      visible: true,
    },
  ],
}
