import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Slider } from "@mui/material"
import { viewControlInTreatment } from "@ulab/wesmileclinical"

import { overlayProps } from "./type"

import "./overlay.scss"
const { setOverlayOpacity } = viewControlInTreatment

const Overlay: FC<overlayProps> = ({ openOverlay, preparation }) => {
  const { t } = useTranslation("common")
  const [sliderValue, setSliderValue] = useState<number>(100)
  const handleChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number)
  }
  useEffect(() => {
    if (!preparation || !openOverlay) {
      return
    }
    setOverlayOpacity(sliderValue / 100)
  }, [sliderValue, openOverlay])
  return (
    openOverlay && (
      <Box className="overlayBox">
        <div className={"title"}>{`Transparency`}</div>
        <div className={"sliderBox"}>
          <Slider
            onChange={handleChange}
            value={sliderValue}
            valueLabelDisplay="auto"
          />
        </div>
        <div className={"valBox"}>
          <p>0</p>
          <p>100%</p>
        </div>
      </Box>
    )
  )
}
export default Overlay
