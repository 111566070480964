import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../core/app/hooks"
import { RootState } from "../core/app/store"

import { Box } from "@mui/material"

import ToolButton from "../modules/Clinical/components/ToolBar/toolButton"

import quickPlan from "./images/QuickPlan.svg"
import simpleMod from "./images/SimpleMod.svg"
import weSetup from "./images/WeSetup.svg"
import weStaging from "./images/WeStaging.svg"
import weFeatures from "./images/WeFeatures.svg"

const WeSmileRightBar = (props) => {
  const buttons = [
    {
      src: quickPlan,
      disabledSrc: quickPlan,
      key: "QuickPlan",
      title: "Quick Plan",
    },
    {
      src: simpleMod,
      disabledSrc: simpleMod,
      key: "SimpleMod",
      title: "Simple Mod",
    },
    {
      src: weSetup,
      disabledSrc: weSetup,
      key: "WeSetup",
      title: "We Setup",
    },
    {
      src: weStaging,
      disabledSrc: weStaging,
      key: "WeStaging",
      title: "We Staging",
    },
    {
      src: weFeatures,
      disabledSrc: weFeatures,
      key: "WeFeatures",
      title: "We Features",
    },
  ]
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const { t } = useTranslation("common")
  const [curBtn, setCurBtn] = useState("none")
  const rightClick = (item: string) => {
    if (curBtn == item) {
      setCurBtn("none")
      props.openPanel("none")
    } else {
      setCurBtn(item)
      props.openPanel(item)
    }
  }

  return weSmileUI ? (
    <Box sx={{ height: "200%" }}>
      {buttons.map((i) => {
        return (
          <ToolButton
            disabledBg={i.disabledSrc}
            placement={"left"}
            title={i.title}
            key={i.key}
            val={i.key}
            bg={i.src}
            isSelected={i.key == curBtn}
            isFocused={i.key === "vc"}
            callBack={() => {
              rightClick(i.key)
            }}
          />
        )
      })}
    </Box>
  ) : (
    <></>
  )
}
export default WeSmileRightBar
