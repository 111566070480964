import React, { FC, useEffect, useState } from "react"

import front from "../assets/images/clinical/front.png"

import {
  UBox,
  UButton,
  UFileProcessingCard,
  UImagePreview,
  UModalPopup,
  USelect,
  UText,
  UTextField,
} from "./components"
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  styled,
} from "./mui.components"
import { UsmileDarkThemeProvider } from "."

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const USmileUIcomponents: FC = () => {
  const [imageFiles, setFiles] = useState<File[]>([])
  const [showModal, setShowModal] = useState(false)

  const myAsyncFunction = async () => {
    const response = await fetch(front)
    const blob = await response.blob()

    setFiles([
      ...imageFiles,
      new File([blob], "image.png", { type: "image/png" }),
    ])
  }

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <Item>
            <UText variant={"h5"}>{"UI Elements"}</UText>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <UButton
              sxProp={{ mt: 3, width: "100%" }}
              variant={"contained"}
              btnType={"submit"}
              btnText={"Login"}
            />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <USelect
                id={"select-name"}
                labelId={"demo-simple-select-label"}
                label={"Select"}
                defaultValue={""}
                options={[
                  { key: "1", value: "COPA" },
                  { key: "2", value: "uSmile" },
                ]}
              />
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <UTextField
              fieldType={"text"}
              inputLabel={"name"}
              fieldName={"name"}
              value={""}
            />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>{"New compoenent"}</Item>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        sx={{ m: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 6 }}
      >
        <Grid item md={12}>
          <Item>
            <UText variant={"h5"}>{"Box"}</UText>
          </Item>
        </Grid>
        <Grid item md={12}>
          <UBox component={"div"}>
            <Box sx={{ mb: 3 }}>{"Box Wrapper"}</Box>
          </UBox>
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={1}
        sx={{ m: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 6 }}
      >
        <Grid item md={12}>
          <Item>
            <UText variant={"h5"}>{"File "}</UText>
          </Item>
        </Grid>
        <Grid item md={6}>
          <Item>
            <UFileProcessingCard
              fileName={
                "MC269_lprofile_occlusion_uMC269_lprofile_occlusion_u.png"
              }
              fileSize={100}
              progressValue={30}
              progressstatus={"complete"}
              onRemove={() => {
                console.log(".sdsdsdsd")
              }}
            />
          </Item>
        </Grid>
      </Grid>
      <Grid container gap={20}>
        {imageFiles.length === 0 ? (
          <UButton
            onClickHandler={myAsyncFunction}
            variant="contained"
            btnText="Upload Image"
            sxProp={{ mb: 3 }}
          ></UButton>
        ) : (
          <>
            <UImagePreview
              files={imageFiles.map((file) => {
                return {
                  src: URL.createObjectURL(file),
                  name: file.name,
                }
              })}
              deleteImage={() => setFiles([])}
              imageHeight={400}
              imageWidth={400}
            ></UImagePreview>

            <UImagePreview
              files={[...imageFiles, ...imageFiles, ...imageFiles].map(
                (file) => {
                  return {
                    src: URL.createObjectURL(file),
                    name: file.name,
                  }
                },
              )}
              sxImageProp={{ border: "1px solid rgba(0, 0, 0, 0.40)" }}
              imageHeight={120}
              imageWidth={120}
            ></UImagePreview>
          </>
        )}
      </Grid>
      <Grid>
        <UButton
          btnText="Open Modal"
          variant={"outlined"}
          onClickHandler={() => setShowModal(true)}
        ></UButton>
        <UModalPopup
          sxModalProps={{
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
              height: "110px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          title={<UText variant="h6">Test</UText>}
          isAlert={showModal}
          content={"Hello world"}
          btnList={[
            <UButton
              key={"ok"}
              btnText={"ok"}
              variant={"contained"}
              onClickHandler={() => setShowModal(false)}
            ></UButton>,
          ]}
        ></UModalPopup>
      </Grid>
    </Box>
  )
}

export default USmileUIcomponents
