import React, { Dispatch, FC, SetStateAction } from "react"

import { UTextField } from "../../../../../components"
import { Box } from "../../../../../components/mui.components"

const USearch: FC<{
  search: string
  updateSearch: (search: string) => void
}> = ({ search, updateSearch }) => {
  return (
    <Box component={"div"} sx={{ width: "auto" }}>
      <UTextField
        fieldType={"text"}
        fieldName={"search"}
        inputLabel={"search"}
        value={search}
        onInputchange={(e) => updateSearch(e.target.value)}
      />
    </Box>
  )
}

export default USearch
