import * as React from "react"
import { useEffect } from "react"
import { Stack, Grid, Typography, Divider } from "@mui/material"
import { FlowButton, FlowAccordion } from "../../Custom/FlowCustom"
import { Radio, RadioGroup } from "@mui/material"
import { FormControl, FormControlLabel, FormLabel } from "@mui/material"
import { useState } from "react"

const QuickRx = (props) => {
  const RxSetup = (props) => {
    const [setupValue, setSetupValue] = useState("")
    const handleSetupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSetupValue((event.target as HTMLInputElement).value)
    }
    useEffect(() => {
      console.log("Start QuickRx")
      return () => {
        console.log("End QuickRx")
      }
    }, [])
    return (
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={setupValue}
          onChange={handleSetupChange}
        >
          <FormControlLabel
            value="current"
            control={<Radio />}
            label="Current"
          />
          <FormControlLabel
            value="uAssist"
            control={<Radio />}
            label="uAssist Protocol"
          />
          <FormControlLabel
            value="preferance"
            control={<Radio />}
            label="My Preferance"
          />
          <FlowButton> Add Comment ...</FlowButton>
        </RadioGroup>
      </FormControl>
    )
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography>Quick Rx (Simple Prescription)</Typography>
      <RxSetup />
      <FlowButton>Submit </FlowButton>
      <Divider />
      <Typography>Smart Rx (Full Prescription) </Typography>
      <FlowButton>Start ...</FlowButton>
      <Divider />
    </Stack>
  )
}
export default QuickRx
