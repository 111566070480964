import { FC, useEffect } from "react"
import { Box, Button } from "@mui/material"
import { pdf, PDFViewer } from "@react-pdf/renderer"
import html2canvas from "html2canvas"

import Auxilliaries from "../Report/components/Auxilliaries/auxilliaries"
import IPR from "../Report/components/IPR/ipr"
import { reportProps } from "../Report/type"

import ReportPDF from "./reportPdf"

import "../Report/report.scss"

export const ScreeshotReport: FC<reportProps> = ({
  reportShow,
  setReportShow,
  toothlist,
  attachment,
  iprData,
}) => {
  return (
    reportShow && (
      <Box
        sx={{
          position: "fixed",
          zIndex: -6,
        }}
      >
        <Box
          width={710}
          height={1080}
          sx={{
            backgroundColor: "#ECEFF1",
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
            id="auxilliaries"
          >
            <Auxilliaries toothlist={toothlist} attachment={attachment} />
          </div>

          <div
            style={{
              position: "relative",
            }}
            id="auxilliaries"
          >
            <Auxilliaries toothlist={toothlist} attachment={attachment} />
          </div>

          <div
            style={{
              position: "relative",
              width: 710,
              height: 663,
              borderRadius: 4,
              padding: "0 16px",
            }}
            id="ipr"
          >
            <IPR toothlist={toothlist} iprData={iprData} />
          </div>
        </Box>
      </Box>
    )
  )
}
