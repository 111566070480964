import { FC, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { caseManagement, viewEdit } from "@ulab/wesmileclinical"

import WasmCanves from "../../../components/WasmView/wasmCanves"
import {
  clinicalRequestBasicType,
  getZipsType,
} from "../../../core/app/slices/clinical/clinical.types"
import {
  fetchFileList,
  fetchFileZips,
} from "../../../core/app/slices/clinical/clinicalThunkApi"
import { UsmileLightThemeProvider } from "../../../ui-component"
import Summary from "../../Uassist/USmartForms/Summary"
import Boarding from "../components/Boarding/boarding"
import BoltonPanel from "../components/BoltonPanel/boltonPanel"
import Fill from "../components/Fill/fill"
import Header from "../components/Header/header"
import Movement from "../components/Movement/movement"
import NotReady from "../components/NotReady/notReady"
import Overlay from "../components/Overlay/overlay"
import Report from "../components/Report/report"
import RightPanel from "../components/RightPanel/rightPanel"
import SideBar from "../components/SideBar/sideBar"
import Toggle from "../components/Toggle/toggle"
import ToothInfo from "../components/ToothInfo/toothInfo"
import Transition from "../components/Transition/transition"
const {
  setcalsetupfinishCallback,
  getBoltonReport,
  getAttachmentIPRReport,
  getHasArchType,
} = caseManagement
const { switchViewToEdit } = viewEdit

import { Backdrop, LinearProgress } from "@mui/material"
import { RootState } from "../../../core/app/store"
import { useAppSelector, useAppDispatch } from "../../../core/app/hooks"
import { tryPresetup } from "../../../wasm3d/CommonPages/UFlowPresetup"
import { setRunPresetup } from "../../../wasm3d/Wasm3DServiceSlice"
import { sagemakerModule } from "@ulab/wesmileclinical/src/core/modules/sagemaker/sagemakerModule"
import WeSmileRightBar from "../../../wasm3d/WeSmileRightBar"
import WeSmileRightPanel from "../../../wasm3d/WeSmileRightPanel"
import ManuStagingPercent from "../../../wasm3d/CommonPages/ManuStagingPercent"
import { UFlowPresetup } from "../../../wasm3d/CommonPages/UFlowPresetup"
import { string } from "prop-types"

const Treat: FC = () => {
  const dispatch = useAppDispatch()
  const { fileListReady, zipList, zipNames } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const navigate = useNavigate()
  const headRef = useRef()
  // Includes download zip and loading model completion
  const [preparation, setPreparation] = useState<boolean>(false)
  // bolton show or hidden
  const [boltonPanelShow, setBoltonPanelShow] = useState<boolean>(false)
  // bolton data
  const [boltonData, setBoltonData] = useState(null)
  // Report show or hidden
  const [reportShow, setReportShow] = useState<boolean>(false)
  // report data
  const [reportData, setReportData] = useState({
    attachment: {},
    toothlist: [],
    iprData: {},
  })
  const [openMovement, setopenMovement] = useState(false)
  const [openFill, setopenFill] = useState()
  // overlay off or on
  const [openOverlay, setOpenOverlay] = useState<boolean>(false)
  const { caseId, patientId } = useParams()

  const [boardingStep, setboardingStep] = useState<number>(0)
  const [openBoarding, setopenBoarding] = useState(true)
  const [weSmileRightName, setWeSmileRightName] = useState("none")

  // Availability of upper and lower jaw
  const [isHasUpAndlow, setIsHasUpAndLow] = useState<boolean>(false)

  // Simulates a state value to represent whether the case is ready or not.
  const [isCaseReady, setIsCaseReady] = useState<boolean>(
    window.location.href.includes("isReady") ? false : true,
  )
  // Maxillary and mandibular stage data and current maxillary and mandibular stage index values
  const [stageObj, setStageObj] = useState({
    upList: [],
    downList: [],
    up_current: 0,
    down_current: 0,
  })

  const [isShowSummary, setIsShowSummary] = useState<boolean>(false)
  // change tooth size button to unselected
  const closeToothSize = () => {
    headRef?.current.closeToothSizeByHead()
  }
  useEffect(() => {
    if (!isCaseReady) {
      setPreparation(true)
    }

    if (!isCaseReady) return

    setIsLoading(false)
    dispatch(
      fetchFileList({
        orgId: "",
        patientId,
        caseId,
      }),
    )
    // 加载完模型的回调 Callback after loading the model

    setcalsetupfinishCallback(() => {
      // close loading
      setPreparation(true)
      // get bolton data
      const bolton = getBoltonReport() || null
      setBoltonData(bolton)
      //The test phase is set to editable mode by default 测试阶段默认可编辑状态
      switchViewToEdit(true)
      // get report data
      const { attachment, toothlist, ipr } = getAttachmentIPRReport()
      const { lowlist, uplist } = toothlist
      const list = lowlist.concat(uplist)
      setReportData({
        attachment,
        toothlist: list,
        iprData: ipr,
      })
      // get archType

      getHasArchType().then(
        ({
          hasUpperArch,
          hasLowerArch,
        }: {
          hasUpperArch: boolean
          hasLowerArch: boolean
        }) => {
          setIsHasUpAndLow(hasUpperArch && hasLowerArch)
        },
      )

      caseManagement.setBackgroundColor(36 / 255, 46 / 255, 51 / 255)
      sagemakerModule.logMsgCallback = (msg: string) => {
        setLoadingMsg(msg)
      }
    })
    return () => {
      setPreparation(false)
    }
  }, [isCaseReady])

  useEffect(() => {
    if (zipNames.length) {
      dispatch(
        fetchFileZips({
          orgId: "",
          patientId,
          caseId,
          zipNames,
        }),
      )
    } else if (fileListReady) {
      // zero files, so it's empty case
      navigate(-1)
    }
  }, [zipNames])

  useEffect(() => {
    if (Object.keys(zipList).length > 0) {
      caseManagement.openCase(
        document.getElementById("canvas") as HTMLCanvasElement,
        zipList,
      )
    }
  }, [zipList])

  useEffect(() => {
    if (!openBoarding) {
      setboardingStep(-1)
    }
  }, [openBoarding])

  const { runPresetup } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )

  const [isLoading, setIsLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState("")

  useEffect(() => {
    if (runPresetup == "start") {
      setIsLoading(true)
      setLoadingMsg("Close current case.")
      caseManagement.closeCase()
      setLoadingMsg("Run presetup.")
      tryPresetup(() => {
        dispatch(setRunPresetup(""))
        setIsLoading(false)
        setLoadingMsg("Done!")
      })
    }
  }, [runPresetup])

  return (
    <Box
      className="treatMain"
      id="treatMain"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
      }}
    >
      {!preparation && <Transition />}
      {preparation && openBoarding && (
        <Boarding
          step={boardingStep}
          setstep={setboardingStep}
          open={openBoarding}
          setOpen={setopenBoarding}
        />
      )}
      <Header
        ref={headRef}
        preparation={preparation}
        setBoltonPanelShow={setBoltonPanelShow}
        boltonPanelShow={boltonPanelShow}
        setOpenOverlay={setOpenOverlay}
        openOverlay={openOverlay}
        boardingStep={boardingStep}
        isHasUpAndlow={isHasUpAndlow}
        isReady={isCaseReady}
      />
      <Box
        component={"div"}
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SideBar
          id={"left"}
          openFill={setopenFill}
          boardingStep={boardingStep}
          showSummary={setIsShowSummary}
          isShowSummary={isShowSummary}
        />
        <Box
          id="center"
          component={"div"}
          sx={{
            flex: "auto",
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            width: `1%`,
          }}
        >
          <Box
            component={"div"}
            sx={{
              flexGrow: "1",
              position: "relative",
              backgroundColor: "#455A64",
            }}
          >
            <WasmCanves
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "0",
                top: "0",
              }}
            />

            <NotReady isReady={isCaseReady} />
            {isShowSummary && (
              <UsmileLightThemeProvider>
                <Box
                  component={"div"}
                  sx={{
                    width: "640px",
                    position: "absolute",
                    top: "35px",
                    left: "16px",
                    height: "calc(100% - 80px)",
                    overflowY: "scroll",
                    zIndex: "1000",
                    borderRadius: "16px",
                    "&::-webkit-scrollbar": {
                      width: 0,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Summary
                    isPreview={true}
                    hideSummary={() => setIsShowSummary(false)}
                  />
                </Box>
              </UsmileLightThemeProvider>
            )}
            <WeSmileRightPanel panelName={weSmileRightName} />
            <ManuStagingPercent />
          </Box>
          <Toggle
            setReportShow={setReportShow}
            reportShow={reportShow}
            setopenMovement={setopenMovement}
            openMovement={openMovement}
            isReady={isCaseReady}
            setStageObj={setStageObj}
          />
        </Box>
        <Box
          id="right"
          component={"div"}
          sx={{
            width: "49px",
            height: "100%",
            background: "#37474F",
            borderLeft: "1px solid #455A64",
            borderTop: "1px solid #455A64",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            border:
              boardingStep === 1 ? "1px solid #0288D1" : "1px solid #455A64",
            zIndex: boardingStep === 1 && 2000,
          }}
        >
          <RightPanel
            isReady={isCaseReady}
            openPanel={(panelName: string) => {}}
          />
          <WeSmileRightBar
            openPanel={(panelName: string) => {
              setWeSmileRightName(panelName)
            }}
          />
        </Box>
      </Box>
      <BoltonPanel
        data={boltonData}
        setBoltonPanelShow={setBoltonPanelShow}
        boltonPanelShow={boltonPanelShow}
        closeToothSize={closeToothSize}
      />
      <Report
        reportShow={reportShow}
        toothlist={reportData.toothlist}
        attachment={reportData.attachment}
        setReportShow={setReportShow}
        iprData={reportData.iprData}
      />
      <Movement open={openMovement} setOpen={setopenMovement}></Movement>
      <ToothInfo stageObj={stageObj} />
      <Fill open={openFill} setOpen={setopenFill} />
      <Overlay preparation={preparation} openOverlay={openOverlay} />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <Box sx={{ width: "40%" }}>
          {loadingMsg}
          <LinearProgress />
        </Box>
      </Backdrop>
    </Box>
  )
}

export default Treat
