import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import { UText } from "../../../components"
import { Box, Button, Grid } from "../../../components/mui.components"
import { useAppDispatch } from "../../../core/app/hooks"
import { saveUassistFormData } from "../../../core/app/slices/uassist/formThunkApi"
import { uassistAddform } from "../../../core/app/slices/uassistForm/uassistFormThunkApi"
import JsonFormBuilder from "../../../rx-form/builder/JsonFormBuilder"
import {
  defaultData,
  jsonSchemaDefault,
  uiSchemaDefault,
} from "../../../rx-form/Json/openbiteForm"

const OpenBite: FC<{
  isSettings?: boolean
  isResetData?: boolean
  setIsResetData?: (val: boolean) => void
  isSaveData?: boolean
  setIsSaveData?: (val: boolean) => void
}> = ({
  isSettings,
  isResetData,
  setIsResetData,
  isSaveData,
  setIsSaveData,
}) => {
  const { t } = useTranslation("common")
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [data, setData] = useState(defaultData)
  const [error, setError] = useState([])
  const { caseId, patientId } = useParams()

  useEffect(() => {
    if (!isSaveData) return
    setIsFormSubmitted(true)
    setIsSaveData && setIsSaveData(false)
  }, [isSaveData])

  useEffect(() => {
    setData(defaultData)
    setIsResetData && setIsResetData(false)
  }, [isResetData])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    if (error.length) {
      return
    } else if (isFormSubmitted) {
      dispatch(
        saveUassistFormData({
          formId: "19A43BAC-CB3F-4C4B-9F52-9B4F4B4A1094",
          payload: data,
        }),
      )
    }
  }, [error, isFormSubmitted])

  const schema = JSON.parse(jsonSchemaDefault)
  // eslint-disable-next-line no-prototype-builtins
  if (schema.properties.hasOwnProperty("case_specific_instructions")) {
    delete schema.properties.case_specific_instructions
  }

  const uiSchema = JSON.parse(uiSchemaDefault)

  uiSchema.elements = uiSchema.elements.filter(
    (element) => element.label !== "Additional Instructions",
  )

  const submitData = () => {
    setIsFormSubmitted(true)
  }

  const handleDataChange = (data: any, errors: any) => {
    setData(data)
    const currError = errors[0]
    const index = error.findIndex(
      (err) => err.instancePath === currError.instancePath,
    )
    if (index > -1 && currError.message === "") {
      const newErrors = error.filter((err, i) => index !== i)
      setError([...newErrors])
    } else if (index === -1 && currError.message !== "") {
      setError([...error, currError])
    }
  }

  return (
    <Grid
      container
      flexDirection={"column"}
      sx={{
        backgroundColor: "background.default",
        borderRadius: 2,
        padding: 5,
        justifyContent: "space-between",
        width: "640px",
      }}
    >
      <UText variant={"h4"} component={"div"} sxProp={{ textAlign: "center" }}>
        {t("uassist.uAssistForm.openBite")}
      </UText>
      <UText
        variant={"body2"}
        sxProp={{
          mt: isSettings ? 1 : 0,
          mb: 3,
          textAlign: "center",
          color: "rgb(0,0,0,0.6)",
        }}
      >
        {isSettings
          ? t("uassist.uAssistForm.openbiteSettingsDescription")
          : t("uassist.uAssistForm.formInfo")}
      </UText>
      <JsonFormBuilder
        schema={isSettings ? JSON.parse(jsonSchemaDefault) : schema}
        uischema={isSettings ? JSON.parse(uiSchemaDefault) : uiSchema}
        defaultData={data}
        handleChange={handleDataChange}
        isFormSubmitted={isFormSubmitted}
        setIsFormSubmitted={setIsFormSubmitted}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(
              `/uassist/patient/${patientId}/case/${caseId}/prescription`,
            )
          }}
          sx={{ width: "96px", height: "36px" }}
        >
          <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
          {t("button.back")}
        </Button>
        <Button
          variant="contained"
          onClick={submitData}
          sx={{ width: "180px", height: "36px" }}
        >
          {t("button.saveCase")}
        </Button>
      </Box>
    </Grid>
  )
}

export default OpenBite
