import * as React from "react"
import { useEffect, Fragment } from "react"
import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

import { Box, Stack, Grid, Typography, Divider } from "@mui/material"
import { Dialog, DialogContent, DialogContentText } from "@mui/material"
import { FormControlLabel, Checkbox, Slider } from "@mui/material"

import {
  FlowButton,
  FlowNameSliderGrid,
  FlowAccordion,
  FlowBtnGroup,
} from "../../Custom/FlowCustom"

import { gdsPlusSetup, SetupType, OJOBType } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"

const SimpleSetup = (props) => {
  const {
    anterior,
    archform,
    disabled,
    rootTorque,
    toothIPRSpace,
    selectedTooth,
  } = useAppSelector((state: RootState) => state.autoSetupSlice)
  const { OJ, OB, LL, MidlineLower, MidlineUpper } = anterior
  const { ArchformAnt, ArchformMid, ArchformPost } = archform
  const simpleSliders = [ArchformMid, OJ, OB, LL, MidlineLower, MidlineUpper]
  const toothIprSpaceSlidersPrev = [
    toothIPRSpace.IPRToPrev,
    toothIPRSpace.SpaceToPrev,
  ]
  const toothIprSpaceSlidersNext = [
    toothIPRSpace.IPRToNext,
    toothIPRSpace.SpaceToNext,
  ]
  const { isShow, data, toothId } = useAppSelector(
    (state: RootState) => state.toothInfoSlice,
  ) // tooth info data
  const dispatch = useAppDispatch()
  const [openDlg, setOpenDlg] = React.useState(false)
  const [dlgMsg, setDlsMsg] = React.useState("")
  const closeDlg = () => {
    setOpenDlg(false)
  }

  const newByType = (type: SetupType) => {
    setDlsMsg("Please wait, auto setup is running...")
    setOpenDlg(true)
    const timer = setTimeout(() => {
      gdsPlusSetup.setupByType(type, () => {
        setOpenDlg(false)
        console.log("Auto setup the case ...")
        const payload = false
        dispatch({ type: "autosetup/setDisable", payload })
        dispatch({ type: "autosetup/updateState" })
        dispatch({ type: "autostaging/updateState" })
      })
    }, 100) // wait 100ms so the dialog is shown.
  }

  const runResolveOccColl = () => {
    setDlsMsg("Please wait, removing heavy contact...")
    setOpenDlg(true)
    const timer = setTimeout(() => {
      gdsPlusSetup.ResolveOccColl(true, false, () => {
        setOpenDlg(false)
        console.log("ResolveOccColl finished!")
        const payload = false
        dispatch({ type: "autosetup/setDisable", payload })
        dispatch({ type: "autosetup/updateState" })
        dispatch({ type: "autostaging/updateState" })
      })
    }, 100) // wait 100ms so the dialog is shown.
  }

  const extractRestoreTooth = (extract) => {
    setDlsMsg("Please wait, redo setup ...")
    setOpenDlg(true)
    const timer = setTimeout(() => {
      if (extract) gdsPlusSetup.ExtractTooth()
      else gdsPlusSetup.RestoreAllTeeth()
      setOpenDlg(false)
      const payload = false
      dispatch({ type: "autosetup/setDisable", payload })
      dispatch({ type: "autosetup/updateState" })
      dispatch({ type: "autostaging/updateState" })
    }, 100) // wait 100ms so the dialog is shown.
  }

  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "Ant00":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.Improve)
        break
      case "Ant05":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.HalfMmSpace)
        break
      case "AutoAP":
        gdsPlusSetup.getAutoSetupModule().SetBestAP()
        break
      case "CloseSpace":
        gdsPlusSetup.ResolveAllColl(() => {
          console.log("ResolveAllColl finished!")
        })
        break
      case "RmvContact":
        runResolveOccColl()
        break
      case "OptimizeOcc":
        gdsPlusSetup.getAutoSetupModule().SetOJOBByTypeID(OJOBType.HalfMmSpace)
        gdsPlusSetup.ResolveAllColl(() => {
          console.log("ResolveAllColl finished!")
        })
        runResolveOccColl()
        break
      case "ExtractTooth":
        extractRestoreTooth(true)
        break
      case "RestoreAllTeeth":
        extractRestoreTooth(false)
        break
      case "Full":
        newByType(SetupType.Full)
        break
      case "Simple":
        newByType(SetupType.Simple)
        break
      case "Anterior":
        newByType(SetupType.Ant3x3)
        break
      case "Lite":
        newByType(SetupType.COPAModerate)
        break
      case "FineTune":
        newByType(SetupType.FineTune)
        break
      case "OJImprove":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.Improve, () => {})
        break
      case "OJHalfMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.HalfMmSpace, () => {})
        break
      case "OJOneMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.OneMmSpace, () => {})
        break
    }
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  useEffect(() => {
    console.log("selected tooth changed :")
    dispatch({ type: "autosetup/updateSelTooth", payload: toothId })
    dispatch({ type: "autosetup/updateState" })
  }, [isShow, toothId, data])

  useEffect(() => {
    console.log("Start SimpleSetup")
    gdsPlusSetup.SwitchAutoSetupModule(true)
    return () => {
      //console.log("End SimpleSetup")
      //gdsPlusSetup.SwitchAutoSetupModule(false)
      //const payload = true
      //dispatch({ type: "autosetup/setDisable", payload })
    }
  }, [])

  const onSliderChange = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    const payload = { variable: variable, value: value }
    let type = "autosetup/setAnterior"
    if (variable.includes("Archform")) type = "autosetup/setArchform"
    else if (variable.includes("AntIncl")) type = "autosetup/setRootTorque"
    else if (variable.includes("IPRTo") || variable.includes("SpaceTo"))
      type = "autosetup/setToothIPRSpace"
    dispatch({
      type: type,
      payload: payload,
    })
  }
  const onSliderChangeCommitted = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamDouble(variable, value)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  const extractionBtns = [
    { label: "Extract", name: "ExtractTooth" },
    { label: "Restore All", name: "RestoreAllTeeth" },
  ]

  const onSelToothFixedChange = () => {
    let newFixed = !selectedTooth.isFixed
    gdsPlusSetup.SetToothFixed(selectedTooth.ID, newFixed)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
    dispatch({ type: "autosetup/updateSelTooth", payload: selectedTooth.ID })
  }
  const onSelIPRSpaceFixedChange = (isToNext) => {
    let oldFixed = isToNext
      ? selectedTooth.isFixedIprSpaceNext
      : selectedTooth.isFixedIprSpacePrev
    gdsPlusSetup.SetToothIPREnable(!oldFixed, isToNext)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
    dispatch({ type: "autosetup/updateSelTooth", payload: selectedTooth.ID })
  }

  const SideIPRSpaceFixed = (props) => {
    return (
      <Grid container spacing={0.5} alignItems="center">
        <Grid item xs={6}>
          {props.isToNext ? "IPR/Space: To Next" : "IPR/Space: To Prev"}
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  props.isToNext
                    ? selectedTooth.isFixedIprSpaceNext
                    : selectedTooth.isFixedIprSpacePrev
                }
                onChange={() => {
                  onSelIPRSpaceFixedChange(props.isToNext)
                }}
                size="small"
              />
            }
            label="Fixe IPR/Space"
          />
        </Grid>
      </Grid>
    )
  }

  const SimpleSelTooth = () => {
    let labelWithId = "Sel. Tooth: "
    if (isShow) labelWithId = labelWithId + selectedTooth.ID

    return (
      <Fragment>
        <Grid container spacing={0.5} alignItems="center">
          <Grid item xs={4}>
            {labelWithId}
          </Grid>
          {isShow ? (
            <Grid item xs={8}>
              <FormControlLabel
                disabled={disabled || selectedTooth.ID <= 0}
                control={
                  <Checkbox
                    checked={selectedTooth.isFixed}
                    onChange={onSelToothFixedChange}
                    size="small"
                  />
                }
                label="Fixed(Not Move)"
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Fragment>
    )
  }

  const SimpleSetupType = () => {
    return (
      <Grid container spacing={0.5} alignItems="center">
        <Grid item xs={12}>
          <FlowButton
            onClick={() => {
              onButtonClick("FineTune")
            }}
          >
            Modify Current Setup
          </FlowButton>
        </Grid>
      </Grid>
    )
  }

  const archformBtns = [{ label: "Close space", name: "CloseSpace" }]

  const antBtns = [
    { label: "Ant 0mm", name: "OJImprove" },
    { label: "Ant 0.5mm", name: "OJHalfMm" },
    { label: "Ant 1.0mm", name: "OJOneMm" },
  ]
  return (
    <Stack direction="column" spacing={1}>
      <SimpleSetupType />
      <Divider />
      {!disabled ? (
        <Fragment>
          <FlowAccordion label="Tooth">
            <SimpleSelTooth />
            {isShow ? (
              <Fragment>
                <SideIPRSpaceFixed isToNext={false} />
                <FlowNameSliderGrid
                  params={toothIprSpaceSlidersPrev}
                  onChangeFunc={onSliderChange}
                  onChangeCommittedFunc={onSliderChangeCommitted}
                  disabled={disabled}
                />
                <SideIPRSpaceFixed isToNext={true} />
                <FlowNameSliderGrid
                  params={toothIprSpaceSlidersNext}
                  onChangeFunc={onSliderChange}
                  onChangeCommittedFunc={onSliderChangeCommitted}
                  disabled={disabled}
                />
              </Fragment>
            ) : (
              <></>
            )}
          </FlowAccordion>
          <FlowBtnGroup
            disabled={disabled}
            btnGroup={antBtns}
            onButtonClick={onButtonClick}
          />
          <FlowNameSliderGrid
            params={simpleSliders}
            onChangeFunc={onSliderChange}
            onChangeCommittedFunc={onSliderChangeCommitted}
            disabled={disabled}
          />
          <Divider />
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FlowButton
                variant="contained"
                disabled={disabled}
                onClick={() => {
                  onButtonClick("OptimizeOcc")
                }}
              >
                Optimize Occ Contact{" "}
              </FlowButton>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <></>
      )}

      <Dialog open={openDlg} onClose={closeDlg}>
        <DialogContent>
          <Box
            component="img"
            sx={{ height: 120 }}
            alt="Running ..."
            // src={require("@/assets/images/timer10s.gif")}
          />
          <DialogContentText id="alert-dialog-description">
            {dlgMsg}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Stack>
  )
}

export default SimpleSetup
