import { FC, useState } from "react"
import { ReactSVG } from "react-svg"
import { Button, SxProps, ToggleButton } from "@mui/material"

/**
 * svg按钮：选中时为蓝底色，可取消选中
 * @param svg svg对象
 * @param clickFun 点击事件
 * @param isdisable true/false
 * @param boxSx svg父级样式
 * @param svgSx svg样式
 * @param svgColor svg的path fill
 * @param isSelected 是否选中
 * @returns component SvgToggleBtn
 */
const SvgToggleBtn: FC<{
  svg: string
  clickFun: (arg?: unknown) => void
  isdisable?: boolean
  boxSx?: SxProps
  svgSx?: object
  svgColor?: string
  value: string
  isSelected: boolean
}> = ({
  svg,
  clickFun,
  isdisable = false,
  boxSx,
  svgSx,
  svgColor,
  value,
  isSelected,
}) => {
  return (
    <Button
      sx={{
        width: 48,
        height: 48,
        backgroundColor: isSelected ? (theme) => theme.palette.info.dark : "",
        minWidth: 0,
        border: "none",
        "&:hover": {
          backgroundColor: isSelected ? (theme) => theme.palette.info.dark : "",
        },
        ...boxSx,
      }}
      value={value}
      disabled={isdisable}
      aria-label={value}
      onClick={clickFun}
    >
      <ReactSVG
        src={svg}
        style={{
          marginTop: 5,
          cursor: "pointer",
          ...svgSx,
        }}
        afterInjection={(svg) => {
          const path = svg.getElementsByTagName("path")[0]
          path?.setAttribute("fill-opacity", "0.56")

          if (isdisable) {
            path?.setAttribute("fill-opacity", "0.38")
          }
          if (svgColor) {
            path?.setAttribute("fill", svgColor)
          }
        }}
      ></ReactSVG>
    </Button>
  )
}
export default SvgToggleBtn
