import { FC, ReactNode, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PersonIcon from "@mui/icons-material/Person"

import ToothOutline from "../../assets/svgs/tooth-outline.svg"
import ToothOutlineBlue from "../../assets/svgs/toothOutlineBlue.svg"
import {
  Box,
  Chip,
  Container,
  Grid,
  Paper,
} from "../../components/mui.components"
import { useAppDispatch } from "../../core/app/hooks"
import { UButton, UText } from "../../ui-component"
import UModalPopup from "../../ui-component/components/UModalPopup/UModalPopup"
import Crowding from "../Uassist/USmartForms/Crowding"
import DeepBite from "../Uassist/USmartForms/Deepbite"
import Limited from "../Uassist/USmartForms/LimitedTreatment"
import OpenBite from "../Uassist/USmartForms/Openbite"
import Spacing from "../Uassist/USmartForms/Spacing"

import ClinicalSettings from "./UserProfile/ClinicalSettings"
import UserProfile from "./UserProfile/UserProfile"

const SettingsModule: FC = () => {
  const { t } = useTranslation("common")
  const location = useLocation()
  const navigate = useNavigate()
  const smartRxForms = [
    { text: "CROWDING", path: "/crowding" },
    { text: "SPACING", path: "/spacing" },
    { text: "OPEN BITE", path: "/openbite" },
    { text: "DEEP BITE", path: "/deepbite" },
    { text: "LIMITED TREATMENT", path: "/limited" },
  ]

  // const preview = useRef<HTMLInputElement>(null)

  const [settingsPath, setSettingsPath] = useState<
    "userProfile" | "clinicalSettings" | "smartRxForms"
  >("userProfile")
  const [selectedSmartRxForm, setSelectedSmartRxForm] = useState<string>(
    location.pathname,
  )

  useEffect(() => {
    location.pathname.includes("userProfile")
      ? setSettingsPath("userProfile")
      : location.pathname.includes("clinicalSettings")
      ? setSettingsPath("clinicalSettings")
      : setSettingsPath("smartRxForms")
  }, [location])

  const [isSaveOpen, setIsSaveOpen] = useState(false)
  const [isResetOpen, setIsResetOpen] = useState(false)
  const [isResetData, setIsResetData] = useState(false)
  const [isSaveData, setIsSaveData] = useState(false)

  const TabButtons: FC<{
    text: string
    pathName: string
    startIcon: ReactNode
    endIcon?: ReactNode
  }> = ({ text, pathName, startIcon, endIcon }) => {
    return (
      <UButton
        btnText={text}
        variant="text"
        sxProp={{
          width: "100%",
          padding: "9px 16px",
          height: "42px",
          borderRadius: 0,
          justifyContent: "flex-start",
          backgroundColor:
            pathName.includes("smartRxForms") && settingsPath === "smartRxForms"
              ? "rgba(144, 202, 249, 0.16) !important"
              : "transparent",
          color: pathName.includes(settingsPath)
            ? "primary.main"
            : "text.secondary",
          borderLeft: `2px solid ${
            pathName.includes(settingsPath) ? "#215ECD" : "transparent"
          }`,
          ":hover": {
            backgroundColor: "transparent",
          },
          ".MuiButton-startIcon": {
            ml: 0,
            mr: "6px",
            width: 24,
            height: 24,
            svg: {
              fontSize: 24,
            },
          },
          ".MuiButton-endIcon": {
            display: "flex",
            ml: "auto",
          },
        }}
        onClickHandler={() => {
          if (!pathName.includes(settingsPath)) {
            setSelectedSmartRxForm("/crowding")
            navigate(`/settings/${pathName}`)
          }
        }}
        startIcon={startIcon}
        endIcon={endIcon}
      />
    )
  }

  const handleClick = (form) => {
    setSelectedSmartRxForm(form.path)
    navigate(`/settings/smartRxForms${form.path}`)
  }

  const dataHandler = () => {
    if (isResetOpen) {
      setIsResetData(true)
      setIsResetOpen(false)
    }
    if (isSaveOpen) {
      setIsSaveData(true)
      setIsSaveOpen(false)
    }
  }

  return (
    <>
      <Container
        component="main"
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "16px 0",
          padding: "0 !important",
        }}
      >
        <UText
          variant={"h4"}
          sxProp={{ pt: 2, pb: 2, position: "sticky", top: 0 }}
        >
          {t("settings.title")}
        </UText>
        <Grid container>
          <Box
            sx={{
              width: "250px",
              display: "flex",
              flexDirection: "column",
              marginRight: "26px",
              maxHeight: "336px",
              position: "sticky",
              top: "80px",
            }}
          >
            <TabButtons
              text={t("settings.userProfile.title")}
              pathName="userProfile"
              startIcon={<PersonIcon />}
            />
            <TabButtons
              text={t("settings.clinicalSettings.title")}
              pathName="clinicalSettings"
              startIcon={
                <img
                  src={
                    settingsPath === "clinicalSettings"
                      ? ToothOutlineBlue
                      : ToothOutline
                  }
                />
              }
            />
            <TabButtons
              text={t("settings.smartRxForms.title")}
              pathName="smartRxForms/crowding"
              startIcon={<ContentPasteOutlinedIcon />}
              endIcon={
                settingsPath === "smartRxForms" ? (
                  <ExpandLessIcon
                    sx={{
                      color: "#0000008F !important",
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{
                      color: "#0000008F !important",
                    }}
                  />
                )
              }
            />
            {settingsPath === "smartRxForms" && (
              <Grid
                container
                flexDirection={"column"}
                alignItems={"flex-start"}
                sx={{ ml: 6 }}
              >
                {smartRxForms.map((form, index) => {
                  return (
                    <UButton
                      btnText={form.text}
                      variant="text"
                      key={index}
                      disableRipple
                      sxProp={{
                        color: selectedSmartRxForm.includes(form.path)
                          ? "primary.main"
                          : "text.secondary",
                        ":hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClickHandler={() => handleClick(form)}
                    ></UButton>
                  )
                })}
              </Grid>
            )}
          </Box>
          <Box sx={{ marginRight: "22px" }}>
            <Paper
              elevation={0}
              sx={{
                my: { xs: 3, md: 6 },
                borderRadius: "16px",
                marginTop: "0px !important",
                margin: "0 auto",
                width: "max-content",
              }}
            >
              <Routes>
                <Route path={"userProfile"} element={<UserProfile />} />
                <Route
                  path={"clinicalSettings"}
                  element={<ClinicalSettings />}
                />
                <Route
                  path={"smartRxForms/crowding"}
                  element={
                    <Crowding
                      isSettings={true}
                      isResetData={isResetData}
                      setIsResetData={setIsResetData}
                      isSaveData={isSaveData}
                      setIsSaveData={setIsSaveData}
                    />
                  }
                />
                <Route
                  path={"smartRxForms/spacing"}
                  element={
                    <Spacing
                      isSettings={true}
                      isResetData={isResetData}
                      setIsResetData={setIsResetData}
                      isSaveData={isSaveData}
                      setIsSaveData={setIsSaveData}
                    />
                  }
                />
                <Route
                  path={"smartRxForms/openbite"}
                  element={
                    <OpenBite
                      isSettings={true}
                      isResetData={isResetData}
                      setIsResetData={setIsResetData}
                      isSaveData={isSaveData}
                      setIsSaveData={setIsSaveData}
                    />
                  }
                />
                <Route
                  path={"smartRxForms/deepbite"}
                  element={
                    <DeepBite
                      isSettings={true}
                      isResetData={isResetData}
                      setIsResetData={setIsResetData}
                      isSaveData={isSaveData}
                      setIsSaveData={setIsSaveData}
                    />
                  }
                />
                <Route
                  path={"smartRxForms/limited"}
                  element={
                    <Limited
                      isSettings={true}
                      isResetData={isResetData}
                      setIsResetData={setIsResetData}
                      isSaveData={isSaveData}
                      setIsSaveData={setIsSaveData}
                    />
                  }
                />
                <Route
                  path={"*"}
                  element={<Navigate to={"userProfile"} replace />}
                />
              </Routes>
            </Paper>
          </Box>
          {settingsPath === "userProfile" && (
            <Container
              sx={{
                width: "254px",
                padding: "0 32px",
                margin: 0,
                position: "sticky",
                top: "16px",
                maxHeight: "200px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <UText variant="h6">{t("settings.userProfile.status")}</UText>
                <Chip
                  label="Active"
                  sx={{
                    color: "success.main",
                    border: "1px solid",
                    borderColor: "success.main",
                    borderRadius: "100px",
                    backgroundColor: "transparent",
                    padding: "3px 4px",
                    width: "61px",
                    height: "24px",
                    marginLeft: 2,
                    span: {
                      padding: 0,
                    },
                  }}
                ></Chip>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
                <UText variant="subtitle2" color="text.secondary">
                  {t("settings.userProfile.creationDate")}
                </UText>
                <UText variant="subtitle2">Jan 23, 2023</UText>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
                <UText variant="subtitle2" color="text.secondary">
                  {t("settings.userProfile.lastUpdate")}
                </UText>
                <UText variant="subtitle2">May 11, 2023</UText>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
                <UText variant="subtitle2" color="text.secondary">
                  {t("settings.userProfile.lastLogin")}
                </UText>
                <UText variant="subtitle2">Jun 28, 2023</UText>
              </Box>
            </Container>
          )}
          {settingsPath === "smartRxForms" && (
            <Grid
              sx={{
                position: "sticky",
                top: "16px",
                maxHeight: "200px",
              }}
            >
              <UButton
                btnText="Save"
                variant="contained"
                sxProp={{ width: 69, height: 36 }}
                onClickHandler={() => setIsSaveOpen(true)}
              />
              <UButton
                btnText="Reset"
                variant="outlined"
                sxProp={{ ml: 1, width: 74, height: 36 }}
                onClickHandler={() => setIsResetOpen(true)}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <UModalPopup
        isAlert={isSaveOpen || isResetOpen}
        title={
          <UText
            variant={"h6"}
            sxProp={{
              width: "396px",
            }}
          >
            {isSaveOpen
              ? t("settings.saveModalTitle")
              : t("settings.resetModalTitle")}
          </UText>
        }
        sxModalProps={{
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
          ".MuiDialog-paper": {
            maxWidth: 444,
          },
        }}
        content={
          <UText variant={"body1"} sxProp={{ display: "block" }}>
            {isSaveOpen
              ? t("settings.saveModalDescription")
              : t("settings.resetModalDescription")}
          </UText>
        }
        btnList={[
          <UButton
            key={t("button.cancel")}
            variant="text"
            btnText={t("button.cancel")}
            sxProp={{
              width: 116,
              height: 36,
            }}
            onClickHandler={
              isSaveOpen
                ? () => setIsSaveOpen(false)
                : () => setIsResetOpen(false)
            }
          ></UButton>,
          <UButton
            key={t("button.save")}
            variant="contained"
            btnText={
              isSaveOpen
                ? t("button.save")
                : isResetOpen
                ? t("button.reset")
                : ""
            }
            sxProp={{
              width: 136,
              height: 36,
            }}
            onClickHandler={dataHandler}
          ></UButton>,
        ]}
      ></UModalPopup>
    </>
  )
}

export default SettingsModule
