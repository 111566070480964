import { FC, useEffect, useState } from "react"
import { DragDropContext, Draggable } from "react-beautiful-dnd"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router"
import ClearIcon from "@mui/icons-material/Clear"
import DeleteIcon from "@mui/icons-material/Delete"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { AxiosProgressEvent } from "axios"
import { v4 } from "uuid"

import addFile from "../../../assets/images/AddFilled.png"
import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  UModalPopup,
  UText,
} from "../../../components"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  updatePhotoPosition,
  uploadPhotographs,
} from "../../../core/app/slices/records/photograph"
import { PhotoObject } from "../../../core/app/slices/records/photograph/photograph.type"
import { removeIndividualPhotos } from "../../../core/app/slices/records/photograph/photographSlice"
import { deleteFiles } from "../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../core/app/store"
import { convertMBtoKB } from "../../../core/utils/formatters"
import AdditionalPhotograph from "../AdditionalPhotograph"

import StrictModeDroppable from "./StrictModeDroppable"

const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
const FILE_ALLOWED_SIZE = 10 //in MB
const FILE_ALLOWED_LIMIT = 30

export interface positionArray {
  label: string
  src: string | undefined
  position: string
  fileName: string | undefined
}

const UploadPhotographs: FC<{
  file: File
  onRemove: (f: any) => void
  sequence: number
  setIsDragOpen: (val: boolean) => void
  isDragOpen: boolean
}> = ({ file, setIsDragOpen, isDragOpen, onRemove, sequence }) => {
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressstatus, setProgresssStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")
  const [uploadError, setUploadError] = useState<string>()

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { t } = useTranslation("common")

  useEffect(() => {
    const uniqueId = v4()
    const newFile = new File(
      [file],
      `individual-${uniqueId}.${file.type.split("/")[1]}`,
      { type: file.type },
    )
    const formPayload = new FormData()
    formPayload.append("attachment", newFile)
    formPayload.append("original_file_name", file.name)
    formPayload.append("sequence", sequence.toString())
    dispatch(
      uploadPhotographs({
        patientId: patientId,
        caseId: caseId,
        formData: formPayload,
        fileName: `individual-${uniqueId}${file.name.substring(
          file.name.lastIndexOf("."),
        )}`,
        file: newFile,
        orgId: "",
        onFileProcesscallback: function (progress: AxiosProgressEvent): void {
          const { loaded, total } = progress
          setProgresssStatus("loading")

          const percentageProgress = Math.floor((loaded / total) * 100)
          setProgressValue(percentageProgress)
        },
      }),
    )
      .then((action) => {
        if (action.payload.status === 200) {
          setProgresssStatus("complete")
        } else {
          setProgresssStatus("failed")
          setUploadError(t("records.photograph.uploadError"))
        }
      })
      .catch((error) => {
        setProgresssStatus("failed")
      })
  }, [file])

  return (
    <>
      <Grid item sm={12}>
        <UFileProcessingCard
          fileName={file.name}
          fileSize={file.size}
          progressValue={progressValue}
          progressstatus={progressstatus}
          onRemove={() => onRemove(file)}
          filleErrorMessage={uploadError}
        />
      </Grid>
    </>
  )
}

const Individualphotograph: FC<{
  selectedFileSection: (str: "individual" | "both") => void
  isDragOpen: boolean
  setIsDragOpen: (value: boolean) => void
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  showUploadSection: string
}> = ({
  selectedFileSection,
  isDragOpen,
  setIsDragOpen,
  setIsDragEmpty,
  isAlertOpen,
  setIsAlertOpen,
  showUploadSection,
}) => {
  const addIcon: PhotoObject = {
    name: "Add",
    src: addFile,
    position: "",
    fileName: "",
  }

  const photosPositions = [
    {
      label: "Front",
      src: "",
      fileName: "",
      position: "front",
    },
    {
      label: "Profile",
      src: "",
      fileName: "",
      position: "profile",
    },
    {
      label: "Smile",
      src: "",
      fileName: "",
      position: "smile",
    },
    {
      label: "Upper Occlusal",
      src: "",
      fileName: "",
      position: "upperocclusal",
    },
    {
      label: "Right",
      src: "",
      fileName: "",
      position: "right",
    },
    {
      label: "Anterior",
      src: "",
      fileName: "",
      position: "anterior",
    },
    {
      label: "Left",
      src: "",
      fileName: "",
      position: "left",
    },
    {
      label: "Lower Occulusal",
      src: "",
      fileName: "",
      position: "lowerocculusal",
    },
  ]
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const { t } = useTranslation("common")
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [indexNumber, setIndexNumber] = useState<number | null>(null)
  const [photoIndex, setPhotoIndex] = useState<number | null>(null)
  const [imgArray, setImgArray] = useState<PhotoObject[]>([addIcon])
  const [positionArray, setPositionArray] =
    useState<positionArray[]>(photosPositions)
  const [step, setStep] = useState<number>(0)
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { individualPhotos } =
    useAppSelector((state: RootState) => state.PhotosService) || []

  useEffect(() => {
    const isEmptySrc = positionArray.filter((obj) => obj.src !== "")
    if (isEmptySrc.length === 0) {
      setIsDragEmpty(true)
    } else {
      setIsDragEmpty(false)
    }
  }, [imgArray])

  useEffect(() => {
    if (selectedFiles.length === 0 && individualPhotos.length === 0) {
      selectedFileSection("both")
    }
  }, [selectedFiles, individualPhotos])
  useEffect(() => {
    if (showUploadSection === "individual") {
      if (individualPhotos.length > 0 && selectedFiles.length === 0) {
        setIsDragOpen(true)
      }
      if (selectedFiles.length === individualPhotos.length) {
        console.log("inside drag if")
        setIsDragOpen(true)
      }
    }
  }, [showUploadSection, individualPhotos])

  useEffect(() => {
    if (isDragOpen) {
      setSelectedFiles([])
    }
  }, [isDragOpen])

  useEffect(() => {
    if (individualPhotos.length > 0) {
      selectedFileSection("individual")
    } else if (individualPhotos.length === 0) {
      setIsDragOpen(false)
      selectedFileSection("both")
    }
    setStep(0)
    individualPhotos.map((photo) => {
      if (photo.position !== "") {
        const index = positionArray.findIndex(
          (p) => p.position.toUpperCase() === photo.position,
        )
        positionArray[index].fileName = photo.fileName
        positionArray[index].src = photo.src
      }
    })
    setImgArray([
      ...individualPhotos.filter((photo) => photo.position === ""),
      addIcon,
    ])
  }, [individualPhotos])

  const selectedIndividualFile = (files: FileList): void => {
    selectedFileSection("individual")
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      setSelectedFiles((o) => [file, ...o])
    }
  }

  const handleOnDragEnd = (_result) => {
    scrollDown()
    if (
      !_result.destination ||
      _result.destination.droppableId === "verticalScroll"
    )
      return

    // Changing position on ImgArray
    const photoIndex = imgArray.findIndex(
      (photo) => photo.sequence === _result.draggableId,
    )
    const draggedItem = imgArray[photoIndex]
    const newArray = imgArray
      .slice(0, photoIndex)
      .concat(imgArray.slice(photoIndex + 1))
    setImgArray([...newArray])

    // Changing data on positionArray
    const positionIndex = positionArray.findIndex((p) => {
      return p.position === _result.destination.droppableId
    })
    const data = positionArray[positionIndex]
    data.src = draggedItem.src
    data.fileName = draggedItem.fileName

    //API call
    const payload = {
      [draggedItem.fileName]: {
        photo_position: data.position.toUpperCase(),
      },
    }
    dispatch(updatePhotoPosition({ patientId, caseId, payload })).then(
      (action) => {
        if (action.payload.data.status.toUpperCase() !== "SUCCESS") {
          data.src = ""
          data.fileName = ""
        }
      },
    )
  }

  const scrollUp = () => {
    if (imgArray.length - step > 3) {
      const obj = imgArray.shift()
      imgArray.push(obj)
      const newArray = [...imgArray]
      setStep(step + 1)
      setImgArray(newArray)
    }
  }

  const scrollDown = () => {
    if (step !== 0) {
      const obj = imgArray.pop()
      imgArray.unshift(obj)
      const newArray = [...imgArray]
      setImgArray(newArray)
      setStep(step - 1)
    }
  }
  const onRemoveFileFromList = (file: any): void => {
    setSelectedFiles((o) => o.filter((f) => f !== file))
  }

  const getItemStyle = (isDragging, draggableStyle, label) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    border: isDragging
      ? "2px solid rgba(0, 0, 0, 0.60)"
      : label === "Add"
      ? "1px dashed rgba(0, 0, 0, 0.40)"
      : "1px solid rgba(0, 0, 0, 0.40)",

    borderRadius: "4px",
    overflow: "hidden",

    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver
      ? "rgba(33, 150, 243, 0.08)"
      : "rgba(0, 0, 0, 0.04)",
    border: isDraggingOver
      ? "1px dotted rgba(33, 94, 205, 1)"
      : "1px dashed rgba(0, 0, 0, 0.40)",
  })

  const deleteImage = (image: any) => {
    dispatch(
      deleteFiles({
        patientId: patientId,
        caseId: caseId,
        fileName: image.fileName,
        fileType: "photographs",
        version: "v2",
      }),
    ).then(() => {
      const filteredFilesArray = imgArray.filter(
        (item) => item.fileName !== image.fileName,
      )
      const newArray = [...filteredFilesArray]
      dispatch(removeIndividualPhotos({ newArray }))
    })
  }

  const removeImage = (index: number, p: positionArray) => {
    setStep(0)
    const payload = {
      [p.fileName]: {
        photo_position: "",
      },
    }
    dispatch(updatePhotoPosition({ patientId, caseId, payload })).then(
      (action) => {
        if (action.payload.data.status.toUpperCase() === "SUCCESS") {
          positionArray[index].fileName = ""
          positionArray[index].src = ""
        }
      },
    )
  }

  return (
    <>
      {!isDragOpen ? (
        <>
          <UText
            color={"text.primary"}
            sxProp={{ display: "flex", justifyContent: "center" }}
            variant={"h6"}
          >
            {t("records.photograph.individual")}
          </UText>
          <Grid container>
            <Container
              maxWidth={"md"}
              sx={{
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
                ".MuiBox-root": {
                  mt: "0 !important",
                },
              }}
            >
              <UFileBox
                boxSize={{ height: "216px" }}
                allowedFileExtensions={FILE_ALLOWED_TYPES}
                allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
                fileAcceptType={"image/*"}
                id="fileBox"
                selectedFile={selectedIndividualFile}
                fileLimit={FILE_ALLOWED_LIMIT}
                isRequired={false}
                isMultiple={true}
                uploadedFiles={selectedFiles}
                messages={{
                  fileNote: t("records.file.filenote", {
                    fileTypes: "JPG, PNG, TIFF, BMP",
                    fileSize: FILE_ALLOWED_SIZE,
                  }),
                  uploadButton: t("records.file.btnclicktoupload"),
                  uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                  invalidfileFormat: (
                    <Trans components={{ newLine: <br /> }}>
                      {"records.photograph.invalidfileformat"}
                    </Trans>
                  ),
                  invalidFileSize: t("records.file.invalidfilesize", {
                    fileSize: FILE_ALLOWED_SIZE,
                  }),
                  invalidFileLimit: t("records.file.invalidfilelimit", {
                    noOfFiles: FILE_ALLOWED_LIMIT,
                  }),
                }}
              />
            </Container>
          </Grid>
          <UText
            color={"text.secondary"}
            sxProp={{
              display: "flex",
              justifyContent: "center",
              lineHeight: "166%",
              letterSpacing: "0.4px",
            }}
            variant={"caption"}
          >
            {t("records.photograph.individualnote")}
          </UText>

          {selectedFiles.length > 0 && (
            <Container
              maxWidth={"md"}
              sx={{
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
              }}
            >
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {selectedFiles.map((f: File, index: number) => (
                  <UploadPhotographs
                    isDragOpen={isDragOpen}
                    setIsDragOpen={setIsDragOpen}
                    key={index}
                    file={f}
                    sequence={index + 1}
                    onRemove={onRemoveFileFromList}
                  />
                ))}
              </Grid>
            </Container>
          )}
        </>
      ) : (
        <>
          <UText
            color={"#616161"}
            variant={"caption"}
            sxProp={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {t("records.dragAndDropDescriptions")}
          </UText>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Grid
              container
              sm={12}
              md={12}
              item
              sx={{ p: "0 !important", mt: 1, mb: 3 }}
            >
              <Grid
                item
                width={"140px"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "& :hover": {
                        background: "none !important",
                      },
                    }}
                  >
                    <IconButton
                      onClick={scrollUp}
                      sx={{ p: 0, height: "24px", width: "24px" }}
                    >
                      <KeyboardArrowUpIcon fontSize={"large"} />
                    </IconButton>
                  </Box>
                  <StrictModeDroppable droppableId="verticalScroll">
                    {(provided: any) => (
                      <Box
                        sx={{
                          overflow: "hidden",
                          height: "426px",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {[...imgArray]?.map((image, index) => {
                          return (
                            <Draggable
                              key={image.sequence + index + ""}
                              draggableId={image.sequence + ""}
                              index={index}
                              isDragDisabled={image.name === "Add"}
                            >
                              {(provided: any, snapshot: any) => (
                                <Box
                                  component={"div"}
                                  sx={{
                                    m: "10px 10px 20px",
                                    ...getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      image.name,
                                    ),
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <Box
                                    component={"div"}
                                    onClick={(e) => {
                                      if (image.name === "Add") {
                                        setOpenModal(true)
                                      }
                                      e.preventDefault()
                                    }}
                                    onMouseEnter={() => setIndexNumber(index)}
                                    onMouseLeave={() => setIndexNumber(null)}
                                    sx={{
                                      height: "120px",
                                      width: "120px",
                                      borderRadius: "4px",
                                      overflow: "hidden",
                                      background: "rgba(0, 0, 0, 0.04)",
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={image.src}
                                      alt={image.name}
                                      height={
                                        image.name === "Add" ? "21px" : "100%"
                                      }
                                      width={
                                        image.name === "Add" ? "21px" : "100%"
                                      }
                                    />
                                    {indexNumber === index &&
                                      image.name !== "Add" && (
                                        <Box
                                          component={"div"}
                                          sx={{
                                            height: "28px",
                                            width: "28px",
                                            borderRadius: "100px",
                                            background: "#fff",
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "5px",
                                            zIndex: "100",
                                            padding: "4px",
                                          }}
                                          onClick={() => deleteImage(image)}
                                        >
                                          <DeleteIcon
                                            fontSize={"medium"}
                                            sx={{
                                              color: "black",
                                              opacity: "0.56",
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          />
                                        </Box>
                                      )}
                                  </Box>
                                </Box>
                              )}
                            </Draggable>
                          )
                        })}
                        {/* </Slider> */}
                      </Box>
                    )}
                  </StrictModeDroppable>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "& :hover": {
                        background: "none!important",
                      },
                    }}
                  >
                    <IconButton
                      onClick={scrollDown}
                      sx={{ p: 0, height: "24px", width: "24px" }}
                    >
                      <KeyboardArrowDownIcon fontSize={"large"} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                width={"930px"}
                justifyContent={"center"}
                alignContent={"center"}
                sx={{ height: "435px", display: "flex", alignItems: "center" }}
              >
                <Stack
                  useFlexGap
                  direction="row"
                  flexWrap="wrap"
                  justifyContent="center"
                  padding="10px"
                >
                  {positionArray &&
                    positionArray.map((p, index) => (
                      <StrictModeDroppable
                        droppableId={p.position}
                        key={p.position}
                      >
                        {(provided: any, snapshot: any) => (
                          <Box
                            key={p.position}
                            sx={{
                              textAlign: "center",
                              padding: "10px",
                              mx: 2,
                            }}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <UText
                              variant={"body1"}
                              sxProp={{
                                fontWeight: "500",
                                color: "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              {p.label}
                            </UText>
                            {!p.src && (
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "160px",
                                  height: "160px",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  borderRadius: "4px",
                                  mt: "10px",
                                  ...getListStyle(snapshot.isDraggingOver),
                                }}
                              />
                            )}
                            {p.src && (
                              <Box
                                onMouseEnter={() => setPhotoIndex(index)}
                                onMouseLeave={() => setPhotoIndex(null)}
                                sx={{
                                  display: "flex",
                                  width: "160px",
                                  height: "160px",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(0, 0, 0, 0.40)",
                                  background: "rgba(0, 0, 0, 0.04)",
                                  mt: "10px",
                                  overflow: "hidden",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={p.src}
                                  alt={p.label}
                                  height={"100%"}
                                  width={"100%"}
                                />
                                {photoIndex === index && (
                                  <Box
                                    component={"div"}
                                    sx={{
                                      height: "28px",
                                      width: "28px",
                                      borderRadius: "100px",
                                      background: "#fff",
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      zIndex: "100",
                                      padding: "4px",
                                    }}
                                    onClick={() => removeImage(index, p)}
                                  >
                                    <ClearIcon
                                      fontSize={"medium"}
                                      sx={{
                                        color: "black",
                                        opacity: "0.56",
                                        height: "20px",
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                        )}
                      </StrictModeDroppable>
                    ))}
                </Stack>
              </Grid>
            </Grid>
          </DragDropContext>
        </>
      )}
      <AdditionalPhotograph
        title={t("records.xray.modalPopupTitle")}
        openModal={openModal}
        setOpenModal={setOpenModal}
        showAdditionalFilesSection={isDragOpen}
        photographType={"individual"}
        version="v2"
        fileType="photographs"
      />
      <UModalPopup
        isAlert={isAlertOpen}
        title={
          <UText
            variant={"h6"}
            sxProp={{
              width: "396px",
            }}
          >
            {t("records.warningPopup.tilte")}
          </UText>
        }
        sxModalProps={{
          ".MuiDialog-paper": {
            maxWidth: 444,
          },
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        content={
          <>
            <Box component={"div"}>
              <UText
                variant={"body1"}
                sxProp={{ display: "block", height: "32px" }}
              >
                {t("records.warningPopup.subTitle")}
              </UText>
            </Box>
            <Grid sx={{ ml: "10px", pt: 1, mt: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={dontShowAgain}
                    onChange={() => setDontShowAgain(!dontShowAgain)}
                  />
                }
                label={
                  <UText
                    variant={"body1"}
                    sxProp={{ pt: 1, letterSpacing: "0.15px" }}
                  >
                    {t("newpatient.cancelModal.userPreference")}
                  </UText>
                }
              />
            </Grid>
          </>
        }
        btnList={[
          <UButton
            key={t("records.warningPopup.sortBtn")}
            variant="text"
            btnText={t("records.warningPopup.sortBtn")}
            onClickHandler={() => setIsAlertOpen(false)}
            sxProp={{
              height: 36,
            }}
          ></UButton>,
          <UButton
            key={t("records.warningPopup.continueBtn")}
            variant="contained"
            btnText={t("records.warningPopup.continueBtn")}
            sxProp={{
              width: 106,
              height: 36,
            }}
            onClickHandler={() => setIsAlertOpen(false)}
          ></UButton>,
        ]}
      ></UModalPopup>
    </>
  )
}

export default Individualphotograph
