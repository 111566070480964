import * as React from "react"
import { FC } from "react"
import { Outlet } from "react-router-dom"

import { Grid } from "../components/mui.components"
import { UsmileDarkThemeProvider } from "../ui-component"

const Clinical: FC = () => {
  return (
    <UsmileDarkThemeProvider>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        sx={{ minHeight: "85vh" }}
        minWidth={"lg"}
      >
        <Grid container item minWidth={"lg"} sx={{ m: 0 }}>
          <Outlet />
        </Grid>
      </Grid>
    </UsmileDarkThemeProvider>
  )
}

export default Clinical
