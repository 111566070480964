import archSvg from "../../../../assets/images/clinical/rightBar/arch.svg"
import archDis from "../../../../assets/images/clinical/rightBar/archDis.svg"
import auxSvg from "../../../../assets/images/clinical/rightBar/auxi.svg"
import auxDis from "../../../../assets/images/clinical/rightBar/auxiDis.svg"
import vcSvg from "../../../../assets/images/clinical/rightBar/vc.svg"
import vcDis from "../../../../assets/images/clinical/rightBar/vcDis.svg"
import vsSvg from "../../../../assets/images/clinical/rightBar/vs.svg"
import vsDis from "../../../../assets/images/clinical/rightBar/vsDis.svg"

export const rightTmp = [
  {
    src: auxSvg,
    disabledSrc: auxDis,

    key: "aux",
    title: "Auxiliaries",
  },
  {
    src: vcSvg,
    disabledSrc: vcDis,
    key: "vc",
    title: "Virtual C-Chain",
  },
  {
    src: archSvg,
    disabledSrc: archDis,
    key: "arch",
    title: "Arch section adjustment",
  },
  {
    src: vsSvg,
    disabledSrc: vsDis,
    key: "vs",
    title: "Vertical section adjustment",
  },
]
