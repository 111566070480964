import { FC, useEffect } from "react"
import { I18nextProvider, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { Security } from "@okta/okta-react"

import packageJson from "../package.json"

import { useAnalytics } from "./core/app/customHooks"
import { useAppSelector } from "./core/app/hooks"
import { RootState } from "./core/app/store"
import i18nConfig from "./core/config/i18n.config"
import oktaAuthClient from "./core/config/okta.config"
import AnalyticsWrapper from "./AnalyticsWrapper"
import AppRoutes from "./routes"
import { ULoading, UsmileLightThemeProvider } from "./ui-component"

import "./App.scss"
const App: FC = () => {
  const initialized = useAnalytics()
  const navigate = useNavigate()
  const { ready } = useTranslation("common")
  const { loading: patientLoading, newPatientAPI } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { loading: photoLoading, isDownloading } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const { loading: xrayLoading } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { loading } = useAppSelector((state: RootState) => state.userService)
  const { saveDataStatus } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const loadingVariables = [
    patientLoading,
    photoLoading,
    xrayLoading,
    newPatientAPI,
    isDownloading,
    loading,
    saveDataStatus,
  ]

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin))
  }
  const onAuthRequired = () => {
    navigate("auth/login", { replace: true })
  }

  console.log("...VERSION: ", packageJson.version)

  return (
    <UsmileLightThemeProvider>
      <Security
        oktaAuth={oktaAuthClient}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={onAuthRequired}
      >
        <div className={"App"} data-testid={"app"}>
          <AnalyticsWrapper initialized={initialized}>
            {ready && (
              <I18nextProvider i18n={i18nConfig}>
                <ULoading
                  isLoading={loadingVariables.some(
                    (item) => item === "pending",
                  )}
                />
                <AppRoutes />
              </I18nextProvider>
            )}
          </AnalyticsWrapper>
        </div>
      </Security>
    </UsmileLightThemeProvider>
  )
}

export default App
