import type { FC } from "react"
import * as React from "react"

import { useEffect, useRef } from "react"

import { Stack, Typography } from "@mui/material"

import { FlowButton, FlowTabs } from "../../Custom/FlowCustom"

import { gdsPlusSetup, SetupType, OJOBType } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"

import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import SimpleSetup from "./SimpleSetup"
import SimpleStaging from "./SimpleStaging"
import SimpleFeatures from "./SimpleAtt"

const UFlowSimpleMod = (props) => {
  return (
    <FlowTabs labels={["Setup", "Att", "Submit"]}>
      <SimpleSetup />
      <SimpleFeatures />
      <>
        <FlowButton>Submit Mod.</FlowButton>
        <FlowButton>Approve</FlowButton>
      </>
    </FlowTabs>
  )
}

export default UFlowSimpleMod
