import http from "../../../global/http"

export const uassistAddformService = async (
  orgId: string,
  patientId: string,
  caseId: string,
  payload: any,
) => {
  console.log(orgId, patientId, caseId, payload)
}
