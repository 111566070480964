import { FC, useEffect, useRef, useState } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import Close from "../../../assets/images/clinical/closeBolton.svg"
import zoomin from "../../../assets/svgs/AddFilled.svg"
import zoomout from "../../../assets/svgs/RemoveFilled.svg"
import subtract from "../../../assets/svgs/Subtract.svg"
import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  UModalPopup,
  UText,
} from "../../../components"
import {
  Box,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { convertMBtoKB } from "../../../core/utils/formatters"
interface profilePicProps {
  setEditUserInfo: (val: boolean) => void
  editUserInfo: boolean
}

const UploadProfilePic: FC<profilePicProps> = ({
  editUserInfo,
  setEditUserInfo,
}) => {
  const { t } = useTranslation("common")
  const [files, setFiles] = useState<File[]>([])
  const [progressValue, setProgressValues] = useState<number>(0)
  const [zoom, setZoom] = useState<string | null>("zoomout")
  const containerRef = useRef(null)
  const [zoomFactor, setZoomFactor] = useState<number>(1.0)
  const [uploadPhoto, setUploadPhoto] = useState<boolean>(false)
  const [colourChange, setcolourChange] = useState<boolean>(false)
  const [profilePic, setProfilePic] = useState<string>("")
  const [selectedBox, setSelectedBox] = useState<number | null>(null)
  const [randomClr, setRandomClr] = useState<string | null>(null)

  const { user } = useAppSelector((state: RootState) => state.userService)

  const profileClr = [
    "#4A00D5",
    "#739EFF",
    "#8F00C4",
    "#E5339A",
    "#FF6600",
    "#FFD900",
    "#35C749",
    "#16AADC",
    "#EF5350",
    "#215ECD",
    "#1B5E20",
    "#464646",
  ]

  const handleZoom = () => {
    setZoom("zoomin")
    setZoomFactor(zoomFactor + 0.1)
    cropProfilePic()
  }

  const handleZoomOut = () => {
    setZoom("zoomout")
    setZoomFactor(zoomFactor - 0.1)
    cropProfilePic()
  }

  const cropProfilePic = () => {
    const canvas = document.getElementById("myCanvas")
    canvas.height = "240"
    canvas.width = "240"
    const ctx = canvas.getContext("2d")
    const img = document.getElementById("myimg")
    const newWidth = img.width * zoomFactor
    const newHeight = img.height * zoomFactor
    const newX = (canvas.width - newWidth) / 2
    const newY = (canvas.height - newHeight) / 2
    ctx.drawImage(img, newX, newY, newWidth, newHeight)
  }

  const saveProfilePic = () => {
    cropProfilePic()
    const canvas = document.getElementById("myCanvas")
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      setProfilePic(url)
    })
  }

  const selectedProfilePic = (files: FileList): void => {
    setFiles([...files])
  }

  useEffect(() => {
    if (files.length > 0) {
      setTimeout(() => {
        setProgressValues(100)
      }, 3000)
    }
  }, [files])

  useEffect(() => {
    if (selectedBox === null) {
      setRandomClr(profileClr[Math.floor(Math.random() * profileClr.length)])
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {profilePic ? (
          <img
            src={profilePic}
            alt="profile"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "100px",
            }}
          />
        ) : (
          <UText
            variant="h4"
            color="#ffffff"
            sxProp={{
              height: "80px",
              width: "80px",
              borderRadius: "40px",
              backgroundColor:
                selectedBox !== null ? profileClr[selectedBox] : randomClr,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"SV"}
          </UText>
        )}
        {editUserInfo && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              ml: 3,
            }}
          >
            <UButton
              variant={"outlined"}
              btnText={t("button.uploadPhoto")}
              key={"yes"}
              sxProp={{ minWidth: 58, height: 36 }}
              onClickHandler={() => {
                setUploadPhoto(true)
              }}
            />
            {profilePic ? (
              <UButton
                variant={"outlined"}
                btnText={t("button.deletePhoto")}
                key={"no"}
                onClickHandler={() => {
                  setProfilePic("")
                  setFiles([])
                  setProgressValues(0)
                  setZoomFactor(1)
                }}
                sxProp={{ minWidth: 54, height: 36 }}
              />
            ) : (
              <UButton
                variant={"outlined"}
                btnText={t("button.changeClr")}
                key={"no"}
                onClickHandler={() => {
                  setcolourChange(true)
                }}
                sxProp={{ minWidth: 54, height: 36 }}
              />
            )}
          </Box>
        )}
      </Box>
      <UModalPopup
        title={
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <UText variant="h6" component={"div"}>
              Pick Color
            </UText>
            <IconButton
              size="medium"
              onClick={() => setcolourChange(false)}
              className="pickClr"
            >
              <Box component={"img"} src={Close}></Box>
            </IconButton>
          </Grid>
        }
        sxModalProps={{
          ".MuiDialog-paper": {
            paddingTop: 3,
            paddingBottom: 4,
            px: 4,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            margin: 0,
            minWidth: "400px",
            minHeight: "400px",
            overflow: "hidden",
          },
          "#contentCntr": {
            display: "flex",
            flexDirection: "column",
          },
        }}
        content={
          <>
            <Grid
              container
              id={"profileUpload"}
              sx={{
                width: "336px",
                height: "240px",
                justifyContent: "center",
                alignItems: "center",
                rowGap: "8px",
                columnGap: "8px",
                py: "3px",
              }}
            >
              {Array.from(Array(12)).map((_, index) => (
                <Grid item key={index}>
                  <Box
                    component={"div"}
                    sx={{
                      border:
                        selectedBox === index ? "5.2px solid grey" : "none",
                      width: "74px",
                      height: "74px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"div"}
                      key={index}
                      onClick={() => {
                        setSelectedBox(index)
                      }}
                      sx={{
                        width: "64px",
                        height: "64px",
                        backgroundColor: profileClr[index],
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        }
        isAlert={colourChange}
        btnList={[
          <>
            <Box sx={{ display: "flex", padding: 1 }}>
              <UButton
                variant={"text"}
                btnText={t("button.cancel")}
                key={"yes"}
                sxProp={{ width: "74px", height: "36px" }}
                onClickHandler={() => {
                  setcolourChange(false)
                }}
              />
              <UButton
                variant={"contained"}
                btnText={t("button.save")}
                btnType={"submit"}
                key={"no"}
                onClickHandler={() => {
                  setcolourChange(false)
                }}
                sxProp={{ width: "69px", height: "36px", ml: 1 }}
              />
            </Box>
          </>,
        ]}
      />
      <UModalPopup
        title={
          progressValue === 100 && files.length > 0 ? (
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <UText variant="h6" component={"div"}>
                Crop Photo
              </UText>
              <IconButton
                size="medium"
                onClick={() => {
                  setUploadPhoto(false)
                  setFiles([])
                  setProgressValues(0)
                  setZoomFactor(1)
                }}
                className="pickClr"
              >
                <Box component={"img"} src={Close}></Box>
              </IconButton>
            </Grid>
          ) : (
            "Profile Photo"
          )
        }
        sxModalProps={{
          ".MuiDialog-paper": {
            paddingTop: 3,
            paddingBottom: 4,
            px: progressValue === 100 && files.length > 0 ? 4 : 5,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            minWidth: "400px",
            width: "fit-content",
            maxHeight: "fit-content",
            margin: 0,
          },
          "#titleCntr": {
            justifyContent:
              progressValue === 100 && files.length > 0 ? "left" : "center",
            pb: progressValue === 100 && files.length > 0 ? "2px" : 1,
          },
          "#contentCntr": {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          },
        }}
        content={
          <>
            {files.length === 0 && (
              <UFileBox
                boxSize={{
                  height: "216px",
                  width: "463px",
                  margin: "10px",
                }}
                allowedFileExtensions={["png", "tiff", "jpg", "jpeg", "bmp"]}
                allowedfileSize={convertMBtoKB(10)}
                fileAcceptType={"image/*"}
                id="userProfile"
                selectedFile={selectedProfilePic}
                fileLimit={1}
                isRequired={false}
                messages={{
                  fileNote: t("records.file.filenote", {
                    fileTypes: "JPG, PNG, TIFF, BMP",
                    fileSize: 10,
                  }),
                  uploadButton: t("records.file.btnclicktoupload"),
                  uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                  invalidfileFormat: (
                    <Trans components={{ newLine: <br /> }}>
                      {"records.photograph.invalidfileformat"}
                    </Trans>
                  ),
                  invalidFileSize: t("records.file.invalidfilesize", {
                    fileSize: 10,
                  }),
                  invalidFileLimit: t("userProfile.uploadLimitError", {
                    noOfFiles: 1,
                  }),
                }}
              />
            )}
            {files.length > 0 && progressValue !== 100 && (
              <Box
                sx={{
                  py: "6px",
                  width: "483px",
                  px: "1px",
                }}
              >
                <UFileProcessingCard
                  fileName={files[0].name}
                  fileSize={files[0].size}
                  progressValue={100}
                  progressstatus={"loading"}
                  onRemove={() => setFiles([])}
                />
              </Box>
            )}
            {progressValue === 100 && files.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "240px",
                    height: "240px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <Box ref={containerRef}>
                    <img
                      id="myimg"
                      src={URL.createObjectURL(files[0])}
                      alt="profilePic"
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        transform: `scale(${zoomFactor})`,
                      }}
                    />
                  </Box>
                  <img
                    src={subtract}
                    alt="profilePic"
                    style={{ position: "absolute" }}
                  />
                </Box>
                <ToggleButtonGroup value={zoom} exclusive>
                  <ToggleButton
                    value="zoomout"
                    onClick={handleZoomOut}
                    sx={{ width: "120px", height: "40px" }}
                  >
                    <Box component={"img"} src={zoomout}></Box>
                  </ToggleButton>
                  <ToggleButton
                    value="zoomin"
                    onClick={handleZoom}
                    sx={{ width: "120px", height: "40px" }}
                  >
                    <Box component={"img"} src={zoomin}></Box>
                  </ToggleButton>
                </ToggleButtonGroup>
                <canvas id="myCanvas" style={{ display: "none" }}></canvas>
              </Box>
            )}
          </>
        }
        isAlert={uploadPhoto}
        btnList={[
          <>
            <Box sx={{ display: "flex", padding: 1 }}>
              <UButton
                variant={"text"}
                btnText={t("button.cancel")}
                key={"yes"}
                sxProp={{ width: "74px", height: "36px" }}
                onClickHandler={() => {
                  setUploadPhoto(false)
                  setFiles([])
                  setProgressValues(0)
                  setZoomFactor(1)
                }}
              />
              <UButton
                variant={"contained"}
                btnText={t("button.save")}
                btnType={"submit"}
                onClickHandler={() => {
                  setUploadPhoto(false)
                  saveProfilePic()
                  setZoomFactor(1)
                }}
                sxProp={{ width: "69px", height: "36px", ml: 1 }}
              />
            </Box>
          </>,
        ]}
      />
    </>
  )
}

export default UploadProfilePic
