export const schema = {
  type: "object",
  properties: {
    //Radio Group
    arch_form_type: {
      type: "string",
      isRadioGroup: true,
      enum: ["Ovoid", "Natural/Maintain Current Arch Form", "Broad/Square"],
    },
    expansion: {
      type: "string",
      isRadioGroup: true,
      enum: ["Both Anterior and Posterior", "Anterior", "Posterior"],
    },
    expansion_limit_canine_to_canine: {
      type: "string",
      isRadioGroup: true,
      enum: ["No Limit", "0 - 2 mm", "2 mm - 4 mm"],
    },
    expansion_limit_first_molar_to_first_molar: {
      type: "string",
      isRadioGroup: true,
      enum: ["No Limit", "0 - 2 mm", "2 mm - 4 mm"],
    },
    level_lower_curve_of_spee: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        { label: "Intrude Lower Canine - Canine", value: "intrudePosterior" },
        { label: "Extrude Lower Premolars", value: "extrudeAnterior" },
        {
          label: "Additional Instructions",
          value: "SeeAdditionalInstructions",
        },
      ],
      input: {
        width: 310,
        label: "Stage",
        const: "SeeAdditionalInstructions",
        maxRows: 4,
        minRows: 1,
        name: "instructions",
      },
    },
    overbite: {
      type: "string",
      isRadioGroup: true,
      enum: ["2 mm - 3 mm", "Edge to Edge", "Overcorrection"],
      input: {
        width: 310,
        const: "Overcorrection",
        minRows: 1,
        name: "stage",
      },
    },
    overjet: {
      type: "string",
      isRadioGroup: true,
      enum: ["0 - 2 mm", "2 mm - 3 mm", "Custom Amount"],
      input: {
        width: 310,
        const: "Custom Amount",
        minRows: 1,
        name: "stage",
      },
    },
    smile_design: {
      type: "string",
      isRadioGroup: true,
      enum: [
        "Smile Arc",
        "Level the incisal edges",
        "Level the canines with the centrals",
        "Level the gingival margins",
      ],
      input: {
        width: 56,
        startLabel: "Step up laterals",
        endLabel: "mm",
        const: "Level the canines with the centrals",
        minRows: 1,
        name: "stage",
      },
    },
    please_select_area_to_stage_ipr: {
      type: "string",
      isRadioGroup: true,
      title: "Please select area(s) to stage IPR",
      enum: ["3-3", "4-4", "5-5", "Not applicable"],
    },
    order_of_priority: {
      type: "string",
      isChecboxGroup: true,
      title: "Order of Priority (optional)",
      checkboxes: [
        {
          label: "Premolars to maximum limit before anterior teeth",
          value: "premolarsToMaximumLimitBeforeAnteriorTeeth",
        },
        {
          label: "Include posterior teeth if necessary",
          value: "posteriorTeeth",
        },
        { label: "Not in the first round", value: "notInTheFirstRound" },
      ],
    },
    maximum_amount_per_interproximal_contact: {
      type: "string",
      isRadioGroup: true,
      title: "Maximum amount per interproximal contact",
      enum: ["0.2 mm", "0.3 mm", "0.4 mm", "0.5 mm"],
      default: "0.3 mm",
    },
    set_ipr_staging: {
      type: "string",
      isRadioGroup: true,
      title: "Set IPR Staging ",
      enum: ["Preferred Stage(s)", "When contacts align"],
      input: {
        width: 310,
        const: "Preferred Stage(s)",
        minRows: 1,
        name: "stage",
        placeholder: "Stages 0/8/16/24",
      },
      default: "When contacts align",
    },
    ap_optional: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "AP (optional)",
      properties: {
        correct_to_class_canines: {
          key: "correctToClassCanines",
          title: "Correct to Class I - Canines",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
        correct_to_class_molars: {
          key: "correctToClassMolars",
          title: "Correct to Class I - First Molars",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
      },
    },
    acheived_by: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "Achieved By (optional)",
      properties: {
        bite_jump_simulation: {
          key: "bite_jump_simulation",
          title: "Bite Jump Simulation (Global Movement)",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["At the end", "At the beginning", "Throughout treatment"],
              default: "At the end",
            },
          },
        },
        seq_movements: {
          key: "sequentialMovements",
          title: "Sequential Movements (if applicable)",
        },
        dental_movements: {
          key: "denatlMovementOnly",
          title: "Dental Movement Only",
        },
      },
    },
    elastics: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "Elastics (optional)",
      properties: {
        class_II: {
          key: "class_II",
          title: "Class II",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
        class_III: {
          key: "class_III",
          title: "Class III",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
        custom: {
          key: "custom",
          title: "Custom",
          properties: {
            input: {
              width: 310,
              const: "custom",
              isInput: true,
              multiline: true,
              minRows: 1,
              name: "instructions",
            },
          },
        },
      },
    },
    auxiliaries: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Precision Aligner Buttons",
          value: "precisionAligner",
        },
        {
          label: "Caplin Hook",
          value: "CaplinHook",
        },
        {
          label: "Smaller Button",
          value: "SmallerButton",
        },
        { label: "Slits", value: "Slits" },
        { label: "Additional Instructions", value: "AdditionalInstructions" },
      ],
      input: {
        width: 310,
        const: "AdditionalInstructions",
        multiline: true,
        minRows: 1,
        name: "stage",
      },
    },
    attachments: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
      input: {
        width: 56,
        startLabel: "Stage",
        defaultValue: "1",
        const: "Yes",
        minRows: 1,
        name: "stage",
        isNum: true,
      },
    },
    add_group_protocal: {
      type: "array",
      title: "Add Group Protocol (optional)",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Deep Bite",
          value: "DeepBite",
        },
        {
          label: "Distalization (if applicable)",
          value: "Distalization",
        },
        {
          label: "Max Retention",
          value: "MaxRetention",
        },
        { label: "Custom", value: "Custom" },
      ],
      input: {
        width: 310,
        const: "Custom",
        multiline: true,
        minRows: 1,
        name: "instructions",
      },
    },
    pressure_points: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
      input: {
        width: 310,
        const: "Yes",
        maxRows: 4,
        minRows: 1,
      },
    },
    bite_ramps: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
      checkboxGroup: {
        const: "Yes",
        checkboxes: [
          {
            label: "Central Incisors",
            value: "Central Incisors",
          },
          {
            label: "Canines",
            value: "Canines",
          },
          {
            label: "Central and Laterals",
            value: "Central and Laterals",
          },
          {
            label: "Custom",
            value: "Custom",
            input: {
              width: 284,
              multiline: true,
              minRows: 1,
              name: "stage",
            },
          },
        ],
        default: "Central Incisors",
      },
    },
    virtual_chain: {
      type: "string",
      title: "Virtual C-Chain",
      isRadioGroup: true,
      enum: ["Yes", "No", "Not in the initial round"],
      input: {
        width: 80,
        const: "Yes",
        startAdornment: "-",
        defaultValue: "0.01",
        minRows: 1,
        name: "stage",
        endLabel: "Amount of virtual IPR at the last stage",
      },
    },
    velocity_rate_limiting: {
      type: "string",
      isRadioGroup: true,
      title: "Velocity - Rate Limiting Factor Stage",
      enum: [
        ".20 mm linear / 2.0° angular",
        ".22 mm linear / 2.2° angular",
        ".25 mm linear / 2.5° angular",
        "Custom (i.e. Periodontal Concerns)",
      ],
      input: {
        width: 310,
        const: "Custom (i.e. Periodontal Concerns)",
        multiline: true,
        minRows: 1,
        name: "instructions",
      },
    },
    equal_no_of_aligners: {
      type: "string",
      isRadioGroup: true,
      title: "Equal number of aligners (adjust velocity)",
      enum: ["Yes", "No"],
    },
    passive_aligners: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes (if needed)", "No"],
      input: {
        width: 310,
        const: "Yes (if needed)",
        maxRows: 4,
        minRows: 1,
        placeholder: "Instructions (optional)",
      },
    },
    wear_time_of_aligner: {
      type: "string",
      title: "Wear Time of Aligners",
      isRadioGroup: true,
      enum: ["7 Days", "14 Days", "custom"],
      input: {
        width: 56,
        const: "custom",
        maxRows: 4,
        minRows: 1,
        label: "Days",
        endLabel: "Days",
        showInSameLine: true,
        isNum: true,
      },
    },
    case_specific_instructions: {
      title: "Deep Bite Instructions",
      type: "string",
      isTextarea: true,
      isSettings: true,
      placeholder: `Please add instructions for this template here (no abbreviations).\n\nYou will have the opportunity to enter case specific instructions before submitting each case.`,
      options: {
        rows: 6,
      },
    },
  },
  required: [
    "arch_form_type",
    "expansion",
    "expansion_limit_canine_to_canine",
    "expansion_limit_first_molar_to_first_molar",
    "level_lower_curve_of_spee",
    "overbite",
    "overjet",
    "smile_design",
    "please_select_area_to_stage_ipr",
    "attachments",
    "pressure_points",
    "auxiliaries",
    "bite_ramps",
    "virtual_chain",
    "velocity_rate_limiting",
    "equal_no_of_aligners",
    "passive_aligners",
    "wear_time_of_aligner",
  ],
}

export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Group",
      label: "How do you prefer to see teeth at the end of treatment?",
      //Radio Group

      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/arch_form_type",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/expansion",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/expansion_limit_canine_to_canine",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/expansion_limit_first_molar_to_first_molar",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Vertical and AP",

      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/level_lower_curve_of_spee",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/overbite",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/overjet",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Detailing",

      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/smile_design",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "IPR",

      elements: [
        {
          type: "control",
          label: true,
          scope: "#/properties/please_select_area_to_stage_ipr",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/order_of_priority",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/please_select_area_to_stage_ipr",
              schema: {
                enum: ["Not applicable"],
              },
            },
          },
        },
        {
          type: "control",
          scope: "#/properties/maximum_amount_per_interproximal_contact",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/please_select_area_to_stage_ipr",
              schema: {
                enum: ["Not applicable"],
              },
            },
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/set_ipr_staging",
          options: {
            format: "radio",
          },
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/please_select_area_to_stage_ipr",
              schema: {
                enum: ["Not applicable"],
              },
            },
          },
        },
      ],
    },
    {
      type: "Group",
      label: "How to achieve the stimulated treatment - biomechanics",

      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/ap_optional",
        },
        {
          type: "Control",
          label: "Achieved By (optional)",
          scope: "#/properties/acheived_by",
          rule: {
            effect: "SHOW",
            condition: {
              scope: "#/properties/ap_optional",
              schema: true,
            },
          },
        },
        {
          type: "Control",
          label: "Elastics (optional)",
          scope: "#/properties/elastics",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/auxiliaries",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/attachments",
          format: {
            format: "radio",
          },
        },
        {
          type: "Control",
          scope: "#/properties/add_group_protocal",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/pressure_points",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/bite_ramps",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/virtual_chain",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/velocity_rate_limiting",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/equal_no_of_aligners",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/passive_aligners",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/wear_time_of_aligner",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Additional Instructions",
      elements: [
        {
          type: "Control",
          scope: "#/properties/case_specific_instructions",
          label: true,
        },
      ],
    },
  ],
}

export const defaultData = {
  arch_form_type: "Ovoid",
  expansion: "Both Anterior and Posterior",
  expansion_limit_canine_to_canine: "2 mm - 4 mm",
  expansion_limit_first_molar_to_first_molar: "0 - 2 mm",
  ap_optional: {
    correctToClassCanines: {
      radioGroup: "Both",
    },
    correctToClassMolars: {
      radioGroup: "Both",
    },
  },
  level_lower_curve_of_spee: ["intrudePosterior", "extrudeAnterior"],
  overbite: "Edge to Edge",
  overjet: "0 - 2 mm",
  smile_design: "Smile Arc",
  please_select_area_to_stage_ipr: "4-4",
  order_of_priority: [],
  maximum_amount_per_interproximal_contact: "0.3 mm",
  set_ipr_staging: "When contacts align",
  attachments: "Yes",
  pressure_points: "No",
  bite_ramps: "Yes",
  virtual_chain: "No",
  velocity_rate_limiting: ".22 mm linear / 2.2° angular",
  equal_no_of_aligners: "Yes",
  passive_aligners: "Yes (if needed)",
  wear_time_of_aligner: "7 Days",
  acheived_by: {},
  elastics: {
    class_II: {
      radioGroup: "Both",
    },
  },
  auxiliaries: ["precisionAligner", "Slits"],
  add_group_protocal: ["DeepBite", "Distalization"],
}

export const jsonSchemaDefault = JSON.stringify(schema, null, 2)
export const uiSchemaDefault = JSON.stringify(uischema, null, 2)
