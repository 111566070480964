import { FC, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"

import { Box, CssBaseline, Grid } from "../../components/mui.components"
import { isTokenValid } from "../../core/config/okta.config"

import Footer from "./Footer/Footer"
import Header from "./Header/Header"

const MainLayout: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isLogin = isTokenValid()

  useEffect(() => {
    if (isLogin) {
      if (location.pathname.includes("/auth") || location.pathname === "/") {
        navigate("/patients")
      }
    } else {
      if (!location.pathname.includes("/auth")) {
        navigate("auth/login")
      }
    }
  }, [location])

  return (
    <>
      {isLogin && (
        <>
          <CssBaseline />
          <Header />
          <Grid
            sx={{
              background: "#F7F8F9",
              flexGrow: 1,
            }}
            container
            component={"main"}
          >
            <Outlet />
          </Grid>
          {!location.pathname.includes("/clinical/") && <Footer />}
        </>
      )}
    </>
  )
}

export default MainLayout
