import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IConfigState {
  spinner: boolean
}

const initialState: IConfigState = {
  spinner: true,
}

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLoadingSpinner: (state, action: PayloadAction<boolean>) => {
      state.spinner = action.payload
    },
  },
})

export const { setLoadingSpinner } = configSlice.actions

export default configSlice.reducer
