import { useEffect } from "react"

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    document.addEventListener("mousedown", listener)
    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [ref, handler])

  const listener = (event) => {
    // Do nothing if clicking ref's element or descendent elements
    if (!ref.current || ref.current.contains(event.target)) {
      return
    }
    handler && handler(event)
  }
}

export default useOnClickOutside
