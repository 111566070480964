import { memo } from "react"
import { Box, Paper, Collapse, Typography } from "@mui/material"
import UFlowQuickPlan from "./UFlowPages/QuickPlan/UFlowQuickPlan"
import UFlowSimpleMod from "./UFlowPages/SimpleMod/UFlowSimpleMod"
import WeFlowSetup from "./WeFlowPages/Setup/WeFlowSetup"
import WeFlowStaging from "./WeFlowPages/Staging/WeFlowStaging"
import WeFlowFeatures from "./WeFlowPages/Features/WeFlowFeatures"

const WeSmileRightPanel = (props) => {
  const getPanel = (panelName: string) => {
    if (panelName == "QuickPlan") return <UFlowQuickPlan />
    else if (panelName == "SimpleMod") return <UFlowSimpleMod />
    else if (panelName == "WeSetup") return <WeFlowSetup />
    else if (panelName == "WeStaging") return <WeFlowStaging />
    else if (panelName == "WeFeatures") return <WeFlowFeatures />
    else return <></>
  }
  if (props.panelName == "none") return <></>
  return (
    <Collapse in={props.panelName != "none"}>
      <Box
        sx={{
          right: 0,
          top: 0,
          width: 400,
          minHeight: 600,
        }}
        position="absolute"
      >
        <Paper sx={{ width: 400, minHeight: 600 }} elevation={4}>
          {getPanel(props.panelName)}
        </Paper>
      </Box>
    </Collapse>
  )
}
export default memo(WeSmileRightPanel)
