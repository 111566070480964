import { FC, useCallback, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AxiosProgressEvent } from "axios"
import { v4 } from "uuid"

import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  UModalPopup,
} from "../../components"
import { Grid } from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { uploadPhotographs } from "../../core/app/slices/records/photograph"
import { PhotoObject } from "../../core/app/slices/records/photograph/photograph.type"
import {
  removeAdditionalPhoto,
  setAdditionalPhotos,
  setIndividualPhotos,
} from "../../core/app/slices/records/photograph/photographSlice"
import { setAdditionalXrays } from "../../core/app/slices/records/xrays/xraysSlice"
import { removeAdditionalXray } from "../../core/app/slices/records/xrays/xraysSlice"
import {
  deleteFiles,
  uploadXray,
} from "../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../core/app/store"
import { convertMBtoKB } from "../../core/utils/formatters"

const FILE_ALLOWED_TYPES = ["png", "tiff", "jpg", "jpeg", "bmp"]
const FILE_ALLOWED_SIZE = 10 //in MB
const FILE_ALLOWED_LIMIT = 30

const UploadPhotographs: FC<{
  file: File
  onRemove: (f: any) => void
  sequence: number
  isXray?: boolean
  photographType: string
  removeCompletedFiles?: (file: File) => void
  setUploadedFiles: (callback: (o: PhotoObject[]) => PhotoObject[]) => void
}> = ({
  file,
  onRemove,
  sequence,
  isXray,
  photographType,
  removeCompletedFiles,
  setUploadedFiles,
}) => {
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressstatus, setProgresssStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")
  const [uploadError, setUploadError] = useState<string>()

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { t } = useTranslation("common")

  useEffect(() => {
    const uniqueId = v4()
    const newFile = new File(
      [file],
      `${
        photographType !== "individual" ? "others" : "individual"
      }-${uniqueId}.${file.type.split("/")[1]}`,
      { type: file.type },
    )
    const formPayload = new FormData()
    formPayload.append("attachment", newFile)
    formPayload.append("original_file_name", file.name)
    formPayload.append("sequence", sequence.toString())
    const apiFunction = isXray ? uploadXray : uploadPhotographs
    dispatch(
      apiFunction({
        patientId: patientId,
        caseId: caseId,
        formData: formPayload,
        fileName: newFile.name,
        isAdditional: true,
        file,
        radioGraphType: "others",
        orgId: "",
        onFileProcesscallback: function (progress: AxiosProgressEvent): void {
          const { loaded, total } = progress
          setProgresssStatus("loading")

          const percentageProgress = Math.floor((loaded / total) * 100)
          setProgressValue(percentageProgress)
        },
      }),
    )
      .then((action) => {
        if (action.payload.status === 200) {
          setProgresssStatus("complete")
          removeCompletedFiles && removeCompletedFiles(file)
          setUploadedFiles((o: PhotoObject[]) => [
            {
              src: URL.createObjectURL(file),
              position: "",
              sequence: sequence.toString(),
              name: file.name,
              fileName: newFile.name,
              size: file.size,
            },
            ...o,
          ])
        } else {
          setProgresssStatus("failed")
          setUploadError(t("records.photograph.uploadError"))
        }
      })
      .catch((error) => {
        setProgresssStatus("failed")
      })
  }, [file])

  return (
    <>
      <Grid item sm={12}>
        <UFileProcessingCard
          fileName={file.name}
          fileSize={file.size}
          progressValue={progressValue}
          progressstatus={progressstatus}
          onRemove={() => onRemove(file)}
          filleErrorMessage={uploadError}
        />
      </Grid>
    </>
  )
}

const AdditionalPhotograph: FC<{
  title: string
  openModal: boolean
  setOpenModal: (value: boolean) => void
  showAdditionalFilesSection: boolean
  linkText?: string
  downloadedFiles?: { name: string; size: number; fileName: string }[]
  isXray?: boolean
  photographType?: string
  version?: string
  fileType?: string
}> = ({
  title,
  openModal,
  setOpenModal,
  showAdditionalFilesSection,
  linkText,
  downloadedFiles,
  isXray,
  photographType,
  fileType,
  version,
}) => {
  const { t } = useTranslation("common")
  const { isPhotoUploading, individualPhotos, additionalPhotos } =
    useAppSelector((state: RootState) => state.PhotosService)

  const { otherXrays } = useAppSelector((state: RootState) => state.xrayService)

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const [additionalFiles, setAdditionalFiles] = useState<File[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<PhotoObject[]>([])
  const [renderedUploads, setRenderedUploads] = useState<JSX.Element[]>([])
  const [file, setfile] = useState<File | null>(null)

  useEffect(() => {
    if (file !== null) {
      const obj = uploadedFiles.find((obj) => obj.name === file?.name)
      const fileObj = additionalFiles.filter((item) => item.name !== file?.name)
      const renderObj = renderedUploads.filter(
        (item) => item.props.file.name !== file?.name,
      )
      setAdditionalFiles(fileObj)
      setRenderedUploads(renderObj)
      dispatch(
        deleteFiles({
          patientId: patientId,
          caseId: caseId,
          fileName: obj?.fileName,
          fileType: fileType,
          version: version,
        }),
      )
      const newArray = uploadedFiles.filter((obj) => obj.name !== file?.name)
      setUploadedFiles(newArray)
    }
  }, [file])

  const selectedAdditionalFiles = (files: FileList): void => {
    const newFiles = Array.from(files).filter(
      (file) =>
        !additionalFiles.map((file) => file.name).includes(file.name) &&
        (photographType === "composite"
          ? !additionalPhotos.map((file) => file.name).includes(file.name)
          : !otherXrays.map((file) => file.name).includes(file.name)),
    )
    const b = newFiles.map((f, index) => {
      setAdditionalFiles((o) => [f, ...o])

      return (
        <UploadPhotographs
          isXray={isXray}
          file={f}
          photographType={photographType}
          sequence={
            photographType === "individual"
              ? Number(individualPhotos[individualPhotos.length - 1].sequence) +
                index +
                1
              : 0
          }
          setUploadedFiles={setUploadedFiles}
          onRemove={() => setfile(f)}
          key={index}
        />
      )
    })
    console.log(...b, "big element")
    setRenderedUploads((prevUploads) => [...prevUploads, ...b])
  }

  const deleteAdditionalFiles = () => {
    uploadedFiles?.map((file) => {
      dispatch(
        deleteFiles({
          patientId: patientId,
          caseId: caseId,
          fileName: file.fileName,
          fileType: fileType,
          version: version,
        }),
      )
    })
    setAdditionalFiles([])
    setRenderedUploads([])
    setUploadedFiles([])
    setOpenModal(false)
  }

  const resetStateValues = () => {
    setAdditionalFiles([])
    setRenderedUploads([])
    setUploadedFiles([])
    setOpenModal(false)
  }

  return (
    showAdditionalFilesSection && (
      <>
        {photographType !== "individual" &&
          downloadedFiles &&
          downloadedFiles.length > 0 && (
            <Grid
              container
              gap={"10px"}
              sx={{
                width: 400,
                margin: "auto",
                pb: "6px",
                mt: "18px",
              }}
            >
              {downloadedFiles.map((file, index) => (
                <Grid item sm={12} key={index}>
                  <UFileProcessingCard
                    fileName={file.name}
                    fileSize={file.size}
                    progressstatus={"complete"}
                    onRemove={() => {
                      dispatch(
                        deleteFiles({
                          patientId: patientId,
                          caseId: caseId,
                          fileName: file.fileName,
                          fileType: fileType,
                          version: version,
                        }),
                      ).then((action) => {
                        if (action.payload.data.status === "Success") {
                          const filteredFilesArray = downloadedFiles.filter(
                            (item) => item.fileName !== file.fileName,
                          )
                          const newArray = [...filteredFilesArray]
                          if (photographType === "composite") {
                            dispatch(removeAdditionalPhoto({ newArray }))
                          }
                          if (photographType === "xrays") {
                            dispatch(removeAdditionalXray({ newArray }))
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}

        {linkText && (
          <UButton
            variant="text"
            disableRipple
            btnText={linkText}
            sxProp={{
              textDecoration: "underline",
              textTransform: "initial",
              fontWeight: 400,
              lineHeight: 1.43,
              letterSpacing: "0.17px",
              width: "100%",
              padding: "6px 0",
              height: "inherit",
              ":hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
                textDecorationColor: "rgb(33, 94, 205, .4)",
                cursor: "pointer",
              },
            }}
            onClickHandler={() => setOpenModal(true)}
          />
        )}

        <UModalPopup
          title={title}
          sxModalProps={{
            ".MuiDialog-paper": {
              padding: 5,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: 563,
              borderRadius: 4,
              maxHeight: "80vh",
            },
            "#titleCntr": { justifyContent: "center" },
            "#contentCntr": {
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            },
            "#btnListCntr": { gap: 1 },
          }}
          isAlert={openModal}
          content={
            <>
              <Grid container flexDirection={"column"}>
                <UFileBox
                  boxSize={{ height: "216px", mt: "0 !important" }}
                  allowedFileExtensions={FILE_ALLOWED_TYPES}
                  allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
                  fileAcceptType={"image/*"}
                  id="composite"
                  selectedFile={selectedAdditionalFiles}
                  fileLimit={FILE_ALLOWED_LIMIT}
                  isRequired={false}
                  isMultiple
                  messages={{
                    fileNote: t("records.file.filenote", {
                      fileTypes: "JPG, PNG, TIFF, BMP",
                      fileSize: FILE_ALLOWED_SIZE,
                    }),
                    uploadButton: t("records.file.btnclicktoupload"),
                    uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                    invalidfileFormat: (
                      <Trans components={{ newLine: <br /> }}>
                        {"records.photograph.invalidfileformat"}
                      </Trans>
                    ),
                    invalidFileSize: t("records.file.invalidfilesize", {
                      fileSize: FILE_ALLOWED_SIZE,
                    }),
                    invalidFileLimit: t(
                      "records.file.invalidfilelimitComposite",
                      {
                        noOfFiles: FILE_ALLOWED_LIMIT,
                      },
                    ),
                  }}
                />
              </Grid>
              {(additionalFiles.length !== 0 || uploadedFiles.length !== 0) && (
                <Grid
                  container
                  flexDirection={"column"}
                  gap={"10px"}
                  flexWrap={"nowrap"}
                  overflow={"scroll"}
                  sx={{
                    padding: "0 2px 1px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {/* {uploadedFiles.length !== 0 &&
                    uploadedFiles.map((file, index) => (
                      <Grid item sm={12} key={index}>
                        <UFileProcessingCard
                          fileName={file.name}
                          fileSize={file.size}
                          progressValue={100}
                          progressstatus={"complete"}
                          onRemove={() => {
                            console.log("remove")
                          }}
                        />
                      </Grid>
                    ))} */}
                  {/* {additionalFiles.length !== 0 &&
                    additionalFiles.map((file, index) => (
                      <UploadPhotographs
                        isXray={isXray}
                        file={file}
                        photographType={photographType}
                        sequence={
                          photographType === "individual"
                            ? Number(
                                individualPhotos[individualPhotos.length - 1]
                                  .sequence,
                              ) +
                              index +
                              1
                            : 0
                        }
                        // removeCompletedFiles={() => {
                        //   // removeItem(index)
                        // }}
                        setUploadedFiles={setUploadedFiles}
                        onRemove={() => {
                          const updatedArray = [...additionalFiles] // Create a copy of the array
                          updatedArray.splice(index, 1)
                          setAdditionalFiles([...updatedArray])
                        }}
                        key={index}
                      />
                    ))} */}
                  {renderedUploads.length > 0 && renderedUploads}
                </Grid>
              )}
            </>
          }
          btnList={[
            <UButton
              key={"Cancel"}
              btnText="Cancel"
              variant="outlined"
              onClickHandler={() => {
                deleteAdditionalFiles()
              }}
              sxProp={{ width: 90, height: 36 }}
            ></UButton>,
            <UButton
              key={"Done"}
              btnText="Done"
              variant="contained"
              disabled={isPhotoUploading === "pending"}
              onClickHandler={() => {
                switch (photographType) {
                  case "composite":
                    dispatch(setAdditionalPhotos({ fileObj: uploadedFiles }))
                    break
                  case "individual":
                    dispatch(setIndividualPhotos({ fileObj: uploadedFiles }))
                    break
                  case "xrays":
                    dispatch(setAdditionalXrays({ fileObj: uploadedFiles }))
                }
                resetStateValues()
              }}
              sxProp={{ width: 72, height: 36 }}
            ></UButton>,
          ]}
        ></UModalPopup>
      </>
    )
  )
}

export default AdditionalPhotograph
