import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export const autoStagingSlice = createSlice({
  name: "autostaging",
  initialState: {
    curStage: 0,
    stagingUpdate: false,
  },
  reducers: {
    setCurStage(state, action: PayloadAction<number>) {
      state.curStage = action.payload
    },
    updateState(state, action) {
      // everytime staging changed, state.stagingUpdate is flipped.
      state.stagingUpdate = !state.stagingUpdate
    },
  },
})

export const { updateState, setCurStage } = autoStagingSlice.actions
