import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import {
  cutoutIDs,
  leftNums,
  legendsTmp,
  pressIDs,
  qua1Ids,
  qua2Ids,
  qua3Ids,
  qua4Ids,
  rightNums,
  slitIDs,
  svgs,
} from "./column"
import { auxProps } from "./type"

import "./auxilliaries.scss"
const Auxilliaries: FC<auxProps> = ({ attachment, toothlist }) => {
  const [auxData, setData] = useState({})
  const { t } = useTranslation("common")
  useEffect(() => {
    const obj = {}
    for (const key of Object.keys(attachment)) {
      const attachTmp = attachment[key].attachment

      if (attachTmp && attachTmp.length) {
        for (let i = 0; i < attachTmp.length; i++) {
          const { attachmentId, onLingualSide } = attachTmp[i]
          //press
          if (pressIDs.includes(attachmentId)) {
            if (!obj[key]) {
              obj[key] = {}
            }
            if (onLingualSide) {
              obj[key].lingual = {
                icon: svgs.pressure,
              }
            } else {
              obj[key].buccal = {
                icon: svgs.pressure,
              }
            }
          }
          //cutout
          if (cutoutIDs.includes(attachmentId)) {
            if (!obj[key]) {
              obj[key] = {}
            }

            if (onLingualSide) {
              obj[key].lingual = {
                icon: key <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
              }
            } else {
              obj[key].buccal = {
                icon: key <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
              }
            }
          }
          //slit
          if (slitIDs.includes(attachmentId)) {
            if (!obj[key]) {
              obj[key] = {}
            }

            if (onLingualSide) {
              obj[key].lingual = {
                icon:
                  (key >= 1 && key <= 8) || (key >= 17 && key <= 24)
                    ? svgs.slit13
                    : svgs.slit24,
              }
            } else {
              obj[key].buccal = {
                icon:
                  (key >= 1 && key <= 8) || (key >= 17 && key <= 24)
                    ? svgs.slit13
                    : svgs.slit24,
              }
            }
          }
        }
      }
    }
    setData(obj)
  }, [attachment])
  return (
    <Box className={"auxWrap"}>
      <div className={"legendsBox"}>
        {legendsTmp.map((i) => {
          return (
            <div className={"container"} key={i.key}>
              <img src={i.icon} />
              <p>{i.name}</p>
            </div>
          )
        })}
      </div>
      <div className={"content"}>
        <div className={"leftBox"}>
          <div className="names">
            <p>Lingual</p>
            <p>Buccal</p>
            <p>Lingual</p>
          </div>
          <div className={"quaBox"}>
            <div className={"qua qua1"}>
              {qua1Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility:
                          auxData[i] && auxData[i].lingual
                            ? "visible"
                            : "hidden",
                        backgroundImage:
                          auxData[i] && auxData[i].lingual
                            ? `url(${auxData[i].lingual.icon})`
                            : "none",
                        border: "none",
                      }}
                    ></div>
                    <div className="commonBox commonCenter">{leftNums[j]}</div>
                    {/* buccal */}
                    {!toothlist.includes(i) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage:
                            auxData[i] && auxData[i].buccal
                              ? `url(${auxData[i].buccal.icon})`
                              : "none",
                          border:
                            auxData[i] && auxData[i].buccal
                              ? "none"
                              : "1px solid #455A64",
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={"qua qua4"}>
              {qua4Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* buccal */}
                    {!toothlist.includes(i) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage:
                            auxData[i] && auxData[i].buccal
                              ? `url(${auxData[i].buccal.icon})`
                              : "none",
                          border:
                            auxData[i] && auxData[i].buccal
                              ? "none"
                              : "1px solid #455A64",
                        }}
                      ></div>
                    )}
                    <div className="commonBox commonCenter">{leftNums[j]}</div>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility:
                          auxData[i] && auxData[i].lingual
                            ? "visible"
                            : "hidden",
                        backgroundImage:
                          auxData[i] && auxData[i].lingual
                            ? `url(${auxData[i].lingual.icon})`
                            : "none",
                        border: "none",
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
            <div className={"direction"} style={{ top: "0", right: "-8px" }}>
              R
            </div>
          </div>
        </div>
        <div className={"leftBox rightBox"}>
          <div className={"quaBox"}>
            <div className={"qua qua2"}>
              {qua2Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility:
                          auxData[i] && auxData[i].lingual
                            ? "visible"
                            : "hidden",
                        backgroundImage:
                          auxData[i] && auxData[i].lingual
                            ? `url(${auxData[i].lingual.icon})`
                            : "none",
                        border: "none",
                      }}
                    ></div>
                    <div className="commonBox commonCenter">{rightNums[j]}</div>
                    {/* buccal */}
                    {!toothlist.includes(i) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage:
                            auxData[i] && auxData[i].buccal
                              ? `url(${auxData[i].buccal.icon})`
                              : "none",
                          border:
                            auxData[i] && auxData[i].buccal
                              ? "none"
                              : "1px solid #455A64",
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={"qua qua3"}>
              {qua3Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* buccal */}
                    {!toothlist.includes(i) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage:
                            auxData[i] && auxData[i].buccal
                              ? `url(${auxData[i].buccal.icon})`
                              : "none",
                          border:
                            auxData[i] && auxData[i].buccal
                              ? "none"
                              : "1px solid #455A64",
                        }}
                      ></div>
                    )}

                    <div className="commonBox commonCenter">{rightNums[j]}</div>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility:
                          auxData[i] && auxData[i].lingual
                            ? "visible"
                            : "hidden",
                        backgroundImage:
                          auxData[i] && auxData[i].lingual
                            ? `url(${auxData[i].lingual.icon})`
                            : "none",
                        border: "none",
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
            <div className={"direction"} style={{ top: "0", left: "-8px" }}>
              L
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default Auxilliaries
