import { ChangeEvent, FC, FocusEventHandler, ReactNode } from "react"
import React, { RefObject } from "react"

import { TextField } from "../../mui.components"

export interface IFormInput {
  inputLabel: string
  helperText?: string | null
  fieldName: string
  endAdornmentIcon?: ReactNode | null
  fieldType?: "text" | "password"
  onInputchange?: (value: string) => void
  onBlurChange?: () => void
  onFocusChange?: () => void
  value?: string
  hasError?: boolean
  placeholder?: string
  inputRef?: RefObject<HTMLInputElement>
}

const UTextField: FC<IFormInput> = ({
  fieldType,
  fieldName,
  inputLabel,
  placeholder,
  endAdornmentIcon,
  onInputchange,
  onBlurChange,
  onFocusChange,
  hasError,
  value,
  inputRef,
}) => {
  return (
    <TextField
      error={hasError}
      fullWidth
      inputRef={inputRef}
      type={fieldType}
      id={fieldName}
      label={inputLabel}
      name={fieldName}
      placeholder={placeholder}
      defaultValue={value || ""}
      onBlur={(e) => {
        onBlurChange && e.target.value !== "" && onBlurChange()
      }}
      onFocus={(e) => {
        onFocusChange && e.target.value !== "" && onFocusChange()
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
        onInputchange && onInputchange(e.target.value)
      }}
      InputProps={{
        endAdornment: endAdornmentIcon,
      }}
      InputLabelProps={placeholder ? { shrink: true } : {}}
    />
  )
}

UTextField.defaultProps = {
  endAdornmentIcon: null,
  onInputchange: undefined,
  fieldType: "text",
  hasError: false,
}

export default UTextField
