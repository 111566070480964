import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  addNewCaseService,
  addNewPatientService,
  editClinicalSettingService,
  fetchCaseByCaseIdService,
  fetchPatientByIdService,
  getPatientList,
  updateCaseService,
} from "./patient.service"
import {
  AddNewPatientParams,
  CreateCaseParams,
  PatientQueryparams,
  UpdateCaseParams,
} from "./patient.types"

export const fetchPatientList = createAsyncThunk(
  "patientService/fetchPatientList",
  async (
    { patientQueryparams }: { patientQueryparams: PatientQueryparams },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      patientQueryparams = {
        ...patientQueryparams,
        sort_type: "desc",
        sort_by: "id",
      }
      return await getPatientList(orgId, patientQueryparams)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const addNewPatient = createAsyncThunk(
  "patientService/addNewPatient",
  async (
    addNewPatientParams: AddNewPatientParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await addNewPatientService(orgId, addNewPatientParams)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const addNewCase = createAsyncThunk(
  "patientService/addNewCaseService",
  async (
    { patientId, payload }: CreateCaseParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await addNewCaseService(orgId, patientId, payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateCase = createAsyncThunk(
  "patientService/updateCase",
  async (
    { patientId, caseId, payload }: UpdateCaseParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return updateCaseService(orgId, patientId, caseId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchPatientById = createAsyncThunk(
  "patientService/fetchPatientById",
  async ({ patientId }, { rejectWithValue, getState }) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await fetchPatientByIdService(orgId, patientId)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchCaseByCaseId = createAsyncThunk(
  "patientService/fetchCaseByCaseId",
  async ({ patientId, caseId }, { rejectWithValue, getState }) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await fetchCaseByCaseIdService(orgId, patientId, caseId)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const editClinicalSetting = createAsyncThunk(
  "patientService/editClinicalSetting",
  async ({ payload }, { rejectWithValue, getState }) => {
    try {
      return editClinicalSettingService(payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
