import {
  defaultData as otherdata,
  jsonSchemaDefault as otherschema,
  uiSchemaDefault as otheruischema,
} from "../../../../rx-form/Json/deepBiteForm"
import {
  defaultData,
  jsonSchemaDefault,
  uiSchemaDefault,
} from "../../../../rx-form/Json/prescriptionForm"
import http from "../../../global/http"

export const fetchFormData = async (orgId: string) => {
  return {
    defaultData,
    jsonSchemaDefault,
    uiSchemaDefault,
    otherdata,
    otherschema,
    otheruischema,
  }
}

export const fetchFormList = async (orgId: string) => {
  const url = `/shared/v1/org/${orgId}/forms`
  try {
    const res = await http.get(url)
    return res
  } catch (err) {
    return err
  }
}
export const saveUassistFormDataService = async (
  orgId: string,
  formId: string,
  payload: string,
): Promise<any> => {
  try {
    const { data } = await http.put(
      `shared/v1/org/${orgId}/forms/forms/${formId}/default-data/`,
      { form_default_data: payload, org_id: orgId },
    )
    return data
  } catch (e) {
    console.log(e)
  }
}
