import * as React from "react"
import { useEffect, Fragment } from "react"
import { RootState } from "../../../core/app/store"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

import { Box, Stack, Grid, Typography, Divider } from "@mui/material"
import { FormControlLabel, Checkbox, Slider } from "@mui/material"

import { FlowNameSliderGrid, FlowBtnGroup } from "../../Custom/FlowCustom"

import { gdsPlusSetup, IPRRange } from "@ulab/wesmileclinical"
import { stagingManager } from "@ulab/wesmileclinical"

const DrawIPRSpace = (props) => {
  const { disabled, toothIPRSpace, selectedTooth } = useAppSelector(
    (state: RootState) => state.autoSetupSlice,
  )

  const { toothId } = useAppSelector((state: RootState) => state.toothInfoSlice)
  const upperArchIPRBtns = [
    { label: "Ant. Only", name: "upperAnt" },
    { label: "Full", name: "upperFull" },
    { label: "Inc. Only", name: "upperInc" },
  ]

  const lowerArchIPRBtns = [
    { label: "Ant. Only", name: "lowerAnt" },
    { label: "Full", name: "lowerFull" },
    { label: "Inc. Only", name: "lowerInc" },
  ]

  const toothIprSpaceSlidersPrev = [
    toothIPRSpace.IPRToPrev,
    toothIPRSpace.SpaceToPrev,
  ]
  const toothIprSpaceSlidersNext = [
    toothIPRSpace.IPRToNext,
    toothIPRSpace.SpaceToNext,
  ]
  const { isShow, data } = useAppSelector(
    (state: RootState) => state.toothInfoSlice,
  ) // tooth info data
  const dispatch = useAppDispatch()

  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "upperAnt":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.AntOnly)
        break
      case "upperFull":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.Full)
        break
      case "upperInc":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.IncisorOnly)
        break
      case "lowerAnt":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.AntOnly)
        break
      case "lowerFull":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.Full)
        break
      case "lowerInc":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.IncisorOnly)
        break
    }
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
  }

  useEffect(() => {
    console.log("selected tooth changed :")
    dispatch({ type: "autosetup/updateSelTooth", payload: toothId })
    dispatch({ type: "autosetup/updateState" })
  }, [isShow, toothId, data])

  useEffect(() => {
    console.log("Start DrawIPRSpace")
    //gdsPlusSetup.SwitchAutoSetupModule(true)
    return () => {
      console.log("End DrawIPRSpace")
      //gdsPlusSetup.SwitchAutoSetupModule(false)
      //const payload = true
      //dispatch({ type: "autosetup/setDisable", payload })
    }
  }, [])

  const onSliderChange = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    const payload = { variable: variable, value: value }
    let type = "autosetup/setAnterior"
    if (variable.includes("Archform")) type = "autosetup/setArchform"
    else if (variable.includes("AntIncl")) type = "autosetup/setRootTorque"
    else if (variable.includes("IPRTo") || variable.includes("SpaceTo"))
      type = "autosetup/setToothIPRSpace"
    dispatch({
      type: type,
      payload: payload,
    })
  }
  const onSliderChangeCommitted = (value: number, variable: string) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamDouble(variable, value)
    dispatch({ type: "autosetup/updateState" })
  }

  const onSelToothFixedChange = () => {
    let newFixed = !selectedTooth.isFixed
    gdsPlusSetup.SetToothFixed(selectedTooth.ID, newFixed)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
    dispatch({ type: "autosetup/updateSelTooth", payload: selectedTooth.ID })
  }
  const onSelIPRSpaceFixedChange = (isToNext) => {
    let oldFixed = isToNext
      ? selectedTooth.isFixedIprSpaceNext
      : selectedTooth.isFixedIprSpacePrev
    gdsPlusSetup.SetToothIPREnable(!oldFixed, isToNext)
    dispatch({ type: "autosetup/updateState" })
    dispatch({ type: "autostaging/updateState" })
    dispatch({ type: "autosetup/updateSelTooth", payload: selectedTooth.ID })
  }

  const SideIPRSpaceFixed = (props) => {
    return (
      <Grid container spacing={0.5} alignItems="center">
        <Grid item xs={6}>
          {props.isToNext ? "IPR/Space: To Next" : "IPR/Space: To Prev"}
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  props.isToNext
                    ? selectedTooth.isFixedIprSpaceNext
                    : selectedTooth.isFixedIprSpacePrev
                }
                onChange={() => {
                  onSelIPRSpaceFixedChange(props.isToNext)
                }}
                size="small"
              />
            }
            label="Fixe IPR/Space"
          />
        </Grid>
      </Grid>
    )
  }

  const SimpleSelTooth = () => {
    let labelWithId = "Sel. Tooth: "
    if (isShow) labelWithId = labelWithId + selectedTooth.ID
    return (
      <Fragment>
        <Grid container spacing={0.5} alignItems="center">
          <Grid item xs={4}>
            {labelWithId}
          </Grid>
          {isShow ? (
            <Grid item xs={8}>
              <FormControlLabel
                disabled={disabled || selectedTooth.ID <= 0}
                control={
                  <Checkbox
                    checked={selectedTooth.isFixed}
                    onChange={onSelToothFixedChange}
                    size="small"
                  />
                }
                label="Fixed(Not Move)"
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Fragment>
    )
  }

  const archformBtns = [{ label: "Close space", name: "CloseSpace" }]

  const antBtns = [
    { label: "Ant 0mm", name: "OJImprove" },
    { label: "Ant 0.5mm", name: "OJHalfMm" },
    { label: "Ant 1.0mm", name: "OJOneMm" },
  ]
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">Arch IPR/Space</Typography>
      <FlowBtnGroup
        lineLabel={"Upper"}
        disabled={disabled}
        btnGroup={upperArchIPRBtns}
        onButtonClick={onButtonClick}
      />
      <FlowBtnGroup
        lineLabel={"Lower"}
        disabled={disabled}
        btnGroup={lowerArchIPRBtns}
        onButtonClick={onButtonClick}
      />
      <Divider />
      {!disabled ? (
        <Fragment>
          <SimpleSelTooth />
          {isShow ? (
            <Fragment>
              <SideIPRSpaceFixed isToNext={false} />
              <FlowNameSliderGrid
                params={toothIprSpaceSlidersPrev}
                onChangeFunc={onSliderChange}
                onChangeCommittedFunc={onSliderChangeCommitted}
                disabled={disabled}
              />
              <SideIPRSpaceFixed isToNext={true} />
              <FlowNameSliderGrid
                params={toothIprSpaceSlidersNext}
                onChangeFunc={onSliderChange}
                onChangeCommittedFunc={onSliderChangeCommitted}
                disabled={disabled}
              />
            </Fragment>
          ) : (
            <></>
          )}
        </Fragment>
      ) : (
        <></>
      )}
    </Stack>
  )
}

export default DrawIPRSpace
