import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactSVG } from "react-svg"
import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { caseManagement } from "@ulab/wesmileclinical"

import Close from "../../../../assets/svgs/close.svg"
import Lower from "../../../../assets/svgs/lower.svg"
import Next from "../../../../assets/svgs/next.svg"
import Pre from "../../../../assets/svgs/pre.svg"
import Upper from "../../../../assets/svgs/upper.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import Drag from "../../../../components/Drag/Drag"
import { UText } from "../../../../ui-component"

import "./movement.scss"

const Movement: FC<{ open: boolean; setOpen: (arg: boolean) => void }> = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation("common")
  const [isUpper, setisUpper] = useState(true)
  // total:编号为stageLength，实际显示是stageLength-1数据; 顺序是total，1，2，3，4，......stageLength-2,stageLength-1；stage 0不显示
  const [currentStage, setcurrentStage] = useState(0)

  const [isDisableDrag, setisDisableDrag] = useState(false)

  const [stageLength, setstageLength] = useState(0)

  const [tableData, settableData] = useState([])

  useEffect(() => {
    if (!open) return
    const report = caseManagement.getToothReports(isUpper)

    setstageLength(report.size)
    // 默认显示total数据
    setcurrentStage(report.size)
  }, [open, isUpper])

  /**
   *  获取当前stage的数据
   */
  useEffect(() => {
    if (!open) return
    const report = caseManagement.getToothReports(isUpper)
    const currentPageReport = report.get(
      currentStage === stageLength ? stageLength - 1 : currentStage,
    )

    let res = [[], [], [], [], [], []]
    // table的行数
    for (let i = 0; i < 6; i++) {
      // table的列数
      for (let y = 0; y < 16; y++) {
        res[i].push("-")
      }
    }
    currentPageReport.forEach(({ id, distanceRotate }) => {
      res = [
        ...res.map((item, index) => {
          const value = distanceRotate[index].toString()

          /**
           *  id 为 wasm tooth id,初始值 = 1
           */
          if (isUpper) {
            item[id - 1] = value
          } else {
            item[32 - id] = value
          }
          return item
        }),
      ]
    })

    settableData([
      {
        key: t("treat.movement.messial"),
        name: 0,
        unit: t("treat.movement.unit"),
        value: res[0],
      },
      {
        key: t("treat.movement.buccal"),
        name: 1,
        unit: t("treat.movement.unit"),
        value: res[1],
      },
      {
        key: t("treat.movement.extrusion"),
        name: 2,
        unit: t("treat.movement.unit"),
        value: res[2],
      },
      {
        key: t("treat.movement.torque"),
        name: 3,
        value: res[3],
      },
      {
        key: t("treat.movement.tipping"),
        name: 4,
        value: res[4],
      },
      {
        key: t("treat.movement.rotation"),
        name: 5,
        value: res[5],
      },
    ])
  }, [currentStage])

  /**
   * 仅total stage有tooth背景色
   * @param id :wasm tooth id, 从上牙右边智齿开始算初始值 = 1，顺时针递增
   * @param type: 0:Mesial/Distal;1:Buccal/Lingual;2:Extrusion/Intrusion;3:Torque;4:Tipping;5:Rotation
   * @returns 当前id牙齿的背景色
   */
  const getToothColor = ({ id, type, value }) => {
    if ((value === "-" || currentStage !== stageLength) && isUpper) {
      // 上牙左右牙的默认背景色
      return id < 9 ? "rgba(206, 147, 216, 0.12)" : "rgba(144, 202, 249, 0.12)"
    }

    if ((value === "-" || currentStage !== stageLength) && !isUpper) {
      // 下牙左右牙的默认背景色
      return 32 - id < 9
        ? "rgba(206, 147, 216, 0.12)"
        : "rgba(144, 202, 249, 0.12)"
    }
    // 背景色临界值
    let yellow, red
    // 位移量的绝对值
    const _value = Math.abs(value)

    /**
     * 切牙/尖牙Distal/Mesial 边界值
     */
    if (
      (toothTools.isIncisorTeeth(id) || toothTools.isCaninesTeeth(id)) &&
      type === 0
    ) {
      red = 1
    } else if (
      /**
       * 切牙/尖牙/前磨牙/磨牙Lingual 边界值
       */
      (toothTools.isIncisorTeeth(id) ||
        toothTools.isCaninesTeeth(id) ||
        toothTools.isPremolarsTeeth(id) ||
        toothTools.isMolarTeeth(id)) &&
      type === 1 &&
      value.indexOf("-") !== -1
    ) {
      yellow = 1
      red = 2
    } else if (
      (toothTools.isIncisorTeeth(id) ||
        toothTools.isCaninesTeeth(id) ||
        toothTools.isPremolarsTeeth(id) ||
        toothTools.isMolarTeeth(id)) &&
      type === 1 &&
      value.indexOf("-") === -1
    ) {
      /**
       * 切牙/尖牙/磨牙/前磨牙的buccal边界值
       */
      yellow = 2
      red = 3
    } else if (
      /**
       * 切牙/尖牙的Extrusion边界值
       */
      (toothTools.isIncisorTeeth(id) || toothTools.isCaninesTeeth(id)) &&
      type === 2 &&
      value.indexOf("-") === -1
    ) {
      yellow = 2.5
      red = 3
    } else if (
      /**
       * 切牙/尖牙/磨牙/前磨牙的Intrusion边界值
       */
      (toothTools.isIncisorTeeth(id) ||
        toothTools.isCaninesTeeth(id) ||
        toothTools.isPremolarsTeeth(id) ||
        toothTools.isMolarTeeth(id)) &&
      type === 2 &&
      value.indexOf("-") !== -1
    ) {
      yellow = 0.5
      red = 1
    } else if (toothTools.isCaninesTeeth(id) && type === 3) {
      /**
       * 尖牙的Torque边界值
       */
      red = 1
    } else if (toothTools.isIncisorTeeth(id) && type === 3) {
      /**
       * 切牙的Torque边界值
       */
      yellow = 10
      red = 15
    } else if (
      /**
       * 中切牙的Rotate边界值
       */
      toothTools.isIncisorTeeth(id) &&
      type === 5 &&
      (id === 8 || id === 9 || id === 24 || id === 25)
    ) {
      yellow = 40
      red = 50
    } else if (
      /**
       * 侧切牙的Rotate边界值
       */
      toothTools.isIncisorTeeth(id) &&
      type === 5 &&
      id !== 8 &&
      id !== 9 &&
      id !== 24 &&
      id !== 25
    ) {
      yellow = 30
      red = 40
    } else if (toothTools.isCaninesTeeth(id) && type === 5) {
      /**
       * 尖牙的Rotate边界值
       */
      yellow = 45
      red = 55
    } else if (toothTools.isPremolarsTeeth(id) && type === 5) {
      /**
       * 前磨牙的Rotate边界值
       */
      yellow = 20
      red = 30
    } else if (
      /**
       * 前磨牙/磨牙的Mesial边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 0 &&
      value.indexOf("-") === -1
    ) {
      yellow = 1
      red = 2
    } else if (
      /**
       * 前磨牙/磨牙的Distal边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 0 &&
      value.indexOf("-") !== -1
    ) {
      if (id < 17) {
        yellow = 2
        red = 4
      } else {
        red = 1
      }
    } else if (
      /**
       * 前磨牙/磨牙的Extrusion边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 2 &&
      value.indexOf("-") === -1
    ) {
      yellow = 0.1
      red = 0.5
    } else if (
      /**
       * 前磨牙/磨牙的Torque边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 3
    ) {
      yellow = 5
      red = 10
    } else if (toothTools.isMolarTeeth(id) && type === 5) {
      /**
       * 磨牙的Rotation边界值
       */
      yellow = 20
      red = 30
    }

    /**
     *  Torque类型判断真实值，非Torque类型判断绝对值
     */
    if (red && (type === 3 ? Number(value) > red : _value > red)) {
      return "rgba(244, 67, 54, 0.50)"
    }

    if (yellow && (type === 3 ? Number(value) > yellow : _value > yellow)) {
      return "rgba(255, 167, 38, 0.50)"
    }

    // 返回默认背景色
    if (isUpper) {
      return id < 9 ? "rgba(206, 147, 216, 0.12)" : "rgba(144, 202, 249, 0.12)"
    }

    if (!isUpper) {
      return 32 - id < 9
        ? "rgba(206, 147, 216, 0.12)"
        : "rgba(144, 202, 249, 0.12)"
    }
  }

  const getTable = () => {
    return (
      <TableContainer component={"div"}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: "none" }}></TableCell>
              {[8, 7, 6, 5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 7, 8].map(
                (item, index) => {
                  const isLeft = index < 8
                  return (
                    <TableCell
                      key={index}
                      align="center"
                      sx={{ borderBottom: "none" }}
                    >
                      <Box
                        sx={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: "#fff",
                          height: 20,
                          width: 20,
                          textAlign: "center",
                          borderBottom: isUpper ? "1px solid" : "none",
                          borderRight: isLeft ? "1px solid" : "none",
                          borderLeft: !isLeft ? "1px solid" : "none",
                          borderTop: !isUpper ? "1px solid" : "none",
                          lineHeight: "20px",
                          display: "inline-block",
                        }}
                      >
                        {item}
                      </Box>
                    </TableCell>
                  )
                },
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow
                key={row.key}
                sx={{
                  "&:last-child td, &:last-child th": { border: "none" },
                  height: 32,
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "0px",
                    fontSize: 10,
                    fontWeight: 500,
                    color: "rgba(255, 255, 255)",
                    lineHeight: "32px",
                    borderBottom: "none",
                  }}
                >
                  {row.key}
                  {row.unit && (
                    <Box
                      component={"span"}
                      sx={{ color: "rgba(255, 255, 255, 0.70)" }}
                    >
                      {row.unit}
                    </Box>
                  )}
                </TableCell>
                {row.value.map((item, index) => {
                  let bgColor = ""

                  if (isUpper) {
                    bgColor = getToothColor({
                      id: index + 1,
                      type: row.name,
                      value: item,
                    })
                  } else {
                    bgColor = getToothColor({
                      id: 32 - index,
                      type: row.name,
                      value: item,
                    })
                  }
                  let value = (Math.round(item * 10) / 10).toString()
                  if (value === "0" || value === "-0" || value === "NaN") {
                    value = "-"
                  } else {
                    if (value.indexOf(".") === -1) {
                      value += ".0"
                    }
                    if (value.indexOf("-") === -1) {
                      value = "+" + value
                    }
                  }
                  return (
                    <TableCell
                      key={item + index}
                      align="center"
                      sx={{
                        backgroundColor: bgColor,
                        color: "#FFF",
                        fontSize: 12,
                        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                        borderTop: "1px solid rgba(255, 255, 255, 0.12)",
                        width: 56,
                        padding: "6px 8px",
                        letterSpacing: "0.4px",
                      }}
                    >
                      {value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const handlePage = (action: "pre" | "next") => {
    // currentStage = 1时
    if (action === "pre" && currentStage === 1) {
      setcurrentStage(stageLength)
      return
    }
    // currentStage 非total非1时
    if (action === "pre" && currentStage < stageLength) {
      setcurrentStage(currentStage - 1)
      return
    }
    // currentStage = total时
    if (action === "next" && currentStage === stageLength) {
      setcurrentStage(1)
      return
    }
    // currentStage 非total非last stage时
    if (action === "next" && currentStage < stageLength - 1) {
      setcurrentStage(currentStage + 1)
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 336,
        width: 120,
        backgroundColor: "#2E2E2E",
        color: "#FFF",
      },
    },
  }
  if (!open) return <></>
  return (
    <Drag
      DragPosition={{
        x: document.getElementById("treatMain")?.clientWidth / 2 - 550,
        y: 56,
      }}
      disableDragging={isDisableDrag}
    >
      <Box
        sx={{
          padding: "8px 24px 16px 24px",
          backgroundColor: "#37474F",
          border: "1px solid #455A64",
        }}
      >
        <Box
          id={"title"}
          sx={{
            height: 48,
            padding: "8px 16px",
            color: "#FFF",
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: "0.17px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
          }}
        >
          <Box component={"span"}>{t("treat.movement.title")}</Box>
          <SvgBtn
            svg={Close}
            clickFun={() => {
              setOpen(false)
            }}
            svgSx={{ marginTop: 7 }}
          ></SvgBtn>
        </Box>
        <Box id={"movement"} sx={{ width: "100%", marginTop: 1 }}>
          <Box
            id={"subtitle"}
            sx={{
              height: 42,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "9px 16px",
              }}
              onClick={() => {
                setisUpper(true)
              }}
            >
              <ReactSVG
                src={Upper}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  if (isUpper) {
                    path?.setAttribute("fill", "#90CAF9")
                    path?.setAttribute("fill-opacity", "1")
                  } else {
                    path?.setAttribute("fill", "white")
                    path?.setAttribute("fill-opacity", "0.56")
                  }
                }}
                style={{ marginRight: 8.5 }}
              />
              <UText
                variant="body2"
                sxProp={{
                  color: isUpper ? "#90CAF9" : "rgba(255, 255, 255, 0.70)",
                  fontWeight: 500,
                }}
              >
                {t("treat.movement.upper")}
              </UText>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "9px 16px",
              }}
              onClick={() => {
                setisUpper(false)
              }}
            >
              <ReactSVG
                src={Lower}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  if (!isUpper) {
                    path?.setAttribute("fill", "#90CAF9")
                    path?.setAttribute("fill-opacity", "1")
                  } else {
                    path?.setAttribute("fill", "white")
                    path?.setAttribute("fill-opacity", "0.56")
                  }
                }}
                style={{ marginRight: 8.5 }}
              />
              <UText
                variant="body2"
                sxProp={{
                  color: !isUpper ? "#90CAF9" : "rgba(255, 255, 255, 0.70)",
                  fontWeight: 500,
                }}
              >
                {t("treat.movement.lower")}
              </UText>
            </Box>
          </Box>
          <Box id={"table"}>{getTable()}</Box>
          <Box
            id="foot"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: 40,
              marginTop: 1,
            }}
          >
            <Select
              labelId="stage"
              value={currentStage}
              onChange={({ target }) => {
                setcurrentStage(Number(target?.value))
              }}
              onOpen={() => {
                setisDisableDrag(true)
              }}
              onClose={() => {
                setisDisableDrag(false)
              }}
              MenuProps={MenuProps}
              className={isDisableDrag ? "open-select" : ""}
              sx={{
                width: 120,
                height: "100%",
                color: "#FFF",
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: "0.17px",
                cursor: "pointer",
              }}
            >
              <MenuItem value={stageLength}>Total</MenuItem>
              {(function () {
                const res = []
                for (let index = 1; index < stageLength; index++) {
                  res.push(
                    <MenuItem
                      key={index}
                      value={index}
                      sx={{
                        height: 32,
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.08)",
                        },
                      }}
                    >
                      {"Stage" + index}
                    </MenuItem>,
                  )
                }
                return res
              })()}
            </Select>

            <SvgBtn
              svg={Pre}
              clickFun={() => {
                handlePage("pre")
              }}
              isdisable={currentStage === stageLength}
              boxSx={{ marginLeft: 1.5, marginRight: 3 }}
            ></SvgBtn>
            <SvgBtn
              svg={Next}
              clickFun={() => {
                handlePage("next")
              }}
              isdisable={currentStage + 1 === stageLength}
            ></SvgBtn>
          </Box>
        </Box>
      </Box>
    </Drag>
  )
}

export default Movement
