import { attachmentModule } from "../attachment/attachmentModule";
import { wasmModule } from "../wasm/wasmModule";
import {
  EInitialfinalEvent,
  initialfinalModule,
} from "../initialfinal/initialfinalmodule";
import { GlobalEvents, globalEvents } from "../../../utils/globalevents";

/**
 * check is in split screen status
 * UDesignModuleManager::IsSplitScreenOpened
 */

class DisplayModule {
  private _showType: "left" | "right" | "front" | "back" | "split" = "front";
  private _showMode: "up" | "low" | "all" = "all";
  private _isSpliteMode = false;
  private _zoomRange: [number, number] = [0.25, 2.0];
  private _zoomCb?: (val: number) => void;

  private _overlayStatus = false;

  get zoomCallback() {
    return this._zoomCb;
  }
  getShowType() {
    return this._showMode;
  }

  setShowType(type: "left" | "right" | "front" | "back" | "split") {
    this._showType = type;
    this._checkIsInSplitMode();
    if (this.zoomCallback) {
      this.zoomCallback(1.0);
    }
    globalEvents.fire(GlobalEvents.VIEW_SWITCH_TYPE, type);
  }

  private _checkIsInSplitMode() {
    if (this._showType === "split" && this._showMode === "all") {
      this._isSpliteMode = true;
    } else {
      this._isSpliteMode = false;
    }

    if (this._isSpliteMode === true) {
      wasmModule.moduleManager
        .GetSplitScreenModule()
        .SetMinScaleValue(this._zoomRange[0]);
      wasmModule.moduleManager
        .GetSplitScreenModule()
        .SetMaxScaleValue(this._zoomRange[1]);
      if (!(window as any).treatment) {
        (window as any).treatment = {};
      }
      (window as any).treatment.viewZoomCallbackInside = this._zoomCb;
      wasmModule.moduleManager
        .GetSplitScreenModule()
        .SetUpdateSliderCB("treatment.viewZoomCallbackInside");
    }
  }

  /**
   * set callback which emited on zoom in WASM
   * @param cb
   */
  setZoomCallback(range: [number, number], cb: (val: number) => void) {
    if (!wasmModule.isWASMInit) {
      return;
    }

    // uninside
    wasmModule.moduleManager.GetMainWindowModule().SetMinScaleValue(range[0]);
    wasmModule.moduleManager.GetMainWindowModule().SetMaxScaleValue(range[1]);

    this._zoomRange = range;
    if (cb) {
      this._zoomCb = cb;
    }

    if (!(window as any).treatment) {
      (window as any).treatment = {};
    }
    (window as any).treatment.viewZoomCallback = cb;
    wasmModule.moduleManager
      .GetMainWindowModule()
      .SetUpdateSliderCB("treatment.viewZoomCallback");
  }

  /**
   * invoted from FE to zoom
   * @param val the value of scale
   * @returns
   */
  zoomWithValue(scale: number) {
    if (
      !wasmModule.isWASMInit ||
      !wasmModule.moduleManager.GetSplitScreenModule
    ) {
      return;
    }

    if (this._isSpliteMode === true) {
      wasmModule.moduleManager.GetSplitScreenModule().ZoomAutomatic(scale);
    } else {
      wasmModule.moduleManager.GetMainWindowModule().ZoomAutomatic(scale);
    }
  }

  /**
   * SetToRightBuccalView
   */
  setToRightBuccalView() {
    wasmModule.statusController.SetToRightBuccalView();
  }

  /**
   * SetToAnteriorView
   */
  setToAnteriorView() {
    wasmModule.statusController.SetToAnteriorView();
  }

  /**
   * SetToLeftBuccalView
   */
  setToLeftBuccalView() {
    wasmModule.statusController.SetToLeftBuccalView();
  }

  /**
   * SplitScreenDisplay
   * @param isOn open or close
   */
  splitScreenDisplay(isOn: boolean) {
    if (isOn !== wasmModule.moduleManager.IsSplitScreenOpened()) {
      wasmModule.statusController.SplitScreenDisplay(isOn);
      if (this.zoomCallback) {
        this.zoomCallback(1.0);
      }
    }
  }

  /**
   * show upper or lower arch
   * @param arch 0:upper  1:lower
   */
  showArchByIndex(arch: number) {
    if (arch === 0) {
      wasmModule.statusController.DisplayUpperArch();
      this._showMode = "up";
    } else if (arch === 1) {
      wasmModule.statusController.DisplayLowerArch();
      this._showMode = "low";
    } else {
      wasmModule.statusController.DisplayBothArch();
      this._showMode = "all";
    }
    // this._checkIsInSplitMode();
  }

  switchGum(isShowNewGum: boolean) {
    wasmModule.statusController.SwitchGum(isShowNewGum);
  }

  switchOverlayModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;
    wasmModule.moduleManager.SwitchModuleOverlay(isOpen);
    this._overlayStatus = isOpen;
  }

  opacityOverlay(opacity: number) {
    if (!wasmModule.isInit) return;
    const overlayModule = wasmModule.moduleManager.GetModuleOverlay();
    if (overlayModule) {
      overlayModule.AdjustOpacityForCutOtherArch(opacity);
    } else {
      console.log("overlayModule not find!!!");
    }
  }
}

export const displayModule = new DisplayModule();
initialfinalModule.event.on(EInitialfinalEvent.CLOSED, () => {
  displayModule.zoomWithValue(1.0);
  if (displayModule.zoomCallback) {
    displayModule.zoomCallback(1.0);
  }
});
