const schema = {
  type: "object",
  properties: {
    arch_form_type: {
      type: "string",
      isRadioGroup: true,
      enum: ["Ovoid", "Natural/Maintain Current Arch Form", "Broad/Square"],
    },
    spacing: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        { label: "Contract", value: "contract" },
        { label: "Contract if needed", value: "contractIfNeeded" },
        {
          label: "Retrocline",
          value: "retrocline",
        },
        { label: "Retrocline if needed", value: "retroclineIfNeeded" },
      ],
    },
    level_lower_curve_of_spee: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        { label: "Intrude Lower Canine - Canine", value: "intrudePosterior" },
        { label: "Extrude Lower Premolars", value: "extrudeAnterior" },
        {
          label: "Additional Instructions",
          value: "AdditionalInstructions",
        },
      ],
      input: {
        width: 310,
        const: "AdditionalInstructions",
        minRows: 1,
        name: "instructions",
        multiline: true,
      },
    },
    overbite: {
      type: "string",
      isRadioGroup: true,
      enum: ["2 mm - 3 mm", "Edge to Edge", "Overcorrection"],
      input: {
        width: 310,
        const: "Overcorrection",
        minRows: 1,
        name: "stage",
        multiline: true,
        isNum: true,
      },
    },
    overjet: {
      type: "string",
      isRadioGroup: true,
      enum: ["0 - 2 mm", "2 mm - 3 mm", "Custom Amount"],
      input: {
        width: 310,
        const: "Custom Amount",
        multiline: true,
        minRows: 1,
        name: "stage",
        isNum: true,
      },
    },
    smile_design: {
      type: "string",
      isRadioGroup: true,
      enum: [
        "Smile Arc",
        "Level the incisal edges",
        "Level the canines with the centrals",
        "Level the gingival margins",
      ],
      input: {
        width: 56,
        startLabel: "Step up laterals",
        endLabel: "mm",
        const: "Level the canines with the centrals",
        minRows: 1,
        name: "stage",
        isNum: true,
      },
    },
    stage_ipr: {
      type: "string",
      isRadioGroup: true,
      enum: ["3 - 3", "4 - 4", "5 - 5", "Not applicable"],
    },
    order_of_priority: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Premolars to maximum limit before anterior teeth",
          value: "Premolars to maximum limit before anterior teeth",
        },
        { label: "Not in the first round", value: "Not in the first round" },
      ],
    },
    max_interproximal_conatct: {
      type: "string",
      isRadioGroup: true,
      enum: ["0.2 mm", "0.3 mm", "0.4 mm", "0.5 mm"],
    },
    set_ipr_staging: {
      type: "string",
      isRadioGroup: true,
      enum: ["Preferred Stage(s)", "When contacts align"],
      input: {
        width: 310,
        const: "Preferred Stage(s)",
        minRows: 1,
        multiline: true,
        name: "stage",
        placeholder: "i.e. Stages 0/8/16/24",
      },
    },
    space_closure_requirements: {
      type: "string",
      isRadioGroup: true,
      enum: [
        "Retract Anterior Teeth",
        "Protract Posterior Teeth",
        "Reciprocal Closure",
      ],
    },
    ap_optional: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "AP (optional)",
      properties: {
        correct_to_class_canines: {
          key: "correctToClassCanines",
          title: "Correct to Class I - Canines",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
        correct_to_class_molars: {
          key: "correctToClassMolars",
          title: "Correct to Class I - First Molars",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
      },
    },
    acheived_by: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "Achieved By (optional)",
      properties: {
        bite_jump_simulation: {
          key: "bite_jump_simulation",
          title: "Bite Jump Simulation (Global Movement)",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["At the end", "At the beginning", "Throughout treatment"],
              default: "At the end",
            },
          },
        },
        seq_movements: {
          key: "sequentialMovements",
          title: "Sequential Movements (if applicable)",
        },
        dental_movements: {
          key: "denatlMovementOnly",
          title: "Dental Movement Only",
        },
      },
    },
    elastics: {
      isUCheckboxAccordion: true,
      type: "object",
      title: "Elastics (optional)",
      properties: {
        class_II: {
          key: "class_II",
          title: "Class II",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
        class_III: {
          key: "class_III",
          title: "Class III",
          properties: {
            radioGroup: {
              type: "string",
              isRadioGroup: true,
              enum: ["Both", "Right", "Left"],
              default: "Both",
            },
          },
        },
        custom: {
          key: "custom",
          title: "Custom",
          properties: {
            input: {
              width: 310,
              const: "custom",
              isInput: true,
              multiline: true,
              minRows: 1,
              name: "instructions",
            },
          },
        },
      },
    },
    auxillaries: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Precision Aligner Buttons",
          value: "Precision Aligner Buttons",
        },
        {
          label: "Caplin Hook",
          value: "Caplin Hook",
        },
        {
          label: "Smaller Button",
          value: "Smaller Button",
        },
        { label: "Slits", value: "Slits" },
        { label: "Additional Instructions", value: "Additional Instructions" },
      ],
      input: {
        width: 310,
        const: "Additional Instructions",
        multiline: true,
        minRows: 1,
        name: "stage",
      },
    },
    attachments: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
      input: {
        width: 56,
        startLabel: "Stage",
        defaultValue: "1",
        const: "Yes",
        minRows: 1,
        name: "stage",
        isNum: true,
      },
    },
    add_group_protocol: {
      type: "array",
      isChecboxGroup: true,
      checkboxes: [
        {
          label: "Deep Bite",
          value: "Deep Bite",
        },
        {
          label: "Open Bite",
          value: "Open Bite",
        },
        {
          label: "Distalization (if applicable)",
          value: "Distalization",
        },
        { label: "Custom", value: "Custom" },
      ],
      input: {
        width: 310,
        const: "Custom",
        multiline: true,
        minRows: 1,
        name: "instructions",
      },
    },
    pressure_points: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
      input: {
        width: 310,
        multiline: true,
        const: "Yes",
        minRows: 1,
        name: "stage",
      },
    },
    bite_ramps: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
      checkboxGroup: {
        const: "Yes",
        name: "checkbox",
        checkboxes: [
          {
            label: "Central Incisors",
            value: "Central Incisors",
          },
          {
            label: "Canines",
            value: "Canines",
          },
          {
            label: "Central and Laterals",
            value: "Central and Laterals",
          },
          {
            label: "Custom",
            value: "Custom",
            input: {
              width: 284,
              multiline: true,
              minRows: 1,
              name: "stage",
              const: "Custom",
            },
          },
        ],
        default: "Central Incisors",
      },
    },
    virtual_C_chain: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No", "Not in the initial round"],
      input: {
        width: 80,
        const: "Yes",
        startAdornment: "-",
        defaultValue: "0.01",
        minRows: 1,
        name: "stage",
        endLabel: "Amount of virtual IPR at the last stage",
      },
    },
    velocity: {
      type: "string",
      isRadioGroup: true,
      enum: [
        ".20 mm linear / 2.0° angular",
        ".22 mm linear / 2.2° angular",
        ".25 mm linear / 2.5° angular",
        "Custom",
      ],
      input: {
        width: 310,
        const: "Custom",
        minRows: 1,
        name: "stage",
      },
    },
    equal_no_of_aligners: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes", "No"],
    },
    passive_aligners: {
      type: "string",
      isRadioGroup: true,
      enum: ["Yes (if needed)", "No"],
      input: {
        width: 310,
        const: "Yes (if needed)",
        multiline: true,
        minRows: 1,
        name: "instructions",
        placeholder: "Instructions (optional)",
      },
    },
    wear_time_of_aligners: {
      type: "string",
      title: "Wear Time of Aligners",
      isRadioGroup: true,
      enum: ["7 Days", "14 Days", "custom"],
      input: {
        width: 56,
        showInSameLine: true,
        endLabel: "Days",
        const: "custom",
        minRows: 1,
        name: "customDays",
        isNum: true,
      },
    },
    case_specific_instructions: {
      title: "Spacing Instructions",
      type: "string",
      isTextarea: true,
      isSettings: true,
      placeholder: `Please add instructions for this template here (no abbreviations).\n\nYou will have the opportunity to enter case specific instructions before submitting each case.`,
      options: {
        rows: 6,
      },
    },
  },
  required: [
    "arch_form_type",
    "spacing",
    "level_lower_curve_of_spee",
    "overbite",
    "overjet",
    "smile_design",
    "stage_ipr",
    "max_interproximal_conatct",
    "set_ipr_staging",
    "space_closure_requirements",
    "attachments",
    "pressure_points",
    "auxillaries",
    "bite_ramps",
    "virtual_C_chain",
    "velocity",
    "equal_no_of_aligners",
    "passive_aligners",
    "wear_time_of_aligners",
  ],
}

const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Group",
      label: "How do you prefer to see teeth at the end of treatment?",
      elements: [
        {
          type: "isRadioGroup",
          scope: "#/properties/arch_form_type",
          options: {
            format: "radio",
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/spacing",
        },
      ],
    },
    {
      type: "Group",
      label: "Vertical and AP",
      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/level_lower_curve_of_spee",
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/overbite",
          options: {
            format: "radio",
          },
        },
        {
          type: "isRadioGroup",
          scope: "#/properties/overjet",
          options: {
            format: "radio",
          },
        },
      ],
    },
    {
      type: "Group",
      label: "Detailing",
      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/smile_design",
        },
      ],
    },
    {
      type: "Group",
      label: "IPR",
      elements: [
        {
          type: "Control",
          label: "Please select area(s) to stage IPR",
          scope: "#/properties/stage_ipr",
        },
        {
          type: "Control",
          label: "Order of Priority (optional)",
          scope: "#/properties/order_of_priority",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/stage_ipr",
              schema: { const: "Not applicable" },
            },
          },
        },
        {
          type: "Control",
          label: "Maximum amount per interproximal contact",
          scope: "#/properties/max_interproximal_conatct",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/stage_ipr",
              schema: { const: "Not applicable" },
            },
          },
        },
        {
          type: "Control",
          label: "Set IPR Staging",
          scope: "#/properties/set_ipr_staging",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/stage_ipr",
              schema: { const: "Not applicable" },
            },
          },
        },
      ],
    },
    {
      type: "Group",
      label: "How to achieve the stimulated treatment - biomechanics",
      elements: [
        {
          type: "Control",
          label: true,
          scope: "#/properties/space_closure_requirements",
        },
        {
          type: "Control",
          label: "AP (optional)",
          scope: "#/properties/ap_optional",
        },
        {
          type: "Control",
          label: "Achieved By (optional)",
          scope: "#/properties/acheived_by",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/ap_optional",
              schema: { const: {} },
            },
          },
        },
        {
          type: "Control",
          label: "Elastics (optional)",
          scope: "#/properties/elastics",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/ap_optional",
              schema: { const: {} },
            },
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/auxillaries",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/elastics",
              schema: { const: {} },
            },
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/attachments",
        },
        {
          type: "Control",
          label: "Add Group Protocol (optional)",
          scope: "#/properties/add_group_protocol",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/attachments",
              schema: { const: "No" },
            },
          },
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/pressure_points",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/bite_ramps",
        },
        {
          type: "Control",
          label: "Virtual C-Chain",
          scope: "#/properties/virtual_C_chain",
        },
        {
          type: "Control",
          label: "Velocity - Rate Limiting Factor Stage",
          scope: "#/properties/velocity",
        },
        {
          type: "Control",
          label: "Equal number of aligners (adjust velocity)",
          scope: "#/properties/equal_no_of_aligners",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/passive_aligners",
        },
        {
          type: "Control",
          label: true,
          scope: "#/properties/wear_time_of_aligners",
        },
      ],
    },
    {
      type: "Group",
      label: "Additional Instructions",
      elements: [
        {
          type: "Control",
          scope: "#/properties/case_specific_instructions",
          label: true,
        },
      ],
    },
  ],
}

export const defaultData = {
  arch_form_type: "Ovoid",
  spacing: ["contractIfNeeded", "retroclineIfNeeded"],
  level_lower_curve_of_spee: ["intrudePosterior", "extrudeAnterior"],
  overbite: "2 mm - 3 mm",
  overjet: "0 - 2 mm",
  smile_design: "Smile Arc",
  stage_ipr: "Not applicable",
  space_closure_requirements: "Retract Anterior Teeth",
  ap_optional: {},
  acheived_by: {},
  add_group_protocol: [],
  elastics: {},
  auxillaries: "",
  attachments: "Yes",
  pressure_points: "No",
  bite_ramps: "No",
  virtual_C_chain: "Yes",
  velocity: ".22 mm linear / 2.2° angular",
  equal_no_of_aligners: "Yes",
  passive_aligners: "Yes (if needed)",
  wear_time_of_aligners: "7 Days",
}

export const jsonSchemaDefault = JSON.stringify(schema, null, 2)
export const uiSchemaDefault = JSON.stringify(uischema, null, 2)
