import * as React from "react"
import { useState, useEffect } from "react"
import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import Treat from "./Treat/Treat" // For uSmileAI
import WeSmileTreat from "./Treat/WeSmileTreat"
import WasmMain from "../../wasm3d/WasmMain" // For WeSmileAI
import { resetCase } from "../../core/app/slices/clinical/clinicalSlice"

const ClinicalModule: FC = () => {
  return (
    <Routes>
      <Route path={"treat"} element={<SwitchSmileAI />} />
    </Routes>
  )
}

import { RootState } from "../../core/app/store"
import { useAppSelector, useAppDispatch } from "../../core/app/hooks"

const SwitchSmileAI = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetCase())
  }, [])
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const { VITE_APP_TYPE } = import.meta.env
  // let showWeSmile = weSmileUI && VITE_APP_TYPE == "wesmile"
  // return showWeSmile ? <WasmMain /> : addWeSmile ? <WeSmileTreat /> : <Treat />
  return weSmileUI ? <WeSmileTreat /> : <Treat />
}

export default ClinicalModule
