import Deserialize from "./interface/Deserialize"
import { INewPatientData, IPatient, IPatientList } from "./interface/IPatient"

export default class Patient implements IPatient, Deserialize {
  id = null
  firstName = ""
  lastName = ""
  dob = ""
  address = ""
  street = ""
  city = ""
  state = ""
  zipCode = ""
  email = ""
  phoneNumber = ""
  countrycode = ""

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.firstName
      this.lastName = input.lastName
      this.dob = input.dob
      this.address = input.address
      this.street = input.street
      this.city = input.city
      this.state = input.state
      this.zipCode = input.zipCode
      this.email = input.email
      this.phoneNumber = input.phoneNumber
      this.countrycode = input.countryCode
    }
    return this
  }
}

export class PatientList implements IPatientList, Deserialize {
  id = ""
  firstName = ""
  lastName = ""
  caseDetail = ""
  dmPatientId = ""
  lastMonitoring = ""
  orderStatus = ""
  dob = ""
  status = ""
  dentalMonitoring = ""

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.first_name
      this.lastName = input.last_name
      this.dob = input.patient_dob
      this.caseDetail = input.case_detail
      this.dmPatientId = input.dm_patient_id
      this.orderStatus = input.order_status
      this.status = input.status
      this.dentalMonitoring = input.dental_monitoring
    }
    return this
  }
}

export class PatientData implements INewPatientData {
  id = ""
  firstName = ""
  lastName = ""
  email = ""
  dob = ""

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.first_name
      this.lastName = input.last_name
      this.email = input.email
      this.dob = input.patient_dob
    }
    return this
  }
}
