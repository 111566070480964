import { FC, useEffect, useRef, useState } from "react"
import { Box, Grid } from "@mui/material"
import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  View,
} from "@react-pdf/renderer"
import html2canvas from "html2canvas"

import DentalChart from "../../../components/DentalChart/DentalChart"
import { UButton, UText } from "../../../ui-component"

import Menu from "./component/Menu"

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
})

const exportComponentAsPDF = (
  componentRef: React.RefObject<HTMLDivElement>,
) => {
  if (!componentRef.current) {
    console.warn("the component which is exported to PDF is null !")
    return
  }

  const width = componentRef.current?.clientWidth
  html2canvas(componentRef.current).then(async (canvas) => {
    const imgData = canvas.toDataURL("image/png")
    const MakePDFComponent = () => {
      return (
        <Document>
          <Page style={styles.page} size="letter">
            <View>
              <Image
                style={{ width: width, height: "auto" }}
                source={imgData}
              ></Image>
            </View>
          </Page>
        </Document>
      )
    }

    const blob = await pdf(MakePDFComponent()).toBlob()
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "document.pdf"
    link.click()
  })
}

const DentalPortrait: FC = () => {
  const [menuProps, setMenuProps] = useState<{
    open: boolean
    position: number[]
  }>({ open: false, position: [0, 0] })

  const [appliedTeeth, setappliedTeeth] = useState<
    { teeth: string; checked: string }[]
  >([])
  const [selectedTeethId, setselectedTeethId] = useState<string>("")
  useEffect(() => {
    return () => {
      setMenuProps({ open: false, position: [0, 0] })
    }
  }, [])
  const contentToPDF = useRef(null)

  return (
    <Grid
      sx={{
        width: "100%",
        padding: 5,
        borderRadius: 4,
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
        }}
        ref={contentToPDF}
      >
        <Box sx={{ textAlign: "center" }}>
          <UText
            sxProp={{
              color: "rgba(0, 0, 0, 0.87)",
              width: "100%",
              display: "block",
            }}
            variant={"h4"}
          >
            Dental Chart
          </UText>
          <UText
            sxProp={{
              color: "rgba(0, 0, 0, 0.6)",
              display: "block",
            }}
            variant={"subtitle1"}
          >
            Select tooth indication if needed
          </UText>
          <UText
            variant={"subtitle2"}
            sxProp={{
              color: "#616161",
              fontSize: 12,
              fontStyle: "italic",
              fontWeight: 400,
              letterSpacing: 0.4,
              display: "block",
            }}
          >
            For example: Crown, Implant or Extract
          </UText>
        </Box>
        <DentalChart
          setMenuProps={setMenuProps}
          appliedTeeth={appliedTeeth}
          selectedTeethId={selectedTeethId}
          setselectedTeethId={setselectedTeethId}
        ></DentalChart>
        <Menu
          menuProps={menuProps}
          setMenuProps={setMenuProps}
          appliedTeeth={appliedTeeth}
          selectedTeethId={selectedTeethId}
          setappliedTeeth={setappliedTeeth}
        ></Menu>
      </Box>
      <UButton
        variant={"contained"}
        btnType={"button"}
        btnText={"Next"}
        onClickHandler={async () => {
          exportComponentAsPDF(contentToPDF)
        }}
      />
    </Grid>
  )
}

export default DentalPortrait
