export const formVocabs = {
  class_I: "Class I",
  class_II: "Class II",
  correctToClassCanines: "Correct to Class I - Canines",
  correctToClassMolars: "Correct to Class I - First Molars",
}
const generatePrescriptionDefaultData = (defaultdata) => {
  // TODO: to be changed during api integration
  // TODO: Schema needs to be adjusted to avoid hardcodings here
  defaultdata.arches_to_treat =
    "Upper: Aligner\nBracket Removal, Remove Lingual Bar\n\n Lower: Aligner"
  defaultdata.bite_ramps = "Yes; Central Incisors"
  defaultdata.attachments = "Yes; Stage 1"
  return defaultdata
}

const generatePrescriptionSchema = (originalSchema, tempschema) => {
  originalSchema = JSON.parse(originalSchema)
  tempschema = JSON.parse(tempschema)
  const props = { ...originalSchema.properties, ...tempschema.properties }
  const summaryProperties = {}
  for (const prop in props) {
    const propDetails = props[prop]
    const summaryProperty = {
      title: propDetails.label || propDetails.title,
      type: "string",
      isSummary: true,
      checkbox: propDetails.checkboxes || null,
    }

    if (propDetails.type === "object" && prop !== "arches_to_treat") {
      summaryProperty.ismultiLine = true
    }
    if (
      propDetails.type === "object" &&
      (prop === "ap_optional" || prop === "elastics" || prop === "acheived_by")
    ) {
      summaryProperty.isobj = true
    }
    if (propDetails.type === "array") {
      summaryProperty.isArray = true
    }
    if (
      [
        "deepbite_template_instructions",
        "case_specific_instructions",
        "spacing_template_instructions",
        "crowding_template_instructions",
        "openbite_template_instructions",
        "limitedtreatment_template_instructions",
      ].includes(prop)
    ) {
      summaryProperty.fontweight = 400
    }
    summaryProperties[prop] = summaryProperty
  }

  const summarySchema = {
    type: "object",
    properties: summaryProperties,
  }
  return summarySchema
}

const generatePrescriptionUISchema = (
  prescriptionUiSchema,
  PrescriptionDefaultData,
  otherformsUI,
  otherDefaultData,
) => {
  const prescriptionSchema = {
    type: "Group",
    label: "Review Prescription",
    elements: [],
  }
  JSON.parse(prescriptionUiSchema).elements.forEach((element) => {
    const propName = element.scope.split("/properties/")[1]
    if (propName in PrescriptionDefaultData) {
      const groupElement = {
        type: "isSummary",
        scope: element.scope,
      }
      prescriptionSchema.elements.push(groupElement)
    }
  })

  const otherFormUISchema = {
    type: "AccordionGroup",
    label: PrescriptionDefaultData.smart_rx_template + " smartrx template",
    elements: [],
  }

  for (const element of JSON.parse(otherformsUI).elements) {
    if (element.type === "Group") {
      const summaryGroup = {
        type: "Group",
        label: element.label,
        isSummary: true,
        elements: [],
      }

      for (const groupElement of element.elements) {
        const propertyName = groupElement.scope.substring(1)

        const summaryElement = {
          type: "isSummary",
          label: true,
          scope: groupElement.scope,
        }
        // eslint-disable-next-line no-prototype-builtins
        if (otherDefaultData.hasOwnProperty(propertyName)) {
          summaryElement.default = otherDefaultData[propertyName]
        }

        summaryGroup.elements.push(summaryElement)
      }

      otherFormUISchema.elements.push(summaryGroup)
    }
  }
  for (let i = 1; i < otherFormUISchema.elements.length; i++) {
    otherFormUISchema.elements[i]["top"] = "12px"
  }

  const res = {
    type: "VerticalLayout",
    elements: [prescriptionSchema, otherFormUISchema],
  }

  return res
}

export {
  generatePrescriptionDefaultData,
  generatePrescriptionSchema,
  generatePrescriptionUISchema,
}
