import { FC, useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import { Box } from "@mui/material"

import { default as lowerGum } from "../../assets/svgs/lower-gum.svg"
import { default as upperGum } from "../../assets/svgs/upper-gum.svg"
import {
  dentalChartMenuList,
  number,
  position,
  svgList,
} from "../../modules/Uassist/DentalPortrait/component/toothObject"
interface propsType {
  setMenuProps?: (item: { open: boolean; position: number[] }) => void

  appliedTeeth: { teeth: string; checked: string }[]

  selectedTeethId?: string
  setselectedTeethId?: (item: string) => void
  isReadOnly?: boolean
}
const DentalChart: FC = ({
  setMenuProps,
  appliedTeeth,
  selectedTeethId,
  setselectedTeethId,
  isReadOnly = false,
}: propsType) => {
  const teethAction = (id: string) => {
    if (isReadOnly) return
    const dom = document.getElementById(id)?.getElementsByTagName("div")
    if (!dom) return
    const [_, _div, _svg, ...menuDom] = dom
    menuDom.forEach((element) => {
      // selected
      element.onmouseup = (e) => {
        const x = e.clientX,
          y = e.clientY

        setMenuProps({ open: true, position: [x + 38, y - 150] })
        setselectedTeethId(e?.currentTarget?.parentNode?.children[0]?.id)
      }
    })

    // Hover
    _div.onmouseenter = (e) => {
      const path = e?.currentTarget?.getElementsByTagName("path")[0]
      const isSelect =
        path.getAttribute("fill") === "#01579B" ||
        path.getAttribute("stroke") === "#01579B"
      if (!isSelect) {
        path?.setAttribute("fill", "#BDBDBD")
        path?.setAttribute("stroke", "#D7535E")
      }
    }
    // pressed
    _div.onmousedown = (e) => {
      const path = e?.currentTarget?.getElementsByTagName("path")[0]
      const isSelect =
        path.getAttribute("fill") === "#01579B" ||
        path.getAttribute("stroke") === "#01579B"
      if (!isSelect) {
        path?.setAttribute("fill", "#BDBDBD")
        path?.setAttribute("stroke", "#A0434B")
      }
    }
    // selected
    _div.onmouseup = (e) => {
      const path = e?.currentTarget?.getElementsByTagName("path")[0]
      const isSelect = path.getAttribute("stroke") === "#01579B"
      const x = e.clientX,
        y = e.clientY
      if (!isSelect) {
        setMenuProps({ open: true, position: [x + 30, y - 150] })
        setselectedTeethId(e?.currentTarget?.id)
      } else {
        path?.setAttribute("fill", "white")
        path?.setAttribute("stroke", "#D7535E")
        setMenuProps({ open: false, position: [0, 0] })
        setselectedTeethId("")
      }
    }

    // default
    _div.onmouseleave = (e) => {
      const path = e?.currentTarget?.getElementsByTagName("path")[0]
      const isSelect = path.getAttribute("stroke") === "#01579B"
      const isApplied = path.getAttribute("fill") === "#01579B"
      if (isApplied) {
        return
      }
      if (!isSelect) {
        path?.setAttribute("fill", "white")
        path?.setAttribute("stroke", "#D7535E")
      }
    }
  }

  useEffect(() => {
    if (selectedTeethId) {
      setTimeout(() => {
        const dom = document.getElementById(selectedTeethId)
        const path = dom?.getElementsByTagName("path")[0]
        path?.setAttribute("fill", "white")
        path?.setAttribute("stroke", "#01579B")
      }, 0)
    }
  }, [selectedTeethId])

  const getUpperLeftTooth = () => {
    const res = []
    for (let index = 8; index > 0; index--) {
      res.push(
        <Box key={"ul" + index} id={"ul" + index + "box"}>
          <Box
            component="span"
            sx={{
              position: "absolute",
              left: number["ul" + index][0],
              top: number["ul" + index][1],
              color: "#172B4D",
              fontSize: 16,
              fontWeight: 400,
              userSelect: "none",
            }}
          >
            {index}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: position["ul" + index][0],
              top: position["ul" + index][1],
            }}
          >
            <ReactSVG
              id={"ul" + index}
              src={svgList["ul" + index]}
              afterInjection={(svg) => {
                appliedTeeth.forEach(({ teeth, checked }) => {
                  if (teeth === "ul" + index) {
                    const path = svg.getElementsByTagName("path")[0]
                    path?.setAttribute("fill", "#01579B")
                    path?.setAttribute("stroke", "#D7535E")
                    const id = "ul" + index + checked
                    document.getElementById(id).style.display = "block"
                  }
                })
                teethAction("ul" + index + "box")
              }}
            />
            {dentalChartMenuList.map((item) => {
              const value = item.substring(
                item.lastIndexOf("(") + 1,
                item.length - 1,
              )
              return (
                <Box
                  component={"div"}
                  key={"ul" + index + value}
                  id={"ul" + index + value}
                  sx={{
                    display: "none",
                    color: "#FFF",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 500,
                    position: "absolute",
                    userSelect: "none",
                    marginTop: -0.5,
                  }}
                >
                  {value}
                </Box>
              )
            })}
          </Box>
        </Box>,
      )
    }
    return res
  }

  const getUpperRightTooth = () => {
    const res = []

    for (let index = 8; index > 0; index--) {
      res.push(
        <Box key={"ur" + index} id={"ur" + index + "box"}>
          <Box
            component="span"
            sx={{
              position: "absolute",
              right: number["ur" + index][0],
              top: number["ur" + index][1],
              color: "#172B4D",
              fontSize: 16,
              fontWeight: 400,
              userSelect: "none",
            }}
          >
            {index}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: position["ur" + index][0],
              top: position["ur" + index][1],
            }}
          >
            <ReactSVG
              id={"ur" + index}
              src={svgList["ur" + index]}
              afterInjection={(svg) => {
                appliedTeeth.forEach(({ teeth, checked }) => {
                  if (teeth === "ur" + index) {
                    const path = svg.getElementsByTagName("path")[0]
                    path?.setAttribute("fill", "#01579B")
                    path?.setAttribute("stroke", "#D7535E")
                    const id = "ur" + index + checked
                    document.getElementById(id).style.display = "block"
                  }
                })
                teethAction("ur" + index + "box")
              }}
            />
            {dentalChartMenuList.map((item) => {
              const value = item.substring(
                item.lastIndexOf("(") + 1,
                item.length - 1,
              )
              return (
                <Box
                  component={"div"}
                  key={"ur" + index + value}
                  id={"ur" + index + value}
                  sx={{
                    position: "absolute",
                    display: "none",
                    color: "#FFF",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 500,
                    userSelect: "none",
                    marginTop: -0.5,
                  }}
                >
                  {value}
                </Box>
              )
            })}
          </Box>
        </Box>,
      )
    }
    return res
  }

  const getLowerLeftTooth = () => {
    const res = []

    for (let index = 8; index > 0; index--) {
      res.push(
        <Box key={"dl" + index} id={"dl" + index + "box"}>
          <Box
            component="span"
            sx={{
              position: "absolute",
              left: number["dl" + index][0],
              bottom: number["dl" + index][1],
              color: "#172B4D",
              fontSize: 16,
              fontWeight: 400,
              userSelect: "none",
            }}
          >
            {index}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: position["dl" + index][0],
              bottom: position["dl" + index][1],
            }}
          >
            <ReactSVG
              id={"dl" + index}
              src={svgList["dl" + index]}
              afterInjection={(svg) => {
                appliedTeeth.forEach(({ teeth, checked }) => {
                  if (teeth === "dl" + index) {
                    const path = svg.getElementsByTagName("path")[0]
                    path?.setAttribute("fill", "#01579B")
                    path?.setAttribute("stroke", "#D7535E")
                    const id = "dl" + index + checked
                    document.getElementById(id).style.display = "block"
                  }
                })
                teethAction("dl" + index + "box")
              }}
            />
            {dentalChartMenuList.map((item) => {
              const value = item.substring(
                item.lastIndexOf("(") + 1,
                item.length - 1,
              )
              return (
                <Box
                  component={"div"}
                  key={"dl" + index + value}
                  id={"dl" + index + value}
                  sx={{
                    position: "absolute",
                    display: "none",
                    color: "#FFF",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 500,
                    userSelect: "none",
                    marginTop: -0.5,
                  }}
                >
                  {value}
                </Box>
              )
            })}
          </Box>
        </Box>,
      )
    }
    return res
  }

  const getLowerRightTooth = () => {
    const res = []

    for (let index = 8; index > 0; index--) {
      res.push(
        <Box key={"dr" + index} id={"dr" + index + "box"}>
          <Box
            component="span"
            sx={{
              position: "absolute",
              right: number["dr" + index][0],
              bottom: number["dr" + index][1],
              color: "#172B4D",
              fontSize: 16,
              fontWeight: 400,
              userSelect: "none",
            }}
          >
            {index}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: position["dr" + index][0],
              bottom: position["dr" + index][1],
            }}
          >
            <ReactSVG
              id={"dr" + index}
              src={svgList["dr" + index]}
              afterInjection={(svg) => {
                appliedTeeth.forEach(({ teeth, checked }) => {
                  if (teeth === "dr" + index) {
                    const path = svg.getElementsByTagName("path")[0]
                    path?.setAttribute("fill", "#01579B")
                    path?.setAttribute("stroke", "#D7535E")
                    const id = "dr" + index + checked
                    document.getElementById(id).style.display = "block"
                  }
                })
                teethAction("dr" + index + "box")
              }}
            />
            {dentalChartMenuList.map((item) => {
              const value = item.substring(
                item.lastIndexOf("(") + 1,
                item.length - 1,
              )
              return (
                <Box
                  component={"div"}
                  key={"dr" + index + value}
                  id={"dr" + index + value}
                  sx={{
                    position: "absolute",
                    display: "none",
                    color: "#FFF",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 500,
                    userSelect: "none",
                    marginTop: -0.5,
                  }}
                >
                  {value}
                </Box>
              )
            })}
          </Box>
        </Box>,
      )
    }
    return res
  }

  return (
    <Box
      sx={{ width: 369, height: 528, padding: 3, position: "relative" }}
      id={"DentalChart"}
    >
      <ReactSVG
        id="uppergum"
        src={upperGum}
        style={{
          position: "absolute",
          left: 73,
          top: 56,
          width: 233,
          height: 203,
          flexShrink: 0,
        }}
      />
      <ReactSVG
        src={lowerGum}
        style={{ position: "absolute", left: 73, bottom: 56 }}
      />
      {getUpperLeftTooth()}
      {getUpperRightTooth()}
      {getLowerLeftTooth()}
      {getLowerRightTooth()}
    </Box>
  )
}
export default DentalChart
