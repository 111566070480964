import * as React from "react"
import {
  Box,
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Grid,
  Typography,
  Slider,
  Stack,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
} from "@mui/material"
import { FlowButton2, FlowTabs } from "../Custom/FlowCustom"
import CloseIcon from "@mui/icons-material/CloseOutlined"

import { Rnd } from "react-rnd"
import { useEffect, useState } from "react"

import { stagingManager } from "@ulab/wesmileclinical"
import { wasmModule } from "@ulab/wesmileclinical"
import { GlobalEvents, globalEvents } from "@ulab/wesmileclinical"
import type { IToothPercentInfo } from "@ulab/wesmileclinical"
import { stagingPercent } from "@ulab/wesmileclinical"

import { RootState } from "../../core/app/store"
import { useAppSelector, useAppDispatch } from "../../core/app/hooks"
import { setCurArch } from "../Wasm3DServiceSlice"
import { setCurJaw, setIsShow } from "./ManuStagingPercentSlice"
import { Disabled } from "../../ui-component/components/UButton/UButton.stories"

const ManuStagingPercent = (props) => {
  const { isShow, curJaw } = useAppSelector(
    (state: RootState) => state.manuStagingPercentSlice,
  )
  const { curArch, curView } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  const { curStage, stagingUpdate } = useAppSelector(
    (state: RootState) => state.autoStagingSlice,
  )
  const dispatch = useAppDispatch()

  // Check wht
  let upKFs = []
  let lowKFs = []
  const updateKFs = () => {
    upKFs = []
    let upNodes = stagingManager.wasmStageData.jointUpKeypoints
    for (let i = 0; i < upNodes.length; ++i) {
      if (upNodes[i].nodeType.length != 0) {
        let idx = upNodes[i].stepindex
        upKFs.push(idx)
      }
    }
    lowKFs = []
    let lowNodes = stagingManager.wasmStageData.jointLowerKeypoints
    for (let i = 0; i < lowNodes.length; ++i) {
      if (lowNodes[i].nodeType.length != 0) {
        let idx = lowNodes[i].stepindex
        lowKFs.push(idx)
      }
    }
  }

  const [isKeyFrame, setIsKeyFrame] = useState(false)
  const [isInitFinalKF, setIsInitFinalKF] = useState(false)
  const checkKeyFrame = () => {
    const isUp = curJaw == "upper"
    if (isUp) {
      const curJawStage = stagingManager.stageupIndex
      setIsInitFinalKF(
        curJawStage == 0 || curJawStage == stagingManager.stageUpnNumber,
      )
      const idx = upKFs.findIndex((elem) => {
        return curJawStage === elem
      })
      setIsKeyFrame(idx >= 0)
    } else {
      const curJawStage = stagingManager.stagelowIndex
      setIsInitFinalKF(
        curJawStage == 0 || curJawStage == stagingManager.stageLowNumber,
      )
      const idx = lowKFs.findIndex((elem) => {
        return curJawStage === elem
      })
      setIsKeyFrame(idx >= 0)
    }
  }

  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  const idx = Array(16)
    .fill(0)
    .map((_, i) => i + 1)

  const initPercentData = () => {
    const initdata: Record<number, IToothPercentInfo> = {}
    idx.map((id) => {
      initdata[id] = {
        enable: false,
        angle: 0,
        curStepIndex: 0,
        dist: 0,
        newStepNum: 0,
        percent: 0,
        tID: id,
      }
    })
    return initdata
  }

  const [stagePercentData, setStagePercentData] = useState<
    Record<number, IToothPercentInfo>
  >(initPercentData())
  const [isForwardOnly, setIsForwardOnly] = useState<boolean>(true)

  const [updateFlag, setUpdateFlag] = useState<boolean>(false)

  const ApplyChange = (curJaw) => {
    const isUp = curJaw == "upper"
    const curJawStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    // stagingManager.addNormalNode(isUp, curJawStage, false)
    stagingPercent.addOrUpdateKeyFrame(isUp ? 0 : 1, curJawStage, true)
    dispatch({ type: "autostaging/updateState" })
  }

  const AddKeyFrame = (curJaw) => {
    const isUp = curJaw == "upper"
    const curJawStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingManager.addNormalNode(isUp, curJawStage, false)
    dispatch({ type: "autostaging/updateState" })
  }

  const DelKeyFrame = (curJaw) => {
    const isUp = curJaw == "upper"
    const curJawStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingManager.deleteKeyNode(isUp, curJawStage)
    dispatch({ type: "autostaging/updateState" })
  }

  const setShowManuStaging = (isShow: boolean) => {
    dispatch(setIsShow(false))
  }

  const Top = () => {
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        columns={12}
        alignItems="center"
      >
        <Grid item xs={4}>
          <FormControl size="small">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={curJaw}
              value={curJaw}
              onChange={(_, value) => {
                dispatch(setCurJaw(value))
                dispatch(setCurArch(value == "upper" ? "up" : "low"))
              }}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="upper"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Upper</Typography>}
              />
              <FormControlLabel
                value="lower"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Lower</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {/* 
        <Grid item xs={4}>
          <FormControlLabel
            control={<Switch checked={isForwardOnly} />}
            onChange={(_, value) => {
              setIsForwardOnly(value)
            }}
            label={<Typography variant="body2">Forward Only</Typography>}
          />
        </Grid>*/}

        <Grid item xs={4}>
          <FlowButton2
            disabled={isInitFinalKF || !isKeyFrame}
            onClick={() => {
              ApplyChange(curJaw)
            }}
          >
            Apply
          </FlowButton2>
        </Grid>
        <Grid item xs={2}>
          <FlowButton2
            disabled={isKeyFrame}
            onClick={() => {
              AddKeyFrame(curJaw)
            }}
          >
            Add KF
          </FlowButton2>
        </Grid>
        <Grid item xs={2}>
          <FlowButton2
            disabled={isInitFinalKF || !isKeyFrame}
            onClick={() => {
              DelKeyFrame(curJaw)
            }}
          >
            Del KF
          </FlowButton2>
        </Grid>
      </Grid>
    )
  }
  const Left = () => {
    return (
      <Grid item xs={2}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2">Tooth</Typography>
          <Stack
            sx={{ height: 200 }}
            direction="column"
            justifyContent="space-between"
          >
            <Typography variant="body2" align="right">
              100%
            </Typography>
            <Typography variant="body2" align="right">
              50%
            </Typography>
            <Typography variant="body2" align="right">
              0%
            </Typography>
          </Stack>
          <Typography variant="body2">Dist(mm)</Typography>
          <Typography variant="body2">Angle(&deg;)</Typography>
        </Stack>
      </Grid>
    )
  }
  const OneTooth = (toothProps: IToothPercentInfo) => {
    return (
      <Grid item xs={1}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" align="center">
            {toothProps.tID}
          </Typography>
          <Slider
            size="small"
            sx={{ height: 200 }}
            orientation="vertical"
            min={0}
            max={100}
            valueLabelDisplay="auto"
            defaultValue={Number.parseInt(
              (toothProps.percent * 100).toFixed(0),
            )}
            disabled={!toothProps.enable || !isKeyFrame || isInitFinalKF}
            onChangeCommitted={(_, val) => {
              stagingPercent.setToothMove(toothProps.tID, (val as number) / 100)
              // ApplyChange(curJaw)
              const percentData = stagingPercent.getTeethPercentInfo(
                curJaw == "upper" ? true : false,
              )
              if (percentData) setStagePercentData(percentData)
            }}
          />
          <Typography variant="body2" align="center">
            {toothProps.dist == 0 ? 0 : toothProps.dist.toFixed(1)}
          </Typography>
          <Typography variant="body2" align="center">
            {toothProps.angle == 0 ? 0 : toothProps.angle.toFixed(1)}
          </Typography>
          {/* 
          <Typography variant="body2" align="center">
            {toothProps.newStepNum}
          </Typography>*/}
        </Stack>
      </Grid>
    )
  }
  const allTeeth = idx.map((id) => {
    const tID = id + (curJaw == "upper" ? 0 : 16)
    let percentinfo = stagePercentData[tID]
    if (!percentinfo)
      percentinfo = {
        enable: false,
        angle: 0,
        curStepIndex: 0,
        dist: 0,
        newStepNum: 0,
        percent: 0,
        tID: tID,
      }
    return <OneTooth key={id} {...percentinfo} />
  })
  const PercentAllTeeth = () => {
    return (
      <Grid container spacing={0.1} alignItems="center" columns={18}>
        <Left />
        {allTeeth}
      </Grid>
    )
  }

  interface IGroupOCParam {
    name: string
    unit: string
  }

  const GroupOCParams: IGroupOCParam[] = [
    { name: "Ant. Procl.", unit: "mm" },
    { name: "Ant. I/E.", unit: "mm" },
    { name: "Ant. Root Torq.", unit: "mm" },
    { name: "Post. Procl.", unit: "mm" },
    { name: "Post. I/E.", unit: "mm" },
    { name: "Post. Root Torq.", unit: "mm" },
  ]

  const OneGroupOCParam = (props) => {
    return (
      <>
        <Grid item xs={4}>
          <Typography variant="body2">{props.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {props.valueU.toString() + props.unit}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Slider
            size="small"
            valueLabelDisplay="auto"
            min={-2.0}
            max={2.0}
            step={0.1}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {props.valueL.toString() + props.unit}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Slider
            size="small"
            valueLabelDisplay="auto"
            min={-2.0}
            max={2.0}
            step={0.1}
          />
        </Grid>
      </>
    )
  }
  const allGroupOCParams = GroupOCParams.map((OCParam) => {
    return (
      <OneGroupOCParam
        name={OCParam.name}
        unit={OCParam.unit}
        valueU={22.3}
        valueL={11}
      />
    )
  })
  const GroupOverCorr = () => {
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        columns={18}
        alignItems="center"
      >
        <Grid item xs={4}></Grid>
        <Grid item xs={7}>
          <Typography sx={{ flex: 1, textAlign: "center" }} variant="body1">
            Upper Arch
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography sx={{ flex: 1, textAlign: "center" }} variant="body1">
            Lower Arch
          </Typography>
        </Grid>
        {allGroupOCParams}
      </Grid>
    )
  }

  useEffect(() => {
    if (!wasmModule.isInit) return
    if (isShow) {
      const percentData = stagingPercent.getTeethPercentInfo(
        curJaw == "upper" ? true : false,
      )
      if (percentData) setStagePercentData(percentData)
    } else {
      stagingPercent.close()
    }
  }, [isShow, curJaw])

  useEffect(() => {
    if (!wasmModule.isInit) return
    if (isShow) {
      let curJawNew = curArch == "up" ? "upper" : "lower"
      if (curJawNew != curJaw) {
        dispatch(setCurJaw(curJawNew))
      }
    } else {
      stagingPercent.close()
    }
  }, [curArch])

  useEffect(() => {
    if (!wasmModule.isInit || !isShow) return
    const percentData = stagingPercent.getTeethPercentInfo(
      curJaw == "upper" ? true : false,
    )
    if (percentData) setStagePercentData(percentData)
  }, [updateFlag])

  useEffect(() => {
    globalEvents.on(GlobalEvents.ON_STAGESTEP_CHANGED, () => {
      setUpdateFlag((pre) => !pre)
    })
  }, [])

  useEffect(() => {
    if (!wasmModule.isInit || !isShow) return
    console.log(
      "curJaw=",
      curJaw,
      "curStage=",
      curStage,
      "stageingUpdate=",
      stagingUpdate,
    )
    const percentData = stagingPercent.getTeethPercentInfo(
      curJaw == "upper" ? true : false,
    )
    if (percentData) {
      setStagePercentData(percentData)
      updateKFs()
      checkKeyFrame()
    }
  }, [curJaw, curStage, stagingUpdate])

  return isShow ? (
    <Rnd
      // onDrag={dragEventHandler}
      default={{
        x: 15,
        y: 50,
        width: 600,
        height: 300,
      }}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      bounds="window"
      // enableUserSelectHack={false}
      dragHandleClassName="my-drag-handle"
    >
      <Card>
        <CardHeader
          className="my-drag-handle"
          sx={{ backgroundColor: "lightskyblue", height: 30 }}
          action={
            <IconButton
              aria-label="settings"
              sx={{ width: 30, height: 30, top: -10 }}
              onClick={() => {
                setShowManuStaging(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant: "body1" }}
          title="Key Frame Editor"
        />
        <CardContent>
          <Top />
          <FlowTabs labels={["Percentage", "Group Over Corr."]}>
            <PercentAllTeeth />
            <GroupOverCorr />
          </FlowTabs>
        </CardContent>
      </Card>
    </Rnd>
  ) : (
    <></>
  )
}
export default ManuStagingPercent

/*
function mapStateToProps(state) {
  const { isShow, curJaw } = state.manustagingpercent
  const { arch } = state.mainModel // current arch and view
  const {
    vtkmodel: { stageData },
  } = state.patients
  return { isShow, curJaw, stageData, arch }
}

export default connect(mapStateToProps)(ManuStagingPercent)
*/
