import type { FC } from "react"
import * as React from "react"

import { useEffect, useRef } from "react"

import { Stack, Grid, Typography, Divider } from "@mui/material"

import { FlowButton, FlowBtnGroup } from "../../Custom/FlowCustom"

import { wasmModule } from "@ulab/wesmileclinical"
import { attachmentModule } from "@ulab/wesmileclinical"

const SimpleFeatures = (props) => {
  const syncULBtns = [
    { label: "None", name: "SyncVelRestore" },
    { label: "Sync Vel", name: "SyncVelSync" },
    { label: "Passive", name: "SyncVelPassive" },
  ]

  const attBtns = [
    { label: "Add all", name: "AttAutoAll" },
    { label: "Remove", name: "AttRemoveAll" },
  ]

  const onClickAttBtn = (cmd) => {
    console.log("Auto Att button " + cmd + " is clicked.")
    if (!wasmModule) {
      console.log("wasmModule null ptr")
      return
    }
    attachmentModule.openAttachmentModule(true)
    if (cmd == "AttAutoAll") attachmentModule.addAllAttachment()
    else if (cmd == "AttRemoveAll") attachmentModule.deleteAllAttachment(0)
    attachmentModule.openAttachmentModule(false)
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography>Attachment</Typography>
      <FlowBtnGroup
        lineLabel="Auto"
        btnGroup={attBtns}
        onButtonClick={onClickAttBtn}
        btnW={3}
      ></FlowBtnGroup>
      <Divider />
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Typography>Manual Attachment </Typography>
        </Grid>
        <Grid item xs={3}>
          <FlowButton>Add A</FlowButton>
        </Grid>
        <Grid item xs={3}>
          <FlowButton>Add B</FlowButton>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default SimpleFeatures
