import { Event } from "./event";

export const globalEvents = new Event();

export enum GlobalEvents {
  /**
   * args: CaseInfo
   */
  OPENCASE_DONE = "OpenCaseDone",
  CLOSE_CASE = "CloseCase",

  ON_ATTACHMENT_BUTTON_CLICKED = "OnAttachMentBtnClicked",

  TOOTH_CORRECTION_TOGGLE_CLICKED = "OnToothCorrectionToggleClicked",

  /**
   * params =
   * 'trimline' | 'scissors'
   */
  TOOTH_CORRECTION_TRIMMING_CLICKED = "OnToothCorrectionTrimmingClicked",

  /**
   * params = 
   *  export enum EInoutType {
        Select = 'select',
        Deform = 'deform',
        Smooth = 'smooth',
        Reset = 'reset',
        BackOneStep = 'backonestep',
      }
   */
  TOOTH_CORRECTION_INOUT_CLICKED = "OnToothCorrectionInoutClicked",

  /**
   * 0-1
   */
  TOOTH_CORRECTION_INOUT_SMOOTHMESH = "ToothCorrectionInoutSmoothMesh",

  /**
   * export enum ERemovalType {
      Magicwand = 'magicwandtool',
      Pen = 'pentool',
      TrimAndFill = 'tftool',
      Tooth = 'toothtool',
      Reset = 'reset',
      Back = 'back',
    }
   */
  TOOTH_CORRECTION_REMOVAL_CLICKED = "ToothCorrectionInoutRemoval",

  // - trimming
  ON_TRIMING_CLICKED = "OnTrimmingClicked",
  ON_TRIMING_MODE_CHANGED = "OnTrimingModeChanged",
  ON_TRIMMING_DRAW = "OnTrimmingDraw",
  ON_TRIMMING_CUT = "OnTrimmingCut",
  ON_TRIMMING_UNDO = "OnTrimmingUndo",
  ON_TRIMMING_CLEAR = "OnTrimmingClear",

  // staging controller
  /**
   * params = { upStep: up, downStep: down }
   */
  ON_STAGESTEP_CHANGED = "OnStageStepChanged",
  /**
   * ViewType
   */
  VIEW_SWITCH_TYPE = "ViewSwitchType",
}
