import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { LoginCallback } from "@okta/okta-react"

import LandingLayout from "../containers/LandingLayout/LandingLayout"
import MainLayout from "../containers/MainLayout/MainLayout"
import AuthModule from "../modules"
import ClinicalModule from "../modules/Clinical/Clinical.module"
import PatientModule from "../modules/Patient/Patient.module"
import RecordsModule from "../modules/Records/Records.module"
import SettingsModule from "../modules/Settings/Settings.module"
import Confirmation from "../modules/Uassist/Summary/Confirmation"
import UassistModule from "../modules/Uassist/Uassist.module"
import Auth from "../pages/Auth"
import Clinical from "../pages/Clinical"
import PageNotFound from "../pages/PageNotFound"
import Patients from "../pages/Patient"
import Records from "../pages/Records"
import Settings from "../pages/Settings"
import Uassist from "../pages/Uassist"
import { USmileUIcomponents } from "../ui-component"

import SecureRoute from "./SecureRoute"

const AppRoutes: FC = () => {
  return (
    <Routes>
      {/* Patient routes */}
      <Route path={"/"} element={<MainLayout />}>
        <Route element={<SecureRoute />}>
          <Route element={<Patients />}>
            <Route path={"patients/*"} element={<PatientModule />} />
          </Route>
          <Route element={<Records />}>
            <Route
              path={"records/patient/:patientId/case/:caseId/*"}
              element={<RecordsModule />}
            />
          </Route>
          <Route element={<Clinical />}>
            <Route
              path={"clinical/patient/:patientId/case/:caseId/*"}
              element={<ClinicalModule />}
            />
          </Route>
          <Route element={<Settings />}>
            <Route path={"settings/*"} element={<SettingsModule />} />
          </Route>
          <Route element={<Uassist />}>
            <Route
              path={"uassist/patient/:patientId/case/:caseId/*"}
              element={<UassistModule />}
            />
            <Route
              path={
                "uassist/patient/:patientId/case/:caseId/summary/confirmation"
              }
              element={<Confirmation />}
            />
          </Route>
        </Route>
        <Route path={"/"} element={<Navigate to={"/patients"} replace />} />
      </Route>

      {/* login routes */}
      <Route element={<LandingLayout />}>
        <Route element={<Auth />}>
          <Route path={"auth/*"} element={<AuthModule />} />
        </Route>
        <Route path={"*"} element={<PageNotFound />} />
      </Route>
      <Route path={"login/callback"} element={<LoginCallback />} />
      <Route path={"/ex"} element={<USmileUIcomponents />} />
    </Routes>
  )
}

export default AppRoutes
