import { createAsyncThunk } from "@reduxjs/toolkit"

import { uassistFormParams } from "./uassist.types"
import { uassistAddformService } from "./uassistform.service"

export const uassistAddform = createAsyncThunk(
  "uassistFormService/uassistAddform",
  async (
    { patientId, caseId, payload }: uassistFormParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return uassistAddformService(orgId, patientId, caseId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
